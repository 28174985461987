export interface MemberPreferences {
    AutoMuck: boolean;
    JumpToTable: boolean;
    RunItTwice: MemberPreferencesRunItTwice;
    PlayerNotes;
    PlayerColor;
}


export enum MemberPreferencesRunItTwice {
    ON = 1,
    OFF = 0,
    ASK = 2
}