import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  @Input() title: string;
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeIconClick() {
    this.close.emit();
  }

}
