import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthService } from '../../../../../auth/auth.service';
import { GameControlsBase } from '../../game-controls.base';

@Component({
  selector: 'app-game-controls-mobile-landscape',
  templateUrl: './game-controls-mobile-landscape.component.html',
  styleUrls: ['./game-controls-mobile-landscape.component.scss']
})
export class GameControlsMobileLandscapeComponent extends GameControlsBase implements OnInit, OnChanges {

  @Input() showChatBox: boolean;
  @Output() gameControlsAdditionalDataOnClickOutside = new EventEmitter<void>();

  constructor(
    public authService: AuthService,
    ) {
    super();
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (!changes.betControls.currentValue.showSlider) { this.showBetAction = false }
    } catch (error) {
      this.showBetAction = false
    }
  }

  gameControlsAdditionalDataClickOutside() {
    this.gameControlsAdditionalDataOnClickOutside.emit()
  }
}
