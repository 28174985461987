import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { NotificationsService } from '../../../core/notifications/notifications.service';
import { ManagerService } from '../../../core/services/manager.service';
import { Currency } from '../../../shared/enums';
import { User } from '../../../shared/models/user';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';

@Component({
  selector: 'app-dialog-casinos-info',
  templateUrl: './dialog-casinos-info.component.html',
  styleUrls: ['./dialog-casinos-info.component.scss']
})
export class DialogCasinosInfo implements OnInit {

  user: User;
  buyInAmount: number;
  casinosInfo: any[] = [];
  selected: any = null;

  constructor(
    public dialogRef: MatDialogRef<DialogCasinosInfo>,
    @Inject(MAT_DIALOG_DATA) public data: { redirectUrl: string },
    public authService: AuthService,
    private notificationService: NotificationsService,
    public managerService: ManagerService
  ) {
    this.casinosInfo = this.managerService.casinosInfo.slice() // COPY
  }

  ngOnInit(): void {
    this.managerService.user$.pipe(take(1)).subscribe({
      next: (user) => {
        this.user = user
      }
    })
  }

  select(casinoInfo: any) {
    console.log("@selected", casinoInfo)
    if ((casinoInfo.Parameters.buyInAmount && casinoInfo.Parameters.buyInAmount === '##') ||
      casinoInfo.Parameters.BuyInAmount && casinoInfo.Parameters.BuyInAmount === '##'

    ) {
      this.selected = casinoInfo
    } else if (!this.selected) {
      this.dialogRef.close({ buyInAmount: null, product: casinoInfo.Parameters.product });
    } else {
      this.dialogRef.close({ buyInAmount: null, product: this.selected.Parameters.product });
    }
  }

  ok() {
    const user = this.authService.user
    const availableMoney = CurrencyDeviderPipe.prototype.transform(user.playerBalance[user.selectedCurrency.Id]?.availableMoney, user.selectedCurrency);

    if (user.selectedCurrency.Iso !== 'USD') {
      this.notificationService.showError(`Please select USD currency`)
    } else if (availableMoney < this.buyInAmount) {
      this.notificationService.showError(`You don't have enough available money`)
    } else {
      this.dialogRef.close({ buyInAmount: this.buyInAmount, product: this.selected.Parameters.product });
    }

  }


  cancel() {
    this.selected = null;
  }

  close() {
    this.dialogRef.close();
  }


}
