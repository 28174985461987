import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-leave-table',
  templateUrl: './dialog-leave-table.component.html',
  styleUrls: ['./dialog-leave-table.component.scss']
})
export class DialogLeaveTableComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogLeaveTableComponent>) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  leaveSeat() {
    this.dialogRef.close({ seat: true });
  }

  leaveTable() {
    this.dialogRef.close({ table: true });
  }
}
