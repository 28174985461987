import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-shop',
  templateUrl: './dialog-shop.component.html',
  styleUrls: ['./dialog-shop.component.scss']
})
export class DialogShopComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogShopComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }
  ngOnInit(): void {
  }


  cancel() {
    this.dialogRef.close();
  }

  onBuy() {
    if (this.dialogData?.type === 1 && this.dialogData?.data?.Cost > this.dialogData?.playerBalance[this.dialogData?.selectedCurrency?.Id]?.availableMoney) {
      return
    } else if (this.dialogData?.type === 2 && this.dialogData?.data?.EmotikenValue > this.dialogData?.playerBalance[this.dialogData?.emotikenCurrency?.Id]?.availableMoney) {
      return
    }
    this.dialogRef.close(true)
  }
}
