import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from './auth/auth-store/auth.reducer';
import * as fromNav from './main/nav/nav-store/nav-store.reducer';
import * as fromActiveGames from './main/game/store/game-store.reducer';
import * as fromTournaments from './main/tournament/store/tournaments.reducer';
import * as fromLobby from './main/lobby/lobby-store/lobby.reducer';

export interface State {
    auth: fromAuth.State;
    nav: fromNav.State;
    activeGames: fromActiveGames.State;
    tournaments: fromTournaments.State;
    lobby: fromLobby.State;
}

export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    nav: fromNav.navReducer,
    activeGames: fromActiveGames.activeGamesReducer,
    tournaments: fromTournaments.tournamentsReducer,
    lobby: fromLobby.reducer,
};
