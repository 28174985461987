import { Component, OnInit, Input } from '@angular/core';
import { PlayerStats } from '../../../../../shared/models/player-stats';

@Component({
  selector: 'app-game-stats',
  templateUrl: './game-stats.component.html',
  styleUrls: ['./game-stats.component.scss']
})
export class GameStatsComponent implements OnInit {

  @Input() playerStats: PlayerStats;

  constructor() { }

  ngOnInit() {
  }

}
