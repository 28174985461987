import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Card } from '../../helpers/card';
import { cardDecoder } from '../../../../shared/helpers/card-decoder';

@Component({
  selector: 'app-player-replace-cards',
  templateUrl: './player-replace-cards.component.html',
  styleUrls: ['./player-replace-cards.component.scss']
})
export class PlayerReplaceCardsComponent implements OnInit {

  private playerId: number;
  public cards: Card[];
  public cardsToRemove: Card[] = [];
  public cardsToRemoveIndexes: number[] = [];
  public cardsString: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<PlayerReplaceCardsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { playerId: number, timeToPlay: number, playerCards: Card[] }
  ) {
    this.playerId = data.playerId;
    this.cards = data.playerCards;

    this.cards.forEach(card => {
      this.cardsString.push(cardDecoder(card.cardData.Suit, card.cardData.Number));
    });
  }

  ngOnInit() {
  }

  selectCard(index: number, card: string) {

    const image = document.getElementById(card);
    image.style.filter = 'opacity(30%)';

    for (let i = 0; i < this.cardsToRemoveIndexes.length; i++) {
      if (index === this.cardsToRemoveIndexes[i]) {
        this.cardsToRemoveIndexes.splice(i, 1);
        this.cardsToRemove.splice(i, 1);
        image.style.filter = 'opacity(100%)';
        return;
      }
    }

    this.cardsToRemoveIndexes.push(index);
    this.cardsToRemove.push(this.cards[index]);

  }

  discardCards() {
    this.cardsToRemoveIndexes.sort();
    this.dialogRef.close({
      idPlayer: this.data.playerId,
      cardsIndexes: this.cardsToRemoveIndexes,
      cardsToRemove: this.cardsToRemove
    });
  }

  close() {
    this.dialogRef.close();
  }

}
