import * as PIXI from 'pixi.js';

import { Point } from '../models';
import { Vector } from './vector';

export class KamikazeCard {
    container;
    startPosition: Point;
    targetPosition: Point;
    currentPosition: Point;
    shouldMove = false;
    cardSprite;
    movingSpeed = 0;
    timeToTraget = 0.3;
    movingDirection: Point;
    oldDistance = 9999;
    textures;

    constructor(textures, newStartPosition: Point, newTargetPosition: Point) {
        this.textures = textures;
        this.startPosition = newStartPosition;
        this.targetPosition = newTargetPosition;
        this.currentPosition = { x: this.startPosition.x, y: this.startPosition.y };

        this.container = new PIXI.Container();
        this.container.position.set(this.currentPosition.x, this.currentPosition.y);
        this.container.scale.set(0.5);

        this.cardSprite = new PIXI.Sprite(this.textures['backCard'].texture);
        this.container.addChild(this.cardSprite);

        this.setTarget(this.targetPosition);
    }

    update(dt) {
        if (this.shouldMove) {
            this.move(dt);
        }
    }

    move(dt) {
        this.currentPosition.x += dt / 1000 * this.movingSpeed * this.movingDirection.x;
        this.currentPosition.y += dt / 1000 * this.movingSpeed * this.movingDirection.y;
        const newDistance = Vector.prototype.distace(this.targetPosition, this.currentPosition);
        if (this.oldDistance <= newDistance) {
            this.shouldMove = false;
            this.selfDistruct();
        }
        this.container.position.set(this.currentPosition.x, this.currentPosition.y);
        this.oldDistance = newDistance;
    }

    setTarget(targetPosition: Point) {
        this.targetPosition = targetPosition;
        const distanceToTarget = Vector.prototype.distace(targetPosition, this.currentPosition);
        this.movingSpeed = distanceToTarget / this.timeToTraget;
        this.movingDirection = Vector.prototype.direction(targetPosition, this.currentPosition);
        this.shouldMove = true;
    }

    selfDistruct() {
        this.container.parent.removeChild(this.container);
    }
}
