import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Howler } from 'howler';
// import { Store } from '@ngrx/store';

import { MemberProfile } from '../../../../shared/models';
import { AppActions } from '../../../../app.actions';
import { State } from '../../../../app.reducers';

@Component({
  selector: 'app-sound-control',
  templateUrl: './sound-control.component.html',
  styleUrls: ['./sound-control.component.scss']
})
export class SoundControlComponent implements OnInit {

  // ###############################################################################
  // REMOVE ###############################################################################
  // ###############################################################################
  // NE KORISTI SE - APRIL

  public volume = Howler.volume();

  constructor(
    public dialogRef: MatDialogRef<SoundControlComponent>,
    @Inject(MAT_DIALOG_DATA) public memberProfile: MemberProfile,
    // public store: Store<State>,
  ) { }

  ngOnInit() {
  }

  public onSliderChange(volumeValue) {
    Howler.volume(volumeValue.toFixed(1));
    try {
      localStorage.setItem('top-volume-settings', volumeValue.toFixed(1));
    } catch (e) {
    }
  }

  public timeBankStatusChange(timeBank: boolean) {
    try {
      localStorage.setItem('top-timebank-sound', String(timeBank));
      this.memberProfile.PlayTimeBankSound = timeBank;
      //  this.store.dispatch(new AppActions.AuthAction.MemberProfileReceived(this.memberProfile));
    } catch (e) {
    }
  }

}
