import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import fscreen from 'fscreen';
import * as MobileDetect from 'mobile-detect/mobile-detect';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit, OnDestroy {

  environment = environment;

  mobileDetect: MobileDetect;
  hasFullscreenSupport: boolean;
  isFullscreen: boolean;
  isAndroid: boolean;

  constructor() {
    if (this.environment.production) {
      this.mobileDetect = new MobileDetect(window.navigator.userAgent);
      this.hasFullscreenSupport = fscreen.fullscreenEnabled;
    }
  }

  ngOnInit(): void {
    if (this.environment.production) {
      this.isAndroid = this.mobileDetect.os() === 'AndroidOS'
      if (this.isAndroid && this.hasFullscreenSupport) {
        fscreen.addEventListener('fullscreenchange', () => {
          this.isFullscreen = (fscreen.fullscreenElement !== null);
        }, false);
      }
    }
  }

  ngOnDestroy() {
    if (this.environment.production) {
      if (this.hasFullscreenSupport) {
        fscreen.removeEventListener('fullscreenchange');
      }
    }
  }


  openFullscreen() {
    fscreen.requestFullscreen(document.body);
  }
}
