import { Component, OnInit, Input, ElementRef, HostListener, Output, EventEmitter, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { GameHistory } from '../../../../../shared/models/game-history';
import { PlayerStats } from '../../../../../shared/models/player-stats';
import { Chat } from '../../../../../shared/models/chat';
import { environment } from '../../../../../../environments/environment';
import { Settings } from '../../../../../shared/models';
import { ManagerService } from '../../../../../core/services/manager.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataManagerService } from '../../../../../core/services/data-manager.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfigService } from '../../../../../core/services/config.service';

enum emoticonTab {
  HIDDEN = 20,
  TEXT,
  GIF
}
@Component({
  selector: 'app-game-controls-additional-data',
  templateUrl: './game-controls-additional-data.component.html',
  styleUrls: ['./game-controls-additional-data.component.scss']
})
export class GameControlsAdditionalDataComponent implements OnInit, OnDestroy {

  @Input() tableId: number;
  @Input() gameHistories: Array<GameHistory> = [];
  @Input() playerStats: PlayerStats;
  @Input() chatMessages: Array<Chat>;
  @Input() disableChat: boolean;
  @Output() closeControls = new EventEmitter<void>();

  environment = environment;
  selectedTab = 'CHAT';

  settings: Settings;
  destroy$ = new Subject<boolean>();

  enumEmoticonTab = emoticonTab;

  emoticonsTab = emoticonTab.HIDDEN
  reactions: any = []
  gifs: any = []

  config;
  constructor(
    private managerService: ManagerService,
    private dataManager: DataManagerService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService,

  ) { 
    this.config = this.configService.config;
  }

  ngOnInit(): void {

    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          this.settings = settings;
          if (this.settings?.ChatDisabled === 'True') {
            this.selectedTab = 'INFO'
          }
        }
      })

    this.managerService.emoticonsInfo$.pipe(take(1)).subscribe(results => {

      this.gifs = results.filter(el => el.IsLocked === 0 && el.Type === 'gif')//.map(el=>{el.ImageUrl.replace('\\\\','/'); return el; })
      this.reactions = results.filter(el => el.IsLocked === 0 && el.Type === 'text')

    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setEmoticonsTab(tab: emoticonTab) {
    if (this.emoticonsTab === tab) {
      this.emoticonsTab = emoticonTab.HIDDEN
    } else {
      this.emoticonsTab = tab
    }
  }

  sendChatReaction(reaction: any) {
    this.dataManager.sendChatReaction(this.tableId, reaction?.Id);

    if (this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait)) {
      this.setEmoticonsTab(emoticonTab.HIDDEN)
      this.closeControls.emit()
    } else {
      this.setEmoticonsTab(emoticonTab.HIDDEN)
    }
  }
}
