import { Point } from '../../models';

export const cardFirstPositionLeft: Point = { x: 9, y: -5 };
export const cardLastPositionLeft: Point = { x: 190, y: -5 };

export const cardFirstPositionRight: Point = { x: 130, y: 105 };
export const cardLastPositionRight: Point = { x: 340, y: 105 };

// export const cardFirstPositionUnlimitedRight: Point = { x: -9, y: 57 };
// export const cardLastPositionUnlimitedRight: Point = { x: 134, y: 57 };

// export const cardFirstPositionUnlimitedLeft: Point = { x: 206, y: 57 };
// export const cardLastPositionUnlimitedLeft: Point = { x: 349, y: 57 };

// export const hiddenCardPositionsUnlimitedLeft: Point[] = [
//     { x: 72, y: 86 },
//     { x: 59, y: 97 },
//     { x: 47, y: 107 },
//     { x: 35, y: 118 },
//     { x: 24, y: 129 },
// ];

// export const hiddenCardPositionsUnlimitedRight: Point[] = [
//     { x: 273, y: 86 },
//     { x: 287, y: 97 },
//     { x: 298, y: 107 },
//     { x: 310, y: 118 },
//     { x: 319, y: 129 },
// ];

export const cardWidth = 86; // ✅
export const cardHeight = 122; // ✅

export const cardScaleStandard = 1.15;

// export const cardScaleUnlimited = 1.15;
// export const cardScaleUnlimitedHoldEm = 1.25;
// export const hiddenCardScaleUnlimited = 0.75;

export const cardScaleCharrig = 1.25;

// export const hiddenCardRotationUnlimited = -0.785; // -45 deg
