import * as PIXI from 'pixi.js';
import { Point } from '../models';
import { Vector } from './vector';

export class FoldedCards {
    container: PIXI.Container;
    private cards: PIXI.Sprite[] = [];
    private backCardTexture: PIXI.Texture;
    private spawnRadius = 20;
    private randomAngle = 30 * 0.0174;
    id: number;
    cardsAlpha = 1;
    private fadeOutSpeed = 0.001;
    private delayTimer = 1000;

    constructor(textures, position: Point, numberOfCards: number) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);
        this.backCardTexture = textures['backCard'].texture;
        this.createCards(numberOfCards);
    }

    private createCards(numOfCards: number) {
        for (let i = 0; i < numOfCards; i++) {
            const card = new PIXI.Sprite(this.backCardTexture);
            const pos = Vector.randomOne();
            const rot = this.randomAngle * Math.random() * (Math.random() > 0.5 ? 1 : -1);
            pos.x *= this.spawnRadius;
            pos.y *= this.spawnRadius;
            card.position.set(pos.x, pos.y);
            card.rotation = rot;
            card.scale.set(0.3);
            this.container.addChild(card);
        }

    }

    fadeOutAnim(dt) {
        if (this.delayTimer <= 0) {
            this.cardsAlpha -= this.fadeOutSpeed * dt;
            if (this.cardsAlpha < 0) { this.cardsAlpha = 0; }
            this.container.alpha = this.cardsAlpha;
        } else {
            this.delayTimer -= dt;
        }
    }
}
