import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Currency } from '../../enums';

@Component({
  selector: 'app-currency-switcher',
  templateUrl: './currency-switcher.component.html',
  styleUrls: ['./currency-switcher.component.scss']
})
export class CurrencySwitcherComponent implements OnInit {

  public Currency = Currency;

  @Input() selectedCurrency: Currency;
  @Output() change: EventEmitter<Currency> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  changeCurrency(currency: Currency) {
    this.change.emit(currency);
  }

}
