import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NoteColors } from '../../../../shared/enums/note-color';
import { DialogPlayerSpellsComponent } from '../dialog-player-spells/dialog-player-spells.component';

@Component({
  selector: 'app-player-note',
  templateUrl: './player-note.component.html',
  styleUrls: ['./player-note.component.scss']
})
export class PlayerNoteComponent implements OnInit {

  public note: string;
  public playerName: string;


  nav: string = 'PLAYER_NOTE'
  enumNoteColors = NoteColors
  noteColors: string[] = Object.keys(this.enumNoteColors).slice(Object.keys(this.enumNoteColors).length / 2)
  color: string;

  mode = 'EDIT'

  constructor(
    public dialogRef: MatDialogRef<PlayerNoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { playerId: number, note: string, color: string, playerName: string, spells: any[] },
    public dialog: MatDialog,

  ) {
    this.note = { ...data }.note;
    this.playerName = data.playerName;
    this.color = data.color;

    if (this.note && this.note !== '') {
      this.mode = 'VIEW'
    }
    // if (!this.note && !!this.color) {
    //   this.nav = 'COLORS'


    // }

    if (!this.color) { this.color = this.enumNoteColors[0] }
  }

  ngOnInit() {
  }

  clickAddNote() {
    this.dialogRef.close({ playerId: this.data.playerId, note: this.note, color: this.color });
  }

  clearAll() {
    // this.note = ''
    // this.color = ''

    this.dialogRef.close({ playerId: this.data.playerId, note: '', color: '' });
  }

  close() {
    this.dialogRef.close();
  }

  viewSpells() {
    this.dialog.open(DialogPlayerSpellsComponent, {
      width: '300px',
      position: { bottom: '75px' },
      data: this.data
    })
  }
}
