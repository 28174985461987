import { Component, OnInit } from '@angular/core';
import { DataManagerService } from '../../../core/services/data-manager.service';

export enum VerificationDocumentType {
  GOVERNMENT = 1,
  UTILITY_BILL = 2,
  PAN_CARD = 3,
}

@Component({
  selector: 'app-verification-documents',
  templateUrl: './verification-documents.component.html',
  styleUrls: ['./verification-documents.component.scss']
})

export class VerificationDocumentsComponent implements OnInit {

  fileGovId: File;
  fileUtilityBill: File;
  filePan: File;

  constructor(
    private dataManager: DataManagerService
  ) { }

  ngOnInit(): void {
  }


  onChangeGovermentId(event) {
    this.fileGovId = event.target.files[0];
  }

  onChangeUtilityBill(event) {
    this.fileUtilityBill = event.target.files[0];
  }

  onChangePanCard(event) {
    this.filePan = event.target.files[0];
  }

  upload() {
    if (this.fileGovId !== undefined) {
      this.dataManager.uploadImage(this.fileGovId, VerificationDocumentType.GOVERNMENT);
    }
    if (this.fileUtilityBill !== undefined) {
      this.dataManager.uploadImage(this.fileUtilityBill, VerificationDocumentType.UTILITY_BILL);
    }
    if (this.filePan !== undefined) {
      this.dataManager.uploadImage(this.filePan, VerificationDocumentType.PAN_CARD);
    }
  }
}
