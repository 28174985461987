export enum GameStatus {
    WaitingPlayers = 1, // then place dealer, take blinds, the distribute 2 cards to each player
    PreFlop = 2, // then distribute the 3 flop card
    Flop = 3, // then distribute the 1 card
    Turn = 4, // then distribute the 1 last card
    River = 5, // last bidding round, then the game end
    Showdown = 6, // winners
    SeventhStreet = 8, // extra bidding for 7 card stud
    TripleDrawBettingRound1 = 9,
    ReplaceCards1 = 11,
    TripleDrawBettingRound2 = 12,
    ReplaceCards2 = 13,
    TripleDrawBettingRound3 = 14,
    ReplaceCards3 = 15,
    TripleDrawBettingRound4 = 16,
}
