
import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';
import { AnchorPosition } from './const';

export class AnimatedSexyDealer {
    private containerSize: Size = { width: 172, height: 256 };

    container: PIXI.Container;
    private dealAnim: PIXI.AnimatedSprite;
    private idleSprite: PIXI.Sprite;
    private animTextures: PIXI.Texture[] = [];

    constructor(textures) {
        this.container = new PIXI.Container();
        for (let index = 0; index < 4; index++) {
            this.animTextures.push(textures['animDealer'].textures['f' + (index + 1) + '.png']);
        }

        this.idleSprite = new PIXI.Sprite(textures['animDealer'].textures['idle.png']);
        this.container.addChild(this.idleSprite);

        this.dealAnim = new PIXI.AnimatedSprite(this.animTextures);
        this.dealAnim.onComplete = () => { this.onAnimDone(); };
        this.dealAnim.visible = false;
        this.dealAnim.loop = false;
        this.dealAnim.animationSpeed = 0.1;
        this.container.addChild(this.dealAnim);
    }

    dealCard() {
        if (this.dealAnim.playing) { return; }
        this.idleSprite.visible = false;
        this.dealAnim.visible = true;
        this.dealAnim.play();
    }

    private onAnimDone() {
        this.idleSprite.visible = true;
        this.dealAnim.visible = false;
    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {
            this.container.position.set(newPosition.x - this.containerSize.width / 2,
                newPosition.y - this.containerSize.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x, newPosition.y);
        }
    }
}
