import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';

export const containerSize: Point = { x: 309, y: 314 };

export const infoBoxPositionLeft: Point = { x: 0, y: 57 }; // top-left
export const infoBoxPositionRight: Point = { x: 0, y: 57 }; // top-left
export const infoBoxPositionUnlimitedLeft: Point = { x: 1, y: 66 }; // top-left
export const infoBoxPositionUnlimitedRight: Point = { x: 9, y: 66 }; // top-left

export const avatarMaskPositionLeft: Point = { x: 278, y: 131 }; // center
export const avatarMaskPositionRight: Point = { x: 72, y: 131 }; // center
export const avatarMaskRadius = 62;

export const avatarPositionLeft: Point = { x: 275, y: 132 }; // top-left
export const avatarPositionRight: Point = { x: 76, y: 132 }; // top-left

export const namePositionLeft: Point = { x: 113, y: 107 }; // center
export const namePositionRight: Point = { x: 236, y: 107 }; // center
export const namePositionLeftUnlimited: Point = { x: 207, y: 107 }; // center-right
export const namePositionRightUnlimited: Point = { x: 139, y: 107 }; // center-left
export const nameDefaultStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Saira Semi Condensed',
    fontSize: 44,
    fontWeight: '500',
    fill: '#ffffff'
});
export const nameActiveStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Saira Semi Condensed',
    fontSize: 44,
    fontWeight: '500',
    fill: '#ffffff'
});

export const balancePositionLeft: Point = { x: 113, y: 152 }; // center
export const balancePositionRight: Point = { x: 236, y: 152 }; // center
export const balancePositionLeftUnlimited: Point = { x: 199 - 14, y: 152 }; // center-right
export const balancePositionRightUnlimited: Point = { x: 149 + 14, y: 152 }; // center-left
export const balanceDefaultStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 32,
    fill: '#f8f022'
});
export const balanceActiveStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 32,
    fill: '#f8f022',
});
export const balanceDefaultStyleAdvanced: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 32,
    fill: '#00ff24',
});
export const balanceActiveStyleAdvanced: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 32,
    fill: '#00ff24',
});
export const balanceDefaultStyleUnlimited: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial', fontSize: 38, fill: '#dabb60', fontWeight: 'bold'
});
export const balanceActiveStyleUnlimited: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 38,
    fill: '#dabb60',
    fontWeight: 'bold',
});

export const timerPositionLeftStandard: Point = { x: 124, y: 174 }; // center
export const timerPositionRightStandard: Point = { x: 228, y: 174 }; // center

export const timerPositionLeftAdvanced: Point = { x: 124, y: 131 }; // center
export const timerPositionRightAdvanced: Point = { x: 225, y: 131 }; // center

export const timerPositionLeftUnlimited: Point = { x: 104, y: 193 + 5 }; // center
export const timerPositionRightUnlimited: Point = { x: 234, y: 193 + 5 }; // center

export const dealerPositionRight: Point = { x: 30, y: 180 };
export const dealerPositionLeft: Point = { x: 323, y: 180 };

export const dealerPositionRightUnlimited: Point = { x: 296, y: 118 };
export const dealerPositionLeftUnlimited: Point = { x: 45, y: 118 };

export const timebankStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 18,
    fill: '#ffffff',
    fontWeight: 'bold',
});
export const timebankTimerStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 40,
    fill: '#ffffff',
    fontWeight: 'bold'
});
export const timebankTextPositionLeft: Point = { x: 175, y: 203 }; // center
export const timebankTextPositionRight: Point = { x: 175, y: 203 }; // center

export const timebankTextPositionLeftUnlimited: Point = { x: 114, y: 210 + 14 }; // center
export const timebankTextPositionRightUnlimited: Point = { x: 238, y: 210 + 14 }; // center

export const timebankTimerTextPositionLeft: Point = { x: 278, y: 131 }; // center
export const timebankTimerTextPositionRight: Point = { x: 73, y: 131 }; // center

export const starsPositionsLeft: Point[] = [
    { x: 317, y: 88 },
    { x: 327, y: 101 },
    { x: 334, y: 117 }, // center
    { x: 334, y: 135 },
    { x: 331, y: 153 },
];

export const starsPositionsRight: Point[] = [
    { x: 32, y: 88 },
    { x: 22, y: 101 },
    { x: 16, y: 117 },
    { x: 15, y: 135 },
    { x: 18, y: 153 },
];

export const starsColors: number[] = [
    0x874b28,
    0xd8d6d6,
    0xfee601,
    0x92d1fd,
    0xfd000a
];

export const handTypesText: string[] = [
    'Hidden',
    'High',
    'Pair',
    'Two Pairs',
    '3 of a kind',
    'Straight',
    'Flush',
    'Full House',
    '4 of a kind',
    'Straight Flush',
];

export const chatBubblePositionLeft: Point = { x: 260, y: -50 }; // top-left
export const chatBubblePositionRight: Point = { x: 260, y: -50 }; // top-left

export const playerNameMaxLength = 14;
export const playerNameMaxLengthUnlimited = 7;

export const timebankBackgroundSize: Size = { width: 150, height: 23 };
export const timebankBackgroundPosition: Point = { x: 100, y: 192 };

export const timebankBackgroundSizeUnlimited: Size = { width: 130, height: 23 };
export const timebankBackgroundPositionLeftUnlimited: Point = { x: 50, y: 200 + 14 };
export const timebankBackgroundPositionRightUnlimited: Point = { x: 114, y: 200 + 14 };

export const timebankBackgroundColor = 0x0;

export const noteIconPositionLeft: Point = { x: 170, y: 80 }; // center
export const noteIconPositionRight: Point = { x: 180, y: 80 }; // center

export const playerStatusMaxLength = 14;
export const playerStatusMaxLengthUnlimited = 6;

export const bountyInfoPositionLeft: Point = { x: 280, y: 61 }; // center
export const bountyInfoPositionRight: Point = { x: 70, y: 61 }; // center

export const replacedCardsInfoPositionRight: Point = { x: 30, y: 180 }; // center
export const replacedCardsInfoPositionLeft: Point = { x: 323, y: 180 }; // center


export const proMarkPositionLeft: Point = { x: 32, y: 152 };
export const proMarkPositionRight: Point = { x: 160, y: 152 };












// import { Point, Size } from '../models';

// export const containerSize: Point = { x: 350, y: 239 };

// export const infoBoxPositionLeft: Point = { x: 0, y: 57 }; // top-left
// export const infoBoxPositionRight: Point = { x: 0, y: 57 }; // top-left
// export const infoBoxPositionUnlimitedLeft: Point = { x: 1, y: 66 }; // top-left
// export const infoBoxPositionUnlimitedRight: Point = { x: 9, y: 66 }; // top-left

// export const avatarMaskPositionLeft: Point = { x: 278, y: 131 }; // center
// export const avatarMaskPositionRight: Point = { x: 72, y: 131 }; // center
// export const avatarMaskRadius = 62;

// export const avatarPositionLeft: Point = { x: 275, y: 132 }; // top-left
// export const avatarPositionRight: Point = { x: 76, y: 132 }; // top-left

// export const namePositionLeft: Point = { x: 113, y: 107 }; // center
// export const namePositionRight: Point = { x: 236, y: 107 }; // center
// export const namePositionLeftUnlimited: Point = { x: 207, y: 107 }; // center-right
// export const namePositionRightUnlimited: Point = { x: 139, y: 107 }; // center-left
// export const nameDefaultStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 29,
//     fill: '#ffffff'
// });
// export const nameActiveStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 29,
//     fill: '#ffffff'
// });

// export const balancePositionLeft: Point = { x: 113, y: 152 }; // center
// export const balancePositionRight: Point = { x: 236, y: 152 }; // center
// export const balancePositionLeftUnlimited: Point = { x: 199 - 14, y: 152 }; // center-right
// export const balancePositionRightUnlimited: Point = { x: 149 + 14, y: 152 }; // center-left
// export const balanceDefaultStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 32,
//     fill: '#f8f022'
// });
// export const balanceActiveStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 32,
//     fill: '#f8f022',
// });
// export const balanceDefaultStyleAdvanced: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 32,
//     fill: '#00ff24',
// });
// export const balanceActiveStyleAdvanced: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 32,
//     fill: '#00ff24',
// });
// export const balanceDefaultStyleUnlimited: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial', fontSize: 38, fill: '#dabb60', fontWeight: 'bold'
// });
// export const balanceActiveStyleUnlimited: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 38,
//     fill: '#dabb60',
//     fontWeight: 'bold',
// });

// export const timerPositionLeftStandard: Point = { x: 124, y: 174 }; // center
// export const timerPositionRightStandard: Point = { x: 228, y: 174 }; // center

// export const timerPositionLeftAdvanced: Point = { x: 124, y: 131 }; // center
// export const timerPositionRightAdvanced: Point = { x: 225, y: 131 }; // center

// export const timerPositionLeftUnlimited: Point = { x: 104, y: 193 + 5 }; // center
// export const timerPositionRightUnlimited: Point = { x: 234, y: 193 + 5 }; // center

// export const dealerPositionRight: Point = { x: 30, y: 180 };
// export const dealerPositionLeft: Point = { x: 323, y: 180 };

// export const dealerPositionRightUnlimited: Point = { x: 296, y: 118 };
// export const dealerPositionLeftUnlimited: Point = { x: 45, y: 118 };

// export const timebankStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 18,
//     fill: '#ffffff',
//     fontWeight: 'bold',
// });
// export const timebankTimerStyle: PIXI.TextStyle = new PIXI.TextStyle({
//     fontFamily: 'Arial',
//     fontSize: 40,
//     fill: '#ffffff',
//     fontWeight: 'bold'
// });
// export const timebankTextPositionLeft: Point = { x: 175, y: 203 }; // center
// export const timebankTextPositionRight: Point = { x: 175, y: 203 }; // center

// export const timebankTextPositionLeftUnlimited: Point = { x: 114, y: 210 + 14 }; // center
// export const timebankTextPositionRightUnlimited: Point = { x: 238, y: 210 + 14 }; // center

// export const timebankTimerTextPositionLeft: Point = { x: 278, y: 131 }; // center
// export const timebankTimerTextPositionRight: Point = { x: 73, y: 131 }; // center

// export const starsPositionsLeft: Point[] = [
//     { x: 317, y: 88 },
//     { x: 327, y: 101 },
//     { x: 334, y: 117 }, // center
//     { x: 334, y: 135 },
//     { x: 331, y: 153 },
// ];

// export const starsPositionsRight: Point[] = [
//     { x: 32, y: 88 },
//     { x: 22, y: 101 },
//     { x: 16, y: 117 },
//     { x: 15, y: 135 },
//     { x: 18, y: 153 },
// ];

// export const starsColors: number[] = [
//     0x874b28,
//     0xd8d6d6,
//     0xfee601,
//     0x92d1fd,
//     0xfd000a
// ];

// export const handTypesText: string[] = [
//     'Hidden',
//     'High',
//     'Pair',
//     'Two Pairs',
//     '3 of a kind',
//     'Straight',
//     'Flush',
//     'Full House',
//     '4 of a kind',
//     'Straight Flush',
// ];

// export const chatBubblePositionLeft: Point = { x: 301, y: 8 }; // top-left
// export const chatBubblePositionRight: Point = { x: 99, y: 8 }; // top-left

// export const playerNameMaxLength = 14;
// export const playerNameMaxLengthUnlimited = 7;

// export const timebankBackgroundSize: Size = { width: 150, height: 23 };
// export const timebankBackgroundPosition: Point = { x: 100, y: 192 };

// export const timebankBackgroundSizeUnlimited: Size = { width: 130, height: 23 };
// export const timebankBackgroundPositionLeftUnlimited: Point = { x: 50, y: 200 + 14 };
// export const timebankBackgroundPositionRightUnlimited: Point = { x: 114, y: 200 + 14 };

// export const timebankBackgroundColor = 0x0;

// export const noteIconPositionLeft: Point = { x: 170, y: 80 }; // center
// export const noteIconPositionRight: Point = { x: 180, y: 80 }; // center

// export const playerStatusMaxLength = 14;
// export const playerStatusMaxLengthUnlimited = 6;

// export const bountyInfoPositionLeft: Point = { x: 280, y: 61 }; // center
// export const bountyInfoPositionRight: Point = { x: 70, y: 61 }; // center

// export const replacedCardsInfoPositionRight: Point = { x: 30, y: 180 }; // center
// export const replacedCardsInfoPositionLeft: Point = { x: 323, y: 180 }; // center


// export const proMarkPositionLeft: Point = { x: 32, y: 152 };
// export const proMarkPositionRight: Point = { x: 160, y: 152 };
