import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Legends } from '../../../shared/constants/legend.constants';
import { Required } from '../../../shared/decorators/required.decorator';

@Component({
  selector: 'app-lobby-game-list',
  templateUrl: './lobby-game-list.component.html',
  styleUrls: ['./lobby-game-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LobbyGameListComponent implements OnInit {

  public Legends = Legends;
  public showExchange = true;
  protected _dataSource = [];
  protected _columns = [];
  private selectedColumns = {};

  @Input() @Required currency: string;
  @Input()
  public get columns(): Array<string> { return this._columns; }
  public set columns(val: Array<string>) {
    this._columns = val;
    this.selectedColumns = {};
    this._columns.forEach(column => {
      this.selectedColumns[column] = true;
    });
  }
  @Input()
  public get dataSource(): Array<any> { return this._dataSource; }
  public set dataSource(val) {
    this._dataSource = val;
  }

  @Output() public onRowClick = new EventEmitter();
  @Output() public onRowDblClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  rowClick(row) {
    this.onRowClick.emit(row);
  }

  rowDblCLick(row) {
    this.onRowDblClick.emit(row);
  }

}
