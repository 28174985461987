import { Point } from '../../models';

export const communityCardPositions: Point[] = [
    { x: 660, y: 355 },
    { x: 780, y: 355 },
    { x: 900, y: 355 }, // top-left
    { x: 1020, y: 355 },
    { x: 1140, y: 355 }
];
export const r2tT1Positions: Point[] = [
    { x: 708, y: 304 },
    { x: 819, y: 304 },
    { x: 930, y: 304 }, // top-left
    { x: 1041, y: 304 },
    { x: 1152, y: 304 }
];
export const r2tT2Positions: Point[] = [
    { x: 708, y: 440 },
    { x: 819, y: 440 },
    { x: 930, y: 440 }, // top-left
    { x: 1041, y: 440 },
    { x: 1152, y: 440 }
];
export const r2tCardScale: Point = { x: 1.1, y: 1.1 };
export const communityCardScale: Point = { x: 1.35, y: 1.35 };
