import { PlayerStatus } from '../../../shared/enums/poker-types';
import { environment } from '../../../../environments/environment';

export class Common {

    public static isPlaying(playerStatus: PlayerStatus) {
        return playerStatus === PlayerStatus.Ready ||
            playerStatus === PlayerStatus.LeftNextHand ||
            playerStatus === PlayerStatus.SitoutNextHand;
    }

    public static isLeftSided(seatNumber: number, maxNumOfPlayers: number, useVerticalMode:boolean): boolean {

        if (useVerticalMode) {
            return this.isLeftSidedVertical(seatNumber, maxNumOfPlayers);
        } else {
            return this.isLeftSidedHorizontal(seatNumber, maxNumOfPlayers);
        }
    }

    private static isLeftSidedHorizontal(seatNumber: number, maxNumOfPlayers: number): boolean {
        if (maxNumOfPlayers === 10) {
            switch (seatNumber) {
                case 0:
                case 1: {
                    return true;
                }
                case 2:
                case 3:
                case 4:
                case 5: {
                    return false;
                }
                case 6:
                case 7:
                case 8:
                case 9: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 9) {
            switch (seatNumber) {
                case 0: {
                    return true;
                }
                case 1:
                case 2:
                case 3:
                case 4: {
                    return false;
                }
                case 5:
                case 6:
                case 7:
                case 8: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 8) {
            switch (seatNumber) {
                case 0:
                case 1: {
                    return true;
                }
                case 2:
                case 3:
                case 4: {
                    return false;
                }
                case 5:
                case 6:
                case 7: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 6) {
            switch (seatNumber) {
                case 0:
                case 1: {
                    return false;
                }
                case 2:
                case 3: {
                    return true;
                }
                case 4:
                case 5: {
                    return false;
                }
            }
        } else if (maxNumOfPlayers === 2) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1: {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    private static isLeftSidedVertical(seatNumber: number, maxNumOfPlayers: number): boolean {
        if (maxNumOfPlayers === 10) {
            switch (seatNumber) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5: {
                    return false;
                }
                case 6:
                case 7:
                case 8:
                case 9: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 9) {
            switch (seatNumber) {
                case 0: {
                    return true;
                }
                case 1:
                case 2:
                case 3:
                case 4: {
                    return false;
                }
                case 5:
                case 6:
                case 7:
                case 8: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 8) {
            switch (seatNumber) {
                case 0:
                case 1: {
                    return true;
                }
                case 2:
                case 3:
                case 4: {
                    return false;
                }
                case 5:
                case 6:
                case 7: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 6) {
            switch (seatNumber) {
                case 0:{
                    return false;
                }
                case 1:
                case 2:{
                    return true;
                }
                case 3:
                case 4:
                case 5: {
                    return false;
                }
            }
        } else if (maxNumOfPlayers === 2) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1: {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    public static isPotLeftSided(seatNumber: number, maxNumOfPlayers: number): boolean {
        if (maxNumOfPlayers === 10) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1:
                case 2:
                case 3:
                case 4:
                case 5: {
                    return true;
                }

                case 6:
                case 7:
                case 8:
                case 9: {
                    return false;
                }
            }
        } else if (maxNumOfPlayers === 9) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1:
                case 2:
                case 3:
                case 4: {
                    return true;
                }
                case 5:
                case 6:
                case 7:
                case 8: {
                    return false;
                }
            }
        } else if (maxNumOfPlayers === 8) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1:
                case 2:
                case 3:
                case 4: {
                    return true;
                }
                case 5:
                case 6:
                case 7: {
                    return true;
                }
            }
        } else if (maxNumOfPlayers === 6) {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1:
                case 2:
                case 3: {
                    return true;
                }
                case 4:
                case 5: {
                    return false;
                }
            }
        } else if (maxNumOfPlayers === 2) {
            switch (seatNumber) {
                case 0: {
                    return true;
                }
                case 1: {
                    return false;
                }
            }
        } else {
            switch (seatNumber) {
                case 0: {
                    return false;
                }
                case 1:
                case 2:
                case 3:
                case 4:
                case 5: {
                    return true;
                }

                case 6:
                case 7:
                case 8:
                case 9: {
                    return false;
                }
            }
        }
    }

    public static parseTime(time: number): string {
        const secondsLeft: number = time % 60;
        const minutesLeft: number = time / 60;

        const strToRet = '';
        let minStr = '';
        let secStr = '';

        if (minutesLeft < 10) {
            minStr = '0' + Math.floor(minutesLeft).toString();
        } else {
            minStr = Math.floor(minutesLeft).toString();
        }

        if (secondsLeft < 10) {
            secStr = '0' + Math.floor(secondsLeft).toString();
        } else {
            secStr = Math.floor(secondsLeft).toString();
        }

        return minStr + ':' + secStr;
    }

    public static shiftFoward(steps: number, arr: any) {
        for (let i = 0; i < steps; i++) {
            arr.unshift(arr.pop());
        }
    }

    public static shiftBackwards(steps: number, arr: any) {
        for (let i = 0; i < steps; i++) {
            arr.push(arr.shift());
        }
    }
}
