import { Action } from '@ngrx/store';

import { type } from '../../shared/helpers/utils';
import { User, Authenticate } from '../../shared/models/user';
import { MemberProfile, Gift, PlayerBalance, Settings, PlayerLevelStatus } from '../../shared/models';
import { Currency } from '../../shared/enums';
import { MemberPreferences } from '../../shared/models/member-preferences';

export enum AuthActionTypes {
  Connect = '[Auth] Connect',
  ConnectSuccess = '[Auth] Connect Success',
  ConnectFailure = '[Auth] Connect Failure',

  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  MainRedirect = '[Auth] Main Redirect',

  RequestSecurityCode = '[Auth] Request Security Code',
  RequestSecurityCodeSuccess = '[Auth] Request Security Code Success',
  RequestSecurityCodeFailure = '[Auth] Request Security Code Failure',
  SecurityCodeVerified = '[Auth] Security Code Verified',
  PasswordChange = '[Auth] Password Change',
  PasswordChangeSuccesful = '[Auth] Password Change Success',
  ForgotPasswordCancel = '[Auth] Forgot Password Cancel',

  Register = '[Auth] Register',
  RegistartionStep = '[Auth] Registartion Step',
  RegisterSuccess = '[Auth] Register Success',
  RegisterFailure = '[Auth] Register Failure',
  CheckPromoCode = '[Auth] Check Promo Code',
  PromoCodeOk = '[Auth] Promo Code Ok',
  PromoCodeWrong = '[Auth] Promo Code Wrong',
  RequestPhoneCodeResponse = '[Auth] Request Phone Code Response',
  RegisterVerifyMobileCode = '[Auth] Register Verify Mobile Code',
  RegisterVerifyMobileCodeSuccess = '[Auth] Register Verify Mobile Code',
  RegisterVerifyMobileCodeFailure = '[Auth] Register Verify Mobile Code Failure',

  MemberProfileReceived = '[Auth] Member Profile Received',
  MemberProfileUpdate = '[Auth] Member Profile Update',
  MemberPreferenceReceived = '[Auth] Member Preference Received',
  PlayerBalanceUserUpdate = '[Auth] Player Balance',
  GiftsUserUpdate = '[Auth] Gifts',
  ExchangeRateUserUpdate = '[Auth] Exchange Rate',
  PlayerLevelStatusUpdate = '[Auth] Player Level Status',
  SettingsUpdate = '[Auth] Settings Update',

  CurrencyChange = '[Auth] Currency Change ',
}

export class Connect implements Action {
  readonly type = AuthActionTypes.Connect;

  constructor() { }
}

export class ConnectSuccess implements Action {
  readonly type = AuthActionTypes.ConnectSuccess;

  constructor() { }
}
export class ConnectFailure implements Action {
  readonly type = AuthActionTypes.ConnectFailure;

  constructor() { }
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Authenticate) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor() { } // public payload: { user: User }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor() { }
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class MainRedirect implements Action {
  readonly type = AuthActionTypes.MainRedirect;
}

export class MemberProfileReceived implements Action {
  readonly type = AuthActionTypes.MemberProfileReceived;

  constructor(public payload: MemberProfile) { }
}

export class MemberProfileUpdate implements Action {
  readonly type = AuthActionTypes.MemberProfileUpdate;

  constructor(public payload: MemberProfile) { }
}

export class MemberPreferenceReceived implements Action {
  readonly type = AuthActionTypes.MemberPreferenceReceived;

  constructor(public payload: MemberPreferences) { }
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;

  constructor(public payload: MemberProfile) { }
}

export class RegisterSuccess implements Action {
  readonly type = AuthActionTypes.RegisterSuccess;
}

export class RegisterFailure implements Action {
  readonly type = AuthActionTypes.RegisterFailure;

  constructor(public payload: any) { }
}

export class RegistartionStep implements Action {
  readonly type = AuthActionTypes.RegistartionStep;

  constructor(public payload: number) { }
}

export class RequestSecurityCode implements Action {
  readonly type = AuthActionTypes.RequestSecurityCode;

  constructor(public payload: Authenticate) { }
}

export class RequestSecurityCodeSuccess implements Action {
  readonly type = AuthActionTypes.RequestSecurityCodeSuccess;
}
export class RequestSecurityCodeFailure implements Action {
  readonly type = AuthActionTypes.RequestSecurityCodeFailure;
}

export class SecurityCodeVerified implements Action {
  readonly type = AuthActionTypes.SecurityCodeVerified;
}

export class PasswordChange implements Action {
  readonly type = AuthActionTypes.PasswordChange;

  constructor(public payload: Authenticate) { }
}

export class PasswordChangeSuccesful implements Action {
  readonly type = AuthActionTypes.PasswordChange;
}

export class ForgotPasswordCancel implements Action {
  readonly type = AuthActionTypes.ForgotPasswordCancel;
}

export class CheckPromoCode implements Action {
  readonly type = AuthActionTypes.CheckPromoCode;

  constructor(public payload: MemberProfile) { }
}

export class PromoCodeOk implements Action {
  readonly type = AuthActionTypes.PromoCodeOk;
}

export class PromoCodeWrong implements Action {
  readonly type = AuthActionTypes.PromoCodeWrong;
}

export class RegisterVerifyMobileCode implements Action {
  readonly type = AuthActionTypes.RegisterVerifyMobileCode;

  constructor(public payload: MemberProfile) { }
}

export class PlayerBalanceUserUpdate implements Action {
  readonly type = AuthActionTypes.PlayerBalanceUserUpdate;

  constructor(public payload: any) { }
}

export class GiftsUserUpdate implements Action {
  readonly type = AuthActionTypes.GiftsUserUpdate;

  constructor(public payload: Array<Gift>) { }
}

export class ExchangeRateUserUpdate implements Action {
  readonly type = AuthActionTypes.ExchangeRateUserUpdate;

  constructor(public payload: {currency: Currency, value: number}) { }
}

export class PlayerLevelStatusUpdate implements Action {
  readonly type = AuthActionTypes.PlayerLevelStatusUpdate;

  constructor(public payload: PlayerLevelStatus) { }
}

export class SettingsUpdate implements Action {
  readonly type = AuthActionTypes.SettingsUpdate;

  constructor(public payload: Settings) { }
}

export class CurrencyChange implements Action {
  readonly type = AuthActionTypes.CurrencyChange;

  constructor(public payload: number) { }
}

export type AuthActions =
  | Connect
  | ConnectSuccess
  | ConnectFailure
  | Login
  | LoginSuccess
  | LoginFailure
  | Register
  | RegisterSuccess
  | RegisterFailure
  | RegistartionStep
  | RequestSecurityCode
  | RequestSecurityCodeSuccess
  | RequestSecurityCodeFailure
  | SecurityCodeVerified
  | PasswordChange
  | PasswordChangeSuccesful
  | ForgotPasswordCancel
  | CheckPromoCode
  | PromoCodeOk
  | PromoCodeWrong
  | RegisterVerifyMobileCode
  | MainRedirect
  | MemberProfileReceived
  | MemberProfileUpdate
  | MemberPreferenceReceived
  | PlayerBalanceUserUpdate
  | GiftsUserUpdate
  | ExchangeRateUserUpdate
  | PlayerLevelStatusUpdate
  | SettingsUpdate
  | CurrencyChange
  | LoginRedirect
  | Logout;
