import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatIconModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatRadioModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatRippleModule,
        MatStepperModule,
        PortalModule,
        CdkTableModule,
        OverlayModule,
        MatTabsModule,
        MatSliderModule,
        ScrollingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
    ],
    exports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatIconModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatRadioModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatRippleModule,
        MatStepperModule,
        PortalModule,
        CdkTableModule,
        OverlayModule,
        MatTabsModule,
        MatSliderModule,
        ScrollingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
    ],
})
export class MaterialModule { }
