import * as PIXI from 'pixi.js';

import { Point } from '../models';
import { Table } from './table';

export class Pause {
    container: any;
    timer: number;
    title: any;
    titleStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 35, fontWeight: 'bold', fill: '#ffffff' });

    countdownText: any;
    backOverlay: any;
    backOverlayDefaultColor = 0x000000;
    backOverlayAlpha = 0.8;
    position: Point;
    isActive = false;
    defaultTitleText = 'GAME PAUSED';

    constructor(public gameWidth: number, public gameHeight: number, public table: Table, startPosition?: Point) {
        this.container = new PIXI.Container();

        if (startPosition !== undefined) {
            this.position = startPosition;
        } else {
            this.position = { x: 0, y: 0 };
        }

        // create back overlay
        this.backOverlay = new PIXI.Graphics();
        this.backOverlay.beginFill(this.backOverlayDefaultColor, this.backOverlayAlpha);
        this.backOverlay.drawRect(0, 0, this.gameWidth, this.gameHeight);
        this.backOverlay.endFill();
        this.container.addChild(this.backOverlay);
        this.backOverlay.visible = false;

        // title text
        this.title = new PIXI.Text(this.defaultTitleText, this.titleStyle);
        this.title.position.set(this.gameWidth / 2, this.gameHeight / 2 - 50);
        this.title.anchor.set(0.5, 0.5);
        this.container.addChild(this.title);

        // countdown text
        this.countdownText = new PIXI.Text('T', this.titleStyle);
        this.countdownText.position.set(this.gameWidth / 2, this.gameHeight / 2);
        this.countdownText.anchor.set(0.5, 0.5);
        this.container.addChild(this.countdownText);
    }

    update(dt) {
        if (this.isActive) {
            this.timer -= dt / 1000;
            if (this.timer <= 0) {
                this.stopPause();
                return;
            }
            this.countdownText.text = this.parseTime(this.timer);
        }
    }

    startPause(time: number) {

        this.isActive = true;
        this.timer = time;
        this.container.visible = true;
    }

    stopPause() {

        this.isActive = false;
        this.timer = 0;
        this.container.visible = false;
        this.backOverlay.visible = true;
    }

    parseTime(time: number): string {
        const secondsLeft: number = time % 60;
        const minutesLeft: number = time / 60;
        let minStr = '';
        let secStr = '';

        if (minutesLeft < 10) {
            minStr = '0' + Math.floor(minutesLeft).toString();
        } else {
            minStr = Math.floor(minutesLeft).toString();
        }

        if (secondsLeft < 10) {
            secStr = '0' + Math.floor(secondsLeft).toString();
        } else {
            secStr = Math.floor(secondsLeft).toString();
        }

        return minStr + ':' + secStr;
    }

    disableBackOverlay() {
        this.backOverlay.visible = false;
    }
}
