import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-cards',
  templateUrl: './show-cards.component.html',
  styleUrls: ['./show-cards.component.scss']
})
export class ShowCardsComponent implements OnInit, OnDestroy {

  countDown_: Subscription
  timer: number

  constructor(
    public dialogRef: MatDialogRef<ShowCardsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      time: number
    }) {
    this.timer = data.time

    this.countDown_ = interval(1000).subscribe(() => {
      this.timer--

      if (this.timer <= 0) { this.cancel() }
    })
  }

  ngOnInit(): void {

  }
  ngOnDestroy() {
    this.countDown_.unsubscribe()
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.countDown_.unsubscribe()
    this.dialogRef.close();
  }


}
