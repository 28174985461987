import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as WebFont from 'webfontloader';
import { AssetsLoaderService } from './core/services/assets-loader.service';
import { AuthService } from './auth/auth.service';
import { ManagerService } from './core/services/manager.service';
import { environment } from '../environments/environment';
import { AppWebSocketService } from './core/services/app-web-socket.service';
import { ConnectionStatus } from './shared/enums';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LinkHtmlService } from './core/services/link-html.service';
import { ConfigService } from './core/services/config.service';
import { v3ManagerService } from './core/services/v3-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  env = environment
  config;

  constructor(
    private _translate: TranslateService,
    private _titleService: Title,
    private _assetsLoader: AssetsLoaderService,
    private _router: Router,
    private _authService: AuthService,
    private _managerService: ManagerService,
    private _socketService: AppWebSocketService,

    private _link: LinkHtmlService,
    private configService: ConfigService,
    private v3ManagerService: v3ManagerService,

  ) {
    console.log("environment", environment)
    //CSS ADD ON
    if (true) {
      this._link.addTag({
        rel: 'stylesheet',
        href: `${environment.dataSource}/style/${this.configService.config.code}/modify.css`
      });
    }
    this.config = this.configService.config;

  }



  ngOnInit(): void {
    if (this.env.production) {
      console.log(`%cStop!`, `color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;`);
      console.log(`%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a poker feature or "hack", it is a scam and we will submit a request for review of a prosecutor's decision.`, `color: #444; font-family: sans-serif; font-size: 1.9em; font-weight: normal; `);
    }

    this._socketService.connectionStatus$
      .subscribe((connectionStatus: ConnectionStatus) => {
        if (connectionStatus === ConnectionStatus.close) {
          this._managerService.logout(false)
        }
      });

    this._managerService.loginState$.subscribe({
      next: (loggedIn) => {
        if (loggedIn) {
          this._authService.startPinging();
          this._router.navigate(['/main']);
        }
      }
    })

    this._titleService.setTitle(this.configService.config.title);
    document.body.classList.add(this.configService.config.brand); // for custom theme

    // ## Language
    this._translate.addLangs(this.configService.config.settings.languages.map(el => el.value));
    this._translate.setDefaultLang('en');
    this._translate.use(this.configService.config.settings.defaultLanguage);

    const iterate = (obj) => {
      if (!obj) {
        return null
      }
      Object.keys(obj).forEach(key => {

        console.log(`key: ${key}, value: ${obj[key]}`)

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          iterate(obj[key])
        }
      })
    }

    // this._translate.onLangChange
    //   .pipe(
    //     switchMap((currentLang) => {
    //       return this._translate.getTranslation('en')
    //         .pipe(tap((englishTranslation => {

    //           this._translate.setTranslation('en',{})
    //         })))
    //     }))
    //   .subscribe(currentLang => {
    //     

    //     const d = iterate(currentLang.translations)
    //   })
    // this._translate.getTranslation('en')
    //   .pipe(
    //     switchMap(englishTranslation=>{

    //       return 
    //     })
    //     take(1),
    //   ).subscribe(englishTranslation => {
    //     
    //   })

    // ##

    WebFont.load({
      google: {
        families: ['Saira Semi Condensed', 'Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900']
      },
      active: () => {
        this._authService.wsConnect();

        this._assetsLoader.load();
        this._assetsLoader.pixiLoader$.subscribe({
          next: (data) => {
          },
          error: (err) => {
          }
        })

      }
    })
  }
}
