import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { filter, map, pluck, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { NotificationsService } from '../../../../core/notifications/notifications.service';
import { DataManagerService } from '../../../../core/services/data-manager.service';
import { RequestMessage, ServerMessage } from '../../../../shared/enums/poker-types';
import { ConfigService } from '../../../../core/services/config.service';

@Component({
  selector: 'app-game-password',
  templateUrl: './game-password.component.html',
  styleUrls: ['./game-password.component.scss']
})
export class GamePasswordComponent implements OnInit, OnDestroy {

  environment = environment;
  tablePassword: string;
  loader: boolean;
  destroy$ = new Subject<boolean>();

  config;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { idTable: number },
    private dialogRef: MatDialogRef<GamePasswordComponent>,
    private dataManager: DataManagerService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

  }

  ngOnInit() {
    this.dataManager.messageReceivedObservable
      .pipe(
        filter(el => el.Response === 'ServerMsg'),
        filter(el => el.ServerMsg.filter(item => item.MsgType === ServerMessage.PrivateTablePasswordResponse).length>0),
        map(el => el.ServerMsg.find(item => item.MsgType === ServerMessage.PrivateTablePasswordResponse)),
        filter(el => el.IdTable === this.data.idTable),
        pluck('Value'),
        map(el => !!el),
        takeUntil(this.destroy$)
      )
      .subscribe(passwordCorrect => {
        if (passwordCorrect) {
          this.dialogRef.close(this.tablePassword);
        } else {
          this.loader = false;
          this.translateService.get('dialog.privateTablePassword.error.passwordIncorrect')
            .pipe(take(1))
            .subscribe(translation => {
              this.notificationsService.showError(translation);
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (!this.tablePassword) { return }
    this.loader = true;
    this.dataManager.submitPrivateTablePassword(this.data.idTable, this.tablePassword)
  }

}
