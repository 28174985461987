import * as PIXI from 'pixi.js';
import { FoldedCards } from '../folded-cards';
import * as CONST_HORIZONTAL from './folded-cards-horizontal.const';
import * as CONST_VERTICAL from './folded-cards-vertical.const';
import { Point } from '../../models/point';
import * as TABLE_CONST_HORIZONTAL from '../table-horizontal.const';
import * as TABLE_CONST_VERTICAL from '../table-vertical.const';
import { environment } from '../../../../../environments/environment';

export class FoldedCardsController {
    container: PIXI.Container;
    private cards: FoldedCards[] = [];
    private textures;
    // private maxPlayers: number;
    foldedCardsPositions: Point[] = [];
    private cardsAlpha: number[] = [];
    private fadeOutSpeed = 0.0001;

    private CONST;
    private TABLE_CONST;

    private useVerticalMode:boolean

    constructor(textures, private maxPlayers, useVerticalMode: boolean) {
        this.container = new PIXI.Container();
        this.textures = textures;

        this.useVerticalMode = useVerticalMode

        if (this.useVerticalMode) {
            this.CONST = CONST_VERTICAL;
            this.TABLE_CONST = TABLE_CONST_VERTICAL;
        } else {
            this.CONST = CONST_HORIZONTAL;
            this.TABLE_CONST = TABLE_CONST_HORIZONTAL;
        }

        this.createFoldedCardsPositions();
    }

    update(dt) {
        // if (environment.skinType === SkinType.Unlimited) {
        //     for (let i = this.cards.length - 1; i >= 0; i--) {
        //         this.cards[i].fadeOutAnim(dt);
        //         this.cardsAlpha[i] -= this.fadeOutSpeed * dt;
        //         if (this.cards[i].cardsAlpha <= 0) {
        //             this.removeCard(i);
        //         }
        //     }
        // }
    }

    updateRotationMode(useVerticalMode: boolean) {
        this.useVerticalMode = useVerticalMode
        if (this.useVerticalMode) {
            this.CONST = CONST_VERTICAL;
            this.TABLE_CONST = TABLE_CONST_VERTICAL;
        } else {
            this.CONST = CONST_HORIZONTAL;
            this.TABLE_CONST = TABLE_CONST_HORIZONTAL;
        }
        this.createFoldedCardsPositions()

    }

    addCard(seatNumber: number, numberOfCards: number) {
        const pos: Point = this.foldedCardsPositions[seatNumber];
        const foldedCards = new FoldedCards(this.textures, pos, numberOfCards);
        foldedCards.id = seatNumber;
        this.cards.push(foldedCards);
        this.container.addChild(foldedCards.container);
    }

    clearCards() {
        this.cards.forEach(card => this.container.removeChild(card.container));
        this.cards = [];
        this.cardsAlpha = [];
    }

    private removeCard(cardIndex: number) {
        this.container.removeChild(this.cards[cardIndex].container);
        this.cards.splice(cardIndex, 1);
    }

    /*setMaxPlayersOnTable(maxPlayers: number) {
        this.maxPlayers = maxPlayers;
        this.createFoldedCardsPositions();
    }*/

    private createFoldedCardsPositions() {
        const positions = this.getPositions();
        this.foldedCardsPositions = [];
        const visibility = this.getSeatVisibility();
        visibility[this.maxPlayers].forEach((element, index) => {
            if (element) {
                this.foldedCardsPositions.push(positions[index]);
            }
        });

    }

    getSeatVisibility() {
        if (environment.settings.usePlayerPositionsWithDealer) {
            return this.TABLE_CONST.SeatsVisibilityWithDealer;
        } else {
            return this.TABLE_CONST.SeatsVisibility;
        }
    }

    private getPositions(): Point[] {
        // if (environment.skinType === SkinType.Unlimited) {
        //     if (this.maxPlayers === 6) {
        //         return this.CONST.FoldedCardsPositionsFor6PlayersUnlimited;
        //     } else {
        //         return this.CONST.FoldedCardsPositionsUnlimited;
        //     }
        // } else {
        if (this.maxPlayers === 6) {
            return this.CONST.FoldedCardsPositionsFor6Players;
        } else {
            return this.CONST.FoldedCardsPositions;
        }
        //   }
    }

    rearrangeCards() {

        // feb tested and it doesnt work anything
        this.cards.forEach(card => {
            card.container.position.set(this.foldedCardsPositions[card.id].x, this.foldedCardsPositions[card.id].y);
        });
    }

    setCardPositionsToNormal() {

        this.createFoldedCardsPositions();
    }
}
