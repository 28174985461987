import { Input, Output, EventEmitter } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

export abstract class AppTable extends DataSource<any> {

    @Input() public columns: Array<string> = []; // selected columns to render
    @Input()
    public get dataSource(): Array<any> { return this._dataSource; }
    public set dataSource(val) {
        this._dataSource = val;
        this.dataChange.next(this.dataSource);
    }

    @Output() public onRowClick = new EventEmitter();
    @Output() public onRowDblClick = new EventEmitter();

    protected dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public self;
    protected _dataSource = [];
    constructor() {
        super();
        this.self = this;

    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.dataChange;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataChange.unsubscribe();
    }
}
