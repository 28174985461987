import { Injectable, SecurityContext } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
// import { Store } from '@ngrx/store';

import { ConvertChipsDialogComponent } from './cashier/convert-chips-dialog/convert-chips-dialog.component';
import { UploadIdDialogComponent } from './cashier/upload-id-dialog/upload-id-dialog.component';
import { PhoneVerificationDialogComponent } from './cashier/phone-verification-dialog/phone-verification-dialog.component';
import { AuthService } from '../auth/auth.service';
import { SoundControlComponent } from './game/dialogs/sound-control/sound-control.component';
import { User } from '../shared/models/user';
import { State } from '../app.reducers';
import { getUserState } from '../auth/auth-store/auth.reducer';
import { PhoneStatus } from '../shared/enums/phone-status';
import { GenericTextDialog } from '../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { MemberStatus } from '../shared/enums/member-status';
import { environment } from '../../environments/environment';
import { NotificationsService } from '../core/notifications/notifications.service';
import { AppActions } from '../app.actions';
import { PlayerRankLevelComponent } from './lobby/lobby-dialogs/player-rank-level/player-rank-level.component';
import { BonusUnlockComponent } from './lobby/lobby-dialogs/bonus-unlock/bonus-unlock.component';
import { ENavItem } from './nav/nav-item-type';
import { DataManagerService } from '../core/services/data-manager.service';
import { Gateway } from '../shared/enums';
import { TicketComponent } from './ticket/ticket.component';
import { ManagerService } from '../core/services/manager.service';
import { Ticket } from '../shared/models/ticket';
import { WindowResizeService } from '../core/services/window-resize.service';
import { SelfExcludeComponent } from './self-exclude/self-exclude.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Chat } from '../shared/models/chat';

import { Router, UrlSerializer } from '@angular/router';
import { DialogCasinosInfo } from './nav/dialog-casinos-info/dialog-casinos-info.component';
import { HttpParams } from '@angular/common/http';
import { AppWebSocketService } from '../core/services/app-web-socket.service';
import { LostConnectionService } from '../core/lost-connection/lost-connection.service';
import { SpinnerService } from '../core/spinner/spinner.service';

export interface SidenavState {
  show: boolean;
  settings: boolean;
}

@Injectable()
export class MainService {


  private sidenavSubject = new Subject<SidenavState>();
  sidenavState = this.sidenavSubject.asObservable();

  private rightSidenavSubject = new Subject<SidenavState>();
  rightSidenavState = this.rightSidenavSubject.asObservable();

  private splitScreenModeSubject = new Subject<boolean>();
  splitScreenModeState = this.splitScreenModeSubject.asObservable();

  public user: User;
  public casinoType = 'liveGame';
  public casinoUrl: SafeUrl;
  public is2x2Active = false;
  public flexSize = this.is2x2Active ? 50 : 100;

  dialogConvertChipsRef: MatDialogRef<ConvertChipsDialogComponent>;
  dialogUploadIdRef: MatDialogRef<UploadIdDialogComponent>;
  dialogPhoneVerificationRef: MatDialogRef<PhoneVerificationDialogComponent>;
  dialogConfig: MatDialogConfig;

  public Gateway = Gateway;

  public playerTickets: Array<Ticket> = [];


  lobbyChat$: Subject<Chat> = new Subject<Chat>()

  casinoBuyInAmount: number
  casinoProduct: string
  casinoOpenFromLobby = false;
  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private notification: NotificationsService,
    private dataManager: DataManagerService,
    // private store: Store<State>,
    private managerService: ManagerService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,

    private router: Router, private serializer: UrlSerializer,

    private lostConnectionService: LostConnectionService,

    private _webSocketService: AppWebSocketService,
    private _spinnerService: SpinnerService,


  ) {
    // this.store.select(getUserState).subscribe(user => {
    //   this.user = user;
    // });
    this.managerService.user$.subscribe({
      next: (user) => {
        this.user = user
      }
    })

    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width = '540px';
    this.dialogConfig.disableClose = true;

    this.dataManager.getCurrencyExchange().subscribe(res => { }, e => { });

    this.managerService.playerTickets$.subscribe((tickets: Ticket[]) => {
      this.playerTickets = tickets;
    });

    this.managerService.activeGamesSubjectObservable$.subscribe((numberOfActiveGames) => {
      if (numberOfActiveGames > 4) {
        this.is2x2Active = false;
        this.flexSize = this.is2x2Active ? 50 : 100;
        this.splitScreenModeSubject.next(this.is2x2Active);
      }
    });

    this.casinoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://games.vivogaming.com/?token=demo&operatorid=30155&serverid=6401748&application=lobby');
    this.managerService.casinoTokenReceivedObservable$.subscribe(({ token, operatorid }) => {

      const casino = this.managerService.casinosInfo.find(el => el.Parameters.product === this.casinoProduct)
      console.log("@casino", casino, this.casinoProduct)
      if (casino && !this.casinoOpenFromLobby) {
        let queryParams = {}
        if (casino.Parameters.token && casino.Parameters.token === '##') { queryParams = { ...queryParams, token } }
        if (casino.Parameters.username && casino.Parameters.username === '##') { queryParams = { ...queryParams, username: this.user.memberProfile.Username } }
        if (casino.Parameters.buyInAmount && casino.Parameters.buyInAmount === '##') { queryParams = { ...queryParams, buyInAmount: this.casinoBuyInAmount } }
        if (casino.Parameters.product && casino.Parameters.buyInAmount === '##') { queryParams = { ...queryParams, product: this.casinoProduct } }

        //const url = `${casino.LandingUrl}${serializer.serialize(router.createUrlTree([], { queryParams }))}`
        const url = `${casino.LandingUrl}?${new HttpParams({ fromObject: queryParams }).toString()}`

        // window.open(url, '_blank');


        this.dialog.open(DialogCasinosInfo, { width: '300px', data: { redirectUrl: url } })
        // .afterClosed().subscribe(data => {

        //   // if (data) {
        //   //   this.casinoBuyInAmount = data.buyInAmount
        //   //   this.casinoProduct = data.product;
        //   //   this.dataManager.getCasinoToken(this.casinoProduct);
        //   // }
        // });
      }

    });
  }

  showSidenav() {
    this.sidenavSubject.next(<SidenavState>{ show: true });
  }

  hideSidenav() {
    this.sidenavSubject.next(<SidenavState>{ show: false });
  }

  toggleSidenav(show: boolean, settings: boolean) {
    this.sidenavSubject.next({ show, settings });
  }

  toggleRightSidenav() {
    this.rightSidenavSubject.next();
  }

  toggle2x2() {
    const numOfActiveGames = this.managerService.getNumberOfActiveGames();
    if (numOfActiveGames > 4 || numOfActiveGames <= 1) { return; }
    this.is2x2Active = !this.is2x2Active;
    this.flexSize = this.is2x2Active ? 50 : 100;
    this.splitScreenModeSubject.next(this.is2x2Active);
  }

  disable2x2() {
    this.is2x2Active = false;
    this.flexSize = this.is2x2Active ? 50 : 100;
    this.splitScreenModeSubject.next(this.is2x2Active);
  }

  // resetPassword() {
  //   this.authService.openResetPasswordDialog();
  // }

  showSoundControl() {
    this.dialog.open(SoundControlComponent, { width: '480px', data: this.user.memberProfile });
  }

  showSkinControl() {
    //   this.dialog.open(DevelopComponent, { width: '480px' });
  }

  showPhoneVerification() {
    if (this.user.memberProfile.PhoneStatus === PhoneStatus.Verified) {
      this.showInfoDialog('Info', 'Your profile is already verified.');
    } else {
      this.dialog.open(PhoneVerificationDialogComponent, this.dialogConfig)
        .afterClosed()
        .subscribe((result: string) => {
          if (result !== undefined) {
            // ToDo: doSomething
          }
          this.dialogUploadIdRef = null;
        });
    }
  }

  showInfoDialog(textHeader: string, textBody: string) {
    const dialogRef = this.dialog.open(GenericTextDialog, this.dialogConfig);

    dialogRef.componentInstance.textHeader = textHeader;
    dialogRef.componentInstance.textBody = textBody;
    dialogRef.componentInstance.textAcceptBtn = 'OK';
  }

  showConvertChips() {
    this.dialogConvertChipsRef = this.dialog.open(ConvertChipsDialogComponent, this.dialogConfig);

    this.dialogConvertChipsRef.afterClosed().subscribe((result: string) => {
      if (result !== undefined) {
        // ToDo: doSomething
      }
      this.dialogConvertChipsRef = null;
    });
  }

  showCasierExchange() {
    // const dialogRef = this.dialog.open(GenericTextDialog, {
    //   disableClose: true
    // });

    // dialogRef.componentInstance.textAcceptBtn = 'OK';
    // dialogRef.componentInstance.textDismissBtn = 'Close';
    // dialogRef.componentInstance.textHeader = 'INFO';
    // dialogRef.componentInstance.enableAccept = false;
    // // dialogRef.componentInstance.textBody =
    // //   `Deposit any Altcoin and instantly convert it to Bitcoin.<br><br>
    // //    Deposited funds will be added to your BTC balance after it is confirmed on the blockchain
    // //   `;
    // dialogRef.componentInstance.textBody = 'Coming Soon!';
    // dialogRef.afterClosed().subscribe(clicked => {
    //   if (clicked === 'ok') {
    //     this.dataManager.sendDepositRequestNew(Gateway.deposit_altcoin, 0, this.user.selectedCurrency, '');
    //     this.authService.depositWithdrawalWindow = window.open('', '_blank');
    //   }
    // });
  }

  showCasierDeposit() {

  }





  // casierVerificationId() {
  //   if (this.user.memberProfile.Status === MemberStatus.Verified) {
  //     this.showInfoDialog('Info', 'Your profile is already verified.');
  //   } else {
  //     this.dialogUploadIdRef = this.dialog.open(UploadIdDialogComponent, this.dialogConfig);

  //     this.dialogUploadIdRef.afterClosed().subscribe((result: string) => {
  //       if (result !== undefined) {
  //         // ToDo: doSomething
  //       }
  //       this.dialogUploadIdRef = null;
  //     });
  //   }
  // }



  showTickets() {
    this.dialog.open(TicketComponent);
  }

  showSelfExclude() {
    this.dialog.open(SelfExcludeComponent);
  }

  showPlayerRankLevelDialog() {
    this.dialog.open(PlayerRankLevelComponent, { width: '540px', data: this.user });
  }

  showBonusUnlock() {
    this.dialog.open(BonusUnlockComponent, { width: '400px', data: this.user });
  }

  showChangeAvatar() {
    this.dialog.open(BonusUnlockComponent, { width: '540px', data: this.user });
  }

  logout() {
    const dialogRef = this.dialog.open(GenericTextDialog, {
      disableClose: true,
      width: '230px'
    });

    this.translateService.get(['dialog.logout.button.ok', 'dialog.logout.button.cancel', 'dialog.logout.title', 'dialog.logout.text']).subscribe(trans => {
      dialogRef.componentInstance.textAcceptBtn = trans['dialog.logout.button.ok'];
      dialogRef.componentInstance.textDismissBtn = trans['dialog.logout.button.cancel'];
      dialogRef.componentInstance.textHeader = trans['dialog.logout.title'];
      dialogRef.componentInstance.textBody = trans['dialog.logout.text'];
    });


    dialogRef.afterClosed().subscribe(clicked => {
      if (clicked !== undefined) {
        this.managerService.logout(true);
        // this.managerService.logout();

        // this._spinnerService.show()
        // setTimeout(() => {


        //this.store.dispatch(new AppActions.AuthAction.Logout());

        // this.authService

        // this.managerService.logout();
        // localStorage.removeItem('auth-login');

        // TO DO
        // this.store$.dispatch(new AppActions.GameAction.Logout());
        // this.store$.dispatch(new AppActions.TournamentsActions.Logout());
        // this.store$.dispatch(new AppActions.NavAction.Logout());
        // this.authService.stopPinging()
        // this.lostConnectionService.hide()
        // this.router.navigate(['/login']);
        // this.authService.shouldStoreUser = false;

        //   this._spinnerService.show()
        // }, 500);
      }
    });
  }

  showCasino() {
    this.dialog.open(DialogCasinosInfo, { width: '300px' })
      .afterClosed().subscribe(data => {

        if (data) {
          this.casinoBuyInAmount = data.buyInAmount
          this.casinoProduct = data.product;
          this.dataManager.getCasinoToken(this.casinoProduct);
        }
      });
  }

  openCasinoFromLobby(buyInAmount, product) {
    this.casinoOpenFromLobby = true;
    this.casinoBuyInAmount = buyInAmount
    this.casinoProduct = product;
    this.dataManager.getCasinoToken(this.casinoProduct);
  }

}

