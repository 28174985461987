import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { Chat } from '../../../shared/models/chat';
import { MainService } from '../../main.service';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'lobby-chat',
  templateUrl: './lobby-chat.component.html',
  styleUrls: ['./lobby-chat.component.scss']
})
export class LobbyChatComponent implements OnInit, OnDestroy {

  @ViewChild('chatBox', { static: false }) private chatBoxContainer: ElementRef;
  @Output() onOpenClose: EventEmitter<boolean> = new EventEmitter();

  environment = environment;
  destroy$ = new Subject<boolean>();
  open = false;
  messages: Array<Chat> = [];
  messageText: string;
  loader = false;

  handsetPortrait: boolean;
  handsetLandscape: boolean;

  readonly PAGINATION_PER_PAGE = 25;

  config;

  constructor(
    private mainService: MainService,
    private dataManager: DataManagerService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService
  ) {
    this.config = this.configService.config;
  }

  ngOnInit(): void {
    this.loader = true;
    this.dataManager.getChatLobbyHistory(undefined, this.PAGINATION_PER_PAGE)

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          this.handsetPortrait = result.breakpoints[Breakpoints.HandsetPortrait]
          this.handsetLandscape = result.breakpoints[Breakpoints.HandsetLandscape]
        }
      })


    this.mainService.lobbyChat$
      .pipe(takeUntil(this.destroy$))
      .subscribe(lobbyChatMessage => {
        if (lobbyChatMessage.Value2) {
          let index = this.messages.findIndex(el => el.Value === lobbyChatMessage.Value2)

          if (index > -1) { // if message is already in array
            if (lobbyChatMessage.Value3 == 1) { // if msg is deleted
              this.messages[index].Text = null
              this.messages[index].isDeleted = true
            } else { // if msg is replaced
              this.messages[index].Text = lobbyChatMessage.Text
            }
          } else if (lobbyChatMessage.Value3 == 1) { // if msg is deleted
            lobbyChatMessage.isDeleted = true
            this.messages = [...this.messages, lobbyChatMessage].sort((a, b) => new Date(a.Date).getTime() > new Date(b.Date).getTime() ? 1 : -1)
          }
        } else if (this.messages.findIndex(el => el.Value === lobbyChatMessage.Value) === -1) { // if a new message arrived
          this.messages = [...this.messages, lobbyChatMessage].sort((a, b) => new Date(a.Date).getTime() > new Date(b.Date).getTime() ? 1 : -1)

          this.scrollToBottom()
          setTimeout(() => { this.scrollToBottom() }, 100);
        }
        this.loader = false;

      })
  }

  scrollToBottom(): void {
    try {
      if (this.chatBoxContainer) {
        this.chatBoxContainer.nativeElement.scrollTop = this.chatBoxContainer.nativeElement.scrollHeight + 1000;
      }
    } catch (err) { console.log(err) }
  }


  openClose() {
    this.open = !this.open
    this.scrollToBottom()
    setTimeout(() => { this.scrollToBottom() }, 100);
    this.onOpenClose.emit(this.open);
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      // **** scrolled to the bottom
    } else if (event.target.scrollTop < 1) {
      // **** load more messages in lobby chat
      if (this.messages.length > 0 && !this.loader) {
        this.loader = true;
        this.dataManager.getChatLobbyHistory(this.messages[0].Value, this.PAGINATION_PER_PAGE)
      }
    }
  }

  sendChatMessage() {
    if (this.messageText && this.messageText.trim().length > 0) {
      this.dataManager.sendLobbyChatMessage(this.messageText);
      this.messageText = undefined;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
