export enum FilterType {
    Currency =  'currency',
    Variant = 'variant',
    Limit = 'limit',
    Stakes = 'stakes',
    Speed = 'speed',
    TableOption = 'tableOption',
    Other =  'other',
    GameType =  'gameType',
}
