import { Point } from '../models';

export class Vector {
    static randomOne(): Point {
        const _x = Math.random() * (Math.random() > 0.5 ? 1 : -1);
        const _y = Math.random() * (Math.random() > 0.5 ? 1 : -1);
        return <Point>{ x: _x, y: _y };
    }

    distace(A: Point, B: Point) {
        let dist: number;
        const x: number = A.x - B.x;
        const y: number = A.y - B.y;
        dist = Math.sqrt(x * x + y * y);
        return dist;
    }

    normalize(A: Point) {
        const length = Math.sqrt(A.x * A.x + A.y * A.y);
        const N: Point = { x: A.x / length, y: A.y / length };
        return N;
    }

    direction(target: Point, start: Point) {
        const direction: Point = { x: target.x - start.x, y: target.y - start.y };
        return this.normalize(direction);
    }
}
