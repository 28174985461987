import { Action } from '@ngrx/store';
import { TournamentInformation, TournamentActiveTable, Tournament, TournamentSummary } from '../../../shared/models';
import { LiveLobbyTournament } from '../../../shared/models/live-lobby-tournament';

export const ADD_TOURNAMENT = '[Tournament] ADD_TOURNAMENT';
export const UPDATE_TOURNAMENT = '[Tournament] UPDATE_TOURNAMENT';
export const UPDATE_BLIND_LIST = '[Tournament] UPDATE_BLIND_LIST';
export const SET_VIEW_TOURNAMENT = '[Tournament] SET_VIEW_TOURNAMENT';
export const VIEW_TOURNAMENT = '[Tournament] VIEW_TOURNAMENT';
export const UNVIEW_TOURNAMENT = '[Tournament] UNVIEW_TOURNAMENT';
export const REGISTER_ON_TOURNAMENT = '[Tournament] REGISTER_ON_TOURNAMENT';
export const UNREGISTER_FROM_TOURNAMENT = '[Tournament] UNREGISTER_FROM_TOURNAMENT';
export const UPDATE_TOURNAMENT_INFO = '[Tournament] UPDATE_TOURNAMENT_INFO';
export const SET_TOURNAMENT_SUMMARY = '[Tournament] SET_TOURNAMENT_SUMMARY';
export const UPDATE_TOURNAMENT_SUMMARY = '[Tournament] UPDATE_TOURNAMENT_SUMMARY';
export const LOGOUT = 'LOGOUT';
// 🛠️ CHANGE

export class AddTournament implements Action {
    readonly type = ADD_TOURNAMENT;

    constructor(public tournament: Tournament, public tournamentId: number) { }
}

export class UpdateTournament implements Action {
    readonly type = UPDATE_TOURNAMENT;

    constructor(public tournament: Tournament) { }
}

export class UpdateTournamentBlindList implements Action {
    readonly type = UPDATE_BLIND_LIST;

    constructor(public tournamentId: number, public blindLevels: Array<any>) { }
}

export class SetViewTournament implements Action {
    readonly type = SET_VIEW_TOURNAMENT;

    constructor(public tournamentId: number) { }
}

export class ViewTournament implements Action {
    readonly type = VIEW_TOURNAMENT;

    constructor(public tournamentSummary: TournamentSummary) { }
}

export class UnviewTournament implements Action {
    readonly type = UNVIEW_TOURNAMENT;

    constructor(public tournamentId: number) { }
}

export class RegisterOnTournament implements Action {
    readonly type = REGISTER_ON_TOURNAMENT;

    constructor(public tournamentId: number) { }
}

export class UnregisterFromTournament implements Action {
    readonly type = UNREGISTER_FROM_TOURNAMENT;

    constructor(public tournamentId: number) { }
}

export class UpdateTournamentInfo implements Action {
    readonly type = UPDATE_TOURNAMENT_INFO;

    constructor(public tournamentInformation: TournamentInformation) { }
}

export class SetTournamentSummary implements Action {
    readonly type = SET_TOURNAMENT_SUMMARY;

    constructor(public tournamentSummary: TournamentSummary) { }
}

export class UpdateTournamentSummary implements Action {
    readonly type = UPDATE_TOURNAMENT_SUMMARY;

    constructor(public liveLobbyTournament: LiveLobbyTournament) { }
}

export class Logout implements Action {
    readonly type = LOGOUT;

    constructor() { }
}

export type All
    = AddTournament
    | UpdateTournament
    | UpdateTournamentBlindList
    | SetViewTournament
    | ViewTournament
    | UnviewTournament
    | RegisterOnTournament
    | UnregisterFromTournament
    | UpdateTournamentInfo
    | SetTournamentSummary
    | UpdateTournamentSummary
    | Logout;
