import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

type legendTypes =
  'legend--player-count'
  | 'legend--table-speed'
  | 'legend--freeroll'
  | 'legend--dp-game'
  | 'legend--knockout'
  | 'legend--bounty';

@Component({
  selector: 'app-legend-indicator',
  templateUrl: './legend-indicator.component.html',
  styleUrls: ['./legend-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendIndicatorComponent implements OnInit {

  @Input() legend: string;
  @Input() description: string;
  @Input() className: legendTypes;

  constructor() { }

  ngOnInit() {
  }

}
