import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataManagerService } from '../../../../core/services/data-manager.service';
import { ManagerService } from '../../../../core/services/manager.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Antibot, AntibotDescription } from './antibot';

@Component({
  selector: 'app-antibot',
  templateUrl: './antibot.component.html',
  styleUrls: ['./antibot.component.scss']
})
export class AntibotComponent implements OnInit {

  antibot$: any;
  selectedAnswer: number;
  constructor(
    public dialogRef: MatDialogRef<AntibotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { playerId: number, tableId: number },
    private managerService: ManagerService,
    private translateService: TranslateService,
    private dataManagerService: DataManagerService,
  ) { }

  ngOnInit(): void {
    this.antibot$ = this.managerService.antiBotQuestion$.pipe(
      map((antibot) => {
        antibot.question = this.findCurentLang(antibot.Descriptions.Descriptions)
        return antibot
      })
    )
  }


  onVerify(data: Antibot) {
    if (!this.selectedAnswer) { return }
    const tableId = data.IdTable
    const answerId = data.AnswerId
    const antibotId = data.Id
    const answerValue = this.selectedAnswer
    this.dataManagerService.answerAntibot(tableId, answerId, antibotId, answerValue)
    this.dialogRef.close();

  }

  onSkip(data: Antibot) {
    this.selectedAnswer = undefined;
    const tableId = data.IdTable
    const answerId = data.AnswerId
    const antibotId = data.Id
    const answerValue = 0
    this.dataManagerService.answerAntibot(tableId, answerId, antibotId, answerValue)
  }

  findCurentLang(data: AntibotDescription[]): AntibotDescription {

    if (!data || data.length === 0) { return }

    let lang = data.find(el => el.Language === this.translateService.currentLang) // find by current language

    if (!lang) { // if current language doesnt exist first try to find english otherwise set first available language
      lang = data.find(el => el.Language === 'en')
      if (!lang) {
        lang = data[0]
      }
    }
    return lang
  }
}
