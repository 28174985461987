import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';

export abstract class PlayerTimer {
    isLeftSided: boolean;
    container: PIXI.Container;
    constructor(isLeftSided: boolean) {
        this.isLeftSided = isLeftSided;
    }

    public abstract update(newScale: number): void;
    public abstract reset(): void;
    public abstract changeToTimebankColor(): void;
    public abstract changeToDefaultColor(): void;
}
