import { AuthActions, AuthActionTypes } from './auth.actons';
import { User, Authenticate } from '../../shared/models/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlayerBalance, MemberProfile, Settings, PlayerLevelStatus } from '../../shared/models';
import { AccountType } from '../../shared/enums/poker-types';
import { environment } from '../../../environments/environment';

export interface State {
  connected: boolean;
  loggedIn: boolean;
  resetPasswordStarted: boolean;
  error?: string;
  user: User | null;
  registrationStep: number;
}

export const initialState: State = {
  connected: false,
  loggedIn: false,
  resetPasswordStarted: false,
  registrationStep: 0,
  user: {
    authenticate: {} as Authenticate,
    gifts: [],
    playerBalance: [],
    memberProfile: {} as MemberProfile,
    selectedCurrency: {} as any, // environment.money.defaultCurrency as any, // as any fix
    transactionsHistory: [],
    settings: {} as Settings,
    exchangeRate: {},
    playerLevelStatus: [],
  },
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.ConnectSuccess: {
      return {
        ...state,
        connected: true,
      };
    }
    case AuthActionTypes.ConnectFailure: {
      return {
        ...state,
        connected: false
      };
    }

    case AuthActionTypes.Login: {
      return {
        ...state,
        user: { ...state.user, authenticate: action.payload },
      };
    }

    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        loggedIn: true
      };
    }
    case AuthActionTypes.RequestSecurityCodeSuccess: {
      return {
        ...state,
        resetPasswordStarted: true
      };
    }
    case AuthActionTypes.ForgotPasswordCancel: {
      return {
        ...state,
        resetPasswordStarted: false
      };
    }
    case AuthActionTypes.CheckPromoCode:
    case AuthActionTypes.Register: {
      return {
        ...state,
        user: { ...state.user, memberProfile: action.payload },
      };
    }
    case AuthActionTypes.RegistartionStep: {
      return {
        ...state,
        registrationStep: action.payload,
      };
    }
    case AuthActionTypes.MemberProfileUpdate:
    case AuthActionTypes.MemberProfileReceived: {
      return {
        ...state,
        user: { ...state.user, memberProfile: action.payload },
      };
    }
    case AuthActionTypes.MemberPreferenceReceived: {
      state.user.memberProfile.Preferences = action.payload;
      return { ...state };
    }
    // case AuthActionTypes.PlayerBalanceUserUpdate: {
    //   // let state2 = JSON.parse(JSON.stringify(state))

    //   if (action.payload.Value === undefined) { // if currency is undefined
    //     action.payload.Value = 0;
    //   }
    //   if (action.payload.Value2 === undefined) { // if currency is undefined
    //     action.payload.Value2 = 0;
    //   }
    //   if (state.user.playerBalance === undefined) {
    //     state.user.playerBalance = [];
    //   }
    //   if (state.user.playerBalance === undefined || state.user.playerBalance[action.payload.Value2] === undefined) {
    //     state.user.playerBalance[action.payload.Value2] = <PlayerBalance>{};
    //   }
    //   switch (action.payload.Values[0]) {
    //     case 1:
    //       state.user.playerBalance[action.payload.Value2].availableMoney = action.payload.Value;
    //       break;
    //     case AccountType.CashOnTable:
    //       state.user.playerBalance[action.payload.Value2].onTable = action.payload.Value;
    //       break;
    //     case AccountType.CashToken:
    //       state.user.playerBalance[action.payload.Value2].cashToken = action.payload.Value;
    //       break;
    //     case AccountType.PointsToUnlock:
    //       state.user.playerBalance[action.payload.Value2].points = action.payload.Value;
    //       break;
    //   }


    //   //  state2.registrationStep = new Date().getTime()

    //   let d = []
    //   for (const iterator in state.user.playerBalance) {
    //     if(state.user.playerBalance[iterator]){d[iterator]=state.user.playerBalance[iterator]}
    //   }
    //   return { ...state };


    // }

    case AuthActionTypes.PlayerBalanceUserUpdate: {
      // let state2 = JSON.parse(JSON.stringify(state))


      // let playerBalance = JSON.parse(JSON.stringify(state.user.playerBalance))
      let playerBalance = []

      for (const index in state.user.playerBalance) {
        if (state.user.playerBalance[index]) { playerBalance[index] = state.user.playerBalance[index] }
      }

      if (action.payload.Value === undefined) { // if currency is undefined
        action.payload.Value = 0;
      }
      if (action.payload.Value2 === undefined) { // if currency is undefined
        action.payload.Value2 = 0;
      }
      if (playerBalance === undefined) {
        playerBalance = [];
      }
      if (playerBalance === undefined || playerBalance[action.payload.Value2] === undefined) {
        playerBalance[action.payload.Value2] = <PlayerBalance>{};
      }
      switch (action.payload.Values[0]) {
        case 1:
          playerBalance[action.payload.Value2].availableMoney = action.payload.Value;
          break;
        case AccountType.CashOnTable:
          playerBalance[action.payload.Value2].onTable = action.payload.Value;
          break;
        case AccountType.CashToken:
          playerBalance[action.payload.Value2].cashToken = action.payload.Value;
          break;
        case AccountType.PointsToUnlock:
          playerBalance[action.payload.Value2].points = action.payload.Value;
          break;
      }



      //  state2.registrationStep = new Date().getTime()
      // return { ...state };

      return {
        ...state,
        user: { ...state.user, playerBalance },
      };
    }



    case AuthActionTypes.GiftsUserUpdate: {
      return {
        ...state,
        user: { ...state.user, gifts: action.payload },
      };
    }

    case AuthActionTypes.SettingsUpdate: {
      action.payload.WithdrawalRequireVerification = action.payload.WithdrawalRequireVerification === 'True' ? true : false;
      action.payload.RecaptchaRequired = action.payload.RecaptchaRequired === 'True' ? true : false;
      action.payload.PopupIdTournament = Number(action.payload.PopupIdTournament);
      return {
        ...state,
        user: { ...state.user, settings: action.payload },
      };
    }

    case AuthActionTypes.PlayerLevelStatusUpdate: {
      //action.payload.Levels = new Array(action.payload.Level);
      state.user.playerLevelStatus[action.payload.Currency] = action.payload;
      return { ...state };
    }

    case AuthActionTypes.ExchangeRateUserUpdate: {

      if (action.payload.currency === undefined) { // if currency is undefined
        action.payload.currency = 0;
      }
      if (action.payload.value === undefined) {
        action.payload.currency = 0;
      }

      state.user.exchangeRate[action.payload.currency] = action.payload.value;
      return { ...state };
    }

    case AuthActionTypes.CurrencyChange: {
      // return {
      //   ...state,
      //   user: { ...state.user, selectedCurrency: action.payload },
      // }; fix currency
    }

    case AuthActionTypes.Logout: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getConnected = (state: State) => state.connected;
export const getLoggedIn = (state: State) => state.loggedIn;
export const getResetPasswordStarted = (state: State) => state.resetPasswordStarted;
export const getUser = (state: State) => state.user;
export const getSelectedCurrency = (state: State) => state.user.selectedCurrency;
export const getUserSettings = (state: State) => state.user.settings;
export const getRegistrationStep = (state: State) => state.registrationStep;

export const getState = createFeatureSelector<State>('auth');
export const getAuthState = createSelector(getState, state => state);
export const getConnectedState = createSelector(getState, getConnected);
export const getResetPasswordStartedState = createSelector(getState, getResetPasswordStarted);
export const getLoggedInState = createSelector(getState, getLoggedIn);
export const getUserState = createSelector(getState, getUser);
export const getSelectedCurrencyState = createSelector(getState, getSelectedCurrency);
export const getUserSettingsState = createSelector(getState, getUserSettings);
export const getRegistrationStepState = createSelector(getState, getRegistrationStep);


export const getUserBalance = (state: State) => state.user.playerBalance;

export const getUserBalanceState = createSelector(getState, getUserBalance);
