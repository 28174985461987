import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { GameHistory } from '../../../../../shared/models/game-history';
import { Chat } from '../../../../../shared/models/chat';
import { DataManagerService } from '../../../../../core/services/data-manager.service';

@Component({
  selector: 'app-game-controls-history',
  templateUrl: './game-controls-history.component.html',
  styleUrls: ['./game-controls-history.component.scss']
})
export class GameControlsHistoryComponent implements OnInit {

  @ViewChild('scrollMe2', { static: false }) scrollMe: ElementRef;
  public scrollTop: number = null;

  private _gameHistories: Array<GameHistory> = [];
  private scrollToBottomTimeOut;

  @Input()
  set gameHistories(value: Array<GameHistory>) {
    this._gameHistories = value;

    this.scrollToBottom()
    clearTimeout(this.scrollToBottomTimeOut);
    this.scrollToBottomTimeOut = setTimeout(() => { this.scrollToBottom() }, 100);
  }
  get gameHistories(): Array<GameHistory> {
    return this._gameHistories;
  }

  ngOnInit() {
    setTimeout(() => { this.scrollToBottom() }, 100);
    this.scrollToBottom()

  }


  constructor() { }

  scrollToBottom(): void {
    try {
      if (this.scrollMe) {
        this.scrollTop = this.scrollMe.nativeElement.scrollHeight + 1000;
      }
    } catch (err) { console.log(err) }
  }
}
