import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { SpinnerService } from '../../core/spinner/spinner.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { StoredUser } from '../../shared/models/stored-user';
import { VERSION } from '../../../environments/version';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppWebSocketService } from '../../core/services/app-web-socket.service';
import { Settings } from '../../shared/models';
import { ConnectionStatus } from '../../shared/enums';
import { ManagerService } from '../../core/services/manager.service';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit, OnDestroy {

  username: string;
  password: string;
  token: string;
  settings: Settings

  environment = environment;
  appVersion = VERSION.current;
  loader = false
  destroy$ = new Subject<boolean>();

  config;
  constructor(
    private _route: ActivatedRoute,
    private _spinnerService: SpinnerService,
    private _authService: AuthService,
    private _socketService: AppWebSocketService,
    private _managerService: ManagerService,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.login()
    }
  }

  ngOnInit() {
    this._spinnerService.show();

    this._socketService.connectionStatus$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((connectionStatus: ConnectionStatus) => {

        if (connectionStatus === ConnectionStatus.close) {
          this._authService.wsConnect() // ## In case that user previously logout
        }
      });

    this._managerService.loginState$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (loggedIn) => {
          if (!loggedIn) {
            this.loader = false
          }
        }
      })

    this._managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (settings) {
            this.settings = settings;
            this._spinnerService.hide();
          }
        }
      })

    this._socketService.connectionStatus$
      .pipe(
        switchMap((connectionStatus) => {
          if (connectionStatus === ConnectionStatus.open) {
            return this._route.queryParams
          } else {
            return of(null)
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(queryParams => {

        const storedUser = JSON.parse(localStorage.getItem('auth-login')) as StoredUser;
        const exitUrl = queryParams ? queryParams['exitUrl'] : null

        if (queryParams && queryParams['token']) {
          this.token = queryParams['token'];

          this._managerService.login({ token: queryParams['token'] })
        } else if (exitUrl) {
          window.location.href = exitUrl;
        } else if (storedUser) {
          this.username = storedUser.username;
          this.password = storedUser.password;
          this._authService.shouldStoreUser = true;

          this._managerService.settings$
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: () => {
                // auto login /remember me  this.login()
              }
            })
        }
      })

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  login() {

    const username = this.username.trimLeft().trimRight() ?? ''
    const password = this.password

    if (!username || !password) { return }

    this.loader = true
    this._managerService.login({ username, password })
  }

  register() {
    if (this.settings.RegistrationUrl) {
      window.open(this.settings.RegistrationUrl, '_blank');
    }
  }

  rememberMe(status) {
    this._authService.shouldStoreUser = status
  }

}
