import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { passwordMatchValidator } from '../../../shared/validators/validators';
import { environment } from '../../../../environments/environment';
import { ManagerService } from '../../services/manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';



@Component({
  selector: 'app-create-new-password-dialog',
  templateUrl: './create-new-password-dialog.component.html',
  styleUrls: ['./create-new-password-dialog.component.scss']
})
export class CreateNewPasswordDialog implements OnInit {
  environment = environment;
  destroy$ = new Subject<boolean>();


  passwordForm: FormGroup;

  formErrors = {
    'password': '',
    'passwordConfirm': '',
    'mismatchedPasswords': ''
  };

  validationMessages = {
    'password': {
      'required': 'dialog.resetPassword.formError.password.required',
      'invalidPassword': 'dialog.resetPassword.formError.password.invalidPassword',
      'minlength': 'dialog.resetPassword.formError.password.minLength',
      'maxlength': 'dialog.resetPassword.formError.password.maxLength',
    },
    'passwordConfirm': {
      'required': 'dialog.resetPassword.formError.passwordConfirm.required',
    }
  };
  config;

  constructor(
    public managerService: ManagerService,
    public dialogRef: MatDialogRef<CreateNewPasswordDialog>,
    private fb: FormBuilder,
    private configService: ConfigService,
  ) {
    this.buildForm();
    this.config = this.configService.config;

  }

  ngOnInit() {
    // ** On Logout Close
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (!settings) {
            this.dialogRef.close();
          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submitNewPassword(formData) {
    if (!this.passwordForm.valid) {
      return
    }
    this.dialogRef.close(formData.password);
  }

  cancel() {
    this.dialogRef.close();
  }

  buildForm(): void {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]],
      passwordConfirm: ['', Validators.required],
    }, { validator: passwordMatchValidator });

    this.passwordForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); // (re)set validation messages now
  }

  onValueChanged(data?: any) {
    if (!this.passwordForm) { return; }
    const form = this.passwordForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
}
