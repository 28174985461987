import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Tournament, TournamentPrize2 } from '../../../../shared/models';
import { User } from '../../../../shared/models/user';
import { Comparators } from '../../../../shared/helpers/comparators';
import { BountySpellVariant, SpellStatus } from '../../../../shared/models/bounty-spell';
import { ManagerService } from '../../../../core/services/manager.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBountySpellInfoComponent } from '../../../bounty-spell/dialog-bounty-spell-info/dialog-bounty-spell-info.component';
import { take } from 'rxjs/operators';
import { DialogBountySpellActiveComponent } from '../../../bounty-spell/dialog-bounty-spell-active/dialog-bounty-spell-active.component';
import { DialogPlayerSpellsComponent } from '../../../game/dialogs/dialog-player-spells/dialog-player-spells.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'view-tournament-players-and-prizes',
  templateUrl: './view-tournament-players-and-prizes.component.html',
  styleUrls: ['./view-tournament-players-and-prizes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTournamentPlayersAndPrizesComponent {

  prizeColumns: string[] = []
  spellStatus = SpellStatus
  bountySpellVariant = BountySpellVariant
  @Input() user: User;
  @Input() spellZone: boolean;

  @Input()
  get tournament(): Tournament { return this._tournament; }
  set tournament(value: Tournament) {
    this._tournament = value;

    // SORTING
    if (this._tournament?.tourInfo) {
      this.tournament.tourInfo.Players = this.tournament?.tourInfo?.Players.sort((a, b) => {
        return Comparators.compare(+a.Rank, +b.Rank, true);
      });
    }




    if (this._tournament?.tourInfo?.Prizes2.length > 0) {
      const columns = {}
      for (const item of this._tournament?.tourInfo?.prizes as TournamentPrize2[]) {
        if (item.Position) {
          columns['Position'] = true;
        }
        if (item.Chips && item.Chips.Percentage) {
          columns['ChipsPercentage'] = true;
        }
        if (item.Chips && item.Chips.Amount) {
          columns['ChipsAmount'] = true;
        }
        if (item.Tickets) {
          columns['Tickets'] = true;
        }
        if (item.ExternalTickets) {
          columns['ExternalTickets'] = true;
        }
        if (item.ExternalPoints) {
          columns['Points'] = true;
        }
      }
      this.prizeColumns = Object.keys(columns);

    }

  }

  private _tournament: Tournament;


  constructor(
    private managerService: ManagerService,
    private translateService: TranslateService,
    private dialog: MatDialog,

  ) {

  }

  openLegend() {

    this.managerService.settings$.pipe(take(1))
      .subscribe(settings => {
        const data = settings.BountySpellDescriptionModel

        for (const item of Object.keys(data)) {
          data[item].Info = this.findCurentLang(data[item].Descriptions)
        }

        this.dialog.open(DialogBountySpellInfoComponent, {
          width: '360px',
          data
        })
      })
  }


  findCurentLang(data: any[]): any {

    if (!data || data.length === 0) { return }

    let lang = data.find(el => el.Language === this.translateService.currentLang) // find by current language

    if (!lang) { // if current language doesnt exist first try to find english otherwise set first available language
      lang = data.find(el => el.langId === 'en')
      if (!lang) {
        lang = data[0]
      }
    }

    return lang
  }

  openPlayeSpells(player) {
    console.log("MIGHTY PLAYER SPELLS", player)
    const spells = [...player.PlayerSpells.EarnedSpells]
    this.dialog.open(DialogPlayerSpellsComponent, {
      width: '300px',
      data: { player, spells, playerName: player.Name }
    })
  }
}
