export const Legends = {
    // OLD
    // X: {
    //     label: 'X',
    //     className: 'legend--player-count',
    //     description: 'No of Players on Table',
    // },
    // H: {
    //     label: 'H',
    //     className: 'legend--table-speed',
    //     description: 'Fast Speed Games',
    // },
    // N: {
    //     label: 'N',
    //     className: 'legend--table-speed',
    //     description: 'Normal Speed Games',
    // },
    // D: {
    //     label: 'D',
    //     className: 'legend--dp-game',
    //     description: 'Deep Stack Games',
    // },
    // F: {
    //     label: 'F',
    //     className: 'legend--freeroll',
    //     description: 'Type',
    // },
    // B: {
    //     label: 'B',
    //     className: 'legend--bounty',
    //     description: 'Bounty',
    // },


    // NEW v2
    ANONYMOUS: {
        label: 'LEGEND.ANONYMOUS',
        image: 'anonymous'
    },
    CAP: {
        label: 'LEGEND.CAP',
        image: 'cap'
    },
    HILO: {
        label: 'LEGEND.HILO',
        image: 'hilo'
    },
    DOUBLE_REBUY: {
        label: 'LEGEND.DOUBLE_REBUY',
        image: 'double-rebuy'
    },
    REBUY: {
        label: 'LEGEND.REBUY',
        image: 'rebuy'
    },

    FAST: {
        label: 'LEGEND.FAST',
        image: 'fast'
    },
    LATE: {
        label: 'LEGEND.LATE',
        image: 'late'
    },
    STRADDLE: {
        label: 'LEGEND.STRADDLE',
        image: 'straddle'
    },
    ROCK: {
        label: 'LEGEND.ROCK',
        image: 'rock'
    },
    RE_ENTRY: {
        label: 'LEGEND.RE_ENTRY',
        image: 're-entry'
    },

    BOUNTY: {
        label: 'LEGEND.BOUNTY',
        image: 'bounty'
    },
    DEALER_BOUNTY: {
        label: 'LEGEND.DEALER_BOUNTY',
        image: 'dealer-bounty'
    },
    ANTE: {
        label: 'LEGEND.ANTE',
        image: 'ante'
    },
    PRIVATE: {
        label: 'LEGEND.PRIVATE',
        image: 'private'
    },
    FREEZEOUT: {
        label: 'LEGEND.FREEZEOUT',
        image: 'freezeout'
    },
    JACKPPOT: {
        label: 'LEGEND.JACKPOT',
        image: 'jackpot'
    },
    RABBIT_HUNTING: {
        label: 'LEGEND.RABBIT_HUNTING',
        image: 'rabbit-hunting'
    },
    CALL_TIME: {
        label: 'LEGEND.CALL_TIME',
        image: 'call-time'
    },
    DISCONNECTION_PROTECTION: {
        label: 'LEGEND.DISCONNECTION_PROTECTION',
        image: 'disconnection-protection'
    },
};
