import { Component, OnInit } from '@angular/core';
import { Legends } from '../../../../shared/constants/legend.constants';


@Component({
  selector: 'app-dialog-legend-symbol',
  templateUrl: './dialog-legend-symbol.component.html',
  styleUrls: ['./dialog-legend-symbol.component.scss']
})
export class DialogLegendSymbol implements OnInit {

  legendFields = Object.keys(Legends)
  legends = Legends
  constructor() { }

  ngOnInit(): void {
  }

}
