import * as PIXI from 'pixi.js';
import { Point } from '../models';

export const potTotalTextTopPosition: PIXI.Point = new PIXI.Point(870, 311); // top-left
export const potTotalTextRightPosition: PIXI.Point = new PIXI.Point(1281, 440); // top-left

export const TablePosition: Point = { x: 270, y: 160 }; // top-left
// export const TablePositionUnlimited: Point = { x: 960, y: 480 }; // center
export const TableShadowPosition: Point = { x: 270, y: 290 }; // top-left
export const MainPotPositions: Point[] = [
    { x: 590, y: 460 }, // center
    { x: 590, y: 510 }
];
export const FpsPosition: Point = { x: 10, y: 140 };

export const SexyDealerPosition: Point = { x: 960, y: 136 };
// export const SexyDealerPositionUnlimited: Point = { x: 960, y: 108 };

export const SeatsVisibility = {
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [0, 1, 0, 0, 0, 0, 1, 0, 0, 0], // 2 players
    3: [0, 0, 0, 1, 0, 0, 1, 0, 0, 1], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [1, 0, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};

export const SeatsVisibilityWithDealer = {
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [0, 0, 0, 0, 1, 0, 0, 0, 1, 0], // 2 players
    3: [1, 0, 1, 0, 0, 0, 1, 0, 0, 0], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [1, 0, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};


export const SeatPositionsStandard: Point[] = [
    { x: 592, y: 145 },
    { x: 960, y: 145 },
    { x: 1328, y: 145 },

    { x: 1637, y: 316 },
    { x: 1637, y: 593 }, // center

    { x: 1328, y: 821 },
    { x: 960, y: 821 },
    { x: 592, y: 821 },

    { x: 292, y: 593 },
    { x: 292, y: 316 },
];
export const SeatPositionsStandardFor6Players: Point[] = [
    { x: 356 + 65, y: 118 + 65 },
    { x: 895 + 65, y: 79 + 65 },
    { x: 1451 + 65, y: 118 + 65 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1451 + 65, y: 658 + 65 },
    { x: 895 + 65, y: 756 + 65 },
    { x: 356 + 65, y: 658 + 65 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

// export const SeatPositionsUnlimited: Point[] = [
//     { x: 537, y: 143 + 12},
//     { x: 954, y: 143 + 12},
//     { x: 1371, y: 143 + 12},
//     { x: 1725, y: 331 + 12},
//     { x: 1725, y: 582 + 12}, // center
//     { x: 1371, y: 824 - 60 },
//     { x: 954, y: 824 - 60 },
//     { x: 537, y: 824 - 60 },
//     { x: 196, y: 585 + 12},
//     { x: 196, y: 332 + 12},
// ];
// export const SeatPositionsFor6PlayersUnlimited: Point[] = [
//     { x: 317, y: 193 },
//     { x: 954, y: 144 },
//     { x: 1607, y: 193 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1607, y: 720 - 60 },
//     { x: 954, y: 825 - 60 },
//     { x: 317, y: 720 - 60 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];

// export const SeatPositionsAdvanced: Point[] = [
//     { x: 541, y: 157 },
//     { x: 958, y: 157 },
//     { x: 1367, y: 157 },
//     { x: 1621, y: 346 },
//     { x: 1621, y: 598 }, // center
//     { x: 1367, y: 838 },
//     { x: 953, y: 838 },
//     { x: 542, y: 838 },
//     { x: 301, y: 598 },
//     { x: 301, y: 346 },
// ];
// export const SeatPositionsAdvancedFor6Players: Point[] = [
//     { x: 419, y: 190 },
//     { x: 958, y: 157 },
//     { x: 1504, y: 190 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1504, y: 764 },
//     { x: 953, y: 838 },
//     { x: 419, y: 764 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];

export const PotPositions: Point[] = [
    { x: 594, y: 301 },
    { x: 959, y: 301 },
    { x: 1329, y: 301 },

    { x: 1459, y: 366 },
    { x: 1459, y: 581 }, // center

    { x: 1329, y: 651 },
    { x: 959, y: 651 },
    { x: 594, y: 651 },

    { x: 450, y: 581 },
    { x: 450, y: 366 },
];
export const PotPositionsFor6Players: Point[] = [
    { x: 519, y: 327 },
    { x: 990, y: 285 },
    { x: 1399, y: 327 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1399, y: 622 },
    { x: 960, y: 642 },
    { x: 519, y: 622 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const PlayerPositions: Point[] = [
    { x: 593 - 25, y: 146 + 25 },
    { x: 960 - 25, y: 146 + 25 },
    { x: 1327 - 25, y: 146 + 25 },

    { x: 1694, y: 330 + 25 },
    { x: 1694, y: 600 + 25 },

    { x: 1327 - 25, y: 792 + 25 },
    { x: 960 - 25, y: 792 + 25 }, // center
    { x: 593 - 25, y: 792 + 25 },

    { x: 226 - 25, y: 600 + 25 },
    { x: 226 - 25, y: 330 + 25 },
];
export const PlayerPositionsFor6Players: Point[] = [
    // { x: 320, y: 179 },
    // { x: 956, y: 149 },
    // { x: 1606, y: 179 },
    // { x: 0, y: 0 },
    // { x: 0, y: 0 }, // center
    // { x: 1606, y: 705 },
    // { x: 956, y: 810 },
    // { x: 320, y: 705 },
    // { x: 0, y: 0 },
    // { x: 0, y: 0 },


    { x: 422 - 25, y: 162 + 25 },
    { x: 960 - 25, y: 146 + 25 },
    { x: 1518 - 25, y: 162 + 25 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1518 - 25, y: 776 + 25 },
    { x: 960 - 25, y: 792 + 25 },
    { x: 422 - 25, y: 776 + 25 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

// export const PlayerPositionsUnlimited: Point[] = [
//     { x: 539, y: 129 },
//     { x: 956, y: 129 },
//     { x: 1369, y: 129 },
//     { x: 1723, y: 317 },
//     { x: 1723, y: 570 }, // center
//     { x: 1369, y: 810 - 72 },
//     { x: 956, y: 810 - 72 },
//     { x: 539, y: 810 - 72 },
//     { x: 199, y: 570 },
//     { x: 199, y: 317 },
// ];
// export const PlayerPositionsFor6PlayersUnlimited: Point[] = [
//     { x: 320, y: 179 },
//     { x: 956, y: 129 },
//     { x: 1606, y: 179 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1606, y: 705 - 72 },
//     { x: 956, y: 810 - 72 },
//     { x: 320, y: 705 - 72 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];

export const DealerChipPositions: Point[] = [
    { x: 596, y: 265 },
    { x: 1108, y: 265 },
    { x: 1314, y: 265 },
    { x: 1486, y: 378 },
    { x: 1486, y: 551 }, // center
    { x: 1313, y: 656 },
    { x: 973, y: 660 },
    { x: 598, y: 656 },
    { x: 427, y: 576 },
    { x: 433, y: 337 },
];
export const DealerChipPositionsFor6Players: Point[] = [
    { x: 468, y: 347 },
    { x: 1106, y: 265 },
    { x: 1462, y: 347 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1462, y: 558 },
    { x: 973, y: 660 },
    { x: 468, y: 558 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

// export const PotPositionsUnlimited: Point[] = [
//     { x: 667, y: 266 },
//     { x: 1036, y: 300 },
//     { x: 1242, y: 266 },
//     { x: 1456, y: 376 },
//     { x: 1456, y: 598 }, // center
//     { x: 1245, y: 660 }, // { x: 1245, y: 660 - 62 },
//     { x: 1039, y: 650 }, // { x: 1039, y: 660 - 62 },
//     { x: 667, y: 660 }, // { x: 667, y: 660 - 62 },
//     { x: 443, y: 617 },
//     { x: 443, y: 376 },
// ];
// export const PotPositionsFor6PlayersUnlimited: Point[] = [
//     { x: 493, y: 306 },
//     { x: 1036, y: 300 },
//     { x: 1438, y: 306 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1438, y: 600 - 62 },
//     { x: 1039, y: 650 }, // { x: 1039, y: 660 - 62 },
//     { x: 493, y: 600 - 62 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];

// export const DealerChipPositionsUnlimited: Point[] = [
//     { x: 596, y: 265 },
//     { x: 858, y: 293 },
//     { x: 1314, y: 265 },
//     { x: 1486, y: 378 },
//     { x: 1486, y: 551 }, // center
//     { x: 1313, y: 656 },
//     { x: 888, y: 660 },
//     { x: 598, y: 656 },
//     { x: 427, y: 576 },
//     { x: 433, y: 337 },
// ];
// export const DealerChipPositionsFor6PlayersUnlimited: Point[] = [
//     { x: 468, y: 347 },
//     { x: 858, y: 293 },
//     { x: 1462, y: 347 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1462, y: 558 - 62 },
//     { x: 888, y: 660 },
//     { x: 468, y: 558 - 62 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];

export const ClubLogoLeftPosition: Point = { x: 735, y: 480 };
export const ClubLogoRightPosition: Point = { x: 1185, y: 480 };
export const BrandLogoPosition: Point = { x: 960, y: 400 };

export const BlindsTextPosition: Point = { x: 960, y: 597 };
export const TournamentTimersPosition: Point = { x: 1600, y: 0 };
export const TableNamTextPosition: Point = { x: 960, y: 612 - 40 };
export const TableBlindsTextPosition: Point = { x: 960, y: 612 - 5 };

export const TripleDrawInfoPosition: Point = { x: 960, y: 445 };


export const TableTextRabbitHunting: Point = { x: 960, y: 580 };
