import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Colors } from '../../../shared/constants/color.constants';
import { Currency } from '../../../shared/enums';
import { LobbyFilters, VariantType2 } from '../../../shared/enums/poker-types';
import { User } from '../../../shared/models/user';
import { TournamentStatus2 } from '../../../shared/enums/poker-types';
import { MatDialog } from '@angular/material/dialog';
import { GenericTextDialog } from '../../../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { CurrencyPipe } from '../../../shared/utils/currency.pipe';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';
import { ManagerService } from '../../../core/services/manager.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lobby-list-table',
  templateUrl: './lobby-list-table.component.html',
  styleUrls: ['./lobby-list-table.component.scss']
})
export class LobbyListTableComponent implements OnInit {

  @Output() onTableRowClick: EventEmitter<any> = new EventEmitter();
  @Output() onTableRowDblClick: EventEmitter<any> = new EventEmitter();
  @Output() onSortChange: EventEmitter<any> = new EventEmitter();
  @Output() onLegend: EventEmitter<any> = new EventEmitter();

  @Input() user: User;
  @Input() data: any[]
  @Input() type: LobbyFilters
  @Input() sort: any
  @Input() selectedTableId: string;

  enumLobbyFilters = LobbyFilters;
  enumColors = Colors;

  enumTournamentStatus2 = TournamentStatus2;
  enumVariantType2 = VariantType2
  constructor(private dialog: MatDialog, private managerService: ManagerService) { }

  ngOnInit(): void {
  }

  legendSymbolClick() {
    this.onLegend.emit();
  }

  rowClick(row) {
    console.log("@rowClick")
    this.onTableRowClick.emit(row);
  }

  rowDblCLick(row) {
    console.log("@rowDblCLick")

    this.onTableRowDblClick.emit(row);
  }

  sortChange(field: string, data: string) {
    this.onSortChange.emit({ field, data });
  }

  trackById(index, item): string {
    return `${item.Id}`;
  }


  openCallTimeInfo(row) {
    const dialogRef = this.dialog.open(GenericTextDialog, { width: '300px', disableClose: false });

    dialogRef.componentInstance.textHeader = 'Call Time';
    dialogRef.componentInstance.textBody = `
<div><strong>> +${row.CallTimeConfiguration.CallTimePercent}%</strong></div>
<div>${row.CallTimeConfiguration.CallTimeDuration}min</div>
`;

    dialogRef.componentInstance.enableClose = false;

    dialogRef.componentInstance.enableAccept = false;

  }

  openJackpotInfo(data) {
    this.managerService.jackPotsInfo$
      .pipe(take(1))
      .subscribe(jackPotsInfo => {

        let jackpot: any;
        for (const item of jackPotsInfo) {
          if (item.Id === data.Id) {
            jackpot = item
            jackpot.prizePool = CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.PrizePool, this.user.selectedCurrency), this.user.selectedCurrency)
          }
        }



        const dialogRef = this.dialog.open(GenericTextDialog, { width: '300px', disableClose: false });

        dialogRef.componentInstance.textHeader = jackpot.Name;
        dialogRef.componentInstance.textBody = `
    <div>${jackpot.prizePool}</div>
    <div>${jackpot.Description}</div>
    `;

        dialogRef.componentInstance.textDismissBtn = `OK`

        dialogRef.componentInstance.enableAccept = false;
      })


  }

}
