import { PhoneVerificationDialogComponent } from '../main/cashier/phone-verification-dialog/phone-verification-dialog.component';
import { GenericTextDialog } from '../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, BehaviorSubject, Subscription, Subject, ReplaySubject } from 'rxjs';
import * as MobileDetect from 'mobile-detect/mobile-detect';
import { CreateNewPasswordDialog } from '../core/dialogs/create-new-password-dialog/create-new-password-dialog.component';
import { filter, delay, windowCount, timeout, mergeAll, tap, take, takeUntil, switchMap } from 'rxjs/operators';

// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/delay';

import { NotificationsService } from '../core/notifications/notifications.service';
import { ServerMsg, Settings } from '../shared/models';
import { ServerMessage, ErrorCode } from '../shared/enums/poker-types';
import { DataManagerService } from '../core/services/data-manager.service';
import { ConnectionStatus } from '../shared/enums';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppWebSocketService } from '../core/services/app-web-socket.service';
import { LostConnectionService } from '../core/lost-connection/lost-connection.service';
import { ManagerService } from '../core/services/manager.service';
import { User } from '../shared/models/user';
import { ForceChangePasswordComponent } from '../main/dialogs/force-change-password/force-change-password.component';

export interface PlayersStatistics {
  online: number;
  ringTable: number;
  tournament: number;
}

@Injectable()
export class AuthService {

  user: User;
  settings: Settings;
  email: string;
  pingSubscription: Subscription;

  shouldStoreUser: boolean = false

  isForceChangePasswordDialogOpen = {};

  constructor(
    private socketService: AppWebSocketService,
    private dataManager: DataManagerService,
    private managerService: ManagerService,

    private dialog: MatDialog,

  ) {

    this.managerService.user$.subscribe({
      next: (user) => {
        this.user = user


        if (!this.isForceChangePasswordDialogOpen[user?.memberProfile?.Id] && user?.memberProfile?.ForceChangePassword) {
          this.isForceChangePasswordDialogOpen[user?.memberProfile?.Id] = true
          this.dialog.open(ForceChangePasswordComponent, {
            width: '300px',
            disableClose: true,
            backdropClass: 'backdropForceChangePasswordDialog'
          })


        }
      }

    })


    this.managerService.settings$
      .subscribe({
        next: (settings) => {
          this.settings = settings;
          if (!settings) {
            this.stopPinging();
            this.shouldStoreUser = false
          }
        }
      })

  }

  wsConnect() {
    this.socketService.initializeWebSocket();
    this.dataManager.sendClientInfo().pipe(take(1)).subscribe()
  }

  startPinging() {
    this.dataManager.sendPing();
    if (!this.pingSubscription) {
      this.pingSubscription =
        this.dataManager.messageReceivedObservable
          .pipe(
            filter(data => data.Response === 'ServerMsg'),
            filter(el => el.ServerMsg.filter(item => item.MsgType === ServerMessage.Pong).length > 0),
            delay(5000), // Delay Ping request for 5 seconds
          ).subscribe({
            next: () => {
              this.dataManager.sendPing();
            }, error: () => {

            }
          })
    }


    // ++

    if (this.shouldStoreUser) {
      localStorage.setItem('auth-login', JSON.stringify(
        {
          timestamp: Date.now(),
          type: `login`,
          username: this.user.authenticate.username ? this.user.authenticate.username : this.email,
          password: this.user.authenticate.password
        }
      ));


    } else {
      localStorage.removeItem('auth-login');
    }
  }

  stopPinging() {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
      this.pingSubscription = null;
    }
  }

}








  // this.socketService.connectFailure()

  // APRIL TO DO
  //       this.store.dispatch(new AppActions.AuthAction.ConnectFailure());





/*
if (loggedIn) {
   this.store$.dispatch(new AppActions.GameAction.Logout());
   this.store$.dispatch(new AppActions.TournamentsActions.Logout());
   this.store$.dispatch(new AppActions.NavAction.Logout());
   this.lostConnectionService.show();
   this.socketService.disconnect();
   setTimeout(() => {
     this.store$.dispatch(new AppActions.AuthAction.Connect());
   }, 2000);
   // this.authService.showInternetConnectionDialog();
 } else {
   this.lostConnectionService.hide();
 }
 this.authService.stopPinging();
*/
  // });
  // }
  // }