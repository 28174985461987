import * as PIXI from 'pixi.js';

import { Point, Size } from '../models';
import { Table } from './table';

export class AskShowCard {
    container: any;
    timer: number;

    backOverlay: any;
    backOverlayDefaultColor = 0x000000;
    backOverlayAlpha = 0.8;

    title: any;
    titleStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 70, fontWeight: 'bold', fill: '#ffffff' });
    titlePositionY = 236;

    countdownText: any;
    countdownStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 50, fontWeight: 'bold', fill: '#ffffff' });
    countdownPositionY = 387;

    buttonsColor = 0xc82323;
    buttonsRoundnes = 5;
    buttonsSize: Size = { width: 367, height: 148 };
    buttonsTextStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 50, fontWeight: 'bold', fill: '#ffffff' });

    cancelButton: any;
    cancelButtonPosition: Point = { x: 554, y: 505 };
    cancelButtonText = 'Cancel';
    cancelButtonTextPosition: Point = { x: 736, y: 579 };

    confirmButton: any;
    confirmButtonPosition: Point = { x: 1000, y: 505 };
    confirmButtonText = 'Confirm';
    confirmButtonTextPosition: Point = { x: 1184, y: 579 };

    position: Point;
    isActive = false;

    constructor(public gameWidth: number, public gameHeight: number, public table: Table, startPosition?: Point) {
        this.container = new PIXI.Container();

        if (startPosition !== undefined) {
            this.position = startPosition;
        } else {
            this.position = { x: 0, y: 0 };
        }

        // create back overlay
        this.backOverlay = new PIXI.Graphics();
        this.backOverlay.beginFill(this.backOverlayDefaultColor, this.backOverlayAlpha);
        this.backOverlay.drawRect(0, 0, this.gameWidth, this.gameHeight);
        this.backOverlay.endFill();
        this.container.addChild(this.backOverlay);

        // title text
        this.title = new PIXI.Text('Show Cards?', this.titleStyle);
        this.title.position.set(this.gameWidth / 2, this.titlePositionY);
        this.title.anchor.set(0.5, 0.5);
        this.container.addChild(this.title);

        // countdown text
        this.countdownText = new PIXI.Text('T', this.titleStyle);
        this.countdownText.position.set(this.gameWidth / 2, this.countdownPositionY);
        this.countdownText.anchor.set(0.5, 0.5);
        this.container.addChild(this.countdownText);

        // cancel button
        this.cancelButton = new PIXI.Graphics();
        this.cancelButton.beginFill(this.buttonsColor);
        this.cancelButton.drawRoundedRect(this.cancelButtonPosition.x, this.cancelButtonPosition.y,
            this.buttonsSize.width, this.buttonsSize.height);
        this.cancelButton.endFill();
        this.container.addChild(this.cancelButton);
        this.cancelButton.interactive = true;
        this.cancelButton.on('mousedown', (event) => { this.table.actionShowCards(0); this.stopPause(); });
        this.cancelButton.on('touchstart', (event) => { this.table.actionShowCards(0); this.stopPause(); });
        let buttonText = new PIXI.Text(this.cancelButtonText, this.buttonsTextStyle);
        buttonText.position.set(this.cancelButtonTextPosition.x, this.cancelButtonTextPosition.y);
        buttonText.anchor.set(0.5, 0.5);
        this.container.addChild(buttonText);

        // confirm button
        this.confirmButton = new PIXI.Graphics();
        this.confirmButton.beginFill(this.buttonsColor);
        this.confirmButton.drawRoundedRect(this.confirmButtonPosition.x, this.confirmButtonPosition.y,
            this.buttonsSize.width, this.buttonsSize.height);
        this.confirmButton.endFill();
        this.container.addChild(this.confirmButton);
        this.confirmButton.interactive = true;
        this.confirmButton.on('mousedown', (event) => { this.table.actionShowCards(1); this.stopPause(); });
        this.confirmButton.on('touchstart', (event) => { this.table.actionShowCards(1); this.stopPause(); });
        buttonText = new PIXI.Text(this.confirmButtonText, this.buttonsTextStyle);
        buttonText.position.set(this.confirmButtonTextPosition.x, this.confirmButtonTextPosition.y);
        buttonText.anchor.set(0.5, 0.5);
        this.container.addChild(buttonText);
    }

    update(dt) {
        if (this.isActive) {
            this.timer -= dt / 1000;
            if (this.timer <= 0) {
                // this.table.actionRebuyChips(-1);
                this.stopPause();
                return;
            }
            this.countdownText.text = this.parseTime(this.timer);
        }
    }

    startPause(time: number) {
        this.isActive = true;
        this.timer = time;
        this.container.visible = true;
    }

    stopPause() {
        this.isActive = false;
        this.timer = 0;
        this.container.visible = false;
    }

    parseTime(time: number): string {
        const secondsLeft: number = time % 60;
        const minutesLeft: number = time / 60;

        const strToRet = '';
        let minStr = '';
        let secStr = '';

        if (minutesLeft < 10) {
            minStr = '0' + Math.floor(minutesLeft).toString();
        } else {
            minStr = Math.floor(minutesLeft).toString();
        }

        if (secondsLeft < 10) {
            secStr = '0' + Math.floor(secondsLeft).toString();
        } else {
            secStr = Math.floor(secondsLeft).toString();
        }

        return minStr + ':' + secStr;
    }
}
