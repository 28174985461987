import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Chat } from '../../../../../shared/models/chat';
import { DataManagerService } from '../../../../../core/services/data-manager.service';

@Component({
  selector: 'app-game-chat-advance',
  templateUrl: './game-chat-advance.component.html',
  styleUrls: ['./game-chat-advance.component.scss']
})
export class GameChatAdvanceComponent implements AfterViewInit {

  private _chatMessages: Array<Chat> = [];
  public messageText: string;

  @ViewChild('scrollMe', { static: false }) scrollMe: ElementRef;
  public scrollTop: number = null;

  @Input() tableId: number;
  @Input() disableChat: number;

  @Input()
  set chatMessages(value: Array<Chat>) {
    this._chatMessages = value;
    if (this.scrollMe) {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
    }
  }
  get chatMessages(): Array<Chat> {
    return this._chatMessages;
  }
  @Output() message = new EventEmitter();
  @Output() private close = new EventEmitter();


  constructor(private dataManager: DataManagerService) { }

  ngAfterViewInit() {
    if (this.scrollMe) {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
    }
  }

  sendChatMessage() {
    this.dataManager.sendChatMessage(this.tableId, this.messageText);
    this.messageText = undefined;
  }

  scrollToBootom() {
    if (this.scrollMe) {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
    }
  }

  closeChat() {
    this.close.emit();
  }
}
