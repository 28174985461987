import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lobby-list-table-preview',
  templateUrl: './lobby-list-table-preview.component.html',
  styleUrls: ['./lobby-list-table-preview.component.scss']
})
export class LobbyListTablePreviewComponent implements OnInit {

  @Input() data

  constructor() { }

  ngOnInit(): void {
  }

}
