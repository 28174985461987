import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../auth/auth.service';
import { DataManagerService } from '../../../core/services/data-manager.service';


@Component({
  selector: 'app-upload-id-dialog',
  templateUrl: './upload-id-dialog.component.html',
  styleUrls: ['./upload-id-dialog.component.scss']
})
export class UploadIdDialogComponent implements OnInit {

  fileGovId: File;
  filePan: File;

  constructor(
    public dialogRef: MatDialogRef<UploadIdDialogComponent>,
    public authService: AuthService,
    private dataManager: DataManagerService
  ) { }

  ngOnInit() {
  }

  onChangeGovermentId(event: EventTarget) {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    this.fileGovId = files[0];
  }


  onChangePanCard(event: EventTarget) {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    this.filePan = files[0];
  }

  onApplyClick() {
    if (this.fileGovId !== undefined) {
      this.dataManager.uploadImage(this.fileGovId, 1);
    }
    if (this.filePan !== undefined) {
      this.dataManager.uploadImage(this.filePan, 2);
    }
    this.dialogRef.close('Apply');
  }

}
