import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class SpinnerService {

  spinner$ = new BehaviorSubject<boolean>(true);

  show() {
    this.spinner$.next(true);
  }

  hide() {
    this.spinner$.next(false);
  }
}
