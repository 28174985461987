import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../../shared/enums';
import { environment } from '../../../environments/environment';

@Pipe({ name: 'currCryptoConverter' })
export class CurrencyCryptoConverterPipe implements PipeTransform {
    transform(amount: number, exchangeRate: number): string {
        if (amount && exchangeRate) {
            return '$' + (amount / exchangeRate).toFixed(2).replace(/\.0+$/, '');
        } else {
            return '';
        }
    }
}
