import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../core/services/manager.service';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-casino-game',
  templateUrl: './casino-game.component.html',
  styleUrls: ['./casino-game.component.scss']
})
export class CasinoGameComponent implements OnInit {

  game$ = this.managerService.activeCasinoGame$;
  url$ = this.game$.pipe(
    tap(game => console.log("@game", game)),
    filter(game => game && !!game.url),
    map(game => this.domSanitizer.bypassSecurityTrustResourceUrl(game?.url)),
    tap(game => console.log("@game2", game)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor(private managerService: ManagerService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    console.log("@game here")
    this.managerService.activeCasinoGame$.subscribe(here=>console.log("ngOnInit game", here))
  }

  close() {
    this.managerService.closeActiveCasinoGame()
  }
}
