export const PlayerLevel = {
    1: {
        label: 'Bronze Star',
        className: 'rank-1',
        color: '#572f18',
    },
    2: {
        label: 'Silver Star',
        className: 'rank-2',
        color: '#d8d6d6'

    },
    3: {
        label: 'Gold Star',
        className: 'rank-3',
        color: '#fee601'

    },
    4: {
        label: 'Platinum Star',
        className: 'rank-4',
        color: '#92d1fd'

    },
    5: {
        label: 'All-Star',
        className: 'rank-5',
        color: '#fd000a'
    },
};
