import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Currency, getCurrencyName } from '../../../shared/enums';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { DataManagerService } from '../../../core/services/data-manager.service';

@Component({
  selector: 'app-convert-chips-dialog',
  templateUrl: './convert-chips-dialog.component.html',
  styleUrls: ['./convert-chips-dialog.component.scss']
})

export class ConvertChipsDialogComponent implements OnInit {
  environment = environment;
  skinCurrency;
  convertingCurrency;
  currencyName: string;

  constructor(
    public dialogRef: MatDialogRef<ConvertChipsDialogComponent>,
    public authService: AuthService,
    private dataManager: DataManagerService
  ) { }

  ngOnInit() {
    this.checkSkinDefaultCurrency();
  }

  onApplyClick() {
    if (this.authService.user.playerBalance[this.convertingCurrency] !== undefined
      && this.authService.user.playerBalance[this.convertingCurrency].availableMoney > 0) {
      this.dataManager.sendTdsConvertRequest(this.authService.user.playerBalance[this.convertingCurrency].availableMoney);
    }
    this.dialogRef.close('Apply');
  }

  checkSkinDefaultCurrency() {
    return

  }
}
