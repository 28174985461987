export function removeEndingSpaces(text: string) {
    if (/\s$/.test(text)) {
        text = text.slice(0, -1);
    }
}

export function removeEmpty(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
            removeEmpty(obj[key]);
        } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj;
}
