export enum BountySpellVariant {
    HiddenSpell = -2, // when player has bounty spell but it is hidden for the game apps
    NoSpell = -1,
    DoNotUse = 0, // not in use because of EmitDefaultValue = false
    SuddenDeath = 1,
    RobAPlayer = 2,
    Resurrection = 3,
    SkipBlindOnce = 4,
    RotateSeats = 5,
    Immunity = 6,
    ScanSpells = 7,
}

export enum SpellStatus {
    NotActivated = 1, //### earned spell is not activated
    Activated = 2, //#### earned spell is activated but not used yet (spell will be used in the next hand or canceled)

    Used = 3, // earned spell is used and cannot be used any more
    TakenAway = 4, // the player who knocks out another player takes over from him his unused spells
    Wasted = 5, // the player tried to use the spell but other player used immunity spell to defend himself
    UsedBigBlind = 6, // this is for skip blind spell, the player used skip big blind
    UsedSmallBlind = 7, // this is for skip blind spell, the player used skip small blind
    Disabled = 8, // number of players is less than allowed for executing the spell. all unused spells should be disabled
}

export enum BountySpellErrorCodes {
    NoError = 1,
    TableTypeIsNotTournament = 2,
    UnknownPlayer = 3,
    PlayerHasNoSpell = 4,
    // we can define a minimum players required for spell to be used. if number of active players is less than this number,
    // player cannot use earned spells
    RejectedBuyNumberOfActivePlayers = 5,
    RejectedOtherPlayerRequestedFirst = 6, // some spells have a rule that only one per hand can be activated. 
    RotationRejectedPlayerDoesntExist = 7, // Change seats rejected because selected player doesn't exist (on the current table)
    UnknownSpellVariant = 8, // Received undefined spell variant
    UnsupportedSpell = 9, // Spell type exists but it is not implemented support for it
    RejectedByImmunitySpell = 10, // Execution of the spell is rejected by the immunity spell
    RejectedSameSpellTypeAlreadyActivated = 11, // The player has already activated the same spell type but it is not used
    RejectedOtherPlayerActivatedRobAPlayer = 12, // Rejected RobAPlayer or SuddenDeath because RobAPlayer is already started
    RejectedOtherPlayerActivatedSuddenDeath = 13, // Rejected RobAPlayer or SuddenDeath because SuddenDeath is already started 
}