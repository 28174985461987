import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';
import { AnchorPosition } from './const';

export class PlayerBounty {
    private containerSize: Size = { width: 96, height: 60 };
    private targetSpritePosition: Point = { x: 10, y: 8 }; // top-left
    private playerBountyTextPosition: Point = { x: 70, y: 31 }; // left-center
    private playerBountyTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 40,
        fill: '#fff'
    });
    private backgroundGraphicsColor = 0x000000;
    private backgroundGraphicsAlpha = .8;

    container: PIXI.Container;
    private targetSprite: PIXI.Sprite;
    private playerBountyText: PIXI.Text;
    private bountyCollected: PIXI.Text; // do not display for now
    private backgroundGraphics: PIXI.Graphics;

    private targetSpriteOverlay: PIXI.Sprite;


    private readonly PADDING_TOP = 10;
    private readonly PADDING_LEFT = 10

    private isCelebrity: boolean;
    private textures: any;

    private alphaIndiciator = 1;
    constructor(textures, isCelebrity: boolean) {
        this.textures = textures;
        this.isCelebrity = isCelebrity;

        this.container = new PIXI.Container();

        this.backgroundGraphics = new PIXI.Graphics();
        this.backgroundGraphics.beginFill(this.backgroundGraphicsColor, this.backgroundGraphicsAlpha);
        this.backgroundGraphics.drawRoundedRect(0, 0, this.containerSize.width, this.containerSize.height, 10);
        this.backgroundGraphics.endFill();
        this.container.addChild(this.backgroundGraphics);

        this.targetSprite = new PIXI.Sprite(textures[isCelebrity ? 'bountyTargetCelebrity' : 'bountyTargetRegular'].texture);
        this.targetSprite.position.set(this.targetSpritePosition.x, this.targetSpritePosition.y);
        this.targetSprite.scale.set(.6)
        this.container.addChild(this.targetSprite);

        this.targetSpriteOverlay = new PIXI.Sprite(textures['bountyTargetCelebrityOverlay'].texture);
        this.targetSpriteOverlay.position.set(this.targetSpritePosition.x, this.targetSpritePosition.y);
        this.targetSpriteOverlay.scale.set(.6)
        this.container.addChild(this.targetSpriteOverlay);
        this.targetSpriteOverlay.visible = this.isCelebrity

        this.playerBountyText = new PIXI.Text('', this.playerBountyTextStyle);
        this.playerBountyText.position.set(this.playerBountyTextPosition.x, this.playerBountyTextPosition.y);
        this.playerBountyText.anchor.set(0, 0.5);
        this.container.addChild(this.playerBountyText);

        setTimeout(() => {
            this.refreshBackgroud()
        }, 0);

    }

    update(dt) {
        if (this.targetSpriteOverlay.alpha >= 1) {
            this.alphaIndiciator = -1
        } else if (this.targetSpriteOverlay.alpha <= 0) {
            this.alphaIndiciator = 1
        }

        this.targetSpriteOverlay.alpha += 0.01 * this.alphaIndiciator


    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {

            this.container.position.set(newPosition.x - this.containerSize.width / 2,
                newPosition.y - this.containerSize.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x, newPosition.y);
        }
    }

    setParams(playerBounty: string, isCelebrity: boolean) {
        this.playerBountyText.text = playerBounty;
        this.isCelebrity = isCelebrity;
        this.targetSprite.texture = this.textures[isCelebrity ? 'bountyTargetCelebrity' : 'bountyTargetRegular'].texture
        this.targetSpriteOverlay.visible = this.isCelebrity

        setTimeout(() => {
            this.refreshBackgroud()
        }, 0);
    }

    setSpell(textureId:string){
        this.targetSprite.texture = this.textures[textureId].texture

    }

    private refreshBackgroud() {
        this.backgroundGraphics.clear();
        this.backgroundGraphics.beginFill(this.backgroundGraphicsColor, this.backgroundGraphicsAlpha);
        this.backgroundGraphics.drawRoundedRect(0, 0, this.container.width + this.PADDING_LEFT * 2, this.container.height + this.PADDING_TOP * 2, 25);
        this.backgroundGraphics.endFill();
    }
}
