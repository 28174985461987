import * as PIXI from 'pixi.js';
import { Point } from '../models';

export const potTotalTextTopPosition: PIXI.Point = new PIXI.Point(488, 455); // top-left
export const potTotalTextRightPosition: PIXI.Point = new PIXI.Point(488, 455); // top-left

export const TablePosition: Point = { x: 80, y: 168 }; // top-left
export const TablePositionUnlimited: Point = { x: 960, y: 480 }; // center
export const TableShadowPosition: Point = { x: 270, y: 290 }; // top-left
export const MainPotPositions: Point[] = [
    { x: 438, y: 1070 }, // center
    { x: 638, y: 1070 }
];
export const FpsPosition: Point = { x: 10, y: 140 };

export const SexyDealerPosition: Point = { x: 559, y: 133 };
// export const SexyDealerPositionUnlimited: Poit = { x: 960, y: 108 };

export const SeatsVisibility = {
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [1, 0, 0, 1, 0, 0, 0, 0, 0, 0], // 2 players
    3: [1, 0, 0, 1, 0, 0, 1, 0, 0, 0], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [1, 0, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};

export const SeatsVisibilityWithDealer = {
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0], // 2 players
    3: [1, 0, 0, 1, 0, 1, 0, 0, 0, 0], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};

export const SeatPositionsStandard: Point[] = [
    { x: 540, y: 192 },
    { x: 802, y: 257 },
    { x: 867, y: 491 },
    { x: 932, y: 953 },
    { x: 900, y: 1262 }, // center
    { x: 540, y: 1431 },
    { x: 180, y: 1262 },
    { x: 148, y: 953 },
    { x: 213, y: 491 },
    { x: 278, y: 244 },
];
export const SeatPositionsStandardFor6Players: Point[] = [
    { x: 475 + 65, y: 127 + 65 },
    { x: 867, y: 581 },
    { x: 932, y: 1003 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 540, y: 1431 }, // center (1629) ***
    { x: 148, y: 1003 },
    { x: 213, y: 581 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];



export const PotPositions: Point[] = [
    { x: 539, y: 298 },
    { x: 673, y: 333 },
    { x: 673, y: 582 },
    { x: 673, y: 1048 },
    { x: 691, y: 1218 },
    { x: 539, y: 1273 },// center
    { x: 387, y: 1218 },
    { x: 406, y: 1048 },
    { x: 406, y: 582 },
    { x: 406, y: 333 },
];
export const PotPositionsFor6Players: Point[] = [
    { x: 540, y: 298 },
    { x: 674, y: 672 },
    { x: 674, y: 1098 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 539, y: 1273 }, // center 1453
    { x: 406, y: 1098 },
    { x: 410, y: 672 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const PlayerPositions: Point[] = [
    { x: 540 - 25, y: 147 + 25 },
    { x: 902 - 25, y: 279 + 25 },
    { x: 902 - 25, y: 539 + 25 },
    { x: 902 - 25, y: 1004 + 25 },
    { x: 902 - 25, y: 1327 + 25 },
    { x: 540 - 25, y: 1441 + 25 },// center (1600) ***
    { x: 178 - 25, y: 1327 + 25 },
    { x: 178 - 25, y: 1004 + 25 },
    { x: 178 - 25, y: 539 + 25 },
    { x: 178 - 25, y: 279 + 25 },
];
export const PlayerPositionsFor6Players: Point[] = [
    { x: 540 - 25, y: 147 + 25 },
    { x: 897 - 25, y: 629 + 25 },
    { x: 902 - 25, y: 1054 + 25 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 540 - 25, y: 1441 + 25 },
    { x: 178 - 25, y: 1054 + 25 },
    { x: 178 - 25, y: 629 + 25 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];



export const DealerChipPositions: Point[] = [
    { x: 476, y: 263 },
    { x: 764, y: 419 },
    { x: 717, y: 548 },
    { x: 706, y: 1149 },
    { x: 775, y: 1284 }, // center
    { x: 607, y: 1453 },
    { x: 302, y: 1275 },
    { x: 373, y: 1149 },
    { x: 361, y: 548 },
    { x: 321, y: 419 },
];
export const DealerChipPositionsFor6Players: Point[] = [
    { x: 476, y: 263 },
    { x: 764, y: 419 },
    { x: 717, y: 548 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 607, y: 1453 },
    { x: 302, y: 1275 },
    { x: 373, y: 1149 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];





export const ClubLogoLeftPosition: Point = { x: 735, y: 480 };
export const ClubLogoRightPosition: Point = { x: 1185, y: 480 };
export const BrandLogoPosition: Point = { x: 540, y: 540 };

export const BlindsTextPosition: Point = { x: 542, y: 498 }; // center

export const TournamentTimersPosition: Point = { x: 1600, y: 0 }; // wrong values
export const TableNamTextPosition: Point = { x: 550, y: 1100 }; // wrong values

export const TableBlindsTextPosition: Point = { x: 550, y: 1150 }; // wrong values

export const TripleDrawInfoPosition: Point = { x: 550, y: 805 };

export const TableTextRabbitHunting: Point = { x: 550, y: 580 };



/// *** OLD



export const SeatPositionsUnlimited: Point[] = [
    { x: 537, y: 143 + 12 },
    { x: 954, y: 143 + 12 },
    { x: 1371, y: 143 + 12 },
    { x: 1725, y: 331 + 12 },
    { x: 1725, y: 582 + 12 }, // center
    { x: 1371, y: 824 - 60 },
    { x: 954, y: 824 - 60 },
    { x: 537, y: 824 - 60 },
    { x: 196, y: 585 + 12 },
    { x: 196, y: 332 + 12 },
];
export const SeatPositionsFor6PlayersUnlimited: Point[] = [
    { x: 317, y: 193 },
    { x: 954, y: 144 },
    { x: 1607, y: 193 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1607, y: 720 - 60 },
    { x: 954, y: 825 - 60 },
    { x: 317, y: 720 - 60 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const SeatPositionsAdvanced: Point[] = [
    { x: 541, y: 157 },
    { x: 958, y: 157 },
    { x: 1367, y: 157 },
    { x: 1621, y: 346 },
    { x: 1621, y: 598 }, // center
    { x: 1367, y: 838 },
    { x: 953, y: 838 },
    { x: 542, y: 838 },
    { x: 301, y: 598 },
    { x: 301, y: 346 },
];
export const SeatPositionsAdvancedFor6Players: Point[] = [
    { x: 419, y: 190 },
    { x: 958, y: 157 },
    { x: 1504, y: 190 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1504, y: 764 },
    { x: 953, y: 838 },
    { x: 419, y: 764 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const PotPositionsUnlimited: Point[] = [
    { x: 667, y: 266 },
    { x: 1036, y: 300 },
    { x: 1242, y: 266 },
    { x: 1456, y: 376 },
    { x: 1456, y: 598 }, // center
    { x: 1245, y: 660 }, // { x: 1245, y: 660 - 62 },
    { x: 1039, y: 650 }, // { x: 1039, y: 660 - 62 },
    { x: 667, y: 660 }, // { x: 667, y: 660 - 62 },
    { x: 443, y: 617 },
    { x: 443, y: 376 },
];
export const PotPositionsFor6PlayersUnlimited: Point[] = [
    { x: 493, y: 306 },
    { x: 1036, y: 300 },
    { x: 1438, y: 306 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1438, y: 600 - 62 },
    { x: 1039, y: 650 }, // { x: 1039, y: 660 - 62 },
    { x: 493, y: 600 - 62 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const DealerChipPositionsUnlimited: Point[] = [
    { x: 596, y: 265 },
    { x: 858, y: 293 },
    { x: 1314, y: 265 },
    { x: 1486, y: 378 },
    { x: 1486, y: 551 }, // center
    { x: 1313, y: 656 },
    { x: 888, y: 660 },
    { x: 598, y: 656 },
    { x: 427, y: 576 },
    { x: 433, y: 337 },
];
export const DealerChipPositionsFor6PlayersUnlimited: Point[] = [
    { x: 468, y: 347 },
    { x: 858, y: 293 },
    { x: 1462, y: 347 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1462, y: 558 - 62 },
    { x: 888, y: 660 },
    { x: 468, y: 558 - 62 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];


export const PlayerPositionsUnlimited: Point[] = [
    { x: 539, y: 129 },
    { x: 956, y: 129 },
    { x: 1369, y: 129 },
    { x: 1723, y: 317 },
    { x: 1723, y: 570 }, // center
    { x: 1369, y: 810 - 72 },
    { x: 956, y: 810 - 72 },
    { x: 539, y: 810 - 72 },
    { x: 199, y: 570 },
    { x: 199, y: 317 },
];
export const PlayerPositionsFor6PlayersUnlimited: Point[] = [
    { x: 320, y: 179 },
    { x: 956, y: 129 },
    { x: 1606, y: 179 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1606, y: 705 - 72 },
    { x: 956, y: 810 - 72 },
    { x: 320, y: 705 - 72 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];