import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { User } from '../../../../shared/models/user';
import { Currency } from '../../../../shared/enums/currency';
import { environment } from '../../../../../environments/environment';
import { ManagerService } from '../../../../core/services/manager.service';
import { DataManagerService } from '../../../../core/services/data-manager.service';
import { GiftType } from '../../../../shared/enums';
import { Gift, Settings } from '../../../../shared/models';
import { State } from '../../../../app.reducers';
import { getUserState } from '../../../../auth/auth-store/auth.reducer';
import { AppWebSocketService } from '../../../../core/services/app-web-socket.service';
import { map, tap } from 'rxjs/operators';
import { differenceInDays, parseISO, format } from 'date-fns';

@Component({
  selector: 'app-bonus-unlock',
  templateUrl: './bonus-unlock.component.html',
  styleUrls: ['./bonus-unlock.component.scss']
})
export class BonusUnlockComponent implements OnInit, OnDestroy {
  public Currency = Currency;
  public environment = environment;
  public user: User;
  public gifts = [];
  enumGiftType = GiftType;
  public subscripton: Subscription = new Subscription();
  settings: Settings;

  isRAFEnabled$: Observable<boolean>
  isFDBEnabled$: Observable<boolean>

  gifts$: Observable<any[]>
  tickets$: Observable<any[]>

  rakeback$: Observable<any[]>
  bonuses$: Observable<any[]>
  rafBonuses$: Observable<any[]>

  navigation$: Observable<any[]>;

  tab: string;


  constructor(
    private managerService: ManagerService,
    private dataManager: DataManagerService,
    public dialogRef: MatDialogRef<BonusUnlockComponent>,
    // private store: Store<State>,
    private _webSocketService: AppWebSocketService,
    public ref: ChangeDetectorRef
  ) {

    this.isRAFEnabled$ = combineLatest(this.managerService.settings$, this.managerService.user$)
      .pipe(
        map(([settings, user]) => {
          if (!settings.RAFEnabled) { return false }
          const rafEnabledConfig = JSON.parse(settings.RAFEnabled)
          const config = rafEnabledConfig.find(el => el.Currency === user.selectedCurrency.Id)
          return config?.Enabled
        })
      )

    this.isFDBEnabled$ = combineLatest(this.managerService.settings$, this.managerService.user$)
      .pipe(
        map(([settings, user]) => {
          if (!settings.FDBEnabled) { return false }

          const fdbEnabledConfig = JSON.parse(settings.FDBEnabled)
          const config = fdbEnabledConfig.find(el => el.Currency === user.selectedCurrency.Id)
          return config?.Enabled
        })
      )

    this.navigation$ = combineLatest(this.isRAFEnabled$, this.isFDBEnabled$, this.managerService.skinsSettings$)
      .pipe(
        map(([raf, fdb, skinsSettings]) => {
          const navigation = [
            {
              label: 'Rakeback',
              value: 'rakeback'
            },
            {
              label: 'Bonuses',
              value: 'bonuses'
            },
            {
              label: 'R.A.F',
              value: 'raf'
            },
            {
              label: 'Tickets',
              value: 'tickets'
            }
          ]

          if (!raf) {
            const index = navigation.findIndex(el => el.value === 'raf')
            navigation.splice(index, 1)
          }

          if (!fdb) {
            const index = navigation.findIndex(el => el.value === 'bonuses')
            navigation.splice(index, 1)
          }

          console.log('skinsSettings', skinsSettings, !skinsSettings.shouldHaveGiftsRakeBackTab)
          if (!skinsSettings.shouldHaveGiftsRakeBackTab) {
            const index = navigation.findIndex(el => el.value === 'rakeback')
            navigation.splice(index, 1)
          }

          if (!this.tab) {
            this.tab = navigation[0].value
          }

          return navigation
        }))







    this.rakeback$ = combineLatest(this.managerService.gifts$, this.managerService.user$)
      .pipe(
        map(([data, user]) => data.filter(el => el.Currency === user.selectedCurrency.Id && el.Type === GiftType.RakeBack))
      )

    this.bonuses$ = combineLatest(this.managerService.gifts$, this.managerService.user$)
      .pipe(
        map(([data, user]) => data.filter(el => el.Currency === user.selectedCurrency.Id && (el.Type === GiftType.SignupBonus || el.Type === GiftType.DepositBonus)).map(el => {
          // # Parse the ISO string into a Date object
          const date = parseISO(el.Expiration);
          // # Get the current date
          const currentDate = new Date();
          // # Calculate the difference in days
          const daysDifference = differenceInDays(date, currentDate);

          console.log("@d", daysDifference)


          el.days = daysDifference;
          return el
        }))
      )

    this.bonuses$.subscribe(bonuses => console.log(bonuses))

    this.gifts$ = this.managerService.gifts$
      .pipe(
        map(data => data.filter(el => el.Currency > -1 && el.AmountTotal > 0))
      )

    this.rafBonuses$ = combineLatest(this.gifts$, this.managerService.rafBonuses$)
      .pipe(
        map(([gifts, rafBonuses]) => {

          const arr1 = gifts.filter(el => el.Type === GiftType.ReferAFriendRegister)

          const arr2 = rafBonuses
          //.filter(el => el.Currency > -1 && el.ReferrerGift?.Currency > -1)


          return [...arr1, ...arr2]
        })
      )

    this.tickets$ = this.managerService.tickets$

    // this.subscripton = this.store.select(getUserState).subscribe((user: User) => {
    //   this.user = user;
    //   this.gifts = user.gifts.filter(gift => gift.Currency === user.selectedCurrency);
    // });


    this.managerService.user$.subscribe({
      next: (user) => {
        this.user = user;
        console.log("@user", user.selectedCurrency)
        //this.gifts = user.gifts.filter(gift => gift.Currency === user.selectedCurrency.Id);


      }
    })


    this.managerService.settings$
      .subscribe({
        next: (settings) => {
          this.settings = settings;
        }
      })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscripton) {
      this.subscripton.unsubscribe();
    }
  }

  unlock(gift: any) {
    if ((!gift.Type && gift.Type !== GiftType.RakeBack && gift.Type !== GiftType.DepositBonus && gift.Type !== GiftType.SignupBonus && gift.Type !== GiftType.RakeBack) && (gift?.Progress < 100 || gift?.ReferrerGift?.Progress < 100)) {
      return
    } else if (gift.Type !== GiftType.RakeBack && (gift.Type !== GiftType.DepositBonus || gift.Type !== GiftType.SignupBonus) && gift?.Progress === 0) {
      return
    }
    this.dataManager.unlockGift(gift.ReferrerGift ? gift.ReferrerGift.Id : gift.Id)
    // this.managerService.unlockGift(gift, this.settings);
  }

  changeDetection() {
    this.ref.detectChanges()
  }
}
