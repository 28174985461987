import { Component, Output, EventEmitter, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ImageCropperModel } from '../image-cropper/image-cropper.model';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { environment } from '../../../../environments/environment';
import { ManagerService } from '../../../core/services/manager.service';
import { User } from '../../models/user';
import { ConfigService } from '../../../core/services/config.service';
import { GenericTextDialog } from '../../../core/dialogs/generic-text-dialog/generic-text-dialog.component';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss']
})
export class AvatarUploadComponent {

  environment = environment;
  user: User;




  // @Output() public imageUrlChange: EventEmitter<String> = new EventEmitter<String>();

  // @ViewChild('inputAvatarFile', { static: false }) inputAvatarFile: ElementRef;

  // @Input() @HostBinding('style.width') public width = '96px';
  // @Input() @HostBinding('style.height') public height = '96px';
  // @HostBinding('style.border-radius') public borderRadius = '50%';

  private propagateChange: any = () => { };
  config;

  constructor(
    private sanitizer: DomSanitizer,
    private dataManager: DataManagerService,
    private dialog: MatDialog,
    private http: HttpClient,
    private managerService: ManagerService,
    protected configService: ConfigService

  ) {
    this.config = this.configService.config;

  }


  ngOnInit(): void {
    this.managerService.user$.subscribe({
      next: (user) => {
        this.user = user
      }
    })

  }
  info(){
    const dialogRef = this.dialog.open(GenericTextDialog);


      dialogRef.componentInstance.textHeader = 'Change Avatar';
      dialogRef.componentInstance.textBody = 'Avatar Change awaiting approval';
      dialogRef.componentInstance.textAcceptBtn = 'ok';
      dialogRef.componentInstance.enableClose = false;
      return;
  }
  public addImage(event: EventTarget) {
    console.log("add image",this.user?.memberProfile?.NewAvatarUploadStatus)
    if (this.user?.memberProfile?.NewAvatarUploadStatus === 'Submit') {
      const dialogRef = this.dialog.open(GenericTextDialog);


      dialogRef.componentInstance.textHeader = 'Change Avatar';
      dialogRef.componentInstance.textBody = 'Avatar Change awaiting approval';
      dialogRef.componentInstance.textAcceptBtn = 'ok';
      dialogRef.componentInstance.enableClose = false;
      return;
    }
    const eventObj = event as any;
    const target: HTMLInputElement = eventObj.target;
    const fileList: FileList = target.files;

    if (fileList[0] !== undefined) {
      this.showImageCroppModal(event, fileList);
      // this.dataManager.imageUpload(fileList[0]).subscribe(data => {
      //   this.imageUrl = data.image_url;
      // });
    }
  }

  private showImageCroppModal(event, fileList) {
    this.dialog.open(ImageCropperComponent, {
      data: event, width: '480px'
    })
      .afterClosed()
      .subscribe(result => {
        // this.inputAvatarFile.nativeElement.value = '';
        if (result) {
          const b64Array = result.image.split(';');
          const blob = this.b64ToBlob(b64Array[1].split(',')[1], b64Array[0].split(':')[1], 512);
          this.dataManager.uploadAvatar(b64Array[1].split(',')[1]);
          event.srcElement.value = null;
          // this.uploadAvatar(blob);
          // this.dataManager.imageUpload(blob).subscribe(data => {
          //   this.imageUrl = data.image_url;
          // });
        }
      });
  }


  // uploadAvatar(blob: any) {
  //   this.http.post(`${environment.httpUrl}/UploadAvatar.ashx`, blob, { responseType: 'text' })
  //     .subscribe(res => {
  //       this.imageUrl = environment.httpUrl + '/avatar/' + res;
  //     });
  // }

  private b64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
