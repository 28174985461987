import * as PIXI from 'pixi.js';

import { Point } from '../models';
import { environment } from '../../../../environments/environment';
import { GlowFilter } from '@pixi/filter-glow';
import { CardData } from '../../../shared/models';
import { AnchorPosition } from './const';
import { GameComponent } from '../game.component';

// import { Store } from '@ngrx/store';
import { GameService } from '../game.service';
import { NgZone } from '@angular/core';

import * as CONST from './cards-cotrollers/player-cards-controller-const'

export class Card {
    private isSelecting = false;
    private flashTimer = 0;
    private flashTime = 500;
    private flashPauseTime = 200;
    private isFlashOn = false;

    container: PIXI.Container;
    private cardSprite: PIXI.Sprite;
    private glowFilter: GlowFilter;

    selected: boolean = false

    public gameComponent: GameComponent

    private scaleSpeed = 0.210;
    private timerRef: NodeJS.Timer;
    private cardAnimationTimeRef: NodeJS.Timer;

    private scalePositive: boolean;
    private communityCard: boolean

    private animationMoveCard = 0
    private startYPosition: number;
    private readonly CARD_ANIMATION_SIZE = 25
    private bigCard: boolean;
    public cardData: CardData;
    config;
    constructor(
        private cardAtlas,
        private backCardTexture: PIXI.Texture,
        private rabbitHuntingTexture: PIXI.Texture,
        gameComponent: GameComponent,
        cardData?: CardData,
        communityCard?: boolean,
        bigCard?: boolean
    ) {

        this.container = new PIXI.Container();
        this.gameComponent = gameComponent;
        this.communityCard = communityCard;
        this.cardData = cardData
        this.config = this.gameComponent.config;
        
        if (this.cardData && !this.cardData.IsHidden) {
            if (communityCard) {
                this.cardSprite = new PIXI.Sprite(this.backCardTexture);
            } else {

                if (this.cardAtlas.name === 'playerCardAtlas') {
                    this.cardSprite = new PIXI.Sprite(this.cardAtlas.textures[this.cardData.Name + '.png']);
                } else if (this.cardAtlas.name === 'tableCardAtlas') {
                    this.cardSprite = new PIXI.Sprite(this.cardAtlas.textures[this.cardData.Name + '_table.png']);
                }
            }
        } else {
            this.cardSprite = new PIXI.Sprite(this.backCardTexture);
        }
        this.container.addChild(this.cardSprite);

        this.glowFilter = new GlowFilter({
            distance: 15,
            outerStrength: 1,
            innerStrength: 1,
            color: this.config.settings.cardGlowColor,
            quality: 0.1
        });
        if (communityCard) {
            this.cardSprite.anchor.x = 0.5;

            this.timerRef = setInterval(() => this.doAnimation(), 25);

        }



        if (this.cardData && this.cardData.IsRabbitHunting) {
            let sprite = new PIXI.Sprite(this.rabbitHuntingTexture);
            //sprite.scale.set(1);

            //sprite.position.set(0, 5)


            this.container.addChild(sprite);

        }

        this.startYPosition = this.container.position.y
        this.bigCard = bigCard;

    }


    onSelectCard(quickSelect?: boolean, quickSelectValue?: boolean) {
        console.log("onSelectCard", quickSelect, quickSelectValue)
        const mobile = (this.gameComponent.handsetPortrait || this.gameComponent.handsetLandscape)

        if (this.bigCard) {
            if (this.selected) {
                this.cardSprite.tint = 0xFFFFFF
                this.selected = false;
                this.gameComponent.updateSelectedCards(this)
            } else {
                this.cardSprite.tint = 0x999999
                this.selected = true;
                this.gameComponent.updateSelectedCards(this)
            }
        } else {
            if (quickSelect) {
                const cardYPosition = 70; // 📚 card Y position is fixed to 70px

                if (quickSelectValue) {
                    this.selected = true;
                    this.cardSprite.tint = 0x999999

                    this.container.position.set(this.container.position.x, cardYPosition - this.CARD_ANIMATION_SIZE);

                } else {
                    this.selected = false;
                    this.cardSprite.tint = 0xFFFFFF
                    this.container.position.set(this.container.position.x, cardYPosition);
                }
            } else if (this.animationMoveCard === 0 && !mobile) {
                if (this.selected) {
                    this.setPosition({ x: this.container.position.x, y: this.container.position.y + 1 }, AnchorPosition.topLeft)
                    // this.selected = false
                    this.cardSprite.tint = 0xFFFFFF
                    this.animationMoveCard = this.CARD_ANIMATION_SIZE - 1;
                } else {
                    this.setPosition({ x: this.container.position.x, y: this.container.position.y - 1 }, AnchorPosition.topLeft)
                    // this.selected = true
                    this.cardSprite.tint = 0x999999
                    this.animationMoveCard = -1 * (this.CARD_ANIMATION_SIZE - 1);

                }
                // this.gameComponent.updateSelectedCards(this)

                this.cardAnimationTimeRef = setInterval(() => this.animateCardMove(), 10);

            }
        }

    }

    public setCardSelection(status: boolean) {
        this.cardSprite.off('mousedown')
        this.cardSprite.off('touchstart')

        // #try clearInterval(this.cardAnimationTimeRef);

        if (status) {
            this.cardSprite.interactive = true;
            this.cardSprite.buttonMode = true;
            this.cardSprite.on('mousedown', (event) => { this.onSelectCard() });
            this.cardSprite.on('touchstart', (event) => { this.onSelectCard() });

        } else {
            this.cardSprite.interactive = false;
            this.cardSprite.buttonMode = false;
            this.cardSprite.off('mousedown')
            this.cardSprite.off('touchstart')
            // if (this.selected) {
            //     this.setPosition({ x: this.container.position.x, y: this.container.position.y - this.animationMoveCard }, AnchorPosition.topLeft)
            //     this.selected = false
            //     this.cardSprite.tint = 0xFFFFFF
            //     this.gameComponent.updateSelectedCards(this)

            // }

            // ## try

            // if (this.animationMoveCard !== 0 || this.selected) {

            //     let y = 0
            //     if (this.selected) {
            //         y = this.container.position.y + this.CARD_ANIMATION_SIZE
            //     } else {
            //         y = this.container.position.y - this.animationMoveCard
            //     }
            //     this.setPosition({ x: this.container.position.x, y }, AnchorPosition.topLeft)
            //     this.selected = false
            //     this.cardSprite.tint = 0xFFFFFF
            //     this.gameComponent.updateSelectedCards(this)

            // }

        }
    }




    update(dt) {
        if (this.isSelecting) {
            this.flashing(dt);
        }
    }

    refreshCard(cardAtlas, backCardTexture) {
        this.cardAtlas = cardAtlas;
        this.backCardTexture = backCardTexture;
        if (this.cardData) {
            // this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '_table.png'];

            if (this.cardAtlas.name === 'playerCardAtlas') {
                this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '.png'];
            } else if (this.cardAtlas.name === 'tableCardAtlas') {
                this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '_table.png'];
            }


        } else {
            this.cardSprite.texture = this.backCardTexture;
        }
    }
    //
    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {
            this.container.position.set(newPosition.x - this.container.width / 2, newPosition.y - this.container.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x + (this.communityCard ? this.container.width / 2 : 0), newPosition.y);
        }
    }

    grayscaleCard() {
        this.cardSprite.tint = 0x666666
    }
    ungrayscaleCard() {
        this.cardSprite.tint = 0xFFFFFF
    }

    selectCard() {
        // this.isSelecting = true;
        // this.container.filters = [this.glowFilter];
        // this.isFlashOn = true;
    }

    private flashing(dt) {
        this.flashTimer += dt;
        if (this.isFlashOn) {
            if (this.flashTimer >= this.flashTime) {
                this.flashTimer = 0;
                this.container.filters = [];
                this.isFlashOn = false;
            }
        } else {
            if (this.flashTimer >= this.flashPauseTime) {
                this.flashTimer = 0;
                this.container.filters = [this.glowFilter];
                this.isFlashOn = true;
            }
        }
    }


    private animateCardMove() {
        if (this.animationMoveCard !== 0) {

            if (this.selected) {
                this.animationMoveCard--
                this.setPosition({ x: this.container.position.x, y: this.container.position.y + 1 }, AnchorPosition.topLeft)
                if (this.animationMoveCard === 0) {
                    this.selected = false;
                    this.gameComponent.updateSelectedCards(this)
                    clearInterval(this.cardAnimationTimeRef);
                }
            } else {
                this.animationMoveCard++
                this.setPosition({ x: this.container.position.x, y: this.container.position.y - 1 }, AnchorPosition.topLeft)
                if (this.animationMoveCard === 0) {
                    this.selected = true;
                    this.gameComponent.updateSelectedCards(this)
                    clearInterval(this.cardAnimationTimeRef);
                }
            }
        }
    }

    private doAnimation() {

        if (this.scalePositive) {

            this.cardSprite.scale.x += this.scaleSpeed
        } else {

            this.cardSprite.scale.x -= this.scaleSpeed
        }


        if (this.cardSprite.scale.x < 0.1) {
            this.scalePositive = true

            // this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '_table.png'];

            if (this.cardAtlas.name === 'playerCardAtlas') {
                this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '.png'];
            } else if (this.cardAtlas.name === 'tableCardAtlas') {
                this.cardSprite.texture = this.cardAtlas.textures[this.cardData.Name + '_table.png'];
            }
        } else if (this.cardSprite.scale.x >= 1) {
            clearInterval(this.timerRef);
        }
    }
}
