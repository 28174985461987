import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-bounty-spell-info',
  templateUrl: './dialog-bounty-spell-info.component.html',
  styleUrls: ['./dialog-bounty-spell-info.component.scss']
})
export class DialogBountySpellInfoComponent implements OnInit {

  viewSpellData: any;

  constructor(
    public dialogRef: MatDialogRef<DialogBountySpellInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {

  }

  ngOnInit(): void {
  }

  viewSpell(data) {
    this.viewSpellData = data
  }

}
