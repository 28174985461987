import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tournament } from '../../../../shared/models';
import { Ticket } from '../../../../shared/models/ticket';
import { User } from '../../../../shared/models/user';

export interface DialogTournamentRegistrationData {
  tournament: Tournament
  user: User,
  playerTickets: Ticket[]
}

export enum BuyInCase {
  TableBuyIn,
  TableRebuyCosts
}

@Component({
  selector: 'dialog-tournament-registration',
  templateUrl: './dialog-tournament-registration.component.html',
  styleUrls: ['./dialog-tournament-registration.component.scss']
})
export class DialogTournamentRegistrationComponent {

  enumBuyInCase = BuyInCase
  tournament: Tournament
  user: User
  haveTicket: boolean
  buyInCase: BuyInCase = BuyInCase.TableBuyIn

  constructor(
    public dialogRef: MatDialogRef<DialogTournamentRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTournamentRegistrationData
  ) {

    this.tournament = data.tournament
    this.user = data.user

    if (this.tournament.tourSum.Ticket && data.playerTickets && data.playerTickets.findIndex(el => el.Id === this.tournament.tourSum.Ticket) > -1) {
      this.haveTicket = true
    } else {
      this.haveTicket = false
    }

    if (
      this.tournament.isRegistered &&
      this.tournament.tourSum.ImRegistered &&
      this.tournament.tourSum.ImEliminated &&
      this.tournament.tourSum.OptionReEntry &&
      this.tournament.tourSum.NbAddonUsed < this.tournament.tourSum.ReBuyNbLimit) {
      this.buyInCase = BuyInCase.TableRebuyCosts
    }

  }

  register() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

}
