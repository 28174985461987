import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-game-controls-collapsable',
  templateUrl: './game-controls-collapsable.component.html',
  styleUrls: ['./game-controls-collapsable.component.scss']
})
export class GameControlsCollapsableComponent implements OnInit {

  public showContent = false;
  public environment = environment;

  constructor() { }

  ngOnInit() {
  }

  toggleChat() {
    this.showContent = !this.showContent;
  }
}
