import { Component, OnInit, EventEmitter, ElementRef, ViewChild, Input, Output, OnDestroy } from '@angular/core';

import { Chat } from '../../../../../shared/models/chat';
import { DataManagerService } from '../../../../../core/services/data-manager.service';
import { environment } from '../../../../../../environments/environment';
import { ManagerService } from '../../../../../core/services/manager.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-game-controls-chat',
  templateUrl: './game-controls-chat.component.html',
  styleUrls: ['./game-controls-chat.component.scss']
})
export class GameControlsChatComponent implements OnInit, OnDestroy {

  public environment = environment;

  destroy$ = new Subject<boolean>();

  private chatBubbleTimeout;
  private scrollToBottomTimeOut;

  private _chatMessages: Array<Chat> = [];
  public messageText: string;
  public showChatBubble = true;

  @ViewChild('scrollMe', { static: false }) scrollMe: ElementRef;
  public scrollTop: number = null;

  @Input() disableChat: boolean;
  @Input() tableId: number;
  @Input()
  set chatMessages(value: Array<Chat>) {
    this._chatMessages = value;
    this.showChatBubble = true;



    this.scrollToBottom()
    clearTimeout(this.scrollToBottomTimeOut);
    this.scrollToBottomTimeOut = setTimeout(() => { this.scrollToBottom() }, 100);

    clearTimeout(this.chatBubbleTimeout);
    this.chatBubbleTimeout = setTimeout(() => { this.showChatBubble = false; }, 2000);
  }
  get chatMessages(): Array<Chat> {
    return this._chatMessages;
  }
  @Output() message = new EventEmitter();

  chatDisabled$ = this.managerService.settings$.pipe(
    map(settings => settings.ChatTextDisabled === "True"),
  )
  constructor(private dataManager: DataManagerService, public managerService: ManagerService) { }

  ngOnInit() {
    this.scrollToBottom()
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          console.log("@skin settings", settings)
        }
      })
  }

  ngOnDestroy(): void {

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  sendChatMessage() {
    this.dataManager.sendChatMessage(this.tableId, this.messageText);
    this.messageText = undefined;
  }


  scrollToBottom(): void {
    try {
      if (this.scrollMe) {
        this.scrollTop = this.scrollMe.nativeElement.scrollHeight + 1000;
      }
    } catch (err) { console.log(err) }
  }


}
