
export enum cardSuit { Hearts, Diamods, Clubs, Spades }
export enum cardNumbers { T = 10, Jack, Queen, King, Ace }
export const cardNames: string[] = [
    '', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Jack', 'Queen', 'King', 'Ace'
];

export function cardDecoder(newCardSuit: number, newCardNumber: number, reversed?: boolean) {
    if (newCardSuit === undefined || newCardNumber === undefined) { return ''; }

    let cardNumberString: string;
    let cardSuitString: string;

    if (newCardNumber === cardNumbers.Jack) {
        cardNumberString = 'J';
    } else if (newCardNumber === cardNumbers.Queen) {
        cardNumberString = 'Q';
    } else if (newCardNumber === cardNumbers.King) {
        cardNumberString = 'K';
    } else if (newCardNumber === cardNumbers.Ace) {
        cardNumberString = 'A';
    } else if (newCardNumber === cardNumbers.T) {
        cardNumberString = 'T';
    } else {
        cardNumberString = newCardNumber.toString();
    }

    if (newCardSuit === cardSuit.Hearts) {
        cardSuitString = 'h';
    } else if (newCardSuit === cardSuit.Diamods) {
        cardSuitString = 'd';
    } else if (newCardSuit === cardSuit.Clubs) {
        cardSuitString = 'c';
    } else if (newCardSuit === cardSuit.Spades) {
        cardSuitString = 's';
    } else {
        cardSuitString = '';

    }

    return reversed ? cardNumberString + cardSuitString : cardSuitString + cardNumberString;
}

export function cardNameDecoder(newCardNumber: number, short?: boolean): string {
    let name = cardNames[newCardNumber]
    if (short) {
        if (name === 'Jack') { name = 'J' }
        if (name === 'Queen') { name = 'Q' }
        if (name === 'King') { name = 'K' }
        if (name === 'Ace') { name = 'A' }
    }
    return cardNames[newCardNumber];
}

export function cardSuitDecoder(newCardSuit: number, short?: boolean): string {
    let cardSuitString: string;

    if (newCardSuit === cardSuit.Hearts) {
        cardSuitString = short ? 'h' : 'hearts';
    } else if (newCardSuit === cardSuit.Diamods) {
        cardSuitString = short ? 'd' : 'diamods';
    } else if (newCardSuit === cardSuit.Clubs) {
        cardSuitString = short ? 'c' : 'clubs';
    } else if (newCardSuit === cardSuit.Spades) {
        cardSuitString = short ? 's' : 'spades';
    }

    return cardSuitString
}

