import { ContentObserver } from '@angular/cdk/observers';
import { format, parse, compareAsc, compareDesc, parseISO } from 'date-fns';

export class Comparators {

  public static compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static sngPlayerComparator(propA, propB) {
    const paArray = propA.replace(/\s/g, '').split('/');
    const pbArray = propB.replace(/\s/g, '').split('/');
    let a = 0;
    let b = 0;
    a = paArray[0] / paArray[1];
    b = pbArray[0] / pbArray[1];
    return (a > b ? -1 : 1);
  }

  public static smartSortingPlayerComparator(propA, propB) {
    const paArray = propA.tablePlayers.replace(/\s/g, '').split('/');
    const pbArray = propB.tablePlayers.replace(/\s/g, '').split('/');
    const pa = parseFloat(paArray[0]) / parseFloat(paArray[1]);
    const pb = parseFloat(pbArray[0]) / parseFloat(pbArray[1]);
    const aAccessLimited = propA.IsAccessLimited ? 3 : 0;
    const bAccessLimited = propB.IsAccessLimited ? 3 : 0;
    let a = 0;
    let b = 0;

    if (parseFloat(paArray[0]) === 0) {
      a = 0;
    } else if (parseFloat(paArray[0]) === 1) {
      a = 1;
    } else if (parseFloat(paArray[0]) === parseFloat(paArray[1])) {
      a = 2;
    } else {
      a = 3 + pa;
    }

    if (a) { a += aAccessLimited; }
    if (!a && aAccessLimited) { a += 1; }

    if (parseFloat(pbArray[0]) === 0) {
      b = 0;
    } else if (parseFloat(pbArray[0]) === 1) {
      b = 1;
    } else if (parseFloat(pbArray[0]) === parseFloat(pbArray[1])) {
      b = 2;
    } else {
      b = 3 + pb;
    }

    if (b) { b += bAccessLimited; }
    if (!b && bAccessLimited) { b += 1; }

    if (a === b) {
      if (propA.minStake < propB.minStake) {
        a += 0.1;
      } else {
        b += 0.1;
      }
    }

    return b - a;
  }

  public static playerComparator(a = 0, b = 0, isAsc) {
    a = Number(a);
    b = Number(b);
    return isAsc ? compareAsc(a, b) : compareDesc(a, b);
  }

  public static schedulerComparator(a, b, isAsc) {
    a = parseISO(a);
    b = parseISO(b);

    return isAsc ? compareAsc(a, b) : compareDesc(a, b);
  }

  public static dateComparator(a, b, isAsc) {
    a = new Date(a).getTime();
    b = new Date(b).getTime();

    return this.compare(a, b, isAsc)
  }

}


