import { Component, Input, OnInit } from '@angular/core';
import { Chat } from '../../../../../../shared/models/chat';

@Component({
  selector: 'app-game-chat-bubble',
  templateUrl: './game-chat-bubble.component.html',
  styleUrls: ['./game-chat-bubble.component.scss']
})
export class GameChatBubbleComponent implements OnInit {

  @Input() message: Chat;

  constructor() { }

  ngOnInit() {
  }

}
