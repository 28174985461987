import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { NavStandardComponent } from './nav-container/nav-standard/nav-standard.component';
import { NavPaginatorComponent } from './nav-paginator/nav-paginator.component';
import { NavStandardTableComponent } from './nav-container/nav-standard/nav-standard-table/nav-standard-table.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DialogCasinosInfo } from './dialog-casinos-info/dialog-casinos-info.component';
import { DialogPlayerStatusLevelComponent } from './dialog-player-status-level/dialog-player-status-level.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    NavStandardComponent,
    NavPaginatorComponent,
    NavStandardTableComponent,
    DialogCasinosInfo,
    DialogPlayerStatusLevelComponent,
  ],
  exports: [
    NavStandardComponent,
    NavPaginatorComponent,
    NavStandardTableComponent
  ],
  entryComponents: [
    DialogCasinosInfo,
    DialogPlayerStatusLevelComponent
  ]
})
export class NavModule { }
