import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationsService } from '../../core/notifications/notifications.service';
import { environment } from '../../../environments/environment';
import { DataManagerService } from './data-manager.service';
import { ConfigService } from './config.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private configService: ConfigService

  ) { }

  handleError(error: Error | HttpErrorResponse) {

    const notificationService = this.injector.get(NotificationsService);
    const dataManagerService = this.injector.get(DataManagerService);


    if (error instanceof HttpErrorResponse) {

    } else {
      const errorMsg = this.addContextInfo(error);
    }

    // Log the error anyway
    console.error('It happens: ', error);
  }

  addContextInfo(error) {
    const name = error.name || null;
    const skin = this.configService.config.skinName;
    const time = new Date().getTime();
    const id = `${skin}-${time}`;
    const status = error.status || null;
    const message = error.message || error.toString();
    // const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    const errorToSend = { name, skin, time, id, status, message };
    return errorToSend;
  }
}
