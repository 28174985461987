import { Component, OnInit, Inject, Input, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { ImageCropperModel } from './image-cropper.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { ManagerService } from '../../../core/services/manager.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();

  loader: boolean = true
  loaderCropper: boolean = true
  croppedImage: any = ''
  cropperReady = false



  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public imageChangedEvent: any,

    public managerService: ManagerService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaderCropper = false
    }, 600);

    // ** On Logout Close
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (!settings) {
            this.dialogRef.close();
          }
        }
      })
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.cropperReady = true;
    this.loader = false
  }

  loadImageFailed() {

  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({
      image: this.croppedImage,
    });
  }

  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }

  // private imageResized(image) {
  //   this.resizedImage = image;
  // }

  // public closeDialog() {
  //   this.ImageCroppDialog.close();
  // }

  // public confirmCropp() {
  //   this.ImageCroppDialog.close({
  //     image: this.croppedImage,
  //   });
  // }

}
