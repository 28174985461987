import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AppActions } from '../../../app.actions';
import { State } from '../../../app.reducers';
import { TournamentSummary, TournamentInformation } from '../../../shared/models';
import { DataManagerService } from '../../services/data-manager.service';
import { ManagerService } from '../../services/manager.service';
import { ENavItem } from '../../../main/nav/nav-item-type';

interface BroadcastData {
  text: string;
  tournamentId: number;
}

@Component({
  selector: 'app-broadcast-dialog',
  templateUrl: './broadcast-dialog.component.html',
  styleUrls: ['./broadcast-dialog.component.scss']
})
export class BroadcastDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BroadcastDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BroadcastData,
    private dataManager: DataManagerService,
  ) { }

  ngOnInit() {
  }

  register() {
    // TO DO APRIL
    // this.managerService.addTournament({
    //   tourSum: {} as TournamentSummary,
    //   tourInfo: {} as TournamentInformation,
    //   isRegistered: true,
    //   activeTables: [],
    //   myActiveTable: undefined,
    //   myPosition: undefined,
    //   blindLevels: [],
    // },
    //   this.data.tournamentId,
    // )
    // this.store.dispatch(new AppActions.TournamentsActions.AddTournament(
    //   {
    //     tourSum: {} as TournamentSummary,
    //     tourInfo: {} as TournamentInformation,
    //     isRegistered: true,
    //     activeTables: [],
    //     myActiveTable: undefined,
    //     myPosition: undefined,
    //     blindLevels: [],
    //   },
    //   this.data.tournamentId,
    // ));

    //this.dataManager.getTournamentSummary(this.data.tournamentId);




    this.dialogRef.close('register')
  }

  cancel() {
    this.dialogRef.close();
  }

}
