import * as PIXI from 'pixi.js';
import { environment } from '../../../../environments/environment';

export class PotTotalText {
    public container: PIXI.Container;
    private titleText: PIXI.Text;
    private titleTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 28,
        fill: '#ffffff',
        fontWeight: '500'
    });
    private titlePosition: PIXI.Point = new PIXI.Point(0, -5);
    private amountText: PIXI.Text;
    private amountTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 28,
        fill: '#ffffff',
        fontWeight: '500'

    });
    private amountPosition: PIXI.Point = new PIXI.Point(58, -5);
    private background: PIXI.Graphics;
    private backgroundColor = 0x0;
    private backgroundAlpha = 0.3;


    constructor(title: string, position: PIXI.Point) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);


        this.background = new PIXI.Graphics();
        this.background.beginFill(this.backgroundColor, this.backgroundAlpha);
        this.background.drawRect(0, 0, 0, 0);
        this.background.endFill();
        this.container.addChild(this.background);

        this.titleText = new PIXI.Text(title, this.titleTextStyle);
        this.titleText.position.set(this.titlePosition.x, this.titlePosition.y);
        this.container.addChild(this.titleText);

        this.amountText = new PIXI.Text('0', this.amountTextStyle);
        this.amountText.position.set(this.amountPosition.x, this.amountPosition.y);
        this.container.addChild(this.amountText);
    }

    public setAmount(amount: string) {
        this.amountText.text = amount;
        this.refreshBackgroud();
    }

    private refreshBackgroud() {
        this.background.clear();
        this.background.beginFill(this.backgroundColor, this.backgroundAlpha);
        this.background.drawRoundedRect(-10, -15, this.container.width + 20, this.container.height + 20, 15);
        this.background.endFill();
    }
}
