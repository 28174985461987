import * as PIXI from 'pixi.js';

import { Point } from '../models';
import { Table } from './table';
import { Common } from './common';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

export class Seat {
    id: number;
    container: PIXI.Container;
    defaultTexture: PIXI.Texture;
    mouseOverTexture;
    seatSprite: PIXI.Sprite;
    // container size was here
    text: PIXI.Text;

    private containerSizeStandard: Point = { x: 130, y: 130 };
    private containerSizeAdvanced: Point = { x: 125, y: 125 };
    private seatSpritePositionUnlimited: Point = { x: 1, y: 6 };
    textPositionLeftStandard: Point = { x: 115, y: 74 }; // center
    textPositionRightStandard: Point = { x: 237, y: 74 }; // center
    textStyleStandard: PIXI.TextStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 25, fill: '#ffffff' });
    textPositionLeftAdvanced: Point = { x: 63, y: 83 }; // center
    textPositionRightAdvanced: Point = { x: 63, y: 83 }; // center
    textStyleAdvanced: PIXI.TextStyle = new PIXI.TextStyle({ fontFamily: 'Arial', fontSize: 18, fill: '#ffffff' });
    myTable: Table;
    textPositionLeftUnlimited: Point = { x: 125, y: 89 }; // center
    textPositionRightUnlimited: Point = { x: 221, y: 83 }; // center

    constructor(textures, newId: number, newPosition: Point, newTable: Table, public seatNumber: number) {
        this.id = newId;
        this.myTable = newTable;
        this.container = new PIXI.Container();



        this.container.position.set(newPosition.x - this.containerSizeStandard.x / 2,
            newPosition.y - this.containerSizeStandard.y / 2);
        // this.container.position.set(newPosition.x, newPosition.y);


        this.seatSprite = new PIXI.Sprite(textures['seatDefault'].texture);

        this.seatSprite.interactive = true;
        this.seatSprite.buttonMode = true;
        this.seatSprite.cursor = 'pointer';
        this.seatSprite.on('mousedown', (event) => {
            this.seatSprite.texture = textures['seatClick'].texture;
            this.myTable.actionTakeSeat(this.id);
        });
        this.seatSprite.on('mouseup', (event) => {
            this.seatSprite.texture = textures['seatDefault'].texture;
        });
        this.seatSprite.on('touchstart', (event) => {
            this.seatSprite.texture = textures['seatClick'].texture;
            this.myTable.actionTakeSeat(this.id);
        });
        this.seatSprite.on('touchend', (event) => {
            this.seatSprite.texture = textures['seatDefault'].texture;
        });

        this.container.addChild(this.seatSprite);

        if (false) {
            /*this.text = new PIXI.Text('SIT HERE', this.textStyleAdvanced);
            if (Common.isLeftSided(this.seatNumber)) {
                this.text.position.set(this.textPositionLeftAdvanced.x, this.textPositionLeftAdvanced.y);
            } else {
                this.text.position.set(this.textPositionRightAdvanced.x, this.textPositionRightAdvanced.y);
                this.seatSprite.anchor.x = 1;     // 0 = start, 0.5 = center, 1 = end
                this.seatSprite.scale.x *= -1;    // flip horizontaly
            }
            this.text.anchor.set(0.5, 0.5);
            this.container.addChild(this.text);*/
        } else {
            this.text = new PIXI.Text(this.myTable.seatText, this.textStyleStandard);
            this.text.position.set(this.textPositionLeftStandard.x, this.textPositionLeftStandard.y);

            // if (Common.isLeftSided(this.seatNumber, this.myTable.getMaxPlayers())) {
            //     if (environment.skinType === SkinType.Unlimited) {
            //         this.text.position.set(this.textPositionLeftUnlimited.x, this.textPositionLeftUnlimited.y);
            //     } else {
            //         this.text.position.set(this.textPositionLeftStandard.x, this.textPositionLeftStandard.y);
            //     }
            // } else {
            //     if (environment.skinType === SkinType.Unlimited) {
            //         this.text.position.set(this.textPositionRightUnlimited.x, this.textPositionRightUnlimited.y);
            //     } else {
            //         this.text.position.set(this.textPositionRightStandard.x, this.textPositionRightStandard.y);
            //     }
            //     this.seatSprite.anchor.x = 1;     /* 0 = start, 0.5 = center, 1 = end */
            //     this.seatSprite.scale.x *= -1;    /* flip horizontaly */
            // }
            this.text.anchor.set(0.5, 0.5);
            // this.container.addChild(this.text);
        }
    }

    hideSeat() {
        this.container.visible = false;
    }

    showSeat() {
        this.container.visible = true;
    }

    setPosition(newPosition: Point) {
        if (false) {
            this.container.position.set(newPosition.x - this.containerSizeAdvanced.x / 2,
                newPosition.y - this.containerSizeAdvanced.y / 2);
        } else {
            this.container.position.set(newPosition.x - this.containerSizeStandard.x / 2,
                newPosition.y - this.containerSizeStandard.y / 2);
        }
    }

    setSeatText(newText: string) {
        this.text.text = newText;
    }
}
