import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BetControls } from '../../game-controls-model';

@Component({
  selector: 'app-game-controls-common-post-big-blind',
  templateUrl: './game-controls-common-post-big-blind.component.html',
  styleUrls: ['./game-controls-common-post-big-blind.component.scss']
})
export class GameControlsCommonPostBigBlindComponent implements OnInit {

  @Input() betControls: BetControls;
  @Output() postBigBlindClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  clickPostBigBlind() {
    this.postBigBlindClicked.emit();
  }

}
