import * as PIXI from 'pixi.js';

export const anchorPoints: PIXI.Point[] = [
    new PIXI.Point(0, 0),       // topLeft
    new PIXI.Point(1, 0),       // topRight
    new PIXI.Point(0.5, 0),     // topCenter
    new PIXI.Point(0, 0.5),     // middleLeft
    new PIXI.Point(1, 0.5),     // middleRight
    new PIXI.Point(0.5, 0.5),   // middleCenter
    new PIXI.Point(0, 1),       // bottomLeft
    new PIXI.Point(1, 1),       // bottomRight
    new PIXI.Point(0.5, 1),     // bottomCenter
];

export enum AnchorPosition {
    topLeft,
    topRight,
    topCenter,
    middleLeft,
    middleRight,
    middleCenter,
    bottomLeft,
    bottomRight,
    bottomCenter,
}
