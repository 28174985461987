import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HandHistory } from '../../../shared/models/hand-history';
import { AppWebSocketService } from '../../../core/services/app-web-socket.service';
import { ErrorCode, RequestMessage, VariantType2 } from '../../../shared/enums/poker-types';
import { ManagerService } from '../../../core/services/manager.service';
import { cardDecoder } from '../../../shared/helpers/card-decoder';
import { Currency } from '../../../shared/enums';
import { ENavItem, NavItemType } from '../../nav/nav-item-type';
import { GameType } from '../../../shared/enums/game-type.enum';
import { MainService } from '../../main.service';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogHandReplayComponent } from '../dialog-hand-replay/dialog-hand-replay.component';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-hand-replay',
  templateUrl: './hand-replay.component.html',
  styleUrls: ['./hand-replay.component.scss']
})
export class HandReplayComponent implements OnInit {

  public environment = environment;
  @Input() public handIdError: string;
  @Input() public handHistory: HandHistory = <HandHistory>{};
  @Output() private close = new EventEmitter();

  constructor(
    private managerService: ManagerService,
    private mainService: MainService,
    private dataManagerService: DataManagerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.managerService.handHistory$.subscribe((handHistory: HandHistory) => {
      if (handHistory.Hands) {
        handHistory.Hands.forEach((hand) => {
          if (hand.Cards !== null) {
            hand.Cards.forEach(card => {
              card.Name = cardDecoder(card.Suit, card.Number);

            });
            hand.tableVariant = VariantType2[hand.Variant];

          }
        });
      }
      this.handHistory = handHistory;

    });


  }


  setHandId() {

    this.dialog.open(DialogHandReplayComponent, {
      width: '300px',
      disableClose: true
    })
      .afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(value => {
        if (value) {
          this.askHandReplayData(value)
        }
      });
  }


  askHandReplayData(handId) {
    this.dataManagerService.askHandReplayData(handId)
    this.mainService.toggleSidenav(false, false);
  }

  closeReplay() {
    this.close.emit();
  }

}
