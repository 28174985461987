import { createFeatureSelector, createSelector } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { LobbyFilterOptions } from '../../../shared/models';
import { LobbyActions, LobbyActionTypes } from './lobby.actons';

export interface State {
  currentFiltersValue: number;
  filters: LobbyFilterOptions;
}

export const initialState: State = {
  currentFiltersValue: 0,
  filters: {
    TableType: 1, 	// int [REQUIRED] ONE table type, see list of TableType bellow.
    Variants: [null], 	// int[] array of VariantType2 to be returned, if the array is NOT present, no filter will be made on variant.
    Stakes: null,	// int[] array of StakeType. Return tables with BB matching the configured stake range (micro to high).  if the array is NOT present, no filter wil be made on stake.
    Limits: null, 	// int[] array of LimitTypes. Return table matching the limit types. If the array is NOT present, no filter will be made on limit.
    Speeds: null,	// int[] array of SpeedType. Return the  game speed to be returned, if the array is NOT presetnt, no filter will be made on speed.

    NbSeats: null, // int[] array of number. Return table that have number of seats in the list. If the array is NOT present all tables are returned regardless the number of seats.

    HideFull: false,	// bool, if set to true, will not return tables considered full. if false do nothing. Default value is false.
    HideEmpty: false, // bool, if set to true will not return tables with no players. if false do nothing, Default value is false.
    ShowPrivate: false, // bool, if set to true will return tables that are password protected. if false will return table NOT passworded protected. Default value is false.
    FreerollOnly: false, // bool, if set to true, will return tournament marked as free roll only. if false no filter is made.
    ShowOnlyWithTip: false, // bool, if set to true will return only table where you can tip the dealer.
    currency: 0,
  }
};

export function reducer(state = initialState, action: LobbyActions): State {


  switch (action.type) {

    case LobbyActionTypes.ChangeFilter: {
      return {
        ...state, filters: action.payload
      };
    }

    case LobbyActionTypes.SetVariantFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          Variants: action.payload
        }
      };
    }

    case LobbyActionTypes.SetStakesFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          Stakes: action.payload
        }
      };
    }

    case LobbyActionTypes.SetTableOptionsFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          ShowPrivate: !!action.payload
        }
      };
    }

    case LobbyActionTypes.SetLimitFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          Limits: action.payload
        }
      };
    }

    case LobbyActionTypes.SetSpeedFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          Speeds: action.payload
        }
      };
    }

    case LobbyActionTypes.SetGameTypeFilter: {
      return {
        ...state, filters: {
          ...state.filters,
          TableType: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getFilters = (state: State) => state.filters;


export const getState = createFeatureSelector<State>('lobby');
export const getLobbyState = createSelector(getState, state => state);
export const getFiltersState = createSelector(getState, getFilters);
