import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from '../main.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  columnsTransaction = [
    'Name',
    'Value',
    'Currency',
    'Qty',
  ];
  constructor(
    public dialogRef: MatDialogRef<TicketComponent>,
    public mainService: MainService,
  ) {

  }

  ngOnInit() {
  }

}
