import { Input, Output, EventEmitter } from '@angular/core';

import { Currency, GameStatus } from '../../../shared/enums';
import { environment } from '../../../../environments/environment';
import { Player } from '../helpers/player';
import { Chat } from '../../../shared/models/chat';
import { GameHistory } from '../../../shared/models/game-history';
import { PlayerStats } from '../../../shared/models/player-stats';
import { BetControls, PreBetControls, ActionControls } from './game-controls-model';
import { ControlActions, BetActions, ReplayActions } from './game-controls-enum';
import { PreBet, PreBetType } from '../helpers/table';
import { Card } from '../helpers/card';
import { CallTimeStatus } from '../../../core/services/manager.service';
import { User } from '../../../shared/models/user';
import { Limit } from '../../../shared/enums/poker-types';
import { MemberPreferencesRunItTwice } from '../../../shared/models/member-preferences';

export abstract class GameControlsBase {

    public environment = environment;
    public Currency = Currency;
    public PreBetType = PreBetType;

    public betPreloadType: string;

    public showBetAction: boolean;

    @Input() public callTimeStatus: CallTimeStatus;

    @Input() public tableId: number;
    @Input() public isTournament: boolean;
    @Input() public currency: Currency;
    @Input() public isReplay: boolean;
    @Input() public playerAvailableMoney: number; // from auth service
    @Input() public handTypeText: string;
    @Input() public previousHandNumber: number;
    @Input() public currentHandNumber: number;

    @Input() public currentPlayer: Player;
    @Input() public chatMessages: Array<Chat>;
    @Input() public gameHistories: Array<GameHistory>;
    @Input() public playerStats: PlayerStats;

    @Input() public betControls: BetControls;

    @Input() public preBetControls: PreBetControls;
    @Input() public actionControls: ActionControls;

    @Input() showChat = false;
    @Output() showChatChange = new EventEmitter<boolean>();
    @Input() disableChat = false;

    @Output() betAction = new EventEmitter();
    @Output() changePreBetOutput = new EventEmitter();
    @Output() replayAction = new EventEmitter();
    @Output() controlAction = new EventEmitter();

    @Input() public smallBlind: number;
    @Input() public bigBlind: number;
    @Input() public ante: number;


    @Input() playerSelectedCards: Card[]
    @Input() stacksInBB: boolean;
    @Input() tourSum: boolean;
    @Input() user: User;
    @Input() amIOnTable: boolean;

    @Input() limit: Limit;
    limitType = Limit

    memberPreferencesRunItTwice = MemberPreferencesRunItTwice



    @Input() tournamentInfo: any;
    @Output() myEarnedSpells = new EventEmitter<void>();

    constructor(

    ) {


    }

    onMyEarnedSpells() {
        console.log("@onMyEarnedSpells")
        this.myEarnedSpells.emit()
    }

    //#region control actions

    clickOfferRabbitHunting() {
        this.betControls.showOfferRabbitHunting = false
        this.controlAction.emit({ actionType: ControlActions.OfferRabbitHunting });
    }

    clickReplayPreviousHand(handId) {
        this.controlAction.emit({ actionType: ControlActions.ReplayPreviousHand, data: handId });
    }

    clickStandPat() {
        this.controlAction.emit({ actionType: ControlActions.StandPat, data: null });
    }
    clickDiscard() {
        if (this.playerSelectedCards.length === 0) return
        this.controlAction.emit({ actionType: ControlActions.Discard, data: null });
    }


    clickBuyRebuyChips() {
        this.controlAction.emit({ actionType: ControlActions.BuyRebuyChips, data: null });
    }

    clickDealerTip() {
        this.controlAction.emit({ actionType: ControlActions.DealerTip, data: null });
    }

    clickImBack() {
        this.controlAction.emit({ actionType: ControlActions.ImBack, data: null });
    }

    clickLeaveTable() {
        this.controlAction.emit({ actionType: ControlActions.LeaveTable, data: null });
    }

    clickShowTourInfo() {
        this.controlAction.emit({ actionType: ControlActions.ShowTourInfo, data: null });
    }

    clickLeaveSeat() {
        this.controlAction.emit({ actionType: ControlActions.LeaveSeat, data: null });
    }

    clickRunItTwice() {
        this.controlAction.emit({ actionType: ControlActions.RunTwoTimes, data: null });
    }

    clickStraddle() {
        this.controlAction.emit({ actionType: ControlActions.Straddle, data: null });
    }

    clickSitoutNextHand() {
        this.controlAction.emit({ actionType: ControlActions.SitoutNextHand, data: null });
    }

    clickShowChat(show: boolean) {
        this.showChatChange.emit(show);
    }
    //#endregion

    //#region bet actions
    clickPostBigBlind() {
        this.betAction.emit({ actionType: BetActions.PostBigBlind, data: null });
    }

    clickFold() {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.Fold, data: null });
    }

    clickCall() {

        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.Call, data: null });
    }

    clickCheck() {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.Check, data: null });
    }

    clickRaise(sliderValue) {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.Raise, data: sliderValue });
    }

    clickSliderDecrease() {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.SliderDecrease, data: null });
    }

    clickSliderMin() {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.SliderMin, data: null });
    }

    onSliderChange(value) {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.SliderChange, data: value });
    }

    onSliderinputChange(value) {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.SliderInputChange, data: value });
    }

    clickSliderIncrease() {
        this.betControls.isBetPreload = false;
        this.betAction.emit({ actionType: BetActions.SliderIncrease, data: null });
    }

    clickSliderMax() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = 'MAX';
        this.betAction.emit({ actionType: BetActions.SliderMax, data: null });
    }

    click2BB() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '2BB';
        this.betAction.emit({ actionType: BetActions.BB2, data: null });
    }

    click3BB() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '3BB';
        this.betAction.emit({ actionType: BetActions.BB3, data: null });
    }

    click4BB() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '4BB';
        this.betAction.emit({ actionType: BetActions.BB4, data: null });
    }

    clickQuarterPot() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '1/4';
        this.betAction.emit({ actionType: BetActions.QuarterPot, data: null });
    }

    clickThreeQuartersPot() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '3/4';
        this.betAction.emit({ actionType: BetActions.ThreeQuartersPot, data: null });
    }

    clickHalfPot() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = '1/2';
        this.betAction.emit({ actionType: BetActions.HalfPot, data: null });
    }

    clickPot() {
        this.betControls.isBetPreload = true;
        this.betPreloadType = 'POT';
        this.betAction.emit({ actionType: BetActions.Pot, data: null });
    }

    changePreBet(preBet: PreBet, checked) {
        this.changePreBetOutput.emit({ preBet: preBet, checked: checked });
    }
    //#endregion

    //#region replay actions
    replayPlay() {
        this.replayAction.emit({ actionType: ReplayActions.Play, data: null });
    }

    replayNext() {
        this.replayAction.emit({ actionType: ReplayActions.Next, data: null });
    }

    replayStop() {
        this.replayAction.emit({ actionType: ReplayActions.Stop, data: null });
    }

    replayRestart() {
        this.replayAction.emit({ actionType: ReplayActions.Restart, data: null });
    }



    //#endregion
}
