import * as PIXI from 'pixi.js';

import { Point, Size } from '../models';

export class SexyDealer {
    container: PIXI.Container;
    nextLevelCost = 0;
    chageCost = 0;
    private level = 0;
    private containerSize: Point = { x: 256, y: 256 };
    private position: Point;
    private dealerSprite: PIXI.Sprite;

    constructor(newPosition: Point) {
        this.position = newPosition;
        this.container = new PIXI.Container();

        this.container.position.set(this.position.x - this.containerSize.x / 2,
            this.position.y - this.containerSize.y / 2);
    }

    hide() {
        this.container.visible = false;
    }

    show() {
        this.container.visible = true;
    }

    changeImage(newUrl: string) {
        this.dealerSprite = PIXI.Sprite.from(newUrl);
        this.container.removeChildren();
        this.dealerSprite.scale.set(0.85); // TODO:
        this.container.addChild(this.dealerSprite);
    }

    changeStatus(newLevel, newNextCost, newChangeCost, imageUrl) {
        this.level = newLevel;
        this.nextLevelCost = newNextCost;
        this.chageCost = newChangeCost;
        this.changeImage(imageUrl);
    }
}
