import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ServerMsg } from '../../shared/models';
import { MatDialog } from '@angular/material/dialog';
import { DialogNotification } from '../../shared/components/dialog-notification/dialog-notification.component';
import { ErrorCode } from '../../shared/enums/poker-types';


@Injectable()
export class NotificationsService {

  private errorNotificationSub = new Subject<string>();
  errorObservable = this.errorNotificationSub.asObservable();

  private successNotificationSub = new Subject<string>();
  successObservable = this.successNotificationSub.asObservable();

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
  }

  showDialog(data: ServerMsg) {
    this.dialog.open(DialogNotification, {
      data,
      width: '300px'
    }).afterClosed().subscribe(data => {
      if (data) { }
    });
  }

  showError(errorMsg: string) {
    if (errorMsg === ErrorCode[ErrorCode.LoginSkinNameRequired]) {
      errorMsg = 'Incorrect Username or Password'
    }
    this.toastr.error(errorMsg);
  }

  showSuccess(successMsg: string) {
    this.toastr.success(successMsg);
  }

}
