import { Point } from '../../models';

export const FoldedCardsPositions: Point[] = [
    { x: 671, y: 305 },
    { x: 1038, y: 305 },
    { x: 1367, y: 305 },

    { x: 1550, y: 415 },
    { x: 1540, y: 509 }, // center

    { x: 1367, y: 610 },
    { x: 1038, y: 610 },
    { x: 671, y: 610 },

    { x: 500, y: 510 },
    { x: 500, y: 300 },
];
export const FoldedCardsPositionsFor6Players: Point[] = [
    { x: 435, y: 325 },
    { x: 1040, y: 285 },
    { x: 1515, y: 325 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1515, y: 590 },
    { x: 1040, y: 610 },
    { x: 435, y: 590 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

// export const FoldedCardsPositionsUnlimited: Point[] = [
//     { x: 524, y: 289 },
//     { x: 931, y: 264 },
//     { x: 1408, y: 264 },
//     { x: 1519, y: 429 },
//     { x: 1505, y: 507 }, // center
//     { x: 1372, y: 642 }, // { x: 1372, y: 642 - 62 },
//     { x: 834, y: 652 }, // { x: 892, y: 652 - 62 },
//     { x: 556, y: 604 }, // { x: 556, y: 604 - 62 },
//     { x: 407, y: 525 },
//     { x: 381, y: 429 },
// ];
// export const FoldedCardsPositionsFor6PlayersUnlimited: Point[] = [
//     { x: 552, y: 236 },
//     { x: 931, y: 264 },
//     { x: 1410, y: 252 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 }, // center
//     { x: 1382, y: 665 - 62 },
//     { x: 892, y: 652 - 62 },
//     { x: 536, y: 666 - 62 },
//     { x: 0, y: 0 },
//     { x: 0, y: 0 },
// ];
