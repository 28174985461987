import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TranslateLanguages } from '../../../environments/environment-config.model';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  languages: any = {}
  selectedLanguage: TranslateLanguages
  config

  constructor(private _translateService: TranslateService, private configService: ConfigService) {
    this.config = this.configService.config;
    this.languages = this.config.settings.languages;

    this.languages.push({ value: "ar", displayText: "Arabic" })
    this.languages.push({ value: "ckb", displayText: "Kurdish (Sorani)" })
  }


  ngOnInit(): void {

    this.selectedLanguage = this.languages.find(el => el.value === this._translateService.currentLang)

    this._translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        this.selectedLanguage = this.languages.find(el => el.value === event.lang)
      });


  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeLanguage(language: string) {
    this._translateService.use(language);
  }
}
