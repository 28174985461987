import { Component, OnInit, Input } from '@angular/core';

import { Table } from '../../../../game/helpers/table';
import { NavTableData } from '../../../../../shared/models';
import { environment } from '../../../../../../environments/environment';
import { ConfigService } from '../../../../../core/services/config.service';

@Component({
  selector: 'app-nav-standard-table',
  templateUrl: './nav-standard-table.component.html',
  styleUrls: ['./nav-standard-table.component.scss']
})
export class NavStandardTableComponent implements OnInit {
  public environment = environment;
  @Input() navTable: NavTableData;
  config;
  constructor(private configService: ConfigService) {
    this.config = this.configService.config;
  }

  ngOnInit() {
  }

}
