export enum NoteColors {
    '#EBEBEB' = 0,
    '#17C1F0' = 1,
    '#F45BB6' = 2,
    '#F13B32' = 3,
    '#D6CCB6' = 4,
    '#FA9126' = 5,
    '#92C107' = 6,
    '#463F5A' = 7,
    '#37CFBA' = 8,
    '#E8B50E' = 9,
    '#9758AE' = 10,
    '#3272E1' = 11,
}
