import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AssetsLoaderService } from '../../core/services/assets-loader.service';
import { SkinSettingsService } from './skin-settings.service';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-skin-settings',
  templateUrl: './skin-settings.component.html',
  styleUrls: ['./skin-settings.component.scss']
})
export class SkinSettingsComponent implements OnInit {

  environment = environment;
  theme: string
  loader = false;
  config;

  constructor(
    private skinSettingsService: SkinSettingsService,
    private breakpointObserver: BreakpointObserver,
    private _assetsLoaderService: AssetsLoaderService,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;
    this.config.themes = this.config.themes.filter(el => el.name !== 'avax')
    this.theme = (this.config.themes && this.config.themes.length > 0) ? localStorage.getItem('theme') : null

  }

  ngOnInit() {
    
  }

  useTheme(themeName?: string) {
    if (this.theme === themeName || this.loader) { return }
    this.loader = true
    this.theme = themeName;
    if (!themeName) {
      const newSkinSettings = this._assetsLoaderService.removeTheme();
      this.skinSettingsService.removeTheme(newSkinSettings)
    } else {
      const applyData = this._assetsLoaderService.getTheme(themeName)
      this.skinSettingsService.applyTheme(applyData, themeName)
    }

    this._assetsLoaderService.reloadObservable$
      .pipe(take(1))
      .subscribe(() => { this.loader = false });
  }
}


