import { Component, OnInit } from '@angular/core';
import { GameControlsBase } from '../game-controls.base';
import { AuthService } from '../../../../auth/auth.service';
import { MainService } from '../../../main.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ManagerService } from '../../../../core/services/manager.service';
import { Settings, TournamentInformation } from '../../../../shared/models';
import { Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ControlActions } from '../game-controls-enum';
import { AssetsLoaderService } from '../../../../core/services/assets-loader.service';
import { ConfigService } from '../../../../core/services/config.service';
import { v3ManagerService } from '../../../../core/services/v3-manager.service';
import { Limit, VariantType2 } from '../../../../shared/enums/poker-types';
import { MatDialog } from '@angular/material/dialog';
import { FallGuysComponent } from '../../dialogs/fall-guys/fall-guys.component';

@Component({
  selector: 'app-game-controls',
  templateUrl: './game-controls.component.html',
  styleUrls: ['./game-controls.component.scss']
})
export class GameControlsComponent extends GameControlsBase implements OnInit {

  env = environment
  handsetPortrait = false;
  handsetLandscape = false;

  showChatBox = false;

  settings: Settings;
  destroy$ = new Subject<boolean>();
  buyChipsUrl: string;
  config;

  tournamentInfo$: Observable<TournamentInformation>;
  variantType2 = VariantType2
  limitType = Limit;

  spellZone$: Observable<any>;

  constructor(
    public authService: AuthService,
    public mainService: MainService,
    private breakpointObserver: BreakpointObserver,
    public managerService: ManagerService,
    public assetsLoader: AssetsLoaderService,
    private dialog: MatDialog,
    private configService: ConfigService,
    private _v3ManagerService: v3ManagerService,

  ) {
    super();
    this.config = this.configService.config;

    this.tournamentInfo$ = this.managerService.activeGames$.pipe(
      map(activeGames => Object.values(activeGames).find(el => el.isActive)),
      switchMap(activeGame => {
        if (activeGame?.table?.IdTournament) {
          return this._v3ManagerService.getTournament(activeGame?.table?.IdTournament)
        } {
          return of(undefined)
        }
      }),
    )
    this.spellZone$ = merge(

      this.tournamentInfo$.pipe(
        map(tour => (tour as any)?.tourSum?.BountySpellConfiguration?.MinPlayersRequredToActivateSpell),

        filter(data => data > 0)

      ),


      this.managerService.activeGames$.pipe(
        map(activeGames => Object.values(activeGames).find(el => el.isActive)),
        switchMap(activeGame => {
          if (activeGame?.table?.IdTournament) {
            return this.managerService.spellZones$.pipe(map(zones => zones[activeGame?.table?.IdTournament]))
          } {
            return of(undefined)
          }
        }),
        map(data => data?.minActivePlayersRequired),
        tap(data => console.log("++++spellZone2", data)),

        filter(data => data > 0)

      )

    )

  }

  ngOnInit() {
    const theme = localStorage.getItem('theme')
    if (theme) {
      this.buyChipsUrl = `assets/theme/${theme}/tables/buy_chips.png`
    } else {
      this.buyChipsUrl = `${environment.dataSource}/assets/${this.config.code}/tables/buy_chips.png`


    }
    this.assetsLoader.reloadObservable$.subscribe(() => {
      const theme = localStorage.getItem('theme')
      if (theme) {
        this.buyChipsUrl = `assets/theme/${theme}/tables/buy_chips.png`
      } else {
        this.buyChipsUrl = `${environment.dataSource}/assets/${this.config.code}/tables/buy_chips.png`

      }
    });

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape]).subscribe(result => {
        this.handsetLandscape = result.matches;
      })


    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          this.settings = settings;
        }
      })
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  clickOpenFallGuysLevelsForApps(data: any) {
    this.dialog.open(FallGuysComponent, {
      data,
      width: '360px',
      //position: { bottom: '100px' }
    });
  }
}
