import { Component, OnInit } from '@angular/core';

import { SpinnerService } from './spinner.service';
import { environment } from '../../../environments/environment';
import { debounceTime } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  
  environment = environment;
  visible = true;
  config;

  constructor(
    private spinnerService: SpinnerService,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

  }

  ngOnInit() {
    this.spinnerService.spinner$
      .pipe(debounceTime(250))
      .subscribe((show) => {
        this.visible = show;
      });
  }

}
