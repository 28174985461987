import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ManagerService } from '../../../core/services/manager.service';
import { MainService, SidenavState } from '../../main.service';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { HandHistory } from '../../../shared/models/hand-history';

@Component({
  selector: 'app-sidenav-left',
  templateUrl: './sidenav-left.component.html',
  styleUrls: ['./sidenav-left.component.scss']
})
export class SidenavLeftComponent implements OnInit {

  @Input() config: SidenavState
  @Input() public handIdError: string;
  @Input() public handHistory: HandHistory = <HandHistory>{};
  @Output() private openSkinSettings = new EventEmitter();

  public showReplay = false;

  constructor(
    public managerService: ManagerService,
    public mainService: MainService,
    public dataManagerService: DataManagerService,
  ) { }

  ngOnInit() {

    this.mainService.sidenavState
      .subscribe(() => {
        this.showReplay = false;
      })
  }

  replayerClick() {
    this.showReplay = true;
  }

  closeReplay() {
    this.showReplay = false;
  }

  skinSettingsClick() {
    this.openSkinSettings.emit();
  }

}
