import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AssetsLoaderService } from '../../core/services/assets-loader.service';
import { Carpet } from '../../shared/models';
import { ConfigService } from '../../core/services/config.service';
import { c } from 'bowser';
import { take } from 'rxjs/operators';
import { is } from 'date-fns/locale';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class SkinSettingsService {

  allSettings = JSON.parse(JSON.stringify(this.configService.config.skinSettings));
  selectedSettings = {
    playerCard: this.allSettings.playerCards[0].cardUrl,
    tableCard: this.allSettings.tableCards[0].cardUrl,
    backCard: this.allSettings.backCards[0].cardUrl,

    tableLandscape: this.allSettings.tables.landscape[0].tableUrl,
    tablePortrait: this.allSettings.tables.portrait[0].tableUrl,

    carpetLandscape: this.allSettings.carpets.landscape[0],
    carpetPortrait: this.allSettings.carpets.portrait[0],

    isPortrait: false
  };

  applyData: { [index: string]: string; } = {};


  private changePlayerCardsSub = new Subject<string>();
  changePlayerCardsObservable$ = this.changePlayerCardsSub.asObservable();

  private changeTableCardsSub = new Subject<string>();
  changeTableCardsObservable$ = this.changeTableCardsSub.asObservable();

  private changeBackCardSub = new Subject<string>();
  changeBackCardObservable$ = this.changeBackCardSub.asObservable();

  private changeTableSub = new Subject<number>();
  changeTableObservable$ = this.changeTableSub.asObservable();

  private changeCarpetSub = new Subject<number>();
  changeCarpetObservable$ = this.changeCarpetSub.asObservable();

  private applyChangesSub = new Subject<string>();
  applyChangesObservable$ = this.applyChangesSub.asObservable();


  useTheme$ = new ReplaySubject<string>(1);


  constructor(public _assetsLoaderService: AssetsLoaderService,
    private configService: ConfigService,
    protected breakpointObserver: BreakpointObserver,
  ) {

    this.selectedSettings.backCard = this._assetsLoaderService.skinSettings.backCard;
    this.selectedSettings.tableCard = this._assetsLoaderService.skinSettings.tableCardAtlas.replace('.json', '.png');

    this.selectedSettings.tableLandscape = this._assetsLoaderService.skinSettings.table;
    this.selectedSettings.tablePortrait = this._assetsLoaderService.skinSettings.tableVertical;


    this.selectedSettings.carpetLandscape.carpetUrl = this._assetsLoaderService.skinSettings.carpet;
    this.selectedSettings.carpetPortrait.carpetUrl = this._assetsLoaderService.skinSettings.carpetVertical;

    this.useTheme$.subscribe((themeName) => {


      if (!themeName) {
        const newSkinSettings = this._assetsLoaderService.removeTheme();
        this.removeTheme(newSkinSettings)
      } else {
        const applyData = this._assetsLoaderService.getTheme(themeName)
        this.applyTheme(applyData, themeName)
      }

    })

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.Web
      ])
      .subscribe(result => {
        if (result.matches) {
          if (result.breakpoints[Breakpoints.HandsetPortrait]) {
            this.selectedSettings.isPortrait = true;
          } else if (result.breakpoints[Breakpoints.HandsetLandscape]) {
            this.selectedSettings.isPortrait = false;
          } else {
            this.selectedSettings.isPortrait = false;
          }
        }
      })

  }



  changePlayerCard(cardUrl: string, index: number) {
    this.selectedSettings.playerCard = cardUrl;
    this.applyData['playerCardAtlas'] = this.configService.config.skinSettings.playerCards[index].cardJsonUrl;
    this.changePlayerCardsSub.next(cardUrl);
  }

  changeTableCard(cardUrl: string, index: number) {
    this.selectedSettings.tableCard = cardUrl;
    this.applyData['tableCardAtlas'] = this.configService.config.skinSettings.tableCards[index].cardJsonUrl;
    this.changeTableCardsSub.next(cardUrl);
  }

  changeBackCard(backCardUrl: string) {
    this.selectedSettings.backCard = backCardUrl;
    this.applyData['backCard'] = backCardUrl;
    this.changeBackCardSub.next(backCardUrl);
  }

  changeTable(index: number) {
    this.selectedSettings.tableLandscape = this.configService.config.skinSettings.tables.landscape[index].tableUrl;
    this.selectedSettings.tablePortrait = this.configService.config.skinSettings.tables.portrait[index].tableUrl;

    this.applyData['table'] = this.selectedSettings.tableLandscape;
    this.applyData['tableVertical'] = this.selectedSettings.tablePortrait;

    this.changeTableSub.next(index);
  }

  changeCarpet(index: number) {
    this.selectedSettings.carpetLandscape = this.configService.config.skinSettings.carpets.landscape[index];
    this.selectedSettings.carpetPortrait = this.configService.config.skinSettings.carpets.portrait[index];

    this.applyData['carpet'] = this.selectedSettings.carpetLandscape.carpetUrl;
    this.applyData['carpetVertical'] = this.selectedSettings.carpetPortrait.carpetUrl;

    this.changeCarpetSub.next(index);
  }

  applyChanges() {
    if (Object.keys(this.applyData).length === 0) { return; }
    this._assetsLoaderService.reload(this.applyData);
    this._assetsLoaderService.saveSkinSettings(this.applyData);
    this.applyData = {};
  }

  applyTheme(applyData: { [index: string]: string; }, themeName: string) {
    if (Object.keys(applyData).length === 0) { return; }
    this._assetsLoaderService.saveTheme(themeName);


    this.selectedSettings.carpetLandscape.carpetUrl = `assets/theme/${themeName}/carpets/vertical_background.jpg`
    this.selectedSettings.carpetPortrait.carpetUrl = `assets/theme/${themeName}/carpets/vertical_background.jpg`

    this._assetsLoaderService.reload(applyData);
  }

  removeTheme(data: any) {
    this.selectedSettings.carpetLandscape.carpetUrl = data.carpet
    this.selectedSettings.carpetPortrait.carpetUrl = data.carpetVertical

  }
}
