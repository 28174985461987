import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DealerAction } from '../../../../shared/enums/dealer-action';
import { DealerTipCost } from '../../../../shared/models';


@Component({
  selector: 'app-dealer-tip',
  templateUrl: './dealer-tip.component.html',
  styleUrls: ['./dealer-tip.component.scss']
})
export class DealerTipComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DealerTipComponent>,
    @Inject(MAT_DIALOG_DATA) public dealerTipCost: DealerTipCost
  ) {}

  ngOnInit() {
  }

  clickTip() {
    this.dialogRef.close(DealerAction.tip);
  }

  clickChangeTip() {
    this.dialogRef.close(DealerAction.switch);
  }

}
