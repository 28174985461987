import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Currency } from '../../../shared/enums';
import { Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TimeParser } from '../helpers/time-parser';

export interface Rebuy {
  reBuyNbLimit: number;
  reBuyThreshold: number;
  rebuyFee: number;
  currency: Currency;
  countdown: number;
  showRebuyButton: boolean;
  showRebuyDoubleButton: boolean;
}

export enum RebuyAnswer {
  CANCEL = -1,
  REBUY = 1,
  DOUBLE_REBUY = 2,
}

@Component({
  selector: 'app-game-rebuy',
  templateUrl: './game-rebuy.component.html',
  styleUrls: ['./game-rebuy.component.scss']
})
export class GameRebuyComponent implements OnInit, OnDestroy {

  @Output() rebuyAnswer = new EventEmitter();

  private timerSubscription: Subscription;
  public visible: boolean;
  public rebuy: Rebuy = {} as Rebuy;
  public timeText: string;

  constructor() { }

  ngOnInit() { }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  clickCancel() {
    this.visible = false;
    this.rebuyAnswer.emit(RebuyAnswer.CANCEL);
  }

  clickRebuy() {
    if (!this.rebuy.showRebuyButton) { return }
    this.visible = false;
    this.rebuyAnswer.emit(RebuyAnswer.REBUY);
  }

  clickDoubleRebuy() {
    this.visible = false;
    this.rebuyAnswer.emit(RebuyAnswer.DOUBLE_REBUY);
  }

  public show(rebuyData: Rebuy) {
    this.visible = true;
    this.rebuy = rebuyData;
    this.startInterval();
  }

  private startInterval() {

    this.timerSubscription = timer(0, 1000)
      .pipe(
        take(this.rebuy.countdown),
        map(() => --this.rebuy.countdown)
      )
      .subscribe(() => {
        if (this.rebuy.countdown <= 0) {
          this.rebuyAnswer.emit(RebuyAnswer.CANCEL);
          this.visible = false;
          this.timerSubscription.unsubscribe();
          return;
        }

        this.timeText = TimeParser.parseTime(this.rebuy.countdown);
      });
  }
}
