import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../../shared/shared.module';

import { ViewTournamentComponent } from './containers/view-tournament/view-tournament.component';
import { ViewTournamentPlayersAndPrizesComponent } from './presenters/view-tournament-players-and-prizes/view-tournament-players-and-prizes.component';
import { ViewTournamentActiveTablesComponent } from './presenters/view-tournament-active-tables/view-tournament-active-tables.component';
import { ViewTournamentStructureComponent } from './presenters/view-tournament-structure/view-tournament-structure.component';
import { VewTournamentInfoComponent } from './presenters/view-tournament-info/view-tournament-info.component';

import { DialogTournamentEndResultComponent } from './presenters/dialog-tournament-end-result/dialog-tournament-end-result.component';
import { ViewTournamentStatusComponent } from './presenters/view-tournament-status/view-tournament-status.component';
import { DialogTournamentRegistrationComponent } from './presenters/dialog-tournament-registration/dialog-tournament-registration.component';
import { ButtonTournamentRegistrationComponent } from './presenters/button-tournament-registration/button-tournament-registration.component';
import { LinkifyPipe } from '../../core/linkify.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SwiperModule
  ],
  declarations: [
    ViewTournamentComponent,
    VewTournamentInfoComponent,
    ViewTournamentPlayersAndPrizesComponent,
    ViewTournamentActiveTablesComponent,
    ViewTournamentStructureComponent,

    ViewTournamentStatusComponent,

    DialogTournamentEndResultComponent,
    DialogTournamentRegistrationComponent,

    ButtonTournamentRegistrationComponent,
    LinkifyPipe
  ],
  exports: [
    ViewTournamentComponent,
    ButtonTournamentRegistrationComponent
  ],
  entryComponents: [
    DialogTournamentEndResultComponent,
    DialogTournamentRegistrationComponent
  ],
})
export class TournamentModule { }
