import * as GameActions from './game-store.actions';

import { createSelector, createFeatureSelector } from '@ngrx/store';
import { NavTableData } from '../../../shared/models';

export type Action = GameActions.All;

export interface State {
    activeGames: { [index: number]: NavTableData };
}
const initialState: State = {
    activeGames: {},
};
export function activeGamesReducer(state: State = initialState, action: Action): State {
    switch (action.type) {
        case GameActions.ADD_GAME:
            Object.keys(state.activeGames).map(key => {
                state.activeGames[key].isActive = false;
            });
            state.activeGames[action.payload.table.IdTable] = action.payload;
            return { ...state };

        case GameActions.SET_ACTIVE_GAME:
            Object.keys(state.activeGames).map(key => {
                if (state.activeGames[key].table.IdTable === action.payload.tableId) {
                    state.activeGames[key].isActive = true;
                } else {
                    state.activeGames[key].isActive = false;
                }
            });
            return { ...state };

        case GameActions.UPDATE_GAME:
            state.activeGames[action.payload.table.IdTable] = action.payload;
            return { ...state };

        case GameActions.SET_CARDS_IN_HAND:
            if (state.activeGames[action.tableId]) {
                state.activeGames[action.tableId].cardsInHand = action.cardsInHand;
            }
            return { ...state };

        case GameActions.SET_PLAYER_MONEY:
            if (state.activeGames[action.tableId]) {
                state.activeGames[action.tableId].playerMoney = action.playerMoney;
            }
            return { ...state };

        case GameActions.SET_ON_MOVE:
            if (state.activeGames[action.tableId]) {
                state.activeGames[action.tableId].onMove = action.onMove;
            }
            return { ...state };

        case GameActions.SET_FOLDED:
            if (state.activeGames[action.tableId]) {
                state.activeGames[action.tableId].folded = action.folded;
            }
            return { ...state };

        case GameActions.LEAVE_SEAT:
            if (state.activeGames[action.tableId]) {
                state.activeGames[action.tableId].onMove = false;
                state.activeGames[action.tableId].folded = false;
                state.activeGames[action.tableId].cardsInHand = [];
            }
            return { ...state };

        case GameActions.REMOVE_GAME:
            if (state.activeGames[action.payload.tableId]) {
                delete state.activeGames[action.payload.tableId];
            }
            return { ...state };

        case GameActions.REMOVE_TOURNAMENT:
            if (state.activeGames[action.payload.tournamentId]) {
                delete state.activeGames[action.payload.tournamentId];
            }
            return { ...state };

        case GameActions.LOGOUT:
            return { ...state, activeGames: [] };

        default:
            return state;
    }
}

export const getState = createFeatureSelector<State>('activeGames');
export const getActiveGamesState = createSelector(getState, state => state.activeGames);

export const getActiveGames = createSelector(getState, state => {
    return Object.keys(state.activeGames).map(id => state.activeGames[id]);
});

export function getActiveGamesById(tableId: number) {
    return createSelector(getState, (state: State) => state.activeGames[tableId]);
}
