import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ManagerService } from '../../../core/services/manager.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Settings } from '../../../shared/models';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { passwordMatchValidator } from '../../../shared/validators/validators';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit {
  environment = environment;

  form = this.fb.group({
    password: ['', [Validators.required]],
    passwordConfirm: ['', Validators.required],
  }, { validator: passwordMatchValidator });

  seetings$: Observable<Settings>
  config;
  constructor(
    private dataManagerService: DataManagerService,
    private managerService: ManagerService,
    private dialogRef: MatDialogRef<ForceChangePasswordComponent>,
    private fb: FormBuilder,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;

  }

  ngOnInit(): void {
    this.seetings$ = this.managerService.settings$
      .pipe(tap((settings) => {
        this.form.get('password').setValidators(Validators.required)
        this.form.get('passwordConfirm').setValidators(Validators.pattern(`${settings.PlayerPasswordRegex}`))
      }))
  }


  submit() {
    if (!this.form.valid) {
      return
    }
    this.dataManagerService.sendChangePassword(this.form.value.password);
    this.dialogRef.close()
  }
}
