import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WaitingListCommand } from '../../../../../shared/enums/poker-types';


@Component({
  selector: 'app-game-waiting-list',
  templateUrl: './game-waiting-list.component.html',
  styleUrls: ['./game-waiting-list.component.scss']
})
export class GameWaitingListComponent implements OnInit {

  @Input() waitingList: Array<string>;
  @Input() showWaitingList: boolean;
  @Input() canJoin: boolean;
  @Output() change: EventEmitter<WaitingListCommand> = new EventEmitter<WaitingListCommand>();

  public collapsible = false;

  constructor() { }

  ngOnInit() {
  }

  joinWaitingList() {
    this.change.next(WaitingListCommand.Join);
    this.collapsible = false;
  }

  leaveWaitingList() {
    this.change.next(WaitingListCommand.Leave);
    this.collapsible = false;
  }

}
