import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SkinSettingsService } from '../skin-settings.service';
import { Carpet } from '../../../shared/models';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'app-skin-settings-options',
  templateUrl: './skin-settings-options.component.html',
  styleUrls: ['./skin-settings-options.component.scss']
})
export class SkinSettingsOptionsComponent implements OnInit {
  @Input() theme: string

  playerCards: Array<{ cardUrl: string; cardJsonUrl: string; }>;
  tableCards: Array<{ cardUrl: string; cardJsonUrl: string; }>;
  backCards: Array<{ cardUrl: string; }>;

  tables: {
    landscape: Array<{ tableUrl: string; }>,
    portrait: Array<{ tableUrl: string; }>,
  };
  carpets: {
    landscape: Array<Carpet>;
    portrait: Array<Carpet>;
  }

  handsetPortrait: boolean;
  handsetLandscape: boolean;
  config;

  constructor(
    public skinSettingsService: SkinSettingsService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;

    this.playerCards = this.config.skinSettings.playerCards;
    this.tableCards = this.config.skinSettings.tableCards;
    this.backCards = this.config.skinSettings.backCards;

    this.tables = {
      landscape: this.config.skinSettings.tables.landscape,
      portrait: this.config.skinSettings.tables.portrait
    }
    this.carpets = {
      landscape: this.config.skinSettings.carpets.landscape,
      portrait: this.config.skinSettings.carpets.portrait
    }
  }



  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape]).subscribe(result => {
        this.handsetLandscape = result.matches;
      })
  }

  onCardsChange(value: string, index: number) {
    this.skinSettingsService.changePlayerCard(value, index);
    this.skinSettingsService.changeTableCard(value, index);
  }

  onBackCardChange(value) {
    this.skinSettingsService.changeBackCard(value);
  }

  onTableChange(index: number) {
    this.skinSettingsService.changeTable(index);
  }

  onCarpetChange(index: number) {
    this.skinSettingsService.changeCarpet(index);
  }

}
