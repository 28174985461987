export enum Currency {
    Invalid = -2,
    All = -1,
    PlayMoney = 0,
    USD = 1,
    Euro = 2,
    IndianRupee = 4,
    TDS = 5,
    ChipNoDecimal = 6,
    Chip = 7,
    Tournament = 8,
    IRR = 9, // Iran
    AUD = 10,
    MNT = 11,
    UAH = 12, // Ukrainian hryvnia
    BRL = 13,
    TRY = 14, // Turkish lira
    TND = 15, // Tunisian Dinar
    Bitcoin = 16,
    Ethereum = 18,
    Toman = 19, // ufoPoker
    ILS = 21,
    XGCS = 40,
    Z4L = 41, // zipett
    TCJ = 42, // pokerAlb
    USDT = 43, // Tether
    VEF = 46,
    ARS = 47, // Peso argentino,
    LBP = 48, // Lebanese pound
    Points = 256, // for formatting only
}

export function getCurrencyName(currency) {
    return currency.Name || '';
}

export function getCurrencyIconName(currency: Currency): string {
    return 'currency_chip';
    
    if (currency === Currency.PlayMoney) {
        return 'currency_play_money';
    } else if (currency === Currency.USD) {
        return 'currency_dollar';
    } else if (currency === Currency.Euro) {
        return 'currency_chip' //'currency_euro';
    } else if (currency === Currency.IndianRupee) {
        return 'currency_rupee';
    } else if (currency === Currency.TDS) {
        return 'currency_chip';
    } else if (currency === Currency.ChipNoDecimal) {
        return 'currency_chip';
    } else if (currency === Currency.Chip) {
        return 'currency_chip';
    } else if (currency === Currency.Tournament) {
        return 'currency_chip';
    } else if (currency === Currency.IRR) {
        return 'currency_iranian_rial';
    } else if (currency === Currency.AUD) {
        return 'currency_dollar';
    } else if (currency === Currency.MNT) {
        return 'currency_mnt';
    } else if (currency === Currency.UAH) {
        return 'currency_ukrainian_hryvnia';
    } else if (currency === Currency.TRY) {
        return 'currency_chip'; // should be 'currency_turkish_lira' but svg file is bad
    } else if (currency === Currency.Bitcoin) {
        return 'currency_btc';
    } else if (currency === Currency.Ethereum) {
        return 'currency_eth';
    } else if (currency === Currency.XGCS) {
        return 'currency_xgalaxy';
    } else if (currency === Currency.TND) {
        return 'currency_chip';
    } else if (currency === Currency.Z4L) {
        return 'currency_chip';
    } else if (currency === Currency.TCJ) {
        return 'currency_chip';
    } else if (currency === Currency.Toman) {
        return 'currency_chip';
    } else if (currency === Currency.ILS) {
        return 'currency_chip';
    } else {
        return 'currency_chip';
    }
}
