import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NowDateTime } from './now-date-time';
import { CurrencyDeviderPipe } from './currency-devider.pipe';
import { CurrencyMultiplierPipe } from './currency-multiplier.pipe';
import { CurrencyPipe } from './currency.pipe';
import { CurrencyCryptoConverterPipe } from './currency-crypto-converter.pipe';
import { PlayerPointsPipe } from './player-points.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { CountdownPipe } from './countdown.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NowDateTime,
    CurrencyDeviderPipe,
    CurrencyMultiplierPipe,
    CurrencyPipe,
    CurrencyCryptoConverterPipe,
    PlayerPointsPipe,
    SafeUrlPipe,

    // v2
    CountdownPipe
  ],
  exports: [
    NowDateTime,
    CurrencyDeviderPipe,
    CurrencyMultiplierPipe,
    CurrencyPipe,
    CurrencyCryptoConverterPipe,
    PlayerPointsPipe,
    SafeUrlPipe,


    // v2
    CountdownPipe
  ]
})
export class UtilsModule { }
