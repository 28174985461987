import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyInfo } from '../models/currency-info';

@Pipe({ name: 'currDevider' })
export class CurrencyDeviderPipe implements PipeTransform {

    transform(value: number, currency: CurrencyInfo): number {

        return value / (currency ? currency.Multiplier : 1);
    }
}
