import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { SkinSettingsComponent } from './skin-settings.component';
import { SkinSettingsPreviewComponent } from './skin-settings-preview/skin-settings-preview.component';
import { SkinSettingsService } from './skin-settings.service';
import { SkinSettingsOptionsComponent } from './skin-settings-options/skin-settings-options.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    SkinSettingsComponent,
    SkinSettingsPreviewComponent,
    SkinSettingsOptionsComponent
  ],
  exports: [
    SkinSettingsComponent,
    SkinSettingsPreviewComponent
  ],
  providers: [
    SkinSettingsService
  ]
})
export class SkinSettingsModule { }
