import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tournament } from '../../../../shared/models';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'view-tournament-structure',
  templateUrl: './view-tournament-structure.component.html',
  styleUrls: ['./view-tournament-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTournamentStructureComponent {
  @Input() user: User;
  @Input() tournament: Tournament;
}
