import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Point, Size } from '../../game/models/point';

import { SkinSettingsService } from '../skin-settings.service';
import { Subscription } from 'rxjs';
import { Carpet } from '../../../shared/models';
import { environment } from '../../../../environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { ConfigService } from '../../../core/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-skin-settings-preview',
  templateUrl: './skin-settings-preview.component.html',
  styleUrls: ['./skin-settings-preview.component.scss']
})
export class SkinSettingsPreviewComponent implements OnInit, OnChanges {
  @Input() theme: string

  environment = environment;

  private tableSize: Size
  private cardSizeOnAtlas: Size
  private cardSizeOnCanvas: Size
  private tablePosition: Point

  private cardsTablePosition: Point[]
  private cardsAtlasPosition: Point[]

  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private tableImage: HTMLImageElement;
  private cardAtlasImage: HTMLImageElement;
  private backCardImage: HTMLImageElement;
  public carpetBackground;

  private subscription = new Subscription();

  handsetPortrait: boolean;
  handsetLandscape: boolean;
  loaded: boolean = false;
  constructor(
    private skinSettingsService: SkinSettingsService,
    private breakpointObserver: BreakpointObserver,
    private assetsLoaderService: AssetsLoaderService,
    private configService: ConfigService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(): void {
    if (!this.loaded) { return }
    if (this.theme && (this.configService.config.themes && this.configService.config.themes.length > 0)) {
      this.changeCarpet();
      this.changeTableImage();
      this.changeBackCardImage(`assets/theme/${this.theme}/cards/cardBack.png`);

    } else {
      let skinSettings = {
        playerCardAtlas: this.configService.config.skinSettings.playerCards[0].cardJsonUrl,
        tableCardAtlas: this.configService.config.skinSettings.tableCards[0].cardJsonUrl,
        backCard: this.configService.config.skinSettings.backCards[0].cardUrl,
        carpet: this.configService.config.skinSettings.carpets.landscape[0].carpetUrl,
        carpetVertical: this.configService.config.skinSettings.carpets.portrait[0].carpetUrl,
        table: this.configService.config.skinSettings.tables.landscape[0].tableUrl,
        tableVertical: this.configService.config.skinSettings.tables.portrait[0].tableUrl
      };

      skinSettings = { ...skinSettings, ...this.assetsLoaderService.getSkinSettings() }

      this.changeBackCardImage(skinSettings.backCard);

      const carpetIndex = this.configService.config.skinSettings.carpets.landscape.findIndex(el => el.carpetUrl === skinSettings.carpet)
      this.changeCarpet(carpetIndex);

      const tableIndex = this.configService.config.skinSettings.tables.landscape.findIndex(el => el.tableUrl === skinSettings.table)
      this.changeTableImage(tableIndex);

    }
  }

  ngOnInit() {
    this.canvas = <HTMLCanvasElement>document.getElementById('skin-settings');
    this.context = this.canvas.getContext('2d');

    this.subscription.add(
      this.breakpointObserver
        .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
          this.handsetPortrait = result.matches;
          this.loaded = false

          this.load()
        })
    )

    this.subscription.add(
      this.breakpointObserver
        .observe([Breakpoints.HandsetLandscape]).subscribe(result => {
          this.handsetLandscape = result.matches;
          this.loaded = false

          this.load()
        })
    )

    this.subscription.add(
      this.skinSettingsService.changePlayerCardsObservable$.subscribe(url => {
        this.changeCardsImage(url);
      })
    );

    this.subscription.add(
      this.skinSettingsService.changeBackCardObservable$.subscribe(url => {
        this.changeBackCardImage(url);
      })
    );


    this.subscription.add(
      this.skinSettingsService.changeTableObservable$.subscribe(index => {
        this.changeTableImage(index);
      }),
    );

    this.subscription.add(
      this.skinSettingsService.changeCarpetObservable$.subscribe(index => {
        this.changeCarpet(index);
      })
    );

  }


  private load() {
    if (this.handsetPortrait) {
      this.tableSize = { width: 300, height: 446 }
      this.tablePosition = { x: 15, y: 15 };


      this.cardsAtlasPosition = [{ x: 0, y: 0 }]
      this.cardSizeOnAtlas = { width: 86, height: 122 }

      this.cardsTablePosition = [{ x: 100, y: 190 }, { x: 180, y: 190 }]

      this.cardSizeOnCanvas = { width: 50, height: 71 };

      this.carpetBackground = `url("${this.skinSettingsService.selectedSettings.carpetPortrait.carpetUrl}")`;

    } else {
      this.tableSize = { width: 640, height: 297 };
      this.tablePosition = { x: 32, y: 32 };

      this.cardsAtlasPosition = [
        { x: 353, y: 497 },
        { x: 617, y: 497 },
        { x: 1, y: 125 }
      ];
      this.cardSizeOnAtlas = { width: 86, height: 122 };

      this.cardsTablePosition = [
        { x: 104 + 55 + 3 + 32, y: 119 + 30 },
        { x: 194 - 26 + 55 + 3 + 32, y: 119 + 30 },
        { x: 284 - 26 * 2 + 55 + 3 + 32, y: 119 + 30 },
        { x: 374 - 26 * 3 + 55 + 3 + 32, y: 119 + 30 },
        { x: 464 - 26 * 4 + 55 + 3 + 32, y: 119 + 30 }
      ];
      this.cardSizeOnCanvas = { width: 60, height: 85 };

      this.carpetBackground = this.sanitizer.bypassSecurityTrustStyle(`url("${this.skinSettingsService.selectedSettings.carpetLandscape.carpetUrl}")`);


    }

    Promise.all([
      this.loadImage(this.handsetPortrait ? this.skinSettingsService.selectedSettings.tablePortrait : this.skinSettingsService.selectedSettings.tableLandscape),
      this.loadImage(this.skinSettingsService.selectedSettings.backCard),
      this.loadImage(this.skinSettingsService.selectedSettings.tableCard)
    ]).then((res) => {
      this.tableImage = <HTMLImageElement>res[0];
      this.backCardImage = <HTMLImageElement>res[1];
      this.cardAtlasImage = <HTMLImageElement>res[2];
      this.drawScene();

      if (!this.loaded) {
        this.loaded = true
        if (this.theme && (this.configService.config.themes && this.configService.config.themes.length > 0)) {
          this.changeCarpet();
          this.changeTableImage();
          this.changeBackCardImage(`assets/theme/${this.theme}/cards/cardBack.png`);
        }
      }
    });
  }

  private drawScene() {
    this.context.clearRect(0, 0, this.tableSize.width + this.tablePosition.x, this.tableSize.height + this.tablePosition.y)
    this.context.drawImage(
      this.tableImage,
      this.tablePosition.x,
      this.tablePosition.y,
      this.tableSize.width,
      this.tableSize.height
    );
    this.drawCards();
  }

  private drawCards() {

    if (this.handsetPortrait) {
      this.context.drawImage(
        this.cardAtlasImage,
        this.cardsAtlasPosition[0].x,
        this.cardsAtlasPosition[0].y,
        this.cardSizeOnAtlas.width,
        this.cardSizeOnAtlas.height,
        this.cardsTablePosition[0].x,
        this.cardsTablePosition[0].y,
        this.cardSizeOnCanvas.width,
        this.cardSizeOnCanvas.height
      );
      this.context.drawImage(
        this.backCardImage,
        this.cardsTablePosition[1].x,
        this.cardsTablePosition[1].y,
        this.cardSizeOnCanvas.width,
        this.cardSizeOnCanvas.height
      );
    } else {


      for (let i = 0; i < this.cardsAtlasPosition.length; i++) {
        this.context.drawImage(
          this.cardAtlasImage,
          this.cardsAtlasPosition[i].x,
          this.cardsAtlasPosition[i].y,
          this.cardSizeOnAtlas.width,
          this.cardSizeOnAtlas.height,
          this.cardsTablePosition[i].x,
          this.cardsTablePosition[i].y,
          this.cardSizeOnCanvas.width,
          this.cardSizeOnCanvas.height);
      }

      for (let i = 3; i < 5; i++) {
        this.context.drawImage(
          this.backCardImage,
          0,
          0,
          87,
          122,
          this.cardsTablePosition[i].x,
          this.cardsTablePosition[i].y,
          this.cardSizeOnCanvas.width,
          this.cardSizeOnCanvas.height

        );
      }
    }
  }

  private loadImage(src: string) {
    return new Promise((resolve, reject) => {
      const imageElement = new Image();
      imageElement.src = src;
      imageElement.onload = () => {
        resolve(imageElement);
      };
    });
  }

  public changeTableImage(index?: number) {
    if (this.handsetPortrait) {
      if (this.theme) {
        this.tableImage.src = `assets/theme/${this.theme}/tables/vertical_table.png`;
      } else {
        this.tableImage.src = this.configService.config.skinSettings.tables.portrait[index].tableUrl;
      }
    } else {
      if (this.theme) {
        this.tableImage.src = `assets/theme/${this.theme}/tables/table.png`;
      } else {
        this.tableImage.src = this.configService.config.skinSettings.tables.landscape[index].tableUrl;
      }
    }

    this.tableImage.onload = () => {
      this.drawScene();
    };
  }

  public changeCardsImage(src: string) {
    this.cardAtlasImage.src = src;
    this.cardAtlasImage.onload = () => {
      this.drawScene();
    };
  }

  public changeBackCardImage(src: string) {
    this.backCardImage.src = src;
    this.backCardImage.onload = () => {
      this.drawScene();
    };
  }

  changeCarpet(index?: number) {
    if (this.handsetPortrait) {
      if (this.theme) {
        this.carpetBackground = this.sanitizer.bypassSecurityTrustStyle(`url("assets/theme/${this.theme}/carpets/background.jpg")`);
      } else {
        this.carpetBackground = this.sanitizer.bypassSecurityTrustStyle(`url("${this.configService.config.skinSettings.carpets.portrait[index].carpetUrl}")`);
      }
    } else {
      if (this.theme) {
        this.carpetBackground = this.sanitizer.bypassSecurityTrustStyle(`url("assets/theme/${this.theme}/carpets/vertical_background.jpg")`);
      } else {
        this.carpetBackground = this.sanitizer.bypassSecurityTrustStyle(`url("${this.configService.config.skinSettings.carpets.landscape[index].carpetUrl}")`);
      }
    }
  }

  applySkinSettings() {
    this.skinSettingsService.applyChanges();
  }
}
