import { Action } from '@ngrx/store';

import { LobbyFilterKeyValue, FilterChange, LobbyFilterOptions } from '../../../shared/models';

export enum LobbyActionTypes {
  ChangeFilter = '[Lobby] Change Filter',
  SetVariantFilter = '[Lobby] Set Variant Filter',
  SetStakesFilter = '[Lobby] Set Stakes Filter',
  SetTableOptionsFilter = '[Lobby] Set Table Options Filter',
  SetSpeedFilter = '[Lobby] Set Speed Filter',
  SetLimitFilter = '[Lobby] Set Limit Filter',
  SetGameTypeFilter = '[Lobby] Set Game Type Filter',
  SetTablePrivateFilter = '[Lobby] Set Table Private Filter',
}

export class ChangeFilter implements Action {
  readonly type = LobbyActionTypes.ChangeFilter;

  constructor(public payload: LobbyFilterOptions) { }
}

export class SetVariantFilter implements Action {
  readonly type = LobbyActionTypes.SetVariantFilter;

  constructor(public payload: Array<number>) { }
}

export class SetStakesFilter implements Action {
  readonly type = LobbyActionTypes.SetStakesFilter;

  constructor(public payload: number) { }
}

export class SetTableOptionsFilter implements Action {
  readonly type = LobbyActionTypes.SetTableOptionsFilter;

  constructor(public payload: number) { }
}

export class SetTablePrivateFilter implements Action {
  readonly type = LobbyActionTypes.SetTablePrivateFilter;

  constructor(public payload: boolean) { }
}

export class SetSpeedFilter implements Action {
  readonly type = LobbyActionTypes.SetSpeedFilter;

  constructor(public payload: number) { }
}

export class SetLimitFilter implements Action {
  readonly type = LobbyActionTypes.SetLimitFilter;

  constructor(public payload: number) { }
}

export class SetGameTypeFilter implements Action {
  readonly type = LobbyActionTypes.SetGameTypeFilter;

  constructor(public payload: number) { }
}

export class SetCurrencyFilter implements Action {
  readonly type = LobbyActionTypes.SetGameTypeFilter;

  constructor(public payload: number) { }
}

export type LobbyActions =
  | ChangeFilter
  | SetCurrencyFilter
  | SetVariantFilter
  | SetStakesFilter
  | SetTableOptionsFilter
  | SetTablePrivateFilter
  | SetSpeedFilter
  | SetLimitFilter
  | SetGameTypeFilter;
