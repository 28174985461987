import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

export let isDebugMode = !environment.production;
const noop = (): any => undefined;

export abstract class Logger {

  log: any;
  info: any;
  warn: any;
  error: any;
}

@Injectable()
export class LoggerService implements Logger {

  get log() {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get info() {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

}
