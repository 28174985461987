/// <summary>
/// List of supported gateway for deposit and/or withdrawal
/// </summary>
export enum Gateway {
    Custom = 'Custom',
    PayUMoney = 'PayUMoney',
    Bank = 'Bank',
    Webmoney = 'Webmoney',
    Bitcoin = 'Bitcoin',
    Mehrpal = 'Mehrpal',
    GoCashFree = 'GoCashFree',
    SpectroCoin = 'SpectroCoin',
    Skrill = 'Skrill',
    PayoStar = 'PayoStar',
    Manual = 'Manual',
    Coinbase = 'Coinbase',
    Ethereum = 'Ethereum',
    Discord = 'Discord',
    xGalaxy = 'xGalaxy',
    RazorPay = 'RazorPay',
    PerfectMoney = 'PerfectMoney',
    PerfectMoneyVoucher = 'PerfectMoneyVoucher',
    Zipett = 'Zipett',
    DirectPay = 'DirectPay',
    DirectBankPayment = 'DirectBankPayment',
    GoPayPro = 'GoPayPro',
    paykassma = 'paykassma',
    card2card = 'card2card',
    Cryptocurrency = 'Cryptocurrency',
    CreditCard = 'CreditCard',
    ncx = 'ncx',
    tcjCoin = 'tcjCoin',
    IranianDirectPay2 = 'IranianDirectPay2',
    NeuronCash = 'NeuronCash',
    SecurePayment3d = 'SecurePayment3d',
    AlternativePaymentOptions = 'AlternativePaymentOptions',
    coinSwitch = 'coinSwitch',
    PayPal = 'PayPal',
    Astropay = 'Astropay',
    Paytm = 'Paytm',
    bitCaps = 'bitCaps',
    deposit_altcoin = 'deposit_altcoin',
    Crypto = 'Crypto',
    Papara = 'Papara',
    nowPayments = 'nowPayments'
}
