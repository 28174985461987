import { MTTTableAssigned } from './mtt-table-assigned';
import { TournamentInformation, TournamentPlayers, TournamentPrize, TournamentPrize2 } from './tournament-information';
import { TournamentSummary } from './tournament-summary';

export class Tournament {
    tourInfo: TournamentInformation;
    tourSum: TournamentSummary;
    activeTables: TournamentActiveTable[];
    isRegistered: boolean;
    myActiveTable: number;
    myPosition: number;
    blindLevels: TournamentBlindLevel[]
    _blindLevels?: TournamentBlindLevel[] // privremeno optional kad se obrise blindLevels dodati da bude obavezno

    spellZone?: { activePlayers: number, minActivePlayersRequired: number }


    constructor() {
        this.tourInfo = null
        this.tourSum = null
        this.activeTables = []
        this.isRegistered = false
        this.myActiveTable = null
        this.myPosition = null
        this.blindLevels = []
    }
}

export interface TournamentActiveTable {
    idTable: number;
    minChips: number;
    maxChips: number;
    numberOfPlayers: number;
    playersList: TournamentPlayers[];

    _isMyTable: boolean;
}

export interface TournamentBlindLevel {
    Period: number;
    Blind: number;
    Ante: number;
    PeriodStartMinutes: number;
}


export interface BlindInformation {
    IdTournament: number;
    Blinds: TournamentBlindLevel[]
}
