import { TournamentInformation } from '../../shared/models/tournament-information';
import { Tournament, TournamentSummary } from '../../shared/models';
import { TournamentStatus2 } from '../../shared/enums/poker-types';

// REMOVE TOURNAMENT HELPER ❌
export class TournamentHelper {

  static populateScheduleData(sum: TournamentSummary) {

    const countdownToStart = sum.StartIn * 1000; // in milliseconds
    const lateRegistrationPeriod = sum.PeriodDuration * sum.LateRegistrationPeriod; // in minutes
    const countdownForLateRegistration = (countdownToStart + sum.LateRegistrationDuration * 60 * 1000); // in milliseconds

    sum.LateRegistrationTimeText = lateRegistrationPeriod.toString() + ' minutes';
    sum.TimeBeforeRegistrationStr = sum.TimeBeforeRegistration > 1 ?
      sum.TimeBeforeRegistration.toString() + ' hours prior' : sum.TimeBeforeRegistration.toString() + ' hour prior';

    switch (sum.Status2) {
      case TournamentStatus2.Announced: {
        sum.InformationText = 'Registration begins in:';
        sum.ShowTournamentStartTime = false;
        sum.CountdownText = this.formatPeriod(sum.RegistrationsStartIn * 1000);
        break;
      }
      case TournamentStatus2.RegistrationOpen: {
        sum.ShowTournamentStartTime = true;
        if (countdownToStart < 0) {
          sum.InformationText = 'Tournament starting soon';
          sum.CountdownText = '';
        } else {
          sum.InformationText = 'Tournament begins in:';
          sum.CountdownText = this.formatPeriod(countdownToStart);
        }
        break;
      }
      case TournamentStatus2.RunningWithLateRegistration: {
        if (lateRegistrationPeriod > 0) {
          // sum.CountdownText = 'Late Registration ' + this.formatPeriod(countdownForLateRegistration);
          sum.CountdownText = this.formatPeriod(countdownForLateRegistration);

        } else {
          sum.LateRegistrationTimeText = 'Unavailable';
          sum.CountdownText = '';
        }
        break;
      }
      case TournamentStatus2.RunningRegistrationClosed: {
        sum.InformationText = 'Tournament Status:';
        sum.CountdownText = 'Running';
        break;
      }
      case TournamentStatus2.Completed: {
        sum.InformationText = 'Tournament Status:';
        sum.CountdownText = 'Completed';
        break;
      }
      case TournamentStatus2.Canceled: {
        sum.InformationText = 'Tournament Status:';
        sum.CountdownText = 'Canceled';
        break;
      }
      default:
        sum.InformationText = 'Tournament Status:';
        sum.CountdownText = 'Unknown';
    }
  }

  static formatPeriod(timePeriod: number): string {
    if (timePeriod < 0) { timePeriod = 0; }

    let strToRet = '';
    const secondsTotal = (timePeriod - (timePeriod % 1000)) / 1000;
    const seconds = secondsTotal % 60;
    const minutesTotal = (secondsTotal - (secondsTotal % 60)) / 60;
    const minutes = minutesTotal % 60;
    const hoursTotal = (minutesTotal - minutes) / 60;
    const hours = hoursTotal % 24;
    const days = (hoursTotal - hours) / 24;

    const secondsStr = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    const minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    const hoursSrt = hours < 10 ? '0' + hours.toString() : hours.toString();
    let daysStr = '';
    if (days > 0) {
      if (days > 1) {
        daysStr = days.toString() + ' days, ';
      } else {
        daysStr = days.toString() + ' day, ';
      }
    }
    strToRet = daysStr + hoursSrt + ':' + minutesStr + ':' + secondsStr;

    return strToRet;
  }

  static checkIsTournamentActive(tournament: Tournament) {
    return tournament.tourSum.Status2 > TournamentStatus2.RegistrationOpen
      && tournament.tourSum.Status2 < TournamentStatus2.Completed;
  }
}
