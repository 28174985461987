import { Component, OnInit } from '@angular/core';
import { DataManagerService } from '../../../core/services/data-manager.service';

@Component({
  selector: 'app-verification-mobile',
  templateUrl: './verification-mobile.component.html',
  styleUrls: ['./verification-mobile.component.scss']
})
export class VerificationMobileComponent implements OnInit {

  code: string;

  constructor(
    private dataManager: DataManagerService,
  ) { }

  ngOnInit(): void {
  }

  getSmsCode() {
    this.dataManager.getSmsForVerfiction();
  }


  sendPhoneVerificationCode(){
    if (this.code !== undefined) {
      this.dataManager.sendPhoneVerificationData(this.code);
    }
  }
}
