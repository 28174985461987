import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-emoticon-info',
  templateUrl: './dialog-emoticon-info.component.html',
  styleUrls: ['./dialog-emoticon-info.component.scss']
})
export class DialogEmoticonInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogEmoticonInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { 

  }
  
  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }


}
