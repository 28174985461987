import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { ConfigService } from '../../../../core/services/config.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {

  environment = environment;
  password: string;
  config;

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

  }

  ngOnInit() {
  }


  cancel() {
    this.dialogRef.close();
  }


  onApplyClick(password: String) {
    if (!password) {
      return
    }
    this.dialogRef.close(password);
  }

}
