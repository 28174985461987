import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { FilterChange, LobbyFilterOptions } from '../../../shared/models';
import { FilterType } from '../../../shared/enums/filter-type';
import { LobbyFilters } from '../../../shared/enums/poker-types';
import { Store } from '@ngrx/store';
import { AppActions } from '../../../app.actions';
import { Subscription } from 'rxjs';
import { getFiltersState } from '../../lobby/lobby-store/lobby.reducer';
import { Currency, getCurrencyName } from '../../../shared/enums';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { getSelectedCurrencyState } from '../../../auth/auth-store/auth.reducer';
import { ManagerService } from '../../../core/services/manager.service';
import { pluck } from 'rxjs/operators';
import { User } from '../../../shared/models/user';

@Component({
  selector: 'app-sidenav-right',
  templateUrl: './sidenav-right.component.html',
  styleUrls: ['./sidenav-right.component.scss']
})
export class SidenavRightComponent implements OnInit, OnDestroy {

  @Output() public close = new EventEmitter();
  @Output() public showEmptyTableEvent = new EventEmitter();
  @Input() showEmptyTable: boolean;
  @Output() public useSmartSortEvent = new EventEmitter();
  @Input() useSmartSort: boolean;

  public environment = environment;
  public FilterType = FilterType;
  public lobbyFilterOptions: LobbyFilterOptions;
  public isFilterOn = false;
  private subscription: Subscription = new Subscription();

  public filters: Array<any> = [
    {
      name: 'menu.filter.option.limit.title',
      type: FilterType.Limit,
      options: [
        { realValue: LobbyFilters.LimitAny, displayValue: 'menu.filter.option.limit.all' },
        { realValue: LobbyFilters.LimitNo, displayValue: 'menu.filter.option.limit.no' },
        { realValue: LobbyFilters.LimitPot, displayValue: 'menu.filter.option.limit.pot' },
        { realValue: LobbyFilters.LimitFixed, displayValue: 'menu.filter.option.limit.fixed' },
      ]
    },
    {
      name: 'menu.filter.option.stakes.title',
      type: FilterType.Stakes,
      options: [
        { realValue: LobbyFilters.StakeAny, displayValue: 'menu.filter.option.stakes.all' },
        { realValue: LobbyFilters.StakeMicro, displayValue: 'menu.filter.option.stakes.micro' },
        { realValue: LobbyFilters.StakeLow, displayValue: 'menu.filter.option.stakes.low' },
        { realValue: LobbyFilters.StakeMed, displayValue: 'menu.filter.option.stakes.medium' },
        { realValue: LobbyFilters.StakeHigh, displayValue: 'menu.filter.option.stakes.high' },
      ]
    },
    {
      name: 'menu.filter.option.speed.title',
      type: FilterType.Speed,
      options: [
        { realValue: LobbyFilters.SpeedAny, displayValue: 'menu.filter.option.speed.any' },
        { realValue: LobbyFilters.SpeedFast, displayValue: 'menu.filter.option.speed.fast' },
        { realValue: LobbyFilters.SpeedNormal, displayValue: 'menu.filter.option.speed.normal' },
      ]
    },
  ];

  user:User;

  constructor(

    private store: Store<any>,


    public managerService: ManagerService) {
    // if (environment.money.availableCurrency.length > 2) {
    //   Array.from(environment.money.availableCurrency).forEach((item) => {
    //     if (item.currency === Currency.Tournament) { return; }
    //     this.filters[0].options.push({
    //       realValue: item.currency,
    //       displayValue: item.Name
    //     });
    //   });
    // } else {
    //   this.filters.splice(0, 1);
    // }

    this.subscription.add(
      this.managerService.lobbyFilter$
        .pipe(
          pluck('filters')
        )
        .subscribe(lobbyFilterOptions => {

          this.lobbyFilterOptions = lobbyFilterOptions;


        })
    )



    // this.subscription.add(this.store.select(getFiltersState).subscribe(lobbyFilterOptions => {
    //   this.lobbyFilterOptions = lobbyFilterOptions;
    // }));


    this.subscription.add(
      this.managerService.user$
        .subscribe({
          next: (user) => {
            this.user = JSON.parse(JSON.stringify(user));

          }
        })
    )

    // this.subscription.add(
    //   this.store.select(getSelectedCurrencyState).subscribe(selectedCurrency => {
    //     this.lobbyFilterOptions = { ...this.lobbyFilterOptions, currency: selectedCurrency.Id };
    //   })
    // );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  filterChange(filterChange: FilterChange) {
    // this.store.dispatch(new AppActions.LobbyActions.ChangeFilter(this.lobbyFilterOptions));

    switch (filterChange.filtertype) {
      case FilterType.Currency:
        // this.store.dispatch(new AppActions.AuthAction.CurrencyChange(filterChange.value));
        break;
      case FilterType.Variant:
        this.managerService.setLobbyFilterVariant([filterChange.value])

        // this.store.dispatch(new AppActions.LobbyActions.SetVariantFilter([filterChange.value]));
        break;
      case FilterType.Limit:
        this.managerService.setLobbyFilterLimit(filterChange.value)


        // this.store.dispatch(new AppActions.LobbyActions.SetLimitFilter(filterChange.value));
        break;
      case FilterType.Stakes:
        this.managerService.setLobbyFilterStake(filterChange.value)

        //   this.managerService.lobbyStakesFilter$.next(filterChange.value)

        // this.store.dispatch(new AppActions.LobbyActions.SetStakesFilter(filterChange.value));
        break;
      case FilterType.Speed:
        this.managerService.setLobbyFilterSpeed(filterChange.value)

        // this.managerService.lobbySpeedFilter$.next(filterChange.value)

        //   this.store.dispatch(new AppActions.LobbyActions.SetSpeedFilter(filterChange.value));
        break;

      default:
        break;
    }
  }

  showEmptyTableChange(event: MatSlideToggleChange) {
    this.showEmptyTableEvent.emit(event.checked);
  }

  useSmartSortChange(event: MatSlideToggleChange) {
    this.useSmartSortEvent.emit(event.checked);
  }

  showPrivateTablesChange(event: MatSlideToggleChange) {
    this.managerService.setLobbyFilterTableOptions(event.checked ? 1 : 0)


    // this.managerService.lobbyTableOptionsFilter$.next(event.checked ? 1 : 0)

    // this.store.dispatch(new AppActions.LobbyActions.SetTableOptionsFilter(event.checked ? 1 : 0));
  }

}
