
import { CurrencyDeviderPipe } from '../utils/currency-devider.pipe';
import { CurrencyCryptoConverterPipe } from '../utils/currency-crypto-converter.pipe';
import { Currency } from '../enums';
import { CurrencyPipe } from '../utils/currency.pipe';
import { CurrencyInfo } from '../models/currency-info';

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */

const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

export function getConvertedAmountText(value: number, currency: CurrencyInfo, exchangeRate?: number): string {
  let retText = CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(value, currency), currency);

  if (currency.Id === Currency.Bitcoin || currency.Id === Currency.Ethereum) {
    retText += '\n(' + CurrencyCryptoConverterPipe.prototype.transform(
      value,
      exchangeRate) + ')';
  }

  return retText;
}
