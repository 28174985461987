import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Howler } from 'howler';

import { User } from '../../../shared/models/user';
import { ManagerService, PlayerLevelStatusLevel, PlayerLevelStatus } from '../../../core/services/manager.service';
import { MainService } from '../../main.service';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { environment } from '../../../../environments/environment';
import { Currency } from '../../../shared/enums';
import { MemberProfile, Settings } from '../../../shared/models';
import { combineLatest, Observable, Subscription } from 'rxjs';
// import { Store } from '@ngrx/store';
import { getUserSettingsState } from '../../../auth/auth-store/auth.reducer';
import { AppWebSocketService } from '../../../core/services/app-web-socket.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateNewPasswordDialog } from '../../../core/dialogs/create-new-password-dialog/create-new-password-dialog.component';
import { ENavItem } from '../../nav/nav-item-type';
import { map, shareReplay, take } from 'rxjs/operators';
import { DialogPlayerStatusLevelComponent } from '../../nav/dialog-player-status-level/dialog-player-status-level.component';
import { CurrencyInfo } from '../../../shared/models/currency-info';
import { EditProfileComponent } from '../../dialogs/edit-profile/edit-profile.component';
import { MemberProfileForm } from '../../../shared/models/member-profile-form-element';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { UserAppConfig } from '../../game/models/user-app-config';
import { ConfigService } from '../../../core/services/config.service';
import { MemberPreferencesRunItTwice } from '../../../shared/models/member-preferences';

@Component({
  selector: 'app-quick-info',
  templateUrl: './quick-info.component.html',
  styleUrls: ['./quick-info.component.scss']
})
export class QuickInfoComponent implements OnInit {

  // @Input() user: User;
  @Output() private openReplay = new EventEmitter();
  @Output() private openSkinSettings = new EventEmitter();

  public environment = environment;
  public Currency = Currency;

  public volume = true;
  subscription: Subscription = new Subscription();
  settings: Settings;

  languages: any = {}

  user: User;


  enumPlayerLevelStatusLevel = PlayerLevelStatusLevel;
  playerLevelStatus$: Observable<PlayerLevelStatus>;

  skinsSettings$: Observable<any>

  currencyInfo: CurrencyInfo[];
  emotikenCurrency: CurrencyInfo;

  userAppConfig$: Observable<UserAppConfig>;
  config;

  r2t: MemberPreferencesRunItTwice;
  memberPreferencesRunItTwice = MemberPreferencesRunItTwice

  r2tOpen = false;
  showEditProfile = false;

  constructor(
    public managerService: ManagerService,
    public mainService: MainService,
    public dataManagerService: DataManagerService,
    public translateService: TranslateService,
    // public store: Store<any>,

    private dialog: MatDialog,
    private _appWebSocketService: AppWebSocketService,

    public assetsLoader: AssetsLoaderService,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;
    this.languages = this.config.settings.languages

    this.userAppConfig$ = this.managerService.userAppConfig$


    this.skinsSettings$ = this.managerService.skinsSettings$.pipe(shareReplay(1))

    this.skinsSettings$.subscribe(skinsettings => console.log('skinsettings', skinsettings))

    this.playerLevelStatus$ = combineLatest([
      this.managerService.playerLevelStatus$,
      this.managerService.user$,
      this.managerService.settings$
    ])
      .pipe(
        map(([playerLevelStatus, user, settings]) => {
          let data: any = playerLevelStatus.get(user.selectedCurrency.Id)
          if (data) {
            data.RakebackLevelStructures = settings?.RakebackLevelStructures[user.selectedCurrency.Code]

            data.currentLevel = data?.RakebackLevelStructures?.Levels.find(el => el.Level === data.Level)
            data.nextLevel = data?.RakebackLevelStructures?.Levels.find(el => el.Level === data.Level + 1)
          } else {
            data = {}
          }

          return data
        })
      )


    this.subscription.add(
      this.managerService.currencyInfo$
        .subscribe({
          next: (currencyInfo) => {
            this.currencyInfo = currencyInfo
            this.emotikenCurrency = this.currencyInfo.find(el => el.Id === 255)
          }
        })
    )

    this.subscription.add(
      // this.store.select(getUserSettingsState).subscribe(settings => {
      //   if (settings) {
      //     this.settings = settings;
      //   }
      // })

      this.managerService.settings$
        .subscribe({
          next: (settings) => {
            this.settings = settings;
          }
        })
    );

    this.subscription.add(
      this.managerService.user$.subscribe({
        next: (user) => {
          this.user = user
          this.r2t = user?.memberProfile?.Preferences?.RunItTwice ?? MemberPreferencesRunItTwice.OFF
          console.log('@user', user, user?.memberProfile,)
          this.showEditProfile = user?.memberProfile?.ProfileForm?.length > 0
        }
      })
    );

  }

  updateMuteSound(value: boolean) {
    Howler.volume(value ? 0 : 1);
    this.playToggleSound()
    this.managerService.updateUserAppConfig({ muteSound: value })
  }

  updateStacksInBBRing(value: boolean) {
    this.managerService.updateUserAppConfig({ stacksInBBRing: value })
  }

  updateStacksInBBTourney(value: boolean) {
    this.managerService.updateUserAppConfig({ stacksInBBTourney: value })
  }

  ngOnInit() {
  }

  openDialogPlayerStatusLevel(playerLevelStatus: PlayerLevelStatus) {
    this.dialog.open(DialogPlayerStatusLevelComponent, { data: { playerLevelStatus, currency: this.user.selectedCurrency }, width: '360px' })
  }

  changeLanguage(language: string) {
    this.translateService.use(language);

    this.playClickSound()

  }

  bonusClick() {
    this.mainService.showBonusUnlock()

    this.playBonusUnlockSound()
    // this.managerService.unlockGift(this.dataManagerService.getGiftIdForUnlock(this.user, this.settings));
  }

  logoutClick() {
    this.mainService.logout();

    this.playClickSound()

  }



  replayerClick() {
    this.dataManagerService.getHandReplayList();
    this.openReplay.emit();

    this.playClickSound()

  }

  runTwiceClick(value: MemberPreferencesRunItTwice) {
    console.log("MemberPreferencesRunItTwice", value)
    this.managerService.runItTwice(value);
    this.playToggleSound()
  }

  muckCardClick(value: boolean) {
    this.user.memberProfile.Preferences.AutoMuck = value;
    this.managerService.muckCard(value);

    this.playToggleSound()
  }

  jumpToTableClick(value: boolean) {
    this.user.memberProfile.Preferences.JumpToTable = value;
    this.managerService.jumpToTable(value);

    this.playToggleSound()
  }



  editProfile(data: MemberProfile) {

    this.dialog.open(EditProfileComponent, {
      width: '300px',
      data
    })
    // .afterClosed()
    // .pipe(
    // )
    // .subscribe(result => {
    //   if (result) {
    //     // this.dataManagerService.sendChangePassword(result);
    //   }
    // });

    this.playClickSound()

  }


  resetPassword() {
    // this.mainService.resetPassword();


    this.dialog.open(CreateNewPasswordDialog, {
      width: '300px',
      disableClose: true
    })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.dataManagerService.sendChangePassword(result);
        }
      });

    this.playClickSound()

  }

  skinSettingsClick() {
    // this.mainService.showSkinSettings();
    //  this.openSkinSettings.emit();

    this.managerService.setActiveTab(ENavItem.SkinSettings)
    this.mainService.toggleSidenav(false, false);

    this.playClickSound()

  }

  onShop() {
    this.managerService.setActiveTab(ENavItem.Shop)
    this.mainService.toggleSidenav(false, false);

    this.playClickSound()
  }

  phoneVerification() {
    this.mainService.showPhoneVerification();
  }

  convertChips() {
    this.mainService.showConvertChips();
  }

  casierDeposit() {
    this.mainService.showCasierDeposit();
  }





  showTickets() {
    this.mainService.showTickets();
  }

  showSelfExclude() {
    this.mainService.showSelfExclude();
  }

  openCashier() {
    this.managerService.setActiveTab(ENavItem.Cashier)

    this.mainService.toggleSidenav(false, false);

    this.playClickSound()

  }


  playToggleSound() {
    const themes = this.config.themes
    if (themes && themes.length > 0) {
      const theme = themes.find(el => el.name === localStorage.getItem('theme'))
      if (theme && theme.sound.toggle) {
        this.assetsLoader.sound[theme.name + '-toggle'].play()
      }
    }
  }

  playClickSound() {
    const themes = this.config.themes
    if (themes && themes.length > 0) {
      const theme = themes.find(el => el.name === localStorage.getItem('theme'))
      if (theme && theme.sound.click) {
        this.assetsLoader.sound[theme.name + '-click'].play()
      }
    }
  }

  playBonusUnlockSound() {
    const themes = this.config.themes
    if (themes && themes.length > 0) {
      const theme = themes.find(el => el.name === localStorage.getItem('theme'))
      if (theme && theme.sound.bonusUnlock) {
        this.assetsLoader.sound[theme.name + '-bonus-unlock'].play()
      }
    }
  }
}
