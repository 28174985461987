import { Injectable } from '@angular/core';
import { fromEvent, merge, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class LostConnectionService {

  networkStatus$ = new ReplaySubject<boolean>(1);

  constructor() {
    this.checkNetworkStatus()
  }


  checkNetworkStatus() {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {

        this.networkStatus$.next(status)
      });
  }
}
