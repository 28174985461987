import { LostConnectionService } from './../../core/lost-connection/lost-connection.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, exhaustMap, catchError, delay, filter, take, withLatestFrom, switchMap, mergeMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import {
  Connect,
  Login,
  LoginSuccess,
  LoginFailure,
  AuthActionTypes,
  MainRedirect,
  Register,
  RegisterSuccess,
  CheckPromoCode,
  RequestSecurityCode,
  RequestSecurityCodeSuccess,
  SecurityCodeVerified,
  RequestSecurityCodeFailure,
  ConnectSuccess,
} from './auth.actons';
import { Authenticate, User } from '../../shared/models/user';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { environment } from '../../../environments/environment';
import { MemberProfile } from '../../shared/models';
import { AppActions } from '../../app.actions';
import { Store } from '@ngrx/store';
import { getUserState, getLoggedInState } from './auth.reducer';
import { ConnectionStatus } from '../../shared/enums';
import { DataManagerService } from '../../core/services/data-manager.service';
import { AppWebSocketService } from '../../core/services/app-web-socket.service';
import { SpinnerService } from '../../core/spinner/spinner.service';

@Injectable()
export class AuthEffects {

  @Effect({ dispatch: false })
  Connect$ = this.actions$.pipe(
    ofType(AuthActionTypes.Connect),
    map(() => {
      // this.lostConnectionService.hide();
      if (this.socketService.connectionStatus === ConnectionStatus.close) {
      //  this.socketService.initializeWebSocket(environment.wsUri, environment.settings.reconnectAttempts);
      }
    })
  );

  @Effect({ dispatch: false })
  ConnectSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.ConnectSuccess),
    withLatestFrom(
      this.store$.select(getUserState),
      (action, user: User) => {
        if (user.authenticate.username && user.authenticate.password || user.authenticate.token) {
        //##  this.spinnerService.showFullscreen();
        }
        return user.authenticate;
      }
    ),
    tap(async (authenticate: Authenticate) => {
      await this.dataManager.sendClientInfo();
      if (authenticate.username && authenticate.password || authenticate.token) {
        this.store$.dispatch(new Login(authenticate));
      }
    })
  );

  @Effect({ dispatch: false })
  ConnectFailure$ = this.actions$.pipe(
    ofType(AuthActionTypes.ConnectFailure),
    withLatestFrom(
      this.store$.select(getLoggedInState),
      (action, loggedIn: boolean) => {
        return loggedIn;
      }
    ),
    tap((loggedIn: boolean) => {
      if (loggedIn) {
        this.store$.dispatch(new AppActions.GameAction.Logout());
        this.store$.dispatch(new AppActions.TournamentsActions.Logout());
        this.store$.dispatch(new AppActions.NavAction.Logout());
       //  this.lostConnectionService.show();
        this.socketService.disconnect();
        setTimeout(() => {
          this.store$.dispatch(new AppActions.AuthAction.Connect());
        }, 2000);
        // this.authService.showInternetConnectionDialog();
      } else {
    //    this.lostConnectionService.hide();
      }
      this.authService.stopPinging();
    }),
  );

  @Effect({ dispatch: false })
  Login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    map((action: Login) => action.payload),
    map((auth: Authenticate) => {
      if (this.socketService.connectionStatus === ConnectionStatus.open) {
        this.dataManager.login(auth);
   //     this.spinnerService.showFullscreen();
      }
    })
  );

  @Effect()
  LoginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    map(() => {
      setTimeout(() => {
        this.authService.startPinging();
      }, 5000);
      window.localStorage.removeItem('top-reset-password');
      window.localStorage.removeItem('top-email');
    //  this.spinnerService.hide();
      return new MainRedirect();
    })
  );

  @Effect({ dispatch: false })
  LoginFailure$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginFailure),
    tap(() => {
    //  this.spinnerService.hide();
    })
  );

  @Effect({ dispatch: false })
  LoginRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout),
    tap(authed => {
      this.router.navigate(['/login']);
    })
  );

  @Effect({ dispatch: false })
  MainRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.MainRedirect),
    tap(() => {
      this.router.navigate(['/main']);
    })
  );

  @Effect({ dispatch: false })
  Register$ = this.actions$.pipe(
    ofType(AuthActionTypes.Register),
    tap((action: Register) => {
      // if (environment.auth.register.referalCodeMandatory) {
      //   this.dataManager.sendRegistrationData(action.payload);
      // } else {
      //   this.dataManager.sendRegistrationData({ ...action.payload, ReferalCode: environment.skinName });
      // }
    })
  );

  @Effect({ dispatch: false })
  RegisterSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.RegisterSuccess),
    withLatestFrom(
      this.store$.select(getUserState),
      (action, user: User) => {
        return user.memberProfile;
      }
    ),
    tap((memberProfile: any) => {
      return this.store$.dispatch(new Login({ username: memberProfile.username, password: memberProfile.password }));
    })
  );

  @Effect({ dispatch: false })
  RegisterFailure$ = this.actions$.pipe(
    ofType(AuthActionTypes.RegisterFailure),
    tap((payload) => {
    })
  );

  @Effect({ dispatch: false })
  RequestSecurityCode$ = this.actions$.pipe(
    ofType(AuthActionTypes.RequestSecurityCode),
    map((action: RequestSecurityCode) => {
     // this.dataManager.requestSecurityCode(action.payload.username);
      window.localStorage.setItem('top-username', action.payload.username);
    }),
  );

  @Effect({ dispatch: false })
  RequestSecurityCodeSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.RequestSecurityCodeSuccess),
    map((action: RequestSecurityCodeSuccess) => {
      window.localStorage.setItem('top-reset-password', 'true');
    }),
  );

  @Effect({ dispatch: false })
  RequestSecurityCodeFailure$ = this.actions$.pipe(
    ofType(AuthActionTypes.RequestSecurityCodeFailure),
    map((action: RequestSecurityCodeFailure) => {
    }),
  );

  @Effect({ dispatch: false })
  SecurityCodeVerified$ = this.actions$.pipe(
    ofType(AuthActionTypes.SecurityCodeVerified),
    map((action: SecurityCodeVerified) => {
      window.localStorage.removeItem('top-reset-password');
      window.localStorage.removeItem('top-username');
      //this.authService.openResetPasswordDialog();
    }),
  );

  @Effect({ dispatch: false })
  ForgotPassCancel$ = this.actions$.pipe(
    ofType(AuthActionTypes.ForgotPasswordCancel),
    tap((payload) => {
      window.localStorage.removeItem('top-reset-password');
      window.localStorage.removeItem('top-email');
      this.router.navigate(['/login']);
    })
  );

  @Effect({ dispatch: false })
  CheckPromoCode$ = this.actions$.pipe(
    ofType(AuthActionTypes.CheckPromoCode),
    map((action: CheckPromoCode) => action.payload),
    map((memberProfile: MemberProfile) => {
      this.dataManager.checkPromoCode(memberProfile.ReferalCode);
    })
  );

  @Effect({ dispatch: false })
  PromoCodeOk$ = this.actions$.pipe(
    ofType(AuthActionTypes.PromoCodeOk),
    withLatestFrom(
      this.store$.select(getUserState),
      (action, user: User) => {
        return user.memberProfile;
      }
    ),
    map((memberProfile: MemberProfile) => {
      return this.store$.dispatch(new Register(memberProfile));
    })
  );

  @Effect({ dispatch: false })
  PromoCodeWrong$ = this.actions$.pipe(
    ofType(AuthActionTypes.PromoCodeWrong),
    tap((payload) => {
    })
  );

  @Effect({ dispatch: false })
  Logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    mergeMap(() => {
      this.dataManager.logout();
      window.localStorage.removeItem('auth-login');
      this.authService.stopPinging();
      this.store$.dispatch(new AppActions.GameAction.Logout());
      this.store$.dispatch(new AppActions.TournamentsActions.Logout());
      this.store$.dispatch(new AppActions.NavAction.Logout());
      // this.lostConnectionService.hide();

      return [
        new AppActions.GameAction.Logout(),
        new AppActions.TournamentsActions.Logout(),
        new AppActions.NavAction.Logout()
      ];
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private authService: AuthService,
    private dataManager: DataManagerService,
    private router: Router,
    private notification: NotificationsService,
    private socketService: AppWebSocketService,
    private lostConnectionService: LostConnectionService,
    private spinnerService: SpinnerService,
  ) { }
}
