import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Tournament, TournamentActiveTable } from '../../../../shared/models/tournament';
import { ManagerService } from '../../../../core/services/manager.service';
import { ReplaySubject } from 'rxjs';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'view-tournament-active-tables',
  templateUrl: './view-tournament-active-tables.component.html',
  styleUrls: ['./view-tournament-active-tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTournamentActiveTablesComponent {

  private _selectedTable: TournamentActiveTable;
  selectedTable$ = new ReplaySubject<TournamentActiveTable>(1)

  get selectedTable(): TournamentActiveTable { return this._selectedTable; }
  set selectedTable(value: TournamentActiveTable) {
    this._selectedTable = value;
    this.selectedTable$.next(value)
  }

  @Input() user: User;
  @Input()
  get tournament(): Tournament { return this._tournament; }
  set tournament(value: Tournament) {
    this._tournament = value;
    if (this.tournament?.activeTables?.length > 0 && (!this.selectedTable || !value?.activeTables?.find(el => el?.idTable === this.selectedTable?.idTable))) {
      this.selectedTable = this.tournament.activeTables[0]
    }
  }

  @Input() showPlayersList = true;

  private _tournament: Tournament;

  constructor(public managerService: ManagerService) { }


  onSelectTable(table: TournamentActiveTable) {
    this.selectedTable = table
  }

  openTourneyTable(data: TournamentActiveTable) {
    this.managerService.gameStartFromTournamentStart(data.idTable, this.tournament.tourSum.Id, this.tournament.tourSum)
  }
}
