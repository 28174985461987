import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, of, Subject, timer } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { DataManagerService } from '../../../../core/services/data-manager.service';
import { ManagerService } from '../../../../core/services/manager.service';

export class CountdownTimer {
  days: string
  hours: string
  minutes: string
  seconds: string
  finished: boolean

  constructor(
    days?: string,
    hours?: string,
    minutes?: string,
    seconds?: string,
    finished?: boolean,
  ) {
    this.days = days ? days : "0"
    this.hours = hours ? hours : "0"
    this.minutes = minutes ? minutes : "0"
    this.seconds = seconds ? seconds : "0"
    this.finished = finished !== undefined ? finished : true
  }
}
@Component({
  selector: 'app-call-time',
  templateUrl: './call-time.component.html',
  styleUrls: ['./call-time.component.scss']
})
export class CallTimeComponent implements OnInit {

  callTimeCountdownTimer$: Observable<any>
  loader: boolean = false

  constructor(
    public dialogRef: MatDialogRef<CallTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { playerId: number, tableId: number },

    private managerService: ManagerService,
    private dataManagerService: DataManagerService,
  ) { }

  ngOnInit(): void {
    this.callTimeCountdownTimer$ = this.managerService.callTimeStatus$
      .pipe(
        switchMap((callTimeStatus) => {
          const data = callTimeStatus.find(el => el.IdTable === this.data.tableId && el.IdPlayer === this.data.playerId)
          if (data) {
            if (data.IsActivated === false) {
              return of(null)
            }
            const expirationDate = new Date(new Date(data.ActivatedAt).getTime() + data.CallTimeDuration * 1000) // +1min

            return timer(0, 500)
              .pipe(
                switchMap(() => of(this.dhmsCountdownTimer(expirationDate)))
              )
          } else {
            return of(null)
          }
        }),
        shareReplay(1)
      )
  }

  cancel() {
    this.dialogRef.close();
  }

  accept() {
    this.loader = true
    this.dataManagerService.callTimeAccepted(this.data.tableId)
  }


  dhmsCountdownTimer(expiresAt: Date): CountdownTimer {

    let t = Math.floor((expiresAt.getTime() - new Date().getTime()) / 1000);
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400)
    t -= days * 86400;
    hours = (Math.floor(t / 3600) % 24).toString()
    t -= hours * 3600;
    minutes = (Math.floor(t / 60) % 60).toString()
    t -= minutes * 60
    seconds = (t % 60).toString()
    let data: CountdownTimer = new CountdownTimer(days, hours, minutes, seconds, false)

    if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
      data = new CountdownTimer('00', '00', '00', '00', true)
    } else if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
      data = new CountdownTimer('00', '00', '00', '00', true)
    } else {
      if (days < 10) days = '0' + days
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      if (seconds < 10) seconds = '0' + seconds
      data = new CountdownTimer(days, hours, minutes, seconds, false)
    }

    return data
  }


}
