import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataManagerService } from '../../core/services/data-manager.service';
import { ManagerService } from '../../core/services/manager.service';
import { CurrencyInfo } from '../../shared/models/currency-info';
import { User } from '../../shared/models/user';
import { DialogEmoticonInfoComponent } from './dialog-emoticon-info/dialog-emoticon-info.component';
import { DialogShopComponent } from './dialog-shop/dialog-shop.component';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy {

  env = environment

  emotikensPackages: any;
  ticketsPackages: any;
  currencyPackages: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  emotikenCurrency: CurrencyInfo;
  currencyInfo: CurrencyInfo[];
  user: User;

  tab: number;
  emoticonsInfo: any[];
  emoticonsTab;
  originalEmoticons;

  shopSettings: any = {};
  config;

  constructor(
    private dataManagerService: DataManagerService,
    private managerService: ManagerService,
    private dialog: MatDialog,
    private configService: ConfigService,
  ) { 
    this.config = this.configService.config;
  }

  ngOnInit(): void {
    this.managerService.shopSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (shopSettings) => {
          this.shopSettings = shopSettings
        }
      })


    this.managerService.emoticonsInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (emoticonsInfo) => {
          this.originalEmoticons = JSON.parse(JSON.stringify(emoticonsInfo))
          this.filterEmoticonsByTab(JSON.parse(JSON.stringify(this.originalEmoticons)))
        }
      })

    this.managerService.currencyInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (currencyInfo) => {
          this.currencyInfo = currencyInfo
          this.emotikenCurrency = this.currencyInfo.find(el => el.Id === 255)
        }
      })

    this.managerService.user$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (user) => {
          this.user = user;
          this.load()
        }
      })
  }

  filterEmoticonsByTab(emoticonsInfo) {
    if (this.emoticonsTab) {
      if (this.emoticonsTab === 'ALL') {
        this.emoticonsInfo = emoticonsInfo
      } else if (this.emoticonsTab === 'MY') {
        this.emoticonsInfo = emoticonsInfo.filter(el => el.IsLocked === 0)
      }
    }
  }

  load() {

    if (true) { // later ENV
      if (isNaN(this.tab)) { this.tab = 1 }

      this.managerService.emotikensPackages$
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          result.data = result.data.filter(el => el.Currency === this.user.selectedCurrency.Id || el.Currency === -2)
          this.emotikensPackages = result
        })
    }
    if (true) { // later ENV
      if (isNaN(this.tab)) { this.tab = 2 }

      this.managerService.ticketsPackages$
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.ticketsPackages = result

        })
    }
    if (true) { // later ENV
      if (isNaN(this.tab)) { this.tab = 3 }

      this.managerService.currencyPackages$
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.currencyPackages = result
        })
    }

    this.loadEmotikens();
    this.loadTickets()
    this.loadCurrencyPackages()
  }

  loadEmotikens() {
    this.dataManagerService.getEmotikensPackages()
  }
  loadTickets() {
    this.dataManagerService.getTickets(this.user.selectedCurrency.Id)
  }
  loadCurrencyPackages() {
    this.dataManagerService.currencyGetPackages(this.user.selectedCurrency.Id)
  }


  openEmoticons() {
    this.tab = 4;
    this.emoticonsTab = 'ALL';
    this.filterEmoticonsByTab(JSON.parse(JSON.stringify(this.originalEmoticons)))
  }

  openEmoticonTab(emoticonTab) {
    this.emoticonsTab = emoticonTab;
    this.filterEmoticonsByTab(JSON.parse(JSON.stringify(this.originalEmoticons)))

  }

  openEmoticonInfo(data: any) {
    this.dialog.open(DialogEmoticonInfoComponent, {
      width: '300px',
      disableClose: true,
      data
    })
  }

  buy(type: number, data: any, currency: any) {
    let dialogData = { type, data, currency, playerBalance: this.user.playerBalance, selectedCurrency: this.user.selectedCurrency, emotikenCurrency: this.emotikenCurrency }

    this.dialog.open(DialogShopComponent, {
      width: '300px',
      disableClose: true,
      data: dialogData
    })
      .afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(value => {
        if (value) {
          if (type === 1) {
            if (data.BuyUrl) {
              window.open(data.BuyUrl, '_blank');
            } else {
              this.dataManagerService.emotikensBuyPackage(data.Id);
            }
          } else if (type === 2) {
            this.dataManagerService.buyTicket(data.Id);
          } else if (type === 3) {
            this.dataManagerService.currencyBuyPackage(data.Id);
          } else if (type === 4) {
            this.dataManagerService.emoticonUnlock(data.Id);
          }
        }
      });
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
