import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NguCarouselModule } from '@ngu/carousel';

import { UtilsModule } from './utils/utils.module';
import { DeviderComponent } from './components/devider/devider.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { LegendIndicatorComponent } from './components/legend-indicator/legend-indicator.component';
import { CurrencySwitcherComponent } from './components/currency-switcher/currency-switcher.component';
import { MaterialModule } from './components/materials/material.module';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CardsPreviewComponent } from './components/cards-preview/cards-preview.component';
import { NouisliderModule } from 'ng2-nouislider';
import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { CurrencyChooserComponent } from './components/currency-chooser/currency-chooser.component';
import { DialogNotification } from './components/dialog-notification/dialog-notification.component';
import { TicketComponent } from '../main/ticket/ticket.component';
import { SelfExcludeComponent } from '../main/self-exclude/self-exclude.component';
import { TicketTableComponent } from '../main/ticket/ticket-table/ticket-table.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    MaterialModule,
    FlexLayoutModule,
    NguCarouselModule,
    ImageCropperModule,
    NouisliderModule,
    FormsModule
  ],
  declarations: [
    DeviderComponent,
    DialogHeaderComponent,
    LegendIndicatorComponent,
    ImageCropperComponent,
    CurrencySwitcherComponent,
    CardsPreviewComponent,
    AvatarUploadComponent,
    CurrencyChooserComponent,
    DialogNotification,

    TicketComponent,
    SelfExcludeComponent,
    TicketTableComponent,
    LoaderComponent,
  ],
  exports: [
    DeviderComponent,
    DialogHeaderComponent,
    LegendIndicatorComponent,
    ImageCropperComponent,
    CurrencySwitcherComponent,
    UtilsModule,
    MaterialModule,
    TranslateModule,
    NguCarouselModule,
    FlexLayoutModule,
    CardsPreviewComponent,
    NouisliderModule,
    AvatarUploadComponent,
    CurrencyChooserComponent,
    DialogNotification,

    TicketComponent,
    SelfExcludeComponent,
    TicketTableComponent,

    LoaderComponent,

  ],
  entryComponents: [
    ImageCropperComponent,
    DialogNotification,

    TicketComponent,
    SelfExcludeComponent
  ],
})
export class SharedModule { }
