import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConvertChipsDialogComponent } from './convert-chips-dialog/convert-chips-dialog.component';
import { UploadIdDialogComponent } from './upload-id-dialog/upload-id-dialog.component';
import { PhoneVerificationDialogComponent } from './phone-verification-dialog/phone-verification-dialog.component';
import { CashierComponent } from './cashier.component';
import { SharedModule } from '../../shared/shared.module';
import { GatewayComponent } from './gateway/gateway.component';
import { TransferHistoryComponent } from './transfer-history/transfer-history.component';
import { TransferMoneyToPlayerComponent } from './transfer-money-to-player/transfer-money-to-player.component';
import { VerificationMobileComponent } from './verification-mobile/verification-mobile.component';
import { VerificationDocumentsComponent } from './verification-documents/verification-documents.component';
import { SwiperModule } from 'swiper/angular';
import { DepositRedirectDialogComponent } from './deposit-redirect-dialog/deposit-redirect-dialog.component';
import { WithdrawalRedirectDialogComponent } from './withdrawal-redirect-dialog/withdrawal-redirect-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule
  ],
  declarations: [
    ConvertChipsDialogComponent,
    UploadIdDialogComponent,
    PhoneVerificationDialogComponent,
    CashierComponent,
    GatewayComponent,
    TransferHistoryComponent,
    TransferMoneyToPlayerComponent,
    VerificationMobileComponent,
    VerificationDocumentsComponent,
    DepositRedirectDialogComponent,
    WithdrawalRedirectDialogComponent,
  ],
  exports: [
    CashierComponent,
  ],
  providers: [
    DatePipe,
  ],
  entryComponents: [
    ConvertChipsDialogComponent,
    UploadIdDialogComponent,
    PhoneVerificationDialogComponent,
    DepositRedirectDialogComponent
  ],
})
export class CashierModule { }
