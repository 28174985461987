import * as NavActions from './nav-store.actions';
import { ENavItem } from '../nav-item-type';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export type Action = NavActions.All;

export interface State {
    active: ENavItem;
}

const initialState: State = {
    active: ENavItem.Lobby,
};

const newState = (state, newData) => {
    return Object.assign({}, state, newData);
};

export function navReducer(state: State = initialState, action: Action): State {
    switch (action.type) {
        case NavActions.CHANGE_LAYOUT:
            state.active = action.payload;
            return newState(state, {active: action.payload});

        case NavActions.LOGOUT:
            state = {
                active: ENavItem.Lobby,
            };
            return state;

        default:
            return state;
    }
}


export const getState = createFeatureSelector<State>('nav');
export const getNavLayout = createSelector(getState, state => state.active);
