import * as PIXI from 'pixi.js';
import * as CONST_HORIZONTAL from './table-cards-controller-horizontal.const';
import * as CONST_VERTICAL from './table-cards-controller-vertical.const';
import { Card } from '../card';
import { CardData } from '../../../../shared/models';
import { AnchorPosition } from '../const';
import { environment } from '../../../../../environments/environment';
import { GameComponent } from '../../game.component';

export class TableCardsController {

    container: PIXI.Container;
    communityCards: Card[] = [];
    r2tCardsT1: Card[] = [];
    r2tCardsT2: Card[] = [];

    private CONST;
    public gameComponent: GameComponent
    constructor(private textures, gameComponent: GameComponent, useVerticalMode: boolean) {
        this.container = new PIXI.Container();
        this.gameComponent = gameComponent;
        if (useVerticalMode) {
            this.CONST = CONST_VERTICAL;
        } else {
            this.CONST = CONST_HORIZONTAL;
        }


        // setTimeout(() => {
        //     this.addCommunityCards([
        //         { Suit: 3, Number: 9, Name: 's9', IsRabbitHunting: undefined },
        //         { Suit: 1, Number: 7, Name: 'd7', IsRabbitHunting: undefined },
        //         { Suit: 2, Number: 2, Name: 'c2', IsRabbitHunting: undefined }
        //     ])
        // }, 2000);
    }

    updateRotationMode(useVerticalMode: boolean) {
        if (useVerticalMode) {
            this.CONST = CONST_VERTICAL;
        } else {
            this.CONST = CONST_HORIZONTAL;
        }

        console.log("F this.communityCards", this.communityCards)
        console.log("F this.r2tCardsT1", this.r2tCardsT1)
        console.log("F this.r2tCardsT2", this.r2tCardsT2)

        this.communityCards.forEach((card, index) => {
            card.container.position.set(this.CONST.communityCardPositions[index].x, this.CONST.communityCardPositions[index].y)
        })
        this.r2tCardsT1.forEach((card, index) => {
            console.log("F r2tCardsT1 card", card, this.CONST.r2tT1Positions[index].x, this.CONST.r2tT1Positions[index].y)

            card.container.position.set(this.CONST.r2tT1Positions[index].x, this.CONST.r2tT1Positions[index].y)
        })
        this.r2tCardsT2.forEach((card, index) => {
            console.log("F r2tCardsT1 card", card, this.CONST.r2tT2Positions[index].x, this.CONST.r2tT2Positions[index].y)
            card.container.position.set(this.CONST.r2tT2Positions[index].x, this.CONST.r2tT2Positions[index].y)
        })
    }

    update(dt) {
        this.communityCards.forEach(card => {
            card.update(dt);
        });

        this.r2tCardsT1.forEach(card => {
            card.update(dt);
        });

        this.r2tCardsT2.forEach(card => {
            card.update(dt);
        });
    }

    addCommunityCards(cardsData: CardData[] = []) {

        if (cardsData.length > 5) { throw Error('Max num. of community cards is 5'); }
        cardsData.forEach((cardData, index) => {
            const card = new Card(this.textures['tableCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
            card.setPosition(this.CONST.communityCardPositions[index], AnchorPosition.topLeft);
            card.container.scale.set(this.CONST.communityCardScale.x, this.CONST.communityCardScale.y);
            this.container.addChild(card.container);
            this.communityCards.push(card);
        });

    }

    addCommunityCard(cardData: CardData) {

        const card = new Card(this.textures['tableCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
        card.setPosition(this.CONST.communityCardPositions[this.communityCards.length], AnchorPosition.topLeft);
        card.container.scale.set(this.CONST.communityCardScale.x, this.CONST.communityCardScale.y);
        this.container.addChild(card.container);
        this.communityCards.push(card);


    }

    addCommunityCardWithIndex(cardData: CardData, index: number) {

        const card = new Card(this.textures['tableCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
        card.setPosition(this.CONST.communityCardPositions[index], AnchorPosition.topLeft);
        card.container.scale.set(this.CONST.communityCardScale.x, this.CONST.communityCardScale.y);
        this.container.addChild(card.container);
        this.communityCards.push(card);
    }

    removeAllCards() {
        this.container.removeChildren();
        this.communityCards = [];
        this.r2tCardsT1 = [];
        this.r2tCardsT2 = [];
    }

    getCommunityCardsInShortNotation(): string {
        let shortNotation = '';
        this.communityCards.forEach(card => {
            shortNotation += card.cardData.Name + ' ';
        });
        return shortNotation.trim();
    }

    addR2tCardsT1(cardsData: CardData[] = []) {
        if (cardsData.length > 5) { throw Error('Max num. of r2t cards is 5'); }
        cardsData.forEach((cardData, index) => {
            const card = new Card(this.textures['tableCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
            card.setPosition(this.CONST.r2tT1Positions[index + this.communityCards.length], AnchorPosition.topLeft);
            card.container.scale.set(this.CONST.r2tCardScale.x, this.CONST.r2tCardScale.y);
            this.container.addChild(card.container);
            this.r2tCardsT1.push(card);
        });
    }

    addR2tCardsT2(cardsData: CardData[] = []) {
        if (cardsData.length > 5) { throw Error('Max num. of r2t cards is 5'); }
        cardsData.forEach((cardData, index) => {
            const card = new Card(this.textures['tableCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
            card.setPosition(this.CONST.r2tT2Positions[index + this.communityCards.length], AnchorPosition.topLeft);
            card.container.scale.set(this.CONST.r2tCardScale.x, this.CONST.r2tCardScale.y);
            this.container.addChild(card.container);
            this.r2tCardsT2.push(card);
        });
    }

    selectCards(cardsToSelect: any = []) {

        if (cardsToSelect.length === 0) { return }

        this.communityCards
            .forEach(card => {
                if (cardsToSelect.findIndex(selectCard => selectCard.Suit === card.cardData.Suit && selectCard.Number === card.cardData.Number) === -1) {
                    card.grayscaleCard();
                } else {
                    card.ungrayscaleCard();

                }
            })

        this.r2tCardsT1
            .forEach(card => {
                if (cardsToSelect.findIndex(selectCard => selectCard.Suit === card.cardData.Suit && selectCard.Number === card.cardData.Number) === -1) {
                    card.grayscaleCard();
                } else {
                    card.ungrayscaleCard();

                }
            })

        this.r2tCardsT2
            .forEach(card => {
                if (cardsToSelect.findIndex(selectCard => selectCard.Suit === card.cardData.Suit && selectCard.Number === card.cardData.Number) === -1) {
                    card.grayscaleCard();
                } else {
                    card.ungrayscaleCard();

                }
            })

        // ## OLD BLINKING CARDS
        // cardsToSelect.forEach(selectCard => {
        //     this.communityCards.forEach(card => {
        //         if (!card) { return; }
        //         if (card.cardData.Suit === selectCard.Suit && card.cardData.Number === selectCard.Number) {
        //             card.selectCard();
        //         }
        //     });

        //     this.r2tCardsT1.forEach(card => {
        //         if (!card) { return; }
        //         if (card.cardData.Suit === selectCard.Suit && card.cardData.Number === selectCard.Number) {
        //             card.selectCard();
        //         }
        //     });

        //     this.r2tCardsT2.forEach(card => {
        //         if (!card) { return; }
        //         if (card.cardData.Suit === selectCard.Suit && card.cardData.Number === selectCard.Number) {
        //             card.selectCard();
        //         }
        //     });
        // });
    }

    refreshCardsTexture() {
        this.communityCards.forEach(card => {
            card.refreshCard(this.textures['tableCardAtlas'], this.textures['backCard'].texture);
        });

        this.r2tCardsT1.forEach(card => {
            card.refreshCard(this.textures['tableCardAtlas'], this.textures['backCard'].texture);
        });

        this.r2tCardsT2.forEach(card => {
            card.refreshCard(this.textures['tableCardAtlas'], this.textures['backCard'].texture);
        });
    }
}
