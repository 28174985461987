import { FilterType } from '../enums/filter-type';

export class LobbyFilter {
    currentFiltersValue: number;
    filters: LobbyFilterOptions;

    constructor() {
        this.currentFiltersValue = 0,
            this.filters = {
                TableType: 1, 	// int [REQUIRED] ONE table type, see list of TableType bellow.
                Variants: [null], 	// int[] array of VariantType2 to be returned, if the array is NOT present, no filter will be made on variant.
                Stakes: null,	// int[] array of StakeType. Return tables with BB matching the configured stake range (micro to high).  if the array is NOT present, no filter wil be made on stake.
                Limits: null, 	// int[] array of LimitTypes. Return table matching the limit types. If the array is NOT present, no filter will be made on limit.
                Speeds: null,	// int[] array of SpeedType. Return the  game speed to be returned, if the array is NOT presetnt, no filter will be made on speed.

                NbSeats: null, // int[] array of number. Return table that have number of seats in the list. If the array is NOT present all tables are returned regardless the number of seats.

                HideFull: false,	// bool, if set to true, will not return tables considered full. if false do nothing. Default value is false.
                HideEmpty: false, // bool, if set to true will not return tables with no players. if false do nothing, Default value is false.
                ShowPrivate: false, // bool, if set to true will return tables that are password protected. if false will return table NOT passworded protected. Default value is false.
                FreerollOnly: false, // bool, if set to true, will return tournament marked as free roll only. if false no filter is made.
                ShowOnlyWithTip: false, // bool, if set to true will return only table where you can tip the dealer.
                currency: 0,
            }
    }
}

export interface LobbyFilterKeyValue {
    realValue: number;
    displayValue: string;
}


export interface FilterChange {
    filtertype: FilterType;
    value: number;
}

export interface LobbyFilterOptions {
    TableType: number,
    Variants: Array<number>,
    Stakes: number,
    Limits: number,
    Speeds: number,
    NbSeats: number,
    HideFull: boolean,
    HideEmpty: boolean,
    ShowPrivate: boolean | number,
    FreerollOnly: boolean,
    ShowOnlyWithTip: boolean;
    currency: number;
}
