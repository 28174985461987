import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyInfo } from '../models/currency-info';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'countdown' })
export class CountdownPipe implements PipeTransform {
    /**
 * Gets the millisecond difference between a future date and now
 * @private
 * @param   futureDate: string
 * @returns number  milliseconds remaining
 */
    private getMsDiff = (futureDate: Date): number => (new Date(futureDate).getTime() - Date.now());

    /**
     * Converts milliseconds to the
     *
     * @private
     * @param msRemaining
     * @returns null    when no time is remaining
     *          string  in the format `HH:mm:ss`
     */
    private msToTime(msRemaining: number): string | null {
        if (msRemaining < 0) {
            console.info('No Time Remaining:', msRemaining);
            return '00:00';
        }

        let seconds: string | number = Math.floor((msRemaining / 1000) % 60),
            minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60),
            hours: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) % 24),
            days: string | number = Math.floor(msRemaining / (1000 * 60 * 60 * 24));

        /**
         * Add the relevant `0` prefix if any of the numbers are less than 10
         * i.e. 5 -> 05
         */
        seconds = (seconds < 10) ? '0' + seconds : seconds;
        minutes = (minutes < 10) ? '0' + minutes : minutes;

        if (days > 0) {
            hours = (hours < 10) ? '0' + hours : hours;
            return `${days} days, ${hours}:${minutes}:${seconds}`;
        } else if (hours > 0) {
            hours = (hours < 10) ? '0' + hours : hours;

            return `${hours}:${minutes}:${seconds}`;
        } else {
            return `${minutes}:${seconds}`;
        }

    }

    /**
     * @param futureDate    should be in a valid Date Time format
     *                      e.g. YYYY-MM-DDTHH:mm:ss.msz
     *                      e.g. 2021-06-04T17:27:10.740z
     */
    public transform(futureDate: Date): Observable<string> {
        console.log("---> futureDate,", futureDate, this.getMsDiff(futureDate))
        /**
         * Initial check to see if time remaining is in the future
         * If not, don't bother creating an observable
         */
        if (!futureDate || this.getMsDiff(futureDate) < 0) {
            return null;
        }
        return timer(0, 1000).pipe(
            map(() => {
                return this.msToTime(this.getMsDiff(futureDate));
            })
        );
    }





    //     // value amount in seconds
    //     transform(value: number): Observable<string> {
    //         console.log("new countdown pipe", value)
    //         return timer(0, 1000).pipe(
    //             map((d) => {
    //                 value -= 1;

    //                 if (value <= 0 || isNaN(value)) {
    //                     return;
    //                 }

    //                 const secondsLeft: number = value % 60;
    //                 const minutesLeft: number = value / 60;
    //                 let minStr = '';
    //                 let secStr = '';

    //                 if (minutesLeft < 10) {
    //                     minStr = '0' + Math.floor(minutesLeft).toString();
    //                 } else {
    //                     minStr = Math.floor(minutesLeft).toString();
    //                 }

    //                 if (secondsLeft < 10) {
    //                     secStr = '0' + Math.floor(secondsLeft).toString();
    //                 } else {
    //                     secStr = Math.floor(secondsLeft).toString();
    //                 }

    //                 return minStr + ':' + secStr;
    //             })
    //         );
    //     }
}
