import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-devider',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'role': 'separator',
    '[attr.aria-orientation]': 'vertical ? "vertical" : "horizontal"',
    '[class.app-divider-vertical]': 'vertical',
    'class': 'app-divider'
  },
  template: '',
  styleUrls: ['./devider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviderComponent implements OnInit {

  /** Whether the divider is vertically aligned. */
  @Input()
  get vertical(): boolean { return this._vertical; }
  set vertical(value: boolean) { this._vertical = coerceBooleanProperty(value); }
  private _vertical = false;

  constructor() { }

  ngOnInit() {
  }

}
