import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Limit, VariantType2 } from '../../../../shared/enums/poker-types';

@Component({
  selector: 'app-fall-guys',
  templateUrl: './fall-guys.component.html',
  styleUrls: ['./fall-guys.component.scss']
})
export class FallGuysComponent implements OnInit {
  variantType2 = VariantType2
  limitType = Limit;
  constructor(public dialogRef: MatDialogRef<FallGuysComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
  }
}
