import { Component, ViewChild, } from '@angular/core';
import { Observable } from 'rxjs';
import { Tournament } from '../../../../shared/models';
import { User } from '../../../../shared/models/user';
import { ManagerService } from '../../../../core/services/manager.service';
import { TournamentStatus2 } from '../../../../shared/enums/poker-types';
import { MatDialog } from '@angular/material/dialog';
import { Ticket } from '../../../../shared/models/ticket';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { TableType } from '../../../../shared/enums/table-type';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfigService } from '../../../../core/services/config.service';
import { v3ManagerService } from '../../../../core/services/v3-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogBountySpellInfoComponent } from '../../../bounty-spell/dialog-bounty-spell-info/dialog-bounty-spell-info.component';

export enum SideMenuType {
  TourneyInfo,
  PlayersAndPrizes,
  ActiveTables,
  Structure
}

@Component({
  selector: 'view-tournament',
  templateUrl: './view-tournament.component.html',
  styleUrls: ['./view-tournament.component.scss'],
})

export class ViewTournamentComponent {

  @ViewChild('tourneyNavSwiper', { static: false }) tourneyNavSwiper: any;
  tourneyNavSwiperIndex: number = 0

  tournament$: Observable<Tournament>;
  user$: Observable<User>;
  tickets$: Observable<Ticket[]>

  enumTableType = TableType
  enumTournamentStatus2 = TournamentStatus2
  breakpointObserverHandsetPortrait$: Observable<BreakpointState>
  breakpointObserverHandsetLandscape$: Observable<BreakpointState>

  config;

  spellZone$: Observable<boolean>;

  constructor(
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver,
    private _configService: ConfigService,
    private _v3ManagerService: v3ManagerService,
    private _managerService: ManagerService,
    private translateService: TranslateService,
    private dialog: MatDialog,

  ) {
    this.config = this._configService.config;

    this.tournament$ = this._managerService.viewTournamentId$.pipe(
      switchMap(viewTournamentId => this._v3ManagerService.getTournament(viewTournamentId)),
      shareReplay({ bufferSize: 1, refCount: true }),
    )


    this.spellZone$ = this._managerService.viewTournamentId$.pipe(
      switchMap(viewTournamentId => this._managerService.spellZones$.pipe(map(zones => !!zones[viewTournamentId]))),
      shareReplay({ bufferSize: 1, refCount: true }),
    )

    this.user$ = this._managerService.user$.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.breakpointObserverHandsetPortrait$ = this._breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .pipe(shareReplay({ bufferSize: 1, refCount: true }))

    this.breakpointObserverHandsetLandscape$ = this._breakpointObserver
      .observe([Breakpoints.HandsetLandscape])
      .pipe(shareReplay({ bufferSize: 1, refCount: true }))

  }

  slideTo(index: number) {
    this.tourneyNavSwiper.swiperRef.slideTo(index, 600);
  }

  activeIndexChange($event) {
    this.tourneyNavSwiperIndex = $event.activeIndex
  }

  openLegend() {

    this._managerService.settings$.pipe(take(1))
      .subscribe(settings => {
        const data = settings.BountySpellDescriptionModel

        for (const item of Object.keys(data)) {
          data[item].Info = this.findCurentLang(data[item].Descriptions)
        }

        this.dialog.open(DialogBountySpellInfoComponent, {
          width: '360px',
          data
        })
      })
  }

  findCurentLang(data: any[]): any {

    if (!data || data.length === 0) { return }

    let lang = data.find(el => el.Language === this.translateService.currentLang) // find by current language

    if (!lang) { // if current language doesnt exist first try to find english otherwise set first available language
      lang = data.find(el => el.langId === 'en')
      if (!lang) {
        lang = data[0]
      }
    }

    return lang
  }
}
