import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManagerService } from '../../services/data-manager.service';

@Component({
  selector: 'app-ask-question-dialog',
  templateUrl: './ask-question-dialog.component.html',
  styleUrls: ['./ask-question-dialog.component.scss']
})
export class AskQuestionDialogComponent implements OnInit {

  value: string;
  loader: boolean;


  constructor(
    public dialogRef: MatDialogRef<AskQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataManager: DataManagerService,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(true)
  }

  submit() {
    if (!this.value) { return }
    this.loader = true
    this.dataManager.submitAskQuestion(this.data.requestId, this.value)
    this.dialogRef.close()
  }

}
