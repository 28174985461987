import { GameService } from '../../main/game/game.service';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../environments/environment';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { AuthService } from '../auth.service';
import { AppWebSocketService } from '../../core/services/app-web-socket.service';
import { ManagerService } from '../../core/services/manager.service';
import { DataManagerService } from '../../core/services/data-manager.service';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '../../core/services/helper.service';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  environment = environment;
  securityCode: string;
  email: string;

  resetPasswordStarted = false;
  loader = false
  destroy$ = new Subject<boolean>();
  config;
  constructor(
    private _router: Router,
    private _managerService: ManagerService,
    private _socketService: AppWebSocketService,
    private _dataManager: DataManagerService,
    private _notification: NotificationsService,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.resetPasswordStarted) {
        this.submitForgotPassword()
      } else {
        this.submitSecurityCode()
      }
    }
  }

  ngOnInit() {
    this._managerService.loginState$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (loggedIn) => {
          if (!loggedIn) {
            this.loader = false
          }
        }
      })


    this._managerService.accountRequestSecurityCodeState$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response) {
            this.resetPasswordStarted = true;
          }
          this.loader = false
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submitForgotPassword() {
    const email = this.email.trimLeft().trimRight();

    if (!email) { return }
    this.loader = true;

    this._dataManager.requestSecurityCode(email, this.configService.config.skinName);
  }

  submitSecurityCode() {
    this.loader = true

    const securityCode = this.securityCode.trimLeft().trimRight()
    if (securityCode) {
      this._managerService.login({ username: this.email, securityCode: this.securityCode });
    }

  }

  cancel() {
    this._router.navigate(['/login']);
  }

}
