import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, DoCheck, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-nav-paginator',
  templateUrl: './nav-paginator.component.html',
  styleUrls: ['./nav-paginator.component.scss']
})
export class NavPaginatorComponent {

  @ViewChild('tablesContainer', { static: false }) tablesContainer: ElementRef;
  @Input() showScrollButtons = true;

  handsetPortrait = false;
  handsetLandscape = false;

  private interval: NodeJS.Timer;

  constructor(
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver

  ) {
  }


  ngOnInit() {

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape]).subscribe(result => {
        this.handsetLandscape = result.matches;
      })
  }

  scrollDiv(direction: string) {
    const el = this.el.nativeElement.querySelector('.nav-paginator-content');


    clearInterval(this.interval)
    let counter = 0

    this.interval = setInterval(() => {
      counter++
      if (counter < 100) {
        if (direction === 'LEFT') {
          el.scrollLeft -= 5
        } else if (direction === 'RIGHT') {
          el.scrollLeft += 5
        }
      } else {
        clearInterval(this.interval)
      }
    }, 1);
  }
}
