import * as PIXI from 'pixi.js';
import { Point } from '../models';

export class FloatingText {
    container: PIXI.Container;
    text: PIXI.Text;
    private timerRef: NodeJS.Timer;

    private textStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 24,
        fill: '#ffffff',
        fontWeight: '500'
    });

    private movingSpeed = 2;
    private fadeSpeed = 0.02;
    private scaleSpeed = 0.005;
    private scale = 1;

    constructor(message: string, position: Point) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);

        this.text = new PIXI.Text(message, this.textStyle);
        this.container.addChild(this.text);
        this.timerRef = setInterval(() => this.doAnimation(), 25);
    }

    private doAnimation() {
        this.container.position.y -= this.movingSpeed;
        this.scale += this.scaleSpeed;
        this.text.scale.set(this.scale);
        this.text.alpha -= this.fadeSpeed;
        if (this.text.alpha <= 0) {
            clearInterval(this.timerRef);
            this.selfDistruct();
        }
    }

    private selfDistruct() {
        this.container.parent.removeChild(this.container);
    }
}
