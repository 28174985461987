export enum TransactionType {
    Deposit = 1, // make a deposit from outside payment system
    Withdraw = 2, // make a withdraw to outside payment system
    WithdrawCanceled = 4, // cancel of a withdrawal
    Transfer = 3, // transfer between player
    TransferFrom = 5, // transfer from a player, this type only exist in api, it's the same as Transfer with negative value
    GiftSignupBonus = 60,  // bonus when player make first deposit

    RabbitHunting = 39,  // RabbitHunting
    GiftDepositBonus = 61,
    GiftReferAFriendRegister = 62, // the player who join that get a bonus
    GiftRakeBackBonus = 63, // bonus as rakeback
    GiftReferAFriendReferrer = 64,  // the player who invited that get a bonus
    GiftOther = 69,
    IncomePayout = 70, // when the income money is transfer into cash available
    Rakeback = 71, // periodically returning the rake to the player,

}
