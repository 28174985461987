import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerLevelStatus, PlayerLevelStatusLevel } from '../../../core/services/manager.service';
import { CurrencyInfo } from '../../../shared/models/currency-info';

@Component({
  selector: 'app-dialog-player-status-level',
  templateUrl: './dialog-player-status-level.component.html',
  styleUrls: ['./dialog-player-status-level.component.scss']
})
export class DialogPlayerStatusLevelComponent implements OnInit {
  enumPlayerLevelStatusLevel = PlayerLevelStatusLevel;

  constructor(
    public dialogRef: MatDialogRef<DialogPlayerStatusLevelComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { playerLevelStatus: PlayerLevelStatus, currency: CurrencyInfo }
  ) { }

  ngOnInit(): void {
  }

}
