import { Point } from '../../models';

export const communityCardPositions: Point[] = [
    { x: 242, y: 756 },
    { x: 362, y: 756 },
    { x: 482, y: 756 }, // top-left
    { x: 602, y: 756 },
    { x: 722, y: 756 }
];
export const r2tT1Positions: Point[] = [
    { x: 290, y: 719 },
    { x: 401, y: 719 },
    { x: 512, y: 719 }, // top-left
    { x: 623, y: 719 },
    { x: 734, y: 719 }
];
export const r2tT2Positions: Point[] = [
    { x: 290, y: 881 },
    { x: 401, y: 881 },
    { x: 512, y: 881 }, // top-left
    { x: 623, y: 881 },
    { x: 734, y: 881 }
];
export const r2tCardScale: Point = { x: 1.1, y: 1.1 };
export const communityCardScale: Point = { x: 1.35, y: 1.35 };
