import { Subscription, BehaviorSubject, Subject, ReplaySubject, Observable, of, interval, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { format, addSeconds } from 'date-fns';
import { ENavItem } from '../../main/nav/nav-item-type';
import { TournamentHelper } from '../../main/tournament/tournament-helper';
import { LoggerService } from './logger.service';
import { DataManagerService } from './data-manager.service';
import { ClockService } from './clock.service';
import { DialogTournamentEndResultComponent } from '../../main/tournament/presenters/dialog-tournament-end-result/dialog-tournament-end-result.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Authenticate, User } from '../../shared/models/user';
import { GiftType, getCurrencyName, getCurrencyIconName, ConnectionStatus } from '../../shared/enums';
import { Comparators } from '../../shared/helpers/comparators';
import { CurrencyDeviderPipe } from '../../shared/utils/currency-devider.pipe';
import { CurrencyCryptoConverterPipe } from '../../shared/utils/currency-crypto-converter.pipe';
import { Legends } from '../../shared/constants/legend.constants';
import { HandHistory } from '../../shared/models/hand-history';
import { ServerMessage, Limit, TournamentStatus2, VariantType2, AccountType, ErrorCode, LobbyFilters, GameLayoutTypes, TransactionsHistoryStatus } from '../../shared/enums/poker-types';
import { CurrencyPipe } from '../../shared/utils/currency.pipe';
import { TournamentStatusTextAdjusted } from '../../shared/constants/tournament.constants';
import { NotificationsService } from '../notifications/notifications.service';
import { GenericTextDialog } from '../dialogs/generic-text-dialog/generic-text-dialog.component';
import { Ticket } from '../../shared/models/ticket';
import { TranslateService } from '@ngx-translate/core';
import { SpinNGoSummary } from '../../shared/models/spin-and-go-summary';
import { BroadcastDialogComponent } from '../dialogs/broadcast-dialog/broadcast-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrencyInfo } from '../../shared/models/currency-info';
import { AuthService, PlayersStatistics } from '../../auth/auth.service';
import { LostConnectionService } from '../lost-connection/lost-connection.service';
import { SpinnerService } from '../spinner/spinner.service';
import { Router } from '@angular/router';
import { GatewaysInfo } from '../../shared/models/gateway-info';
import { TransactionType } from '../../shared/enums/transaction-type';
import { getConvertedAmountText } from '../../shared/helpers/utils';
import { MemberPreferences, MemberPreferencesRunItTwice } from '../../shared/models/member-preferences';
import { filter, finalize, map, pairwise, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import {
  TableSummary,
  TournamentSummary,
  Tournament,
  NavTableData,
  TournamentInformation,
  LiveLobbyTournament,
  ServerMsg,
  TournamentEndResult,
  MTTTableAssigned,
  SitNGoSummary,
  TournamentActiveTable,
  GameTableStart,
  Gift,
  LobbyFilterOptions,
  PlayerBalance,
  LobbyFilter,
  Settings,
  CardData,
  MemberProfile,
  TournamentBlindLevel
} from '../../shared/models';
import { AppWebSocketService } from './app-web-socket.service';
import { AskQuestionDialogComponent } from '../dialogs/ask-question-dialog/ask-question-dialog.component';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { DepositRedirectDialogComponent } from '../../main/cashier/deposit-redirect-dialog/deposit-redirect-dialog.component';
import { ForceChangePasswordComponent } from '../../main/dialogs/force-change-password/force-change-password.component';

import * as moment from 'moment-timezone';
import { UserAppConfig } from '../../main/game/models/user-app-config';
import { TransactionsHistory } from '../../shared/models/transaction-history';
import { env } from 'process';
import { AskQuestionR2TComponent } from '../dialogs/ask-question-r2-t/ask-question-r2-t.component';
import { table } from 'console';
import { WithdrawalRedirectDialogComponent } from '../../main/cashier/withdrawal-redirect-dialog/withdrawal-redirect-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';


export enum PlayerLevelStatusLevel {
  BASIC = 0,
  BRONZE = 1,
  SILVER = 2,
  GOLD = 3,
  PLATINUM = 4,
  DIAMOND = 5
}

export interface PlayerLevelStatus {
  AmountCurrentLevel: number;
  AmountNextLevel: number;
  AmountRakebackPaid: number;
  Currency: number;
  IdPlayer: number;
  Level: number;
  Progress: number;
  RewardPercentage: number;
  RewardPercentageNextLevel: number;
  TotalRakebackEarned: number;
}

export interface CallTimeStatus {
  ActivatedAt: Date,
  CallTimeDuration: number,
  CanLeaveTable: boolean,
  IdPlayer: number,
  IdTable: 16364,
  IsActivated: boolean,
  CallTimeExpired: boolean
}

export enum v2WSDataResponse {
  CurrenciesInfo = 'CurrenciesInfo',
  TournamentsSummaries = 'TournamentsSummaries',
  TournamentSummary = 'TournamentSummary',
  TournamentInformation = 'TournamentInformation',
  LiveLobbyTournament = 'LiveLobbyTournament',
  BlindInformation = 'BlindInformation'
}

@Injectable()
export class ManagerService {

  activeCasinoGames$ = new BehaviorSubject<{ url: string, name: string, gameCode: string }[]>([]);
  activeCasinoGames: { url: string, name: string, gameCode: string }[] = [];

  activeCasinoGame$ = new BehaviorSubject<{ url: string, name: string, gameCode: string }>(undefined);
  activeCasinoGame: { url: string, name: string, gameCode: string };

  // - - - - - - - - - - - - - - - - - -
  tableSummaries$ = new BehaviorSubject<TableSummary[]>([]);

  // - - - - - - - - - - - - - - - - - -
  subscription: Subscription;

  //  Observable sources

  playerTickets$ = new ReplaySubject<Ticket[]>(1);

  private activeGamesSubject = new Subject<number>();

  //  Observable streams
  private tableSumsSubject = new BehaviorSubject<TableSummary[]>([]);
  tableSumsObservable$ = this.tableSumsSubject.asObservable();



  activeGamesSubjectObservable$ = this.activeGamesSubject.asObservable();

  private tournamentInformationSubject = new Subject<number>();
  tournamentInformationObservable$ = this.tournamentInformationSubject.asObservable();

  tableSummaries: TableSummary[] = [];
  tournamentsSummaries: TournamentSummary[] = [];
  private clockSubscription: Subscription;

  // regTours: { [index: number]: Tournament; } = {};
  viewingTour: Tournament;
  // subscribedTours: { [index: number]: Tournament; } = {};

  // activeGames: Array<NavTableData> = []; //DEC

  showEmptyTables = true;
  useSmartSort = true;


  // ********

  private casinoTokenReceivedSubject = new Subject<any>();
  casinoTokenReceivedObservable$ = this.casinoTokenReceivedSubject.asObservable();

  private mixTableSubject = new Subject<any>();
  mixTableObservable$ = this.mixTableSubject.asObservable();


  private lobbyListSubject = new BehaviorSubject<any[]>([]);
  lobbyListObservable$ = this.lobbyListSubject.asObservable();

  // ********

  //v2
  public casinosInfo: any = []
  casinosInfo$ = new BehaviorSubject<any[]>([]);

  // Lobby ---
  private lobbyFilter = new LobbyFilter()
  lobbyFilter$ = new BehaviorSubject<LobbyFilter>(this.lobbyFilter);

  // ---------

  lobbyStatsPlayer$ = new ReplaySubject<PlayersStatistics>(1);

  // AUTH
  user$ = new BehaviorSubject<User>(null);
  private user = new User()

  settings$: ReplaySubject<Settings> = new ReplaySubject<Settings>(1);



  private tournaments = {} as { [index: number]: Tournament; };
  private viewingTournamentId: number;

  viewTournament$ = new ReplaySubject<Tournament>(1);
  // private viewTournament = new Tournament()

  activeTab$ = new BehaviorSubject<ENavItem>(ENavItem.Lobby);

  activeGames: { [index: number]: NavTableData } = {}
  activeGames$ = new BehaviorSubject<{ [index: number]: NavTableData }>(this.activeGames);


  onLeaveTable = new Subject<number>();
  gatewaysInfo$ = new ReplaySubject<GatewaysInfo>(1);

  currencyInfo: CurrencyInfo[] = []
  currencyInfo$ = new ReplaySubject<CurrencyInfo[]>(1);






  // -- April refactor

  loginState$ = new BehaviorSubject<boolean>(false);
  accountRequestSecurityCodeState$ = new BehaviorSubject<boolean>(false);


  handReplay$ = new Subject<any>();
  handHistory$ = new Subject<HandHistory>();

  // JackPot
  jackPotsInfo$ = new ReplaySubject<any[]>(1);
  jackPotsInfo: any[] = []


  // AskQuestion
  askQuestionDialogRef
  reloadLobby$ = new Subject<void>();

  emotikensPackages$ = new ReplaySubject<any>(1);
  emotikensPackages: any;

  ticketsPackages$ = new ReplaySubject<any>(1);
  currencyPackages$ = new ReplaySubject<any>(1);

  showPlayerPlus$ = new ReplaySubject<boolean>(1);


  gameLayout = {} as { [tableId: number]: any; };
  emoticonsInfo$ = new ReplaySubject<any[]>(1);
  emoticonsInfo: any


  playerLevelStatus = new Map<number, PlayerLevelStatus>();
  playerLevelStatus$ = new ReplaySubject<Map<number, PlayerLevelStatus>>(1);

  skinsSettings$ = new ReplaySubject<any>(1);

  // -----
  checkEmailExist$ = new Subject<string>();


  shopSettings$ = new ReplaySubject<any>(1);

  callTimeStatus$ = new BehaviorSubject<CallTimeStatus[]>([]);



  // 🎁 
  gifts$ = new ReplaySubject<any>(1);
  tickets$ = new ReplaySubject<any>(1);
  rafBonuses$ = new ReplaySubject<any>(1);


  // Config 📙
  private _userAppConfig: UserAppConfig
  private set userAppConfig(value: UserAppConfig) {
    localStorage.setItem('userAppConfig', JSON.stringify(value));
    this._userAppConfig = value;
    this.userAppConfig$.next(value)
  }

  userAppConfig$ = new ReplaySubject<UserAppConfig>(1);









  // v2 🐋
  v2TournamentSummaries = new Map<number, TournamentSummary>();

  private v2Tournaments = new Map<number, Tournament>(); // key: IdTournament { tourInfo, tourSum, activeTables, isRegistered ... }
  v2Tournaments$ = new ReplaySubject<Map<number, Tournament>>(1)

  v2ActiveGames = new Map<string, NavTableData>(); // 📖 key: TOURNAMENT_123 OR TABLE_123 HeaderMenuBar+InitTable[] => should be only table id check that
  v2Currencies = new Map<number, CurrencyInfo>(); // 📖 key: Currency ID

  v2TransactionsHistory$: Observable<TransactionsHistory[]>


  viewTournamentId$ = new ReplaySubject<number>(1);

  inactivityLogoutDialogRef;


  tournamentEndResultDialogRef: MatDialogRef<any>;
  tournamentEndResultData: TournamentEndResult;


  tableFilters$ = new BehaviorSubject<any>(null);

  spellZones$ = new BehaviorSubject<any>({});
  spellZones = {}

  antiBotQuestion$ = new BehaviorSubject<any>(null);

  depositInitiateResponseUrl$ = new BehaviorSubject<any>(null);
  config;

  constructor(
    // private store: Store<State>,
    private logger: LoggerService,
    private dataManager: DataManagerService,
    private clockService: ClockService,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,

    // private authService:AuthService,
    private lostConnectionService: LostConnectionService,

    private _spinnerService: SpinnerService,
    private router: Router,
    private wsService: AppWebSocketService,

    protected breakpointObserver: BreakpointObserver,
    mediaMatcher: MediaMatcher,
    private httpClient: HttpClient,
    private configService: ConfigService,

  ) {
    console.log("configS", this.configService.config)


    this.configService.config$.subscribe(config => {
      this.config = config;
      this.setActiveTab(ENavItem.Lobby)

    })


    this.configService.config$.subscribe(config => { this.config = config })
    this.skinsSettings$
      .pipe(
        switchMap((settings) => {
          if (!settings || settings.isEnableLogoutAfterInactivity === false) {
            return of(null);
          }

          return this.wsService.activity$.pipe(
            switchMap(() => timer(0, 1000)
              .pipe(
                tap((timer) => {

                  const logoutInactivityTimeMinutes = settings.logoutInactivityTimeMinutes * 60
                  console.log(logoutInactivityTimeMinutes, timer, logoutInactivityTimeMinutes - timer)
                  if (logoutInactivityTimeMinutes - timer === 60) {
                    this.inactivityLogoutDialogRef = this.dialog.open(GenericTextDialog, {
                      disableClose: true,
                    });

                    this.inactivityLogoutDialogRef.componentInstance.textAcceptBtn = 'Ok';
                    this.inactivityLogoutDialogRef.componentInstance.textHeader = 'Inactivity logout';
                    this.inactivityLogoutDialogRef.componentInstance.textBody = 'During inactivity, you will be logged out of the game in 60 seconds.';
                    this.inactivityLogoutDialogRef.componentInstance.enableClose = false
                  }
                  if (logoutInactivityTimeMinutes - timer < 60) {
                    this.inactivityLogoutDialogRef.componentInstance.textBody = `During inactivity, you will be logged out of the game in ${logoutInactivityTimeMinutes - timer} ${logoutInactivityTimeMinutes - timer > 1 ? 'seconds' : 'second'}`;
                  }

                  if (logoutInactivityTimeMinutes - timer <= 0) {

                    try { this.inactivityLogoutDialogRef.close(); } catch (error) { }
                    this.logout(true);
                  }
                })
              )
            )
          )



        })

      )
      .subscribe((activity) => {
        console.log("ACTIVITY", activity);
      })

    // v2 🐋
    this.v2TransactionsHistory$ = this.dataManager.messageReceivedObservable
      .pipe(
        filter(data => data.Response === 'TransactionsHistory'),
        map(data => data.TransactionsHistory as TransactionsHistory[]),
        map(data => (data.map(el => {

          switch (el.Status) {
            case TransactionsHistoryStatus.Confirmed:
              el.statusTranslate = 'Confirmed';
              break;
            case TransactionsHistoryStatus.Pending:
              el.statusTranslate = 'Pending';
              break;
            case TransactionsHistoryStatus.Cancelled:
              el.statusTranslate = 'Cancelled';
              break;
            case TransactionsHistoryStatus.NotDefined:
              el.statusTranslate = 'Not Defined';
            default:
              el.typeTranslate = `Unknown`;
              break;
          }

          switch (el.Type) {
            case TransactionType.Deposit:
              el.typeTranslate = 'Deposit';
              break;
            case TransactionType.Withdraw:
              el.typeTranslate = 'Withdrawal';
              break;
            case TransactionType.Transfer:
              el.typeTranslate = 'Sent Money';
              break;
            case TransactionType.TransferFrom:
              el.typeTranslate = 'Received Money';
              break;
            case TransactionType.GiftDepositBonus:
              el.typeTranslate = 'Gift deposit bonus';
              break;
            case TransactionType.GiftRakeBackBonus:
              el.typeTranslate = 'RakeBack';
              break;
            case TransactionType.WithdrawCanceled:
              el.typeTranslate = 'Cancelled of a withdrawal';
              break;
            case TransactionType.IncomePayout:
              el.typeTranslate = 'Income release';
              break;
            case TransactionType.RabbitHunting:
              el.typeTranslate = 'Rabbit Hunting';
              break;
            default:
              el.typeTranslate = `Unknown`;
              break;
          }

          el.currency = this.v2Currencies.get(el.Currency);

          return el
        })))
      )


    // *****************************

    // # USER APP CONFIG
    const userAppConfig = {
      stacksInBBRing: false,
      stacksInBBTourney: false,
      muteSound: false
    }
    const localStorageUserAppConfig = localStorage.getItem('userAppConfig') ? JSON.parse(localStorage.getItem('userAppConfig')) : {}
    this.userAppConfig = { ...userAppConfig, ...localStorageUserAppConfig }


    // this.store.select(getActiveGames).subscribe(activeGames => {
    //   this.activeGames = activeGames;
    //   this.activeGamesSubject.next(this.activeGames.length);
    // }); // DEC

    // setTimeout(() => {
    //   this.dialog.open(TournamentEndResultComponent, { width: '300px', data: {} });

    // }, 1000);

    /* MARCH
  
  
    this.useSmartSort = environment.settings.lobbyTableSmartSort;
  
    */

    this.dataManager.messageReceivedObservable
      .subscribe(data => {
        this.parseServerMessage(data);

        this.V2ParseServerMessage(data);

      });







    // this.store.select(getTournamentsState).subscribe(tournamentsState => {


    //   // this.regTours = tournamentsState.tournaments;
    //   // this.viewingTour = tournamentsState.tournaments[tournamentsState.viewingTournamentId];
    // });


    // this.store.select(getUserState).subscribe(user => {


    //   // this.user = user;
    // });





    // --- APRIL :::
    this.settings$
      .subscribe({
        next: (settings) => {
          if (settings) {

            this._spinnerService.hide();
          }
        }
      })

    this.wsService.connectionStatus$.subscribe(connectionStatus => {
      console.log("@connection status", connectionStatus)
      console.log("@this.user", this.user.authenticate)
      if (connectionStatus === ConnectionStatus.error) {
        // this.notificationsService.showError(`SERVER ERROR`)
        // TO DO :: - - - - - - - - - -
        this.wsService.initializeWebSocket();

        this.dataManager.sendClientInfo().pipe(take(1)).subscribe(() => {
          console.log("@this.user", this.user.authenticate)
          if (this.user) {
            this.login(this.user.authenticate)
          }
        })
      }
    })

    this.lostConnectionService.networkStatus$
      .pipe(
        pairwise()
      )
      .subscribe(results => {
        const wasConnected = results[0];
        const isConnected = results[1];

        if (this.user) {
          if (wasConnected && !isConnected) {
            console.log("@here disconnect")
            this.wsService.disconnect()

            this.activeGames = []
            this.activeGames$.next(this.activeGames)

            this.tournaments = {} as { [index: number]: Tournament; };
            this.viewingTournamentId = 0
            this.viewTournament$.next(null)
            this.viewTournamentId$.next(undefined)
            this.setActiveTab(ENavItem.Lobby)



            // TO DO : stopPinging

          } else if (!wasConnected && isConnected) {
            console.log("@here init")
            this.wsService.initializeWebSocket();

            this.dataManager.sendClientInfo().pipe(take(1)).subscribe(() => {
              console.log("@this.user", this.user.authenticate)
              if (this.user) {
                this.login(this.user.authenticate)
              }
            })

            /*
            if (this.user.authenticate) {
              setTimeout(() => {
                this.dataManager.sendClientInfo().pipe(take(1)).subscribe(() => {
                  this.login(this.user.authenticate)
                })
              }, 1000);
            }else{
              this.dataManager.sendClientInfo().pipe(take(1)).subscribe(() => {
             //   this.login(this.user.authenticate)
              })
            }
            */

          }
        }

        /*
          1.) disconnect WS
          2.) Remove active games
          3.) Remove active tournaments
          4.) Move to lobby
          3) REMOVE AUTH SERVICE
        */

      })
  }

  // USER APP CONFIG
  updateUserAppConfig(data: Partial<UserAppConfig>) {
    this.userAppConfig = { ...this._userAppConfig, ...data }
  }
  // MANGAER REQ FUNCTIONS *********************** */

  checkEmailExist(email: string): Observable<Pick<ServerMsg, "MsgType" | "MsgTypeLog" | "Text" | "Value">> {
    this.dataManager.checkEmailExist(email)
    return this.dataManager.messageReceivedObservable
      .pipe(
        filter(data => data.Response === 'ServerMsg'),
        filter(data => (data.ServerMsg as ServerMsg[]).filter(serverMsg => serverMsg.MsgType === ServerMessage.AccountEmailUniqueResponse).length === 1),
        map(data => (data.ServerMsg as ServerMsg[]).find(serverMsg => serverMsg.MsgType === ServerMessage.AccountEmailUniqueResponse)),
        take(1)
      )
  }

  clearGameLayoutForTable(tableId: number) {
    this.gameLayout[tableId] = null
  }
  checkUsernameExist(username: string): Observable<Pick<ServerMsg, "MsgType" | "MsgTypeLog" | "Text" | "Value">> {
    this.dataManager.checkUsernameExist(username)
    return this.dataManager.messageReceivedObservable
      .pipe(
        filter(data => data.Response === 'ServerMsg'),
        filter(data => (data.ServerMsg as ServerMsg[]).filter(serverMsg => serverMsg.MsgType === ServerMessage.AccountUsernameUniqueResponse).length === 1),
        map(data => (data.ServerMsg as ServerMsg[]).find(serverMsg => serverMsg.MsgType === ServerMessage.AccountUsernameUniqueResponse)),
        take(1)
      )
  }

  updateAccount(memberProfile: Partial<MemberProfile>): Observable<Pick<ServerMsg, "MsgType" | "MsgTypeLog" | "Text" | "Value">> {
    this.dataManager.updateAccount(memberProfile)

    return this.dataManager.messageReceivedObservable
      .pipe(
        filter(data => data.Response === 'MemberProfile'),
        map(data => data.MemberProfile),
        take(1)
      )
  }











  updateUserPreference(data: MemberPreferences) {
    // case AuthActionTypes.MemberPreferenceReceived: {
    //   state.user.memberProfile.Preferences = action.payload;
    //   return { ...state };
    // }
    this.user.memberProfile.Preferences = data
    this.user$.next(this.user)
  }

  logout(disconnect: boolean) {
    console.log("@logout", disconnect)
    this._spinnerService.show()


    if (disconnect) {
      this.dataManager.logout();
    }
    if (!disconnect) {
      return
    }
    localStorage.removeItem('auth-login');

    this.gameLayout = {} as { [tableId: number]: any; };

    this.activeGames = []
    this.activeGames$.next(this.activeGames)


    this.tournaments = {} as { [index: number]: Tournament; };
    this.viewingTournamentId = 0
    this.viewTournament$.next(null)
    this.viewTournamentId$.next(undefined)


    this.setActiveTab(ENavItem.Lobby)
    // this.lostConnectionService.hide();

    this.settings$.next(null)
    this.skinsSettings$.next(null)

    this.router.navigate(['/login']);


    this.user.authenticate = null;
    this.user$.next(this.user)

  }

  login(auth: Authenticate) {
    if (!auth) { return }
    this.user.authenticate = auth
    console.log("@login", auth)
    this.dataManager.login(auth)
  }


  setLobbyFilterGameType(data: number) {
    this.lobbyFilter.filters.TableType = data
    this.lobbyFilter$.next(this.lobbyFilter)
  }
  setLobbyFilterVariant(data: number[]) {
    this.lobbyFilter.filters.Variants = data
    this.lobbyFilter$.next(this.lobbyFilter)
  }
  setLobbyFilterLimit(data: number) {
    this.lobbyFilter.filters.Limits = data
    this.lobbyFilter$.next(this.lobbyFilter)
  }
  setLobbyFilterStake(data: number) {
    this.lobbyFilter.filters.Stakes = data
    this.lobbyFilter$.next(this.lobbyFilter)
  }
  setLobbyFilterTableOptions(data: number) {
    this.lobbyFilter.filters.ShowPrivate = !!data
    this.lobbyFilter$.next(this.lobbyFilter)
  }
  setLobbyFilterSpeed(data: number) {
    this.lobbyFilter.filters.Speeds = data
    this.lobbyFilter$.next(this.lobbyFilter)
  }


  changeCurrency(data: CurrencyInfo) {
    this.user.selectedCurrency = data
    this.user$.next(this.user)
    this.dataManager.getTransactionsHistory(this.user.selectedCurrency.Id);
  }

  getNumberOfActiveGames(): number {
    return 0 //  return this.activeGames.length; DEC
  }































  // 🐋  
  V2ParseServerMessage(data) {
    let tournamentSummary: TournamentSummary;
    let tournament: Tournament;

    switch (data.Response) {
      case 'AntiBotQuestion':
        console.log("here", data)
        this.antiBotQuestion$.next(data.AntiBotQuestion)
        break;

      case v2WSDataResponse.CurrenciesInfo:
        const currenciesInfo = data.CurrenciesInfo as CurrencyInfo[];
        for (const currency of currenciesInfo) {
          this.v2Currencies.set(currency.Id, currency)
        }
        break;

      case v2WSDataResponse.BlindInformation:

        const blinds = data.BlindInformation.Blinds as TournamentBlindLevel[];
        const idTournament = data.BlindInformation.IdTournament;


        tournament = this.v2Tournaments.get(idTournament);
        if (tournament) {
          tournament.blindLevels = blinds;
          this.v2Tournaments.set(idTournament, tournament);
          this.v2Tournaments$.next(this.v2Tournaments);
        }

        break;

      case v2WSDataResponse.TournamentsSummaries:
        const tournamentSummaries = data.TournamentsSummaries as TournamentSummary[];
        for (const tournamentSummary of tournamentSummaries) {
          this.v2TournamentSummaries.set(tournamentSummary.Id, tournamentSummary)

          tournament = this.v2Tournaments.get(tournamentSummary.Id);
          if (tournament) {
            tournament.tourSum = tournamentSummary;
            this.v2Tournaments.set(tournamentSummary.Id, tournament);
            this.v2Tournaments$.next(this.v2Tournaments);
          }
        }
        break;
      case v2WSDataResponse.TournamentSummary:

        // tournamentSummary = data.TournamentSummary as TournamentSummary;
        // this.v2TournamentSummaries.set(tournamentSummary.Id, tournamentSummary)

        // tournament = this.v2Tournaments.get(tournamentSummary.Id);
        // if (tournament) {
        //   tournament.tourSum = tournamentSummary;
        //   this.v2Tournaments.set(tournamentSummary.Id, tournament);
        //   this.v2Tournaments$.next(this.v2Tournaments);
        // }

        // this.dataManager.sendMTTView(tournamentSummary.Id, true);


        break

      case v2WSDataResponse.LiveLobbyTournament:
        const liveLobbyTournament = data.LiveLobbyTournament as LiveLobbyTournament;

        tournamentSummary = this.v2TournamentSummaries.get(liveLobbyTournament.IdTournament)
        if (tournamentSummary) {
          tournamentSummary.NbPlayersActive = liveLobbyTournament.NbPlayersActive;
          tournamentSummary.NbPlayerRegistered = liveLobbyTournament.NbPlayersRegistered;
          tournamentSummary.Status2 = liveLobbyTournament.Status2;
          tournamentSummary.StartIn = liveLobbyTournament.StartIn;
          tournamentSummary.RegistrationsStartIn = liveLobbyTournament.RegistrationsStartIn;
          tournamentSummary.RegistrationEndIn = liveLobbyTournament.RegistrationEndIn;
          tournamentSummary.BlindIncreaseIn = liveLobbyTournament.BlindIncreaseIn;
          tournamentSummary.BreakIn = liveLobbyTournament.BreakIn;
          tournamentSummary.BreakEndIn = liveLobbyTournament.BreakEndIn;
          tournamentSummary.EndedSince = liveLobbyTournament.EndedSince;
          tournamentSummary.ReBuyEndIn = liveLobbyTournament.ReBuyEndIn;

          if (tournamentSummary.Limit === Limit.FL) {
            tournamentSummary.minStake = liveLobbyTournament.Blind * 2;
            tournamentSummary.maxStake = liveLobbyTournament.Blind * 4;
          } else {
            tournamentSummary.minStake = liveLobbyTournament.Blind;
            tournamentSummary.maxStake = liveLobbyTournament.Blind * 2;
          }
          this.v2TournamentSummaries.set(tournamentSummary.Id, tournamentSummary)
        }


        tournament = this.v2Tournaments.get(liveLobbyTournament.IdTournament);
        if (tournament && tournament.tourSum) {
          tournament.tourSum.NbPlayersActive = liveLobbyTournament.NbPlayersActive;
          tournament.tourSum.NbPlayersActive = liveLobbyTournament.NbPlayersActive;
          tournament.tourSum.NbPlayerRegistered = liveLobbyTournament.NbPlayersRegistered;
          tournament.tourSum.Status2 = liveLobbyTournament.Status2;
          tournament.tourSum.StartIn = liveLobbyTournament.StartIn;
          tournament.tourSum.RegistrationsStartIn = liveLobbyTournament.RegistrationsStartIn;
          tournament.tourSum.RegistrationEndIn = liveLobbyTournament.RegistrationEndIn;
          tournament.tourSum.BlindIncreaseIn = liveLobbyTournament.BlindIncreaseIn;
          tournament.tourSum.BreakIn = liveLobbyTournament.BreakIn;
          tournament.tourSum.BreakEndIn = liveLobbyTournament.BreakEndIn;
          tournament.tourSum.EndedSince = liveLobbyTournament.EndedSince;
          tournament.tourSum.ReBuyEndIn = liveLobbyTournament.ReBuyEndIn;

          if (tournamentSummary.Limit === Limit.FL) {
            tournament.tourSum.minStake = liveLobbyTournament.Blind * 2;
            tournament.tourSum.maxStake = liveLobbyTournament.Blind * 4;
          } else {
            tournament.tourSum.minStake = liveLobbyTournament.Blind;
            tournament.tourSum.maxStake = liveLobbyTournament.Blind * 2;
          }

          this.v2Tournaments.set(liveLobbyTournament.IdTournament, tournament);
          this.v2Tournaments$.next(this.v2Tournaments);
        }


        break;
      case v2WSDataResponse.TournamentInformation:

        const tournamentInformation = data.TournamentInformation as TournamentInformation;

        tournament = this.v2Tournaments.get(tournamentInformation.IdTournament);
        if (!tournament) {
          tournament = {} as Tournament;

          tournament.tourInfo = tournamentInformation;
          tournament.tourInfo.Players = tournamentInformation.Players ?? [];
          tournament.tourInfo.Prizes = tournamentInformation.Prizes ?? [];
          tournament.tourInfo.Prizes2 = tournamentInformation.Prizes2 ?? [];

          tournament.tourSum = this.v2TournamentSummaries.get(tournamentInformation.IdTournament)
          tournament.activeTables = []
          tournament.isRegistered = false;
        }

        // 🟢 added
        if (!tournament.tourSum) {
          return
        }

        // tournament.myActiveTable = null; // ❌ myActiveTable

        // 📚 if this is true restart player list
        if (tournamentInformation.NbPlayerRegistered === tournamentInformation.Players.length) {
          tournament.tourInfo.Players = tournamentInformation.Players;

          // 📚
          // sometimes server doesn't send Prizes so check is needed
          // from doc: "If this property is not present it's because the previous one didn't change."

          if (tournamentInformation.Prizes) {
            tournament.tourInfo.Prizes = tournamentInformation.Prizes;
          }

          if (tournamentInformation.Prizes2) {
            tournament.tourInfo.Prizes2 = tournamentInformation.Prizes2;
          }
        } else {
          // 📚 check for new players to be added to the list
          for (const playerUpdate of tournamentInformation.Players) {
            let existingPlayer = tournament.tourInfo.Players.find(oldPlayer => oldPlayer.IdPlayer === playerUpdate.IdPlayer);
            if (existingPlayer) {
              existingPlayer = playerUpdate;
            } else {
              tournament.tourInfo.Players.push(playerUpdate);
            }
          }
        }

        const myPlayer = tournament.tourInfo.Players.find(player => player.IdPlayer === this.user.memberProfile.Id);
        const imOnTour = !myPlayer?.PositionEnded ? myPlayer : undefined;
        const iWasOnTour = myPlayer?.PositionEnded ? myPlayer : undefined;

        if (imOnTour) {
          tournament.isRegistered = !imOnTour.PositionEnded;

          // 📚
          // Menu bar - Active Games
          // if tournament is not on the list, add it to the list of registered tournaments in navBar
          // check if game is already running, if so open new gameTable

          if (!imOnTour.PositionEnded && !this.v2ActiveGames.has(`TOURNAMENT_${tournamentInformation.IdTournament}`)) {



            const gameTableStart = <GameTableStart>{
              IdTable: imOnTour.IdTable, // 📖 Tournament can have multiple tables, so we will use a IdTable from Player Object
              IdTournament: tournamentInformation.IdTournament,
              tourSum: tournament.tourSum,
              currency: this.v2Currencies.get(tournament.tourSum.Currency),
              name: tournament.tourSum.Name
            };

            // temporary commented
            // this.gameStart(gameTableStart);


            // ❌ myActiveTable - DELETE // used at button-tournament-registration
            // tournament.myActiveTable = this.checkForTourGameTable(
            //   imOnTour.IdTable, tourInfo.IdTournament, imOnTour.PositionEnded, tournament.tourSum
            // );
          }
        } else {
          tournament.isRegistered = false;
        }

        // 🟢 added
        if (!tournament.blindLevels) {
          this.dataManager.requestBlindSchedule(tournamentInformation.IdTournament);
        }

        this.updateTourActiveTables(tournament, tournamentInformation);
        this.updatePlayersRank(tournament, this.user.memberProfile.Id);


        this.v2Tournaments.set(tournamentInformation.IdTournament, tournament);
        this.v2Tournaments$.next(this.v2Tournaments);

        break;


    }
  }





































































  parseServerMessage(data) {
    switch (data.Response) {
      case 'TableFilters':
        const filters = [
          { realValue: LobbyFilters.VariantHoldem, displayValue: 'Hold\'em', },
          { realValue: LobbyFilters.VariantHoldemRock, displayValue: 'Hold\'em Rock', },
          { realValue: LobbyFilters.VariantCapTexasHoldEm, displayValue: 'Cap Hold\'em', },
          { realValue: LobbyFilters.VariantCapTexasHoldEm, displayValue: 'Cap Hold\'em', },
          { realValue: LobbyFilters.SixPlusHoldem, displayValue: '6+ Hold\'em', },

          { realValue: LobbyFilters.VariantOmaha, displayValue: 'Omaha', },
          { realValue: LobbyFilters.VariantOmahaHiLow, displayValue: 'Omaha Hi/Lo', },
          { realValue: LobbyFilters.VariantOmaha3, displayValue: 'Omaha 3', },
          { realValue: LobbyFilters.VariantOmaha5, displayValue: 'Omaha 5', },
          { realValue: LobbyFilters.VariantOmaha5HiLow, displayValue: 'Omaha 5 Hi/Lo', },
          { realValue: LobbyFilters.VariantOmaha6, displayValue: 'Omaha 6', },
          { realValue: LobbyFilters.Omaha7, displayValue: 'Omaha 7', },
          { realValue: LobbyFilters.CapOmaha, displayValue: 'Cap Omaha', },
          { realValue: LobbyFilters.CapOmaha5, displayValue: 'Cap Omaha 5', },
          { realValue: LobbyFilters.CapOmaha6, displayValue: 'Cap Omaha 6', },
          { realValue: LobbyFilters.CapOmaha7, displayValue: 'Cap Omaha 7', },
          { realValue: LobbyFilters.VariantTriOmaha, displayValue: 'Tri Omaha', },

          { realValue: LobbyFilters.Variant7Stud, displayValue: '7 Stud', },
          { realValue: LobbyFilters.SevenStudHiLo, displayValue: '7 Stud Hi/Lo', },
          { realValue: LobbyFilters.Razz, displayValue: 'Razz', },

          { realValue: LobbyFilters.Badugi, displayValue: 'Badugi', },
          { realValue: LobbyFilters.VariantTripleDraw, displayValue: '2-7 Tripple Draw', },
          { realValue: LobbyFilters.FiveCardDraw, displayValue: '5 Card Draw', },
          { realValue: LobbyFilters.Badeucey, displayValue: 'Badeucey', },

          { realValue: LobbyFilters.VariantMixTable, displayValue: 'Mix table', },

        ]

        const tableFiltersData = data.TableFilters
        if (tableFiltersData.RingFilters) {
          tableFiltersData.RingFilters.HoldemFilter = tableFiltersData.RingFilters.HoldemFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.RingFilters.OmahaFilter = tableFiltersData.RingFilters.OmahaFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.RingFilters.OtherGameFilter = tableFiltersData.RingFilters.OtherGameFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
        }

        if (tableFiltersData.SitNGoFilters) {
          tableFiltersData.SitNGoFilters.HoldemFilter = tableFiltersData.SitNGoFilters.HoldemFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.SitNGoFilters.OmahaFilter = tableFiltersData.SitNGoFilters.OmahaFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.SitNGoFilters.OtherGameFilter = tableFiltersData.SitNGoFilters.OtherGameFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
        }

        if (tableFiltersData.SpinNGoFilters) {
          tableFiltersData.SpinNGoFilters.HoldemFilter = tableFiltersData.SpinNGoFilters.HoldemFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.SpinNGoFilters.OmahaFilter = tableFiltersData.SpinNGoFilters.OmahaFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.SpinNGoFilters.OtherGameFilter = tableFiltersData.SpinNGoFilters.OtherGameFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
        }

        if (tableFiltersData.TournamentFilters) {
          tableFiltersData.TournamentFilters.HoldemFilter = tableFiltersData.TournamentFilters.HoldemFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.TournamentFilters.OmahaFilter = tableFiltersData.TournamentFilters.OmahaFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
          tableFiltersData.TournamentFilters.OtherGameFilter = tableFiltersData.TournamentFilters.OtherGameFilter.map(el => {
            el.displayValue = filters.find(filter => filter.realValue === el.Variant)?.displayValue ?? 'Unkonwn'
            return el
          })
        }
        console.log("@tableFiltersData", tableFiltersData)
        this.tableFilters$.next(tableFiltersData)
        break;
      case 'AskQuestion':
        console.log("@ASK QUESTION", data)
        const dialog = this.dialog.open(AskQuestionR2TComponent, {
          data: data.AskQuestion,
          width: '300px',
          //position: { bottom: '100px' }
        });

        dialog.afterClosed()
          .pipe(take(1)).subscribe(result => {
            this.dataManager.r2tAnswerAskQuestion(result.callback, result.idTable, result.value)
          })

        break;

      case 'CallTimeStatus':
        this.callTimeStatus$.next(data.CallTimeStatus.map(el => {

          return el
        }))
        break;

      case 'SkinsInfo':
        this.skinsSettings$.next(data.SkinsInfo.SkinSettings)
        break;

      case 'PlayerLevelStatus':
        const playerLevelStatus = data.PlayerLevelStatus as PlayerLevelStatus;

        this.playerLevelStatus.set(playerLevelStatus.Currency, playerLevelStatus)
        this.playerLevelStatus$.next(this.playerLevelStatus)

        break


      case 'JackPotsInfo':
        this.jackPotsInfo = data.JackPotsInfo
        this.jackPotsInfo$.next(data.JackPotsInfo);
        break;


      case 'GatewaysInfo':
        this.gatewaysInfo$.next({
          deposit: data.GatewaysInfo.DepositResponse ? data.GatewaysInfo.DepositResponse.DepositGateways : null,
          withdraw: data.GatewaysInfo.WithdrawResponse ? data.GatewaysInfo.WithdrawResponse.WithdrawGateways : null
        });
        break;



      case 'AvatarUploadResponse':
        console.log("!data.AvatarUploadResponse.IsApproved", !data.AvatarUploadResponse.IsApproved)
        if (!data.AvatarUploadResponse.IsApproved) {
          const dialogRef = this.dialog.open(GenericTextDialog);


          dialogRef.componentInstance.textHeader = 'Change Avatar';
          dialogRef.componentInstance.textBody = 'Avatar Change awaiting approval';
          dialogRef.componentInstance.textAcceptBtn = 'ok';
          dialogRef.componentInstance.enableClose = false;
        }
        break;

      case 'Settings':
        const settings = data.Settings as Settings;
        try { settings.Banners = JSON.parse(settings.Banners); } catch (e) { }
        try { settings.RakebackLevelStructures = JSON.parse(settings.RakebackLevelStructures); } catch (e) { }
        try { settings.BountySpellDescriptionModel = JSON.parse(settings.BountySpellDescriptionModel); } catch (e) { }

        console.log("mighty bounty @BountySpellDescriptionModel", settings.BountySpellDescriptionModel)
        this.settings$.next(settings)

        break;


      case 'DepositCompleted':
        // this.dialog.closeAll();
        // setTimeout(() => {
        //   this.dialog.open(TransactionDialogComponent);
        // }, 1000);

        // TO DO napraviti kao na yt mali popup notifikacija

        this.dataManager.getTransactionsHistory(this.user.selectedCurrency.Id);
        break;



      case 'TransactionsHistory':
        const transactionsHistory = data.TransactionsHistory;

        for (let i = 0; i < transactionsHistory.length; i++) {
          const tr = transactionsHistory[i];
          const currency = this.currencyInfo.find(el => el.Id === tr.Currency)
          tr.tableAmount = getConvertedAmountText(tr.Amount, currency, this.user.exchangeRate[tr.Currency]);
          const date = new Date(tr.Date);
          tr.tableDate = date.toLocaleDateString() + '<br>' + date.toLocaleTimeString();

          if (tr.Status === 1) {
            tr.tableStatus = 'Confirmed';
          } else if (tr.Status === 2) {
            tr.tableStatus = 'Pending';
          } else if (tr.Status === 3) {
            tr.tableStatus = 'Cancelled';
          } else {
            tr.tableStatus = 'Not Defined';
          }

          switch (tr.Type) {
            case TransactionType.Deposit:
              tr.tableType = 'Deposit';
              break;
            case TransactionType.Withdraw:
              tr.tableType = 'Withdrawal';
              break;
            case TransactionType.Transfer:
              tr.tableType = 'Sent Money';
              break;
            case TransactionType.TransferFrom:
              tr.tableType = 'Received Money';
              break;
            case TransactionType.GiftDepositBonus:
              tr.tableType = 'Gift deposit bonus';
              break;
            case TransactionType.GiftRakeBackBonus:
              tr.tableType = 'RakeBack';
              break;
            case TransactionType.WithdrawCanceled:
              tr.tableType = 'Cancelled of a withdrawal';
              break;
            case TransactionType.IncomePayout:
              tr.tableType = 'Income release';
              break;
            case TransactionType.RabbitHunting:
              tr.tableType = 'Rabbit Hunting';
              break;
            default:
              tr.tableType = tr.Type;
              break;
          }

          tr.tableType = `${tr.tableType} ${tr.ExtraInfo}`;
          tr.tableDeposit = tr.Deposit ? 'Deposit' : 'Withdraw';
          tr.tableCurrency = getCurrencyName(tr.Currency);
        }
        this.user.transactionsHistory = transactionsHistory;
        this.user$.next(this.user)

        break;
      case 'CurrenciesInfo':
        const currenciesInfo = data.CurrenciesInfo as CurrencyInfo[];
        currenciesInfo.map(el => {
          el.icon = getCurrencyIconName(el.Id)
          return el
        })

        this.currencyInfo = currenciesInfo;
        this.currencyInfo$.next(currenciesInfo)

        this.user.selectedCurrency = currenciesInfo[0]
        this.user$.next(this.user)


        // april this.dataManager.getTransactionsHistory(this.user.selectedCurrency.Id);

        break;
      // try {
      //   const temp: Array<CurrencyInfo> = [];

      //   currenciesInfo.forEach(currencyInfo => {
      //     if (!currencyInfo.Id) {
      //       currencyInfo.Id = 0;
      //     }

      //     if (!currencyInfo.NbDecimalVisible) {
      //       currencyInfo.NbDecimalVisible = 0;
      //     }
      //     let availableCurrency = environment.money.availableCurrency.find(ac => ac.currency === currencyInfo.Id);
      //     if (availableCurrency) {
      //       environment.money.availableCurrency.splice(environment.money.availableCurrency.indexOf(availableCurrency), 1);
      //       availableCurrency = {
      //         ...availableCurrency,
      //         ...currencyInfo,
      //         icon: getCurrencyIconName(availableCurrency.currency)
      //       };
      //       temp.push(availableCurrency);
      //     } else {
      //       currencyInfo.currency = currencyInfo.Id;
      //       currencyInfo.deposit = [];
      //       temp.push(currencyInfo);
      //     }
      //   });

      //   environment.money.availableCurrency = temp;
      //   environment.money.defaultCurrency = environment.money.availableCurrency[0].Id;

      //   this.user.selectedCurrency = currenciesInfo[0].Id
      //   this.user$.next(this.user)

      //   //  this.store.dispatch(new AppActions.AuthAction.CurrencyChange(environment.money.defaultCurrency));


      // } catch (ex) { }

      // if (!environment.money.availableCurrency.find(ci => ci.currency === Currency.Tournament)) {
      //   environment.money.availableCurrency.push(tournamentCurrencyInfo);
      // }
      // break;
      case 'MemberProfile':
        if (data.MemberProfile.PlayTimeBankSound === undefined) {
          data.MemberProfile.PlayTimeBankSound = Boolean(localStorage.getItem('top-timebank-sound'));
        }



        this.user.memberProfile = data.MemberProfile
        this.user$.next(this.user)
        //this.store.dispatch(new AppActions.AuthAction.MemberProfileReceived(data.MemberProfile));
        break;


      case 'PlayerLevelStatus':
        //this.store.dispatch(new AppActions.AuthAction.PlayerLevelStatusUpdate(data.PlayerLevelStatus));
        data.PlayerLevelStatus.Levels = new Array(data.PlayerLevelStatus.Level)
        this.user.playerLevelStatus[data.PlayerLevelStatus.Currency] = data.PlayerLevelStatus


        this.user$.next(this.user)

        break;
      case 'Gifts':
        //this.store.dispatch(new AppActions.AuthAction.GiftsUserUpdate(data.Gifts));
        this.user.gifts = data.Gifts
        this.user$.next(this.user)

        this.gifts$.next(data.Gifts);

        break;

      case 'RAFBonuses':

        this.rafBonuses$.next(data.RAFBonuses);

        break;


      case 'CasinosInfo':

        this.casinosInfo = data.CasinosInfo
        this.casinosInfo$.next(this.casinosInfo)

        break;
      case 'MixTablesDetails':


        if (data.MixTablesDetails && data.MixTablesDetails.Tables) {
          for (const item of data.MixTablesDetails.Tables) {
            item.variant = VariantType2[item.Variant] + (item.IsHiLo ? ' Hi/Lo' : '');
            item.limit = Limit[item.Limit];

            item.minStake = item.Blind;
            item.maxStake = item.Blind * 2;
          }
          this.mixTableSubject.next(data.MixTablesDetails);
        }


        break;
      case 'DepositCompleted':
        // this.dialog.closeAll();
        // setTimeout(() => {
        //   this.dialog.open(TransactionDialogComponent);
        // }, 1000);
        this.dataManager.getTransactionsHistory(this.user.selectedCurrency.Id);
        break;
      case 'TableSummaries':
        this.newTableSummaries(data);
        break;

      case 'SitNGoSummaries':
        this.newSitNGoSummaries(data);
        break;

      case 'SpinNGoSummaries':
        this.newSpinNGoSummaries(data);
        break;

      case 'TournamentsSummaries':
        this.newTournamentsSummaries(data);
        break;

      case 'TournamentInformation':

        // MARCH CURRENCY COMMENTED

        // this.updateTourInfoWithCurrency(data.TournamentInformation as TournamentInformation, Currency.Tournament);

        this.processTourInfoMessage(data.TournamentInformation as TournamentInformation, this.user.memberProfile.Id);

        this.tournamentInformationSubject.next(data.TournamentInformation.IdTournament);



        break;

      case 'TableSummary':
        const tableSum = data.TableSummary as TableSummary;
        this.updateTableSummary(tableSum);
        this.gamestartFromTableSummeries(tableSum);
        break;

      case 'TournamentSummary':

        const tournamentSummary = data.TournamentSummary as TournamentSummary;

        this.updateTournamentSummary(tournamentSummary);


        // if (tournamentSummary.Status2 === TournamentStatus2.RunningRegistrationClosed) {
        //   this.gameStartFromTournamentStart(tournamentSummary.IdTournament, tournamentSummary.Id, tournamentSummary);
        //   return
        // }


        const index = this.tournamentsSummaries.findIndex(el => el.Id === tournamentSummary.Id)


        if (index > -1) {

          this.tournamentsSummaries[index] = tournamentSummary
        } else {

          this.tournamentsSummaries.push(tournamentSummary);
        }
        this.startTournamentCountDown();

        // this.setActiveTab(ENavItem.Tournament)

        // this.tournamentView(tournamentSummary);
        /*if (this.regTours[tournamentSummary.Id]
          && this.regTours[tournamentSummary.Id].myActiveTable
          && this.regTours[tournamentSummary.Id].isRegistered) {
          const myActiveTable = this.regTours[tournamentSummary.Id].myActiveTable;
          if (!this.activeGames.some(activeTable => activeTable.table.IdTable === myActiveTable)) {
            this.gameStartFromTournamentStart(myActiveTable, tournamentSummary.Id, tournamentSummary);
          }
        }*/
        // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Tournament)); // DEC comment ovde je init load problem


        this.dataManager.sendMTTView(tournamentSummary.Id, true); // password

        break;

      case 'LiveLobbyRing':

        this.updateTableSummaries(data.LiveLobbyRing);

        this.refreshTableSummeriesData([...this.tableSummaries]);
        break;

      case 'LiveLobbyRingSelected':

        break;

      case 'LiveLobbyTournament':
        this.updateTournamentsSummaries(data.LiveLobbyTournament as LiveLobbyTournament);

        break;

      case 'HandReplayData':
        this.handReplay$.next(data.HandReplayData);
        break;

      case 'HandHistory':
        this.handHistory$.next(data.HandHistory);
        break;

      case 'Tickets':

        const tickets: Ticket[] = [];
        data.Tickets.forEach(t => {
          const currency = this.currencyInfo.find(el => el.Id === t.Currency)

          tickets.push({
            ...t,
            Name: t.Name,
            Value: CurrencyDeviderPipe.prototype.transform(t.Value, currency),
            Currency: this.currencyInfo.find(el => el.Id === t.Currency).Name as any,
            Qty: t.Qty,
            Id: t.Id,
            _currency: this.currencyInfo.find(el => el.Id === t.Currency)
          });
        });
        this.playerTickets$.next(tickets);

        this.tickets$.next(tickets);

        break;

      case 'BlindInformation':
        const blinds = data.BlindInformation.Blinds;
        const idTournament = data.BlindInformation.IdTournament;

        // if (this.viewTournament.tourSum.IdTournament === data.BlindInformation.IdTournamen) {
        //   this.viewTournament.blindLevels = data.BlindInformation.Blinds
        //   this.viewTournament$.next(this.viewTournament)
        // }


        this.tournaments[idTournament].blindLevels = blinds;



        //  this.store.dispatch(new AppActions.TournamentsActions.UpdateTournamentBlindList(idTournament, blinds));
        break;

      case 'GiftUnlocked':
        this.notificationsService.showSuccess('Successfully unlocked!');
        break;

      case 'EmoticonsInfo':
        this.emoticonsInfo = data.EmoticonsInfo
        this.emoticonsInfo$.next(this.emoticonsInfo)
        break
      case 'JackPotsUpdateInfo':

        for (const jackPotInfo of this.jackPotsInfo) {
          for (const item of data.JackPotsUpdateInfo) {
            if (jackPotInfo.Id === item.Id) {
              jackPotInfo.PrizePool = item.PrizePool
            }
          }
        }

        this.jackPotsInfo$.next(this.jackPotsInfo);

        break;

      case 'ServerMsg':

        Array.from(data.ServerMsg).forEach((item: ServerMsg) => {

          if (item.ErrorCode) {
            this.translateAndShowErrorNotification(item.Text, item.ErrorCode);
          }

          switch (item.MsgType) {
            case ServerMessage.NoSpellZone:
              this.tournaments[item.IdTournament].spellZone = {
                activePlayers: item.Value,
                minActivePlayersRequired: item.Value2
              }

              // ****

              this.spellZones[item.IdTournament] = {
                activePlayers: item.Value,
                minActivePlayersRequired: item.Value2
              }

              this.spellZones$.next(this.spellZones)


              break;

            case ServerMessage.RequestWithdrawalResponse:

              if (item.ErrorCode === undefined && item.Text2 && item.Value2 === 1) { //
                if (this.config.brand === 'lp-qxmp' || this.config.brand === 'magicbeanz') {
                  this.setActiveTab(ENavItem.Deposit);
                  this.depositInitiateResponseUrl$.next(item.Text2);
                } else {
                  this.askQuestionDialogRef = this.dialog.open(WithdrawalRedirectDialogComponent, {
                    data: { redirectUrl: item.Text2 },
                    width: '300px',
                  })
                }
              }
              break;

            case ServerMessage.DepositInitiateResponse:
              console.log("--", this.config)
              if (item.ErrorCode === undefined) {
                if (this.config.brand === 'lp-qxmp' || this.config.brand === 'magicbeanz') {
                  this.askQuestionDialogRef = this.dialog.open(DepositRedirectDialogComponent, {
                    data: { redirectUrl: item.Text },
                    width: '300px',
                  })
                } else {
                  this.setActiveTab(ENavItem.Deposit);
                  this.depositInitiateResponseUrl$.next(item.Text);
                }
              }
              break;

            case ServerMessage.NewGameLayoutResponse:

              // Value is a new layout Value2 is 1 for success, 0 for fail.

              if (item.Value2 === 1) {
                this.gameLayout[item.IdTable].active = item.Value
              }

              break


            case ServerMessage.ShopSettingsResponse:

              const shopSettings = {
                shopCurrency: item.Values[0] === 1,
                emotikens: item.Values[1] === 1,
                emojis: item.Values[2] === 1,
                tickets: item.Values[3] === 1,
                currencyPackages: item.Values[4] === 1,
                shopGoods: item.Values[5] === 1
              }
              this.shopSettings$.next(shopSettings)

              break

            case ServerMessage.HandStart:

              this.breakpointObserver
                .observe([
                  Breakpoints.WebPortrait,
                  Breakpoints.WebLandscape,

                  Breakpoints.TabletPortrait,
                  Breakpoints.TabletLandscape,

                  Breakpoints.HandsetPortrait,
                  Breakpoints.HandsetLandscape,


                  Breakpoints.Web,
                  '(orientation: portrait)',
                  '(orientation: landscape)',
                  // ***


                ])
                .pipe(take(1))
                .subscribe(result => {
                  console.log("RESULT ++", result, result.breakpoints['(orientation: portrait)'], result.breakpoints['(orientation: landscape)'],)
                  const data = {

                    web: result.breakpoints[Breakpoints.Web],
                    webPortrait: result.breakpoints[Breakpoints.WebPortrait],
                    webLandscape: result.breakpoints[Breakpoints.WebLandscape],

                    tabletPortrait: result.breakpoints[Breakpoints.TabletPortrait],
                    tabletLandscape: result.breakpoints[Breakpoints.TabletLandscape],

                    handsetPortrait: result.breakpoints[Breakpoints.HandsetPortrait],
                    handsetLandscape: result.breakpoints[Breakpoints.HandsetLandscape],



                  }



                  let active = GameLayoutTypes.Unknown;
                  if (data.webPortrait || data.webLandscape || data.web) {
                    active = GameLayoutTypes.Desktop;
                  } else if (data.tabletPortrait || data.handsetPortrait) {
                    active = GameLayoutTypes.Portrait;
                  } else if (data.tabletLandscape || data.handsetLandscape) {
                    active = GameLayoutTypes.Landscape;
                  } else if (result.breakpoints['(orientation: portrait)']) {
                    active = GameLayoutTypes.Portrait;
                  } else if (result.breakpoints['(orientation: landscape)']) {
                    active = GameLayoutTypes.Landscape;
                  }

                  console.log("active ++ ", active)
                  if (!this.gameLayout[item.IdTable] || this.gameLayout[item.IdTable].active !== active) {
                    this.gameLayout[item.IdTable] = data
                    this.dataManager.sendGameLayout(item.IdTable, active)
                  }
                })

              break


            case ServerMessage.EmotikensPackages:

              const emotikens = item.EmoticensPackages.map(item => {
                item.currency = this.currencyInfo.find(el => el.Id === item.Currency);
                return item
              })

              const emotikensCurrency = this.currencyInfo.find(el => el.Id === 255)

              this.emotikensPackages = { currency: emotikensCurrency, data: emotikens }
              this.emotikensPackages$.next(this.emotikensPackages)
              break

            case ServerMessage.GetTicketsResponse:

              const tickets = item.Tickets.map(item => {
                item.currency = this.currencyInfo.find(el => el.Id === item.Currency);
                item.emotikenCurrency = this.currencyInfo.find(el => el.Id === item.EmotikenCurrency);

                return item
              })

              const ticketsCurrency = this.currencyInfo.find(el => el.Id === 255)
              this.ticketsPackages$.next({ currency: ticketsCurrency, data: tickets })
              break

            case ServerMessage.CurrencyPackages:

              const currencyPackages = item.CurrencyPackages.map(item => {
                item.currency = this.currencyInfo.find(el => el.Id === item.Currency);
                item.currencyToBuy = this.currencyInfo.find(el => el.Id === item.CurrencyToBuy);

                return item
              }).filter(el => !!el.currencyToBuy)

              const CurrencyPackageCurrency = this.currencyInfo.find(el => el.Id === 255)
              this.currencyPackages$.next({ currency: CurrencyPackageCurrency, data: currencyPackages })
              break


            case ServerMessage.OfferExternalAlingment:
              this.showPlayerPlus$.next(item.Value === 1)
              break

            case ServerMessage.PlayerAlignmentInitiateResponse:
              window.open(item.Text, "_blank");
              break

            // ## Ask Question
            case ServerMessage.AskQueston:


              if (this.askQuestionDialogRef) { return };

              this.askQuestionDialogRef = this.dialog.open(AskQuestionDialogComponent, {
                data: {
                  text: item.Text,
                  requestId: item.Value
                },
                width: '300px',
                disableClose: true,
                backdropClass: 'backdropAskQuestionDialog'
              })

              this.askQuestionDialogRef
                .afterClosed()
                .pipe(
                  finalize(() => this.askQuestionDialogRef = undefined)
                ).subscribe(logout => {
                  if (logout) {
                    //  this.logout(true);
                  }
                });



              break

            case ServerMessage.AccountSubmitGovermentIdResponse:
              if (this.askQuestionDialogRef) {
                this.reloadLobby$.next()
                this.notificationsService.showSuccess(`Submitted succesfully`)
                this.askQuestionDialogRef.close()
              };

              break

            // ## AUTH LOGIN ##
            case ServerMessage.ConnectResponse:
              this.loginState$.next(!item.ErrorCode)
              break;


            // ## AUTH FORGOT PASSWORD SECURITY CODE ##
            case ServerMessage.AccountRequestSecurityCodeResponse:
              this.accountRequestSecurityCodeState$.next(!item.ErrorCode)
              break;




            case ServerMessage.ExchangeRate:
              // this.store.dispatch(new AppActions.AuthAction.ExchangeRateUserUpdate(
              //     { currency: item.Value, value: item.Value2 })
              // );

              let currency = item.Value;

              if (item.Value === undefined) { // if currency is undefined
                currency = 0;
              }
              if (item.Value2 === undefined) {
                currency = 0;
              }

              this.user.exchangeRate[currency] = item.Value2;
              this.user$.next(this.user)

              break;

            case ServerMessage.Error:

              switch (item.Value) {
                case ErrorCode.LoginConnectionFromAnotherDevice:
                  this.logout(true)
                  break;

                default:
                  break;
              }

              break

            case ServerMessage.RequestWithdrawalResponse:
              // sad je po default pa ne treba vise
              // if (item.ErrorCode) {
              //   this.notificationsService.showError(item.Text);
              // }
              break;


            case ServerMessage.ImageUploadResponse:
              if (item.Value < 0) {
                this.notificationsService.showError(item.Text);
              } else {
                this.notificationsService.showSuccess(`Documents uploaded successfuly`);
              }

              break;

            case ServerMessage.StatsPlayerOnline:
              let data = {
                online: item.Value,
                ringTable: item.Values[0],
                tournament: item.Values[1],
              }
              this.lobbyStatsPlayer$.next(data);


              break;

            case ServerMessage.TransferMoneyResponse:
              if (item.ErrorCode) {
                this.notificationsService.showError(item.Text);
              } else {
                this.notificationsService.showSuccess('Money successfully transferred');
                this.dataManager.getTransactionsHistory(this.user.selectedCurrency.Id)
              }
              break;

            case ServerMessage.EmoticonUnlockResponse:

              this.user.playerBalance[255].availableMoney = item.Value
              this.user$.next(this.user)

              this.emoticonsInfo = this.emoticonsInfo.map(el => {

                if (el.Id === item.Value2) {
                  el.IsLocked = 0
                }
                return el
              })
              this.emoticonsInfo$.next(this.emoticonsInfo)

              break

            case ServerMessage.BuyTicketResponse:
              this.dataManager.getTickets(this.user.selectedCurrency.Id)

              break
            case ServerMessage.EmotikensBuyPackageReponse:
              this.user.playerBalance[255].availableMoney = item.Value
              this.user$.next(this.user)

              break
            case ServerMessage.PlayerBalance:

              //    this.store.dispatch(new AppActions.AuthAction.PlayerBalanceUserUpdate(item));

              let playerBalance = []

              for (const index in this.user.playerBalance) {
                if (this.user.playerBalance[index]) { playerBalance[index] = this.user.playerBalance[index] }
              }

              if (item.Value === undefined) { // if currency is undefined
                item.Value = 0;
              }
              if (item.Value2 === undefined) { // if currency is undefined
                item.Value2 = 0;
              }
              if (playerBalance === undefined) {
                playerBalance = [];
              }
              if (playerBalance === undefined || playerBalance[item.Value2] === undefined) {
                playerBalance[item.Value2] = <PlayerBalance>{};
              }
              switch (item.Values[0]) {
                case 1:
                  playerBalance[item.Value2].availableMoney = item.Value;
                  break;
                case AccountType.CashOnTable:
                  playerBalance[item.Value2].onTable = item.Value;
                  break;
                case AccountType.CashToken:
                  playerBalance[item.Value2].cashToken = item.Value;
                  break;
                case AccountType.PointsToUnlock:
                  playerBalance[item.Value2].points = item.Value;
                  break;
              }

              this.user.playerBalance = playerBalance

              this.user$.next(this.user)

              break;

            case (ServerMessage.GameStart):
              // this.gameStartFromTournamentStart(item.IdTable, item.IdTournament, this.regTours[item.IdTournament].tourSum);

              break;

            case (ServerMessage.GamePause):

              break;

            case (ServerMessage.GameEnd):
              this.gameTourEnd(item.IdTournament);
              break;

            case (ServerMessage.TournamentCanceled):
              break;

            case (ServerMessage.TournamentEndResult):
              this.tournamentEndResult(item as TournamentEndResult);
              break;

            case (ServerMessage.MTTBlindChanged):
              break;

            case (ServerMessage.AskPlayerReEntry):
              this.askPlayerReEntry(item.IdTournament);
              break;

            case (ServerMessage.MTTTableAssigned):
              /*
               Ovo je promena table na turniru ili igraca
  
              */

              this.checkForTableReassignment(item as MTTTableAssigned, this.user.memberProfile.Id);
              break;

            case (ServerMessage.MTTStarting):
              //  ToDo:
              //  "IdTournament": 183050,
              //  "MsgType": 1070,
              //  "Value2": 1480883430
              break;

            case (ServerMessage.PlayerPlayStatistic):
              //  ToDo: do something with player statistic
              break;

            case (ServerMessage.Info):
              this.updateTableWithInfo(item.IdTable, item.Info);
              break;

            case (ServerMessage.MessageInfo):
              // # admin broadcast message

              const dialog = this.dialog.open(BroadcastDialogComponent, {
                data: {
                  text: item.Text,
                  tournamentId: item.Value2,
                  time: item.Value * 1000
                },
                width: '300px'
              });

              dialog.afterClosed().subscribe(res => {
                if (res === 'register') {
                  this.addTournament({
                    tourSum: {} as TournamentSummary,
                    tourInfo: {} as TournamentInformation,
                    isRegistered: true,
                    activeTables: [],
                    myActiveTable: undefined,
                    myPosition: undefined,
                    blindLevels: [],
                  }, item.Value2
                  )

                  // this.dataManager.getTournamentSummary(settings.PopupIdTournament);
                  this.setActiveTab(ENavItem.Tournament)
                  this.tournamentView({ Id: item.Value2 } as TournamentSummary);

                }
              })



              if (item.Value > 0) { //  (-1 for infinit)
                setTimeout(() => {
                  dialog.close();
                }, item.Value * 1000);
              }

              break;

            case (ServerMessage.GetAuthToken):

              this.casinoTokenReceivedSubject.next({ token: item.Text, operatorid: item.Text2 });
              break;


            case ServerMessage.RequestPhoneCodeResponse:
              if (item.Value < 0) {
                this.notificationsService.showError(item.Text);
              } else {
                this.notificationsService.showSuccess('SMS send successfully.');
                // this.storeLoginSeason('sms');
              }
              break;
            case ServerMessage.RequestSelfExclusionResponse:
              if (item.ErrorCode == ErrorCode.PasswordMismatch) {
                this.notificationsService.showError('PasswordMismatch');
              } else if (!item.ErrorCode) {

                this.logout(true)

                this.notificationsService.showSuccess('Self excluded successfully.');
              }
              break;


          }
        });
        break;
    }
  }

  private askPlayerReEntry(IdTournament: number) {
    if (this.tournamentEndResultDialogRef) {
      this.tournamentEndResultDialogRef.close()
    }

    const dialogRef = this.dialog.open(GenericTextDialog, {
      disableClose: true
    });

    this.translateService.get(['TEXT.YES', 'reEnterTheTournament', 'doYouWantToReEnterTheTournament']).subscribe(trans => {
      dialogRef.componentInstance.textAcceptBtn = trans['TEXT.YES'];
      dialogRef.componentInstance.textHeader = trans['reEnterTheTournament'];
      dialogRef.componentInstance.textBody = trans['doYouWantToReEnterTheTournament'];
    });

    dialogRef.componentInstance.enableClose = true;
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm !== undefined) {
        if (this.tournamentEndResultDialogRef) {
          this.tournamentEndResultDialogRef.close()
        }
        this.tournamentRegister(IdTournament);
      } else {
        if (this.tournamentEndResultData) {
          this.tournamentEndResult(this.tournamentEndResultData);
        }
      }
    });
  }

  private newTableSummaries(data) {
    (data.TableSummaries as TableSummary[]).forEach((item, index) => {
      this.updateTableSummary(item);
    });
    this.tableSummaries = [];
    this.tableSummaries = data.TableSummaries;

    this.refreshTableSummeriesData(this.tableSummaries);
  }

  private updateTableSummary(item) {

    item.PlayerCount = item.PlayerCount ? item.PlayerCount : 0;
    item.tableSpeed = item.IsFast ? 'LABEL.FAST' : 'LABEL.NORMAL';
    item.tableVariant = VariantType2[item.Variant2] + (item.IsHiLo ? ' Hi/Lo' : '');
    if (item.Variant2 === VariantType2.SixPlusHoldem) {
      item.tableVariant = `6+ Hold'em` + (item.IsHiLo ? ' Hi/Lo' : '');
    }
    item.tablePlayers = item.PlayerCount + ' / ' + item.MaxPlayers;
    item.tableWait = item.WaitingList ? item.WaitingList : 0;

    item.isMixTable = item.Variant2 === VariantType2.MixTable

    item.circle = {
      maxPlayers: new Array(item.MaxPlayers),
      skew: 90 - (360 / item.MaxPlayers),
      transform: {
        fix: 90 - (360 / item.MaxPlayers),
        each: (360 / item.MaxPlayers)
      },
      styleTransforms: []
    }

    let index = 0
    for (const el of item.circle.maxPlayers) {
      item.circle.styleTransforms.push(this.sanitizer.bypassSecurityTrustStyle(`transform:rotate(${(item.circle.transform.fix + (index + 1) * item.circle.transform.each)}deg) skew(${item.circle.skew}deg)`))
      index++
    }





    if (item.Limit === Limit.FL) {
      item.minStake = item.Blind * 2;
      item.maxStake = item.Blind * 4;
    } else {
      item.minStake = item.Blind;
      item.maxStake = item.Blind * 2;
    }
    item.tableStakes =
      CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.minStake, this.user.selectedCurrency),
        this.user.selectedCurrency).replace(/ /g, '')
      + ' / ' +
      CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.maxStake, this.user.selectedCurrency),
        this.user.selectedCurrency).replace(/ /g, '');

    item.tableCryptoStakes =
      CurrencyDeviderPipe.prototype.transform(item.minStake, this.user.selectedCurrency)
      + '/' +
      CurrencyDeviderPipe.prototype.transform(item.maxStake, this.user.selectedCurrency);

    item.tableCryptoStakesExchanged =
      CurrencyCryptoConverterPipe.prototype.transform(
        item.minStake, this.user.exchangeRate[this.user.selectedCurrency.Id]
      )
      + '/' +
      CurrencyCryptoConverterPipe.prototype.transform(
        item.maxStake, this.user.exchangeRate[this.user.selectedCurrency.Id]
      );

    item.tableLimit = Limit[item.Limit];


    // OLD
    // item.tableLegends = [];
    // item.tableLegends.push({ ...Legends['X'], label: item.MaxPlayers });
    // item.tableLegends.push(
    //   item.IsFast ? Legends['H'] : Legends['N']);

    // Legends
    item.tableLegends = [];
    //if (item.canReEntry) { item.tableLegends.push(Legends.RE_ENTRY) }
    if (item.IsFast) { item.tableLegends.push(Legends.FAST) }
    //if (item.rebuyEnabled) { item.tableLegends.push(Legends.RE_ENTRY) }
    if (item.IsStraddle) { item.tableLegends.push(Legends.STRADDLE) }
    if (item.HasPassword) { item.tableLegends.push(Legends.PRIVATE) }
    //if (item.ISBountyTournament) { item.tableLegends.push(Legends.FAST) }
    if (item.Ante > 0) { item.tableLegends.push(Legends.ANTE) }
    //if (item.BountyRing > 0) { item.tableLegends.push(Legends.FAST) }

    if (item.IsRabbitHunting) { item.tableLegends.push(Legends.RABBIT_HUNTING) }

    if (item.OptionDisconnectionProtection) { item.tableLegends.push(Legends.DISCONNECTION_PROTECTION) }


    item.currencyInfo = this.currencyInfo.find(el => el.Id === item.Currency)


    if (item.JackPotIds && item.JackPotIds.length > 0) {
      item.jackpot = this.jackPotsInfo.find(el => el.Id == item.JackPotIds[0])
      // item.jackpot.prizePool = CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.jackpot.PrizePool, this.user.selectedCurrency), this.user.selectedCurrency)

      item.tableLegends.push(Legends.JACKPPOT)
    }


    if (item.CallTimeConfiguration?.IsCallTime) { item.tableLegends.push(Legends.CALL_TIME) }

  }

  private updateTableWithInfo(tableId: number, info: any) {
    const table = this.tableSummaries.find(tableSum => tableSum.Id === tableId);
    if (table) {
      table.Info = info;
      this.refreshTableSummeriesData(this.tableSummaries);
    }
  }

  private updateTableSummaries(data) {
    this.tableSummaries.forEach(item => {
      if (item.Id === data.IdTable) {
        item.AveragePot = data.AveragePot;
        item.PlayerCount = data.NbPlayers;
        item.AveragePot = data.AveragePot;
        item.PlayerFlop = data.PlayerFlop;
        item.HandPerHour = data.HandPerHour;
        item.HandPerHour = data.HandPerHour;
        item.WaitingList = data.WaitingList;
        item.Removed = data.Removed;
        item.tablePlayers = data.NbPlayers + ' / ' + item.MaxPlayers;
      }
    });
  }

  private refreshTableSummeriesData(data: TableSummary[]) {

    data = data.map(el => {
      el.currencyInfo = this.currencyInfo.find(currencyInfo => currencyInfo.Id === el.Currency)
      return el
    })
    this.tableSummaries$.next(this.showEmptyTables ? data : data.filter(item => item.PlayerCount > 0))

    if (this.showEmptyTables) {
      this.tableSumsSubject.next(data);
      this.lobbyListSubject.next(data)
    } else {
      this.tableSumsSubject.next(data.filter(item => item.PlayerCount > 0));
      this.lobbyListSubject.next(data.filter(item => item.PlayerCount > 0));
    }
  }

  private refreshTournamentSummeriesData(data: TournamentSummary[]) {
    if (this.lobbyFilter.filters.TableType === LobbyFilters.TypeTournament) {
      this.lobbyListSubject.next(this.showEmptyTables ? data : data.filter(item => item.NbPlayerRegistered > 0));
    }
  }

  private refreshSnGSummeriesData(data: TournamentSummary[]) {
    if (this.showEmptyTables) {
      this.lobbyListSubject.next(data);
    } else {
      this.lobbyListSubject.next(data.filter(item => item.NbPlayerRegistered > 0));
    }
  }

  private refreshSpinnGSummeriesData(data: TournamentSummary[]) {
    if (this.showEmptyTables) {
      this.lobbyListSubject.next(data);
    } else {
      this.lobbyListSubject.next(data.filter(item => item.NbPlayerRegistered > 0));
    }
  }

  private newTournamentsSummaries(data) {
    (data.TournamentsSummaries as TournamentSummary[]).forEach(item => {
      item.isTourney = true
      this.updateTournamentSummary(item);
    });
    this.tournamentsSummaries = [];
    this.tournamentsSummaries.push(...data.TournamentsSummaries);

    this.refreshTournamentSummeriesData(this.tournamentsSummaries);
  }

  private newSitNGoSummaries(data) {
    (data.SitNGoSummaries as SitNGoSummary[]).forEach(item => {
      item.isSitNGo = true
      this.updateTournamentSummary(item);
    });


    this.tournamentsSummaries = [];
    this.tournamentsSummaries.push(...data.SitNGoSummaries);

    this.refreshSnGSummeriesData(this.tournamentsSummaries);
  }

  private newSpinNGoSummaries(data) {
    (data.SpinNGoSummaries as SpinNGoSummary[]).forEach(item => {
      item.isSpinNGo = true
      this.updateTournamentSummary(item);
    });

    this.tournamentsSummaries = [];
    this.tournamentsSummaries.push(...data.SpinNGoSummaries);

    this.refreshSpinnGSummeriesData(this.tournamentsSummaries);
  }

  private updateTournamentSummary(item: TournamentSummary | SitNGoSummary) {

    item.tableVariant = VariantType2[item.Variant2];
    if (item.Variant2 === VariantType2.SixPlusHoldem) {
      item.tableVariant = `6+ Hold'em`
    }
    item.tableSpeed = item.IsFast ? 'LABEL.FAST' : 'LABEL.NORMAL';

    if (item.OptionNoFeesOnRebuyAndAddOn) {
      item.tableRebuyCosts =
        CurrencyPipe.prototype.transform(
          CurrencyDeviderPipe.prototype.transform(item.RebuyFee, this.user.selectedCurrency),
          this.user.selectedCurrency)
          .replace(/ /g, '');
    } else {
      const rebuyCosts = item.RebuyFee / (1 + item.HouseFeePercentage);

      item.tableRebuyCosts =
        CurrencyPipe.prototype.transform(
          CurrencyDeviderPipe.prototype.transform(rebuyCosts, this.user.selectedCurrency),
          this.user.selectedCurrency)
          .replace(/ /g, '')
        + ' + ' +
        CurrencyPipe.prototype.transform(
          CurrencyDeviderPipe.prototype.transform(
            (rebuyCosts * item.HouseFeePercentage), this.user.selectedCurrency), this.user.selectedCurrency)
          .replace(/ /g, '');
    }

    if (item.Freeroll) {
      item.tableBuyIn = 'Freeroll';
    } else if (item.InitialBuyFree) {
      item.tableBuyIn = '1st Buy In Free';
    } else if (item.OptionOnlyTicket) {
      item.tableBuyIn = 'Ticket';
    } else {
      item.tableBuyIn =
        CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.EntryFee, this.user.selectedCurrency),
          this.user.selectedCurrency).replace(/ /g, '')
        + ' + ' +
        CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(
          (item.EntryFee * item.HouseFeePercentage), this.user.selectedCurrency),
          this.user.selectedCurrency).replace(/ /g, '');
    }


    if (item.StartIn) {
      item.tableDateStarting = format(addSeconds(new Date(), item.StartIn), 'MMM dd @ h:mm a');
      item.tableDateDayStarting = format(addSeconds(new Date(), item.StartIn), 'MMM, dd');
    } else {
      console.log("ITEM item.StartIn &&&", item.StartIn, item)

    }


    item.tableLimit = Limit[item.Limit];
    item.tableAddOnSummary = `${item.AddOnNbLimit}`;
    item.tableRebuySummary = `${item.ReBuyNbLimit}`;
    if (item.Blind === undefined) {
      item.Blind = 0;
    }
    if (item.PrizeGuaranteed === undefined) {
      item.PrizeGuaranteed = 0;
    }
    if (item.NbPlayerRegistered === undefined) {
      item.NbPlayerRegistered = 0;
    }

    this.setTournamentPlayers(item);

    item.tableStatus = TournamentStatusTextAdjusted[item.Status2];

  }

  private setTournamentPlayers(item) {
    switch (item.Status2) {
      case TournamentStatus2.Announced:
        item.tablePlayers = `min: ${item.MinPlayers} / max: ${item.MaxPlayers}`;
        break;
      case TournamentStatus2.RegistrationOpen:
        item.tablePlayers = `${item.NbPlayersActive} / ${item.MaxPlayers}`;
        break;
      case TournamentStatus2.RunningWithLateRegistration:
        item.tablePlayers = `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
        break;
      case TournamentStatus2.RunningRegistrationClosed:
        item.tablePlayers = `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
        break;
      case TournamentStatus2.Completed:
        item.tablePlayers = `${item.NbPlayerRegistered}`;
        break;
      case TournamentStatus2.Canceled:
        item.tablePlayers = `${item.NbPlayerRegistered}`;
        break;
      default:
        item.tablePlayers = `${item.NbPlayersActive} / ${item.NbPlayerRegistered}`;
        break;
    }
  }

  updateTournamentsSummariesImregistered(idTournament: number, status: boolean) {
    const tournament = this.tournamentsSummaries.find(el => el.Id === idTournament)
    if (tournament) {
      tournament.ImRegistered = status
      console.log("*111 4 HERE", JSON.parse(JSON.stringify(this.tournamentsSummaries)))

      this.refreshTournamentSummeriesData(this.tournamentsSummaries);
    }


  }

  getTournament(tournamentId: number) {
    return this.tournaments[tournamentId]
  }
  addTournament(data: Tournament, tournamentId: number) {
    this.tournaments[tournamentId] = data;
  }

  updateTournamentsSummaries(data: LiveLobbyTournament) {
    this.tournamentsSummaries.forEach(tour => {
      if (tour.Id === data.IdTournament) {
        tour.NbPlayersActive = data.NbPlayersActive;
        // tour.tablePlayers = data.NbPlayersRegistered + ' / ' + tour.MaxPlayers;
        tour.NbPlayerRegistered = data.NbPlayersRegistered;
        tour.Status2 = data.Status2;
        tour.StartIn = data.StartIn;
        tour.RegistrationsStartIn = data.RegistrationsStartIn;
        tour.RegistrationEndIn = data.RegistrationEndIn;
        tour.BlindIncreaseIn = data.BlindIncreaseIn;
        tour.BreakIn = data.BreakIn;
        tour.BreakEndIn = data.BreakEndIn;
        tour.EndedSince = data.EndedSince;
        tour.ReBuyEndIn = data.ReBuyEndIn;

        if (tour.Limit === Limit.FL) {
          tour.minStake = data.Blind * 2;
          tour.maxStake = data.Blind * 4;
        } else {
          tour.minStake = data.Blind;
          tour.maxStake = data.Blind * 2;
        }


        // MARCH CHANGE CURRENCY CHECK THIS

        // tour.tableCryptoStakes =
        //   CurrencyDeviderPipe.prototype.transform(tour.minStake, Currency.Tournament)
        //   + '/' +
        //   CurrencyDeviderPipe.prototype.transform(tour.maxStake, Currency.Tournament);

        this.setTournamentPlayers(tour);
      }
    });



    const tournamentId = data.IdTournament
    if (this.tournaments[tournamentId] && this.tournaments[tournamentId].tourSum) {
      this.tournaments[tournamentId].tourSum.NbPlayersActive = data.NbPlayersActive;
      this.tournaments[tournamentId].tourSum.NbPlayersActive = data.NbPlayersActive;
      this.tournaments[tournamentId].tourSum.NbPlayerRegistered = data.NbPlayersRegistered;
      this.tournaments[tournamentId].tourSum.Status2 = data.Status2;
      this.tournaments[tournamentId].tourSum.StartIn = data.StartIn;
      this.tournaments[tournamentId].tourSum.RegistrationsStartIn = data.RegistrationsStartIn;
      this.tournaments[tournamentId].tourSum.RegistrationEndIn = data.RegistrationEndIn;
      this.tournaments[tournamentId].tourSum.BlindIncreaseIn = data.BlindIncreaseIn;
      this.tournaments[tournamentId].tourSum.BreakIn = data.BreakIn;
      this.tournaments[tournamentId].tourSum.BreakEndIn = data.BreakEndIn;
      this.tournaments[tournamentId].tourSum.EndedSince = data.EndedSince;
      this.tournaments[tournamentId].tourSum.ReBuyEndIn = data.ReBuyEndIn;

    }


    this.refreshTournamentSummeriesData(this.tournamentsSummaries);

    // this.store.dispatch(new AppActions.TournamentsActions.UpdateTournamentSummary(data));
  }


  startTournamentCountDown() {

    if (this.clockSubscription) { this.clockSubscription.unsubscribe() }
    this.clockSubscription = this.clockService.getClock().subscribe(() => {

      this.tournamentsSummaries.forEach(tourSum => {
        TournamentHelper.populateScheduleData(tourSum);
        --tourSum.StartIn;
        --tourSum.RegistrationsStartIn;

        this.refreshTournamentSummeriesData([...this.tournamentsSummaries]);
      });
    });

  }

  stopTournamentCountDown() {

    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe()
    }
  }

  private tournamentEndResult(tournamentEndResult: TournamentEndResult) {
    this.tournamentEndResultData = tournamentEndResult;

    if (tournamentEndResult.IdPlayer === this.user.memberProfile.Id) {
      tournamentEndResult.TournamentSummary = this.tournamentsSummaries.find(el => el.Id === tournamentEndResult.IdTournament)
      this.tournamentEndResultDialogRef = this.dialog.open(DialogTournamentEndResultComponent, { width: '300px', data: tournamentEndResult });


      this.gameTourEnd(tournamentEndResult.IdTournament);
    }
  }

  private updateTourInfoWithCurrency(tourInfo: TournamentInformation, selectedCurrency: CurrencyInfo) {
    if (tourInfo.Prizes !== undefined) {
      for (const prize of tourInfo.Prizes) {
        prize.stringAmount = CurrencyPipe.prototype.transform(
          CurrencyDeviderPipe.prototype.transform(prize.Amount, selectedCurrency), selectedCurrency);
      }
    }
  }

  private processTourInfoMessage(tourInfo: TournamentInformation, memberId: number) {

    let tournament: Tournament;
    if (this.tournaments[tourInfo.IdTournament]) {
      tournament = this.tournaments[tourInfo.IdTournament]


    } else {
      tournament = {} as Tournament;
      tournament.tourInfo = tourInfo;

      if (!tournament.tourInfo.Players) {
        tournament.tourInfo.Players = [];
      }

      if (!tournament.tourInfo.Prizes) {
        tournament.tourInfo.Prizes = [];
      }

      if (!tournament.tourInfo.Prizes2) {
        tournament.tourInfo.Prizes2 = [];
      }

      tournament.activeTables = [];
      tournament.isRegistered = false;

    }
    tournament.tourSum = this.tournamentsSummaries.find(tourSummary => tourSummary.Id === tourInfo.IdTournament);



    // if (!tournament.tourInfo) {
    //   tournament.tourInfo = tourInfo
    // }

    // if (!tournament.tourInfo.Players) {
    //   tournament.tourInfo.Players = [];
    // }

    // if (!tournament.tourInfo.Prizes) {
    //   tournament.tourInfo.Prizes = [];
    // }

    // 📚 players salje samo nove stvari



    tournament.myActiveTable = null;
    // if this is true restart player list

    if (tourInfo.NbPlayerRegistered === tourInfo.Players.length) {
      tournament.tourInfo.Players = tourInfo.Players;
      // sometimes server doesn't send Prizes so check is needed
      // from doc: "If this property is not present it's because the previous one didn't change."
      if (tourInfo.Prizes) {
        tournament.tourInfo.Prizes = tourInfo.Prizes;
      }
      if (tourInfo.Prizes2) {
        tournament.tourInfo.Prizes2 = tourInfo.Prizes2;
      }
    } else {

      // check for new players to be added to the list
      for (const player of tourInfo.Players) {
        const existingPlayer = tournament.tourInfo.Players.find(oldPlayer => oldPlayer.IdPlayer === player.IdPlayer);
        if (existingPlayer) {
          existingPlayer.IdPlayer = player.IdPlayer;
          existingPlayer.NbChips = player.NbChips = player.NbChips ? player.NbChips : 0;
          existingPlayer.IdTable = existingPlayer.IdTable;
          existingPlayer.Bounty = player.Bounty;
          existingPlayer.BountyEarned = player.BountyEarned ? player.BountyEarned : 0;
          existingPlayer.PositionEnded = player.PositionEnded ? player.PositionEnded : null;

        } else {
          tournament.tourInfo.Players.push(player);
        }
      }
    }

    const myPlayer = tournament.tourInfo.Players.find(player => player.IdPlayer === memberId);



    const imOnTour = !myPlayer?.PositionEnded ? myPlayer : undefined;

    const iWasOnTour = myPlayer?.PositionEnded ? myPlayer : undefined;
    console.log("imOnTour", imOnTour)
    if (imOnTour) {

      tournament.isRegistered = !imOnTour.PositionEnded;

      // if tournament is not on the list, add it to the list of registered tournaments
      if (!imOnTour.PositionEnded && !this.checkIsTournamentTableExist(tourInfo.IdTournament)) {
        // check if game is already running, if so open new gameTable


        tournament.myActiveTable = this.checkForTourGameTable(
          imOnTour.IdTable, tourInfo.IdTournament, imOnTour.PositionEnded, tournament.tourSum
        );
      }
    } else {
      tournament.isRegistered = false;
    }





    this.updateTourActiveTables(tournament, tourInfo);



    this.updatePlayersRank(tournament, memberId);



    // ** update tournaments



    if (tournament.tourSum) {
      this.tournaments[tournament.tourSum.Id] = tournament;

      if (this.viewingTournamentId === tournament.tourSum.Id) {
        this.viewTournament$.next(tournament)
        this.viewTournamentId$.next(this.viewingTournamentId)

      }


      this.updateTournamentsSummariesImregistered(tournament.tourSum.Id, tournament.isRegistered)

    } else if (tournament.tourInfo.IdTournament) {
      this.tournaments[tournament.tourInfo.IdTournament] = tournament;


      if (this.viewingTournamentId === tournament.tourInfo.IdTournament) {

        this.viewTournament$.next(tournament)
        this.viewTournamentId$.next(this.viewingTournamentId)

      }

      this.updateTournamentsSummariesImregistered(tournament.tourInfo.IdTournament, tournament.isRegistered)

    }




    // this.viewTournament = { ...this.viewTournament, ...tournament }

    // this.viewTournament$.next(this.viewTournament)
    //  this.store.dispatch(new AppActions.TournamentsActions.UpdateTournament(tournament));



  }

  checkIsTournamentTableExist(tournamentId) {
    return Object.keys(this.activeGames).some(key => this.activeGames[key].table.IdTournament === tournamentId);
  }

  private updateTourActiveTables(tournament: Tournament, tourInfo: TournamentInformation) {
    // new data arrived, discard all old data
    if (tourInfo.NbPlayerRegistered === tourInfo.Players.length) {
      tournament.activeTables = [];
    }

    // update tables and players
    for (const player of tourInfo.Players) {
      // if player belongs to one of the existing tables
      const tableExist = tournament.activeTables.find(table => table.idTable === player.IdTable);


      if (!tableExist) {
        if (!player.PositionEnded && player.IdTable !== 0) { // => Player at active table have TableId diffrent than 0, inactive tables have ids 0
          tournament.activeTables.push(<TournamentActiveTable>{
            idTable: player.IdTable,
            minChips: player.NbChips,
            maxChips: player.NbChips,
            playersList: [player]
          });
        }
      } else {
        // check if player is already assigned to table
        const existingPlayer = tableExist.playersList.find(tablePlayer => tablePlayer.IdPlayer === player.IdPlayer);
        // if player doesn't already exist in table list add the player to players list
        if (!existingPlayer) {
          tableExist.playersList.push(player);
        }

        if (player.PositionEnded) {
          const index = tableExist.playersList.findIndex(p => p.IdPlayer === player.IdPlayer);
          tableExist.playersList.splice(index, 1);
        }
      }



      if (tableExist && !tableExist.playersList.length) {
        const tableIndex = tournament.activeTables.findIndex(table => table.idTable === tableExist.idTable);
        tournament.activeTables.splice(tableIndex, 1);
      }
    }

    // recalculate min/max chips and numb of players
    for (const table of tournament.activeTables) {
      const tableChips = [];

      for (const player of table.playersList) {
        tableChips.push(player.NbChips);
      }

      table.maxChips = Math.max(...tableChips);
      table.minChips = Math.min(...tableChips);
      table.numberOfPlayers = table.playersList.length;
    }

  }

  private updatePlayersRank(tournament: Tournament, memberId: number) {
    tournament.tourInfo.Players.forEach(player => { player.NbChips = player.NbChips ? player.NbChips : 0; });

    tournament.tourInfo.Players = tournament.tourInfo.Players.sort((a, b) => {
      return Comparators.compare(+a.NbChips, +b.NbChips, false);
    });

    tournament.tourInfo.Players.forEach((player, index) => {
      if (player.PositionEnded) {
        player.Rank = player.PositionEnded;
      } else {
        player.Rank = index + 1;
      }

      if (player.IdPlayer === memberId) {
        tournament.myPosition = player.Rank;
      }
    });
  }

  checkForTourGameTable(tableId: number, tournamentId: number,
    positionEnded: number, tournamentSummary: TournamentSummary): number {
    if (!positionEnded && tableId && tournamentSummary) {
      const gameTableStart = <GameTableStart>{
        IdTable: tableId,
        IdTournament: tournamentId,
        tourSum: tournamentSummary,
        currency: this.currencyInfo.find(el => el.Id === tournamentSummary.Currency),
        name: tournamentSummary.Name
      };
      this.gameStart(gameTableStart);
    }
    return tableId;
  }

  checkForTableReassignment(mttTableAssigned: MTTTableAssigned, memberId: number) {

    //  check if message is for me (player)

    // If it's your own player id: you are assigned to a table, else it's a message to tell that someone join the tournament
    if (mttTableAssigned.IdPlayer === memberId) {


      //  if "IdTable" param exist that means that player has been moved from
      //  old table to new tabe. "IdTable" - old table, "Value" - new table
      if (mttTableAssigned.Value !== undefined) {

        //  remove old table
        this.activeGamesRemoveGameByTournamentId(mttTableAssigned.IdTournament)

        // OLD BUG this.gameTourEnd(mttTableAssigned.IdTournament, mttTableAssigned.Value);



        //  start new table (game)
        this.gameStartFromTournamentStart(
          mttTableAssigned.Value,
          mttTableAssigned.IdTournament,
          this.tournamentsSummaries.find(el => el.Id === mttTableAssigned.IdTournament)
        );
      }
    }
  }

  gamestartFromTableSummeries(tableSum: TableSummary) {
    //for (let i = 0; i < this.activeGames.length; i++) {
    // if (this.activeGames[i].table.IdTable === tableSum.Id) {
    //this.activeTab$.next(ENavItem.Game)
    this.setActiveGame(tableSum.Id)
    this.setActiveTab(ENavItem.Game)

    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Game)); // DEC comment
    // this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: tableSum.Id })); // DEC comment

    // for (const activeGame of this.activeGames) {
    //   if (activeGame.table.IdTable === tableSum.Id) {
    //     activeGame.isActive = true;
    //     this.activeTab$.next(ENavItem.Game)

    //   } else {
    //     activeGame.isActive = false;
    //   }
    // }
    // this.activeGames$.next(this.activeGames)
    // Object.keys(this.activeGames).map(key => {
    //   if (this.activeGames[key].table.IdTable === tableSum.Id) {
    //     this.activeGames[key].isActive = true;
    //   } else {
    //     this.activeGames[key].isActive = false;
    //   }
    // });

    //return;
    //}
    //  }

    const gameTableStart = <GameTableStart>{
      IdTable: tableSum.Id,
      tableSum: tableSum,
      currency: this.currencyInfo.find(el => el.Id === tableSum.Currency),

      name: tableSum.Name
    };
    this.gameStart(gameTableStart);
  }

  gamestartFromSNG(sitNGoSummary: SitNGoSummary) {
    // for (let i = 0; i < this.activeGames.length; i++) {
    //   if (this.activeGames[i].table.IdTable === sitNGoSummary.Id) {
    //     // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Game)); // DEC comment
    //     // this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: sitNGoSummary.Id })); // DEC comment
    //     return;
    //   }
    // }

    const gameTableStart = <GameTableStart>{
      IdTable: sitNGoSummary.Id,
      sitNGoSummary: sitNGoSummary,
      currency: this.currencyInfo.find(el => el.Id === sitNGoSummary.Currency),

      name: sitNGoSummary.Name,
    };
    this.gameStart(gameTableStart);
  }

  gameStartFromTournamentStart(tableId: number, tournamentId: number, tournamentSummary: TournamentSummary) {

    const gameTableStart = <GameTableStart>{
      IdTable: tableId,
      IdTournament: tournamentId,
      tourSum: tournamentSummary,
      currency: this.currencyInfo.find(el => el.Id === tournamentSummary.Currency),
      name: tournamentSummary.Name,

    };

    // const tourStarted = item.Value2;
    this.gameStart(gameTableStart);
  }

  // ## ACTIVE GAMES






  // ##


  gameStart(gameTableStart: GameTableStart) {

    // # OLD AS ARRAY
    // for (const activeGame of this.activeGames) {
    //   activeGame.isActive = false
    // }
    // let navTableData = this.activeGames.find(el => el.table.IdTable === gameTableStart.IdTable)
    // if (navTableData) {
    //   navTableData = <NavTableData>{ table: gameTableStart, onMove: false, isActive: true, playerMoney: 0 }
    // } else {
    //   this.activeGames.push(<NavTableData>{ table: gameTableStart, onMove: false, isActive: true, playerMoney: 0 })
    // }
    // this.activeGames$.next(this.activeGames)

    // # NEW OBJ KEYS



    // gameTableStart.currency = this.currencyInfo.find(el=>el.Id = gameTableStart.currency)
    this.activeGamesAddGame(<NavTableData>{ table: gameTableStart, onMove: false, isActive: true, playerMoney: 0 })
    this.setActiveTab(ENavItem.Game)

    // Object.keys(this.activeGames).map(key => {
    //   this.activeGames[key].isActive = false;
    // });
    // this.activeGames[action.payload.table.IdTable] = action.payload;


    // this.store.dispatch(new AppActions.GameAction.AddGame(
    //   <NavTableData>{ table: gameTableStart, onMove: false, isActive: true, playerMoney: 0 })
    // );
    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Game));


  }

  gameTourEnd(tournamentId: number, tableId?: number) {


    // this.activeGamesRemoveGame(tournamentId)

    if (tableId) {
      this.activeGamesRemoveGame(tableId)
    } else {
      Object.keys(this.activeGames).forEach(key => {
        const index = parseInt(key)
        if (this.activeGames[index].table.IdTournament === tournamentId) {
          this.activeGamesRemoveGame(this.activeGames[index].table.IdTable)
          // server removes sng tournament on end so
          // if it was sng tour. don't ask for summary because server will return
          // error "tounament #xxxxx doesn't exist"
          // if (activeGame.table.sitNGoSummary !== undefined) {
          this.dataManager.getTournamentSummary(tournamentId);
          // }
        }
      })
    }

    this.setActiveTab(ENavItem.Tournament)

    this.viewingTournamentId = tournamentId;
    this.viewTournament$.next(this.tournaments[tournamentId])
    this.viewTournamentId$.next(this.viewingTournamentId)


    //TO DO
    // this.store.dispatch(new AppActions.GameAction.RemoveTournament({ tournamentId: tournamentId }));

    // if (tableId) {
    //   this.store.dispatch(new AppActions.GameAction.RemoveGame({ tableId: tableId }));
    // } else {
    //   this.activeGames.forEach(activeGame => {
    //     if (activeGame.table.IdTournament === tournamentId) {
    //       this.store.dispatch(new AppActions.GameAction.RemoveGame({ tableId: activeGame.table.IdTable }));

    //       // server removes sng tournament on end so
    //       // if it was sng tour. don't ask for summary because server will return
    //       // error "tounament #xxxxx doesn't exist"
    //       // if (activeGame.table.sitNGoSummary !== undefined) {
    //       this.dataManager.getTournamentSummary(tournamentId);
    //       // }
    //     }
    //   });
    // }

    //this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Tournament)); // DEC comment
    // this.store.dispatch(new AppActions.TournamentsActions.SetViewTournament(tournamentId)); // DEC comment
  }

  tournamentView(tournamentSummary: TournamentSummary, password?: string) {

    if (!tournamentSummary) {
      this.viewingTournamentId = null;
      this.viewTournament$.next(null);
      this.viewTournamentId$.next(undefined)
      return
    }
    // const navItemType = new NavItemType(ENavItem.Tournament, true);
    // navItemType.setTournamentData(tournamentSummary);
    this.dataManager.getTournamentSummary(tournamentSummary.Id); // 🟢 added

    this.dataManager.sendMTTView(tournamentSummary.Id, true, password);
    this.dataManager.requestBlindSchedule(tournamentSummary.Id);

    // this.viewTournament = new Tournament()
    // this.viewTournament.tourSum = tournamentSummary
    // this.viewTournament$.next(this.viewTournament)




    if (!this.tournaments[tournamentSummary.Id]) {
      this.tournaments[tournamentSummary.Id] = {} as Tournament;
      this.tournaments[tournamentSummary.Id].tourInfo = {} as TournamentInformation;
      this.tournaments[tournamentSummary.Id].activeTables = [];
    }
    this.tournaments[tournamentSummary.Id].tourSum = tournamentSummary;


    this.viewingTournamentId = tournamentSummary.Id;

    this.viewTournament$.next(this.tournaments[this.viewingTournamentId])
    this.viewTournamentId$.next(this.viewingTournamentId)

    //this.store.dispatch(new AppActions.TournamentsActions.ViewTournament(tournamentSummary));
  }

  tournamentUnview(tournamentId: number) {
    this.dataManager.sendMTTView(tournamentId, false)
  }

  tournamentRegister(tournamentId: number) {
    this.dataManager.sendMTTRegister(tournamentId, true);
  }

  tournamentUnregister(tournamentId: number) {
    this.dataManager.sendMTTRegister(tournamentId, false);
  }

  runItTwice(value: MemberPreferencesRunItTwice) {
    this.dataManager.runItTwice(value);
  }

  runItTwiceTable(value: MemberPreferencesRunItTwice, tableId: number) {
    this.dataManager.runItTwiceTable(value, tableId);
  }

  muckCard(value: boolean) {
    this.dataManager.muckCard(value);
  }

  jumpToTable(value: boolean) {
    this.dataManager.jumpToTable(value);
  }

  requestTableData(lobbyFilter: LobbyFilterOptions, currency: number) {
    this.dataManager.requestTableData(lobbyFilter, currency);
  }

  subscribeLiveLobby() {
    this.dataManager.subscribeLiveLobby(true);
  }

  unlockGift(gift: Gift, settings) {
    // if (gift !== undefined) {
    //   switch (gift.Type) {
    //     case GiftType.RakeBack:
    //       this.dataManager.unlockGift(gift.Id, gift.AmountRemaining);
    //       break;
    //     default:
    //       this.dataManager.unlockGift(gift.Id, settings.PointExchangeRateValue);
    //       break;
    //   }
    // } else {
    //   this.notificationsService.showError('Bonus unavailable.');
    // }
  }

  showEmptyTablesChange(event: boolean) {
    this.showEmptyTables = event;
    this.refreshTableSummeriesData(this.tableSummaries);
    this.refreshTournamentSummeriesData(this.tournamentsSummaries);
    this.refreshSnGSummeriesData(this.tournamentsSummaries);
    this.refreshSpinnGSummeriesData(this.tournamentsSummaries);
  }

  useSmartSortChange(event: boolean) {
    this.useSmartSort = event;
  }











  // v2 new


  // =========================
  // ACTIVE TAB & GAMES
  // =========================

  setActiveTab(data: ENavItem) {
    console.log("@set active tab", data)
    // this.activeCasinoGame = null
    // this.activeCasinoGame$.next(this.activeCasinoGame)
    this.activeTab$.next(data)
  }

  setActiveGame(tableId: number) {

    Object.keys(this.activeGames).forEach(key => {
      const index = parseInt(key)
      if (this.activeGames[index].table.IdTable === tableId) {
        this.activeGames[index].isActive = true;
      } else {
        this.activeGames[index].isActive = false;
      }
    })

    // for (const activeGame of this.activeGames) {
    //   if (activeGame.table.IdTable === tableId) {
    //     activeGame.isActive = true;
    //   } else {
    //     activeGame.isActive = false;
    //   }
    // }
    this.activeGames$.next(this.activeGames)
  }

  activeGamesAddGame(data: NavTableData) {
    let deleteReplayKey;

    Object.keys(this.activeGames).map(key => {
      this.activeGames[key].isActive = false;
      if (this.activeGames[key].table.IdTable < 0 && data.table.IdTable < 0) {
        deleteReplayKey = key
      }
    });


    if (deleteReplayKey) {
      delete this.activeGames[deleteReplayKey]
    }

    data.createdAt = new Date()
    this.activeGames[data.table.IdTable] = data;
    this.activeGames$.next(this.activeGames)
  }

  activeGamesRemoveGame(tableId: number) {
    if (this.activeGames[tableId]) {
      delete this.activeGames[tableId];
    }
    this.activeGames$.next(this.activeGames)
  }


  activeGamesRemoveGameByTournamentId(tournamentId: number) {
    Object.keys(this.activeGames).forEach(key => {
      const tableId = parseInt(key)
      if (this.activeGames[tableId].table.IdTournament === tournamentId) {
        this.activeGamesRemoveGame(this.activeGames[tableId].table.IdTable)
      }
    })
  }

  // =========================


  setCardsInHand(tableId: number, cardsInHand: CardData[] = []) {
    if (this.activeGames[tableId]) {
      this.activeGames[tableId].cardsInHand = cardsInHand
    }
    this.activeGames$.next(this.activeGames)
  }

  setFolded(tableId: number, folded: boolean = true) {
    if (this.activeGames[tableId]) {
      this.activeGames[tableId].folded = folded
    }
    this.activeGames$.next(this.activeGames)
  }

  setOnMove(tableId: number, onMove: boolean = false) {
    if (this.activeGames[tableId]) {
      this.activeGames[tableId].onMove = onMove
    }
    this.activeGames$.next(this.activeGames)
  }

  leaveSeat(tableId: number) {
    if (this.activeGames[tableId]) {
      this.activeGames[tableId].onMove = false;
      this.activeGames[tableId].folded = false;
      this.activeGames[tableId].cardsInHand = [];
    }
    this.activeGames$.next(this.activeGames)
  }

  removeGame(tableId: number) {
    if (this.activeGames[tableId]) {
      delete this.activeGames[tableId];
    }

    this.activeGames$.next(this.activeGames)
  }


  setPlayerMoney(tableId: number, playerMoney: number) {
    if (this.activeGames[tableId]) {
      this.activeGames[tableId].playerMoney = playerMoney;
    }

    this.activeGames$.next(this.activeGames)
  }

  updateGame(data: NavTableData) {
    if (this.activeGames[data.table.IdTable]) {
      this.activeGames[data.table.IdTable].playerMoney = data.table.IdTable;
    }
    this.activeGames$.next(this.activeGames)

  }

  //








  // getTournamentRegisterButton(tournamentSum: TournamentSummary) {
  //   let enableRegisterButton: boolean
  //   let registerButtonText: string = ``


  //   switch (tournamentSum.Status2) {
  //     case TournamentStatus2.Announced: {
  //       enableRegisterButton = false;
  //       break;
  //     }
  //     case TournamentStatus2.RegistrationOpen: {
  //       enableRegisterButton = true;
  //       if (tournamentSum.ImRegistered) {
  //         this.translateService.get('TITLE.UNREGISTER').subscribe(trans => {
  //           registerButtonText = trans;
  //         });
  //       } else {
  //         this.translateService.get('BUTTON.REGISTER').subscribe(trans => {
  //           registerButtonText = trans;
  //         });
  //       }
  //       break;
  //     }
  //     case TournamentStatus2.RunningWithLateRegistration: {
  //       if (tournamentSum.ImRegistered) {
  //         if (tournamentSum.ImRegistered &&
  //           tournamentSum.ImEliminated &&
  //           tournamentSum.OptionReEntry) {
  //           enableRegisterButton = true;
  //         } else {
  //           enableRegisterButton = false;
  //         }
  //       } else {
  //         enableRegisterButton = true;
  //       }
  //       this.translateService.get('BUTTON.JUMPIN').subscribe(trans => {
  //         registerButtonText = trans;
  //       });
  //       break;
  //     }
  //     case TournamentStatus2.RunningRegistrationClosed: {
  //       enableRegisterButton = false;
  //       break;
  //     }
  //     case TournamentStatus2.Completed: {
  //       enableRegisterButton = false;
  //       break;
  //     }
  //     case TournamentStatus2.Canceled: {
  //       enableRegisterButton = false;
  //       break;
  //     }
  //     default:
  //       enableRegisterButton = false;
  //   }

  //   return { enable: enableRegisterButton, text: registerButtonText }
  // }





  translateAndShowErrorNotification(errorText: string, errorCode: number) {
    let transalationText = errorText

    if (ErrorCode[errorCode]) {
      transalationText = `api.error.${ErrorCode[errorCode]}`
    }

    this.translateService.get(transalationText)
      .pipe(take(1))
      .subscribe(translation => {
        this.notificationsService.showError(translation);
      });
  }



  getCountryState(baseUrl: string) {
    console.log("@base url", baseUrl)
    return this.httpClient.get<any>(`${baseUrl}/v1/countries/list`)
  }



  // #####################

  setActiveCasinoGame({ url, name, gameCode }) {

    const activeGame = this.activeCasinoGames.find(el => el.gameCode === gameCode)
    if (!activeGame) {
      this.activeCasinoGame = { url, name, gameCode }
      this.activeCasinoGames = [{ url, name, gameCode }]

      this.activeCasinoGame$.next(this.activeCasinoGame)
      this.activeCasinoGames$.next(this.activeCasinoGames)
    } 

    console.log('setActiveCasinoGame', { url, name, gameCode })
    this.activeTab$.next(ENavItem.CasinoGame)
    this.setActiveGame(null)


    return

    // const activeGame = this.activeCasinoGames.find(el => el.gameCode === gameCode)
    // if (!activeGame) {
    //   this.activeCasinoGame = { url, name, gameCode }
    //   this.activeCasinoGames = [{ url, name, gameCode }, ...this.activeCasinoGames]
    //   this.activeCasinoGames$.next(this.activeCasinoGames)
    // } else {
    //   this.activeCasinoGame = activeGame
    // }
    // this.activeCasinoGame$.next(this.activeCasinoGame)


    // this.activeTab$.next(ENavItem.CasinoGame)

    // this.setActiveGame(null)
  }



  closeActiveCasinoGame() {
    this.activeCasinoGames = []
    this.activeCasinoGames$.next(this.activeCasinoGames)
    this.activeCasinoGame = null
    this.activeCasinoGame$.next(this.activeCasinoGame)

    this.activeTab$.next(ENavItem.Lobby)

    return
    this.activeCasinoGames = this.activeCasinoGames.filter(el => el.gameCode !== this.activeCasinoGame.gameCode)
    this.activeCasinoGames$.next(this.activeCasinoGames)
    this.activeCasinoGame = null
    this.activeCasinoGame$.next(this.activeCasinoGame)
    this.activeTab$.next(ENavItem.Lobby)
  }

}
