import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '../../../../shared/models/user';
import { PlayerLevel } from '../../../../shared/constants/player-level.constants';

@Component({
  selector: 'app-player-rank-level',
  templateUrl: './player-rank-level.component.html',
  styleUrls: ['./player-rank-level.component.scss']
})
export class PlayerRankLevelComponent implements OnInit {

  public PlayerLevel = PlayerLevel;

  constructor(
    public dialogRef: MatDialogRef<PlayerRankLevelComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) { }

  ngOnInit() {
  }

  clickHowItWorks() {
    window.open('https://cryptopokergroup.com/help', '_blank');
  }

}
