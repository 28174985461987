import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-pre-bet-controls',
  templateUrl: './game-pre-bet-controls.component.html',
  styleUrls: ['./game-pre-bet-controls.component.scss']
})
export class GamePreBetControlsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
