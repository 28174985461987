import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { ManagerService } from '../../../core/services/manager.service';

@Component({
  selector: 'app-dialog-hand-replay',
  templateUrl: './dialog-hand-replay.component.html',
  styleUrls: ['./dialog-hand-replay.component.scss']
})
export class DialogHandReplayComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();
  form: FormGroup;

  constructor(
    private dataManagerService: DataManagerService,
    public managerService: ManagerService,
    public dialogRef: MatDialogRef<DialogHandReplayComponent>,
    private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    // ** On Logout Close
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (!settings) {
            this.dialogRef.close();
          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  buildForm(): void {
    this.form = this.fb.group({
      handId: ['', [Validators.required]]
    });

  }

  askHandReplayData() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.handId);
    }
  }

}
