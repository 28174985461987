import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GameControlsBase } from '../../game-controls.base';

@Component({
  selector: 'app-game-controls-common-im-back',
  templateUrl: './game-controls-common-im-back.component.html',
  styleUrls: ['./game-controls-common-im-back.component.scss']
})
export class GameControlsCommonImBackComponent implements OnInit {

  @Output() imBackClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  clickImBack() {
    this.imBackClicked.emit();
  }

}
