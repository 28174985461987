import { Component, OnInit } from '@angular/core';

import { GameControlsBase } from '../../game-controls.base';
import { AuthService } from '../../../../../auth/auth.service';
import { CurrencyMultiplierPipe } from '../../../../../shared/utils/currency-multiplier.pipe';

@Component({
  selector: 'app-game-controls-desktop',
  templateUrl: './game-controls-desktop.component.html',
  styleUrls: ['./game-controls-desktop.component.scss']
})
export class GameControlsDesktopComponent extends GameControlsBase implements OnInit {

  constructor(public authService: AuthService) {
    super();
  }

  ngOnInit() {
  }


}
