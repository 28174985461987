import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ENavItem } from './nav/nav-item-type';
import { NavItemType } from './nav/nav-item-type';
import { environment } from '../../environments/environment';
import { Currency } from '../shared/enums/currency';
import { GameComponent } from './game/game.component';
import { GameType } from '../shared/enums/game-type.enum';
import { NavTableData, Settings, TournamentSummary, TournamentInformation, TableSummary } from '../shared/models';
import { GameService } from './game/game.service';
import { User } from '../shared/models/user';
import { PopUpComponent } from './dialogs/pop-up/pop-up.component';
import { MainService, SidenavState } from './main.service';
import { CarpetType } from '../shared/enums/carpet-type';
import { ManagerService } from '../core/services/manager.service';
import { DataManagerService } from '../core/services/data-manager.service';
import { SkinSettingsService } from './skin-settings/skin-settings.service';
import { NotificationsService } from '../core/notifications/notifications.service';
import { delay, filter, map, take } from 'rxjs/operators';
import { ErrorCode, VariantType2 } from '../shared/enums/poker-types';
import { AssetsLoaderService } from '../core/services/assets-loader.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfigService } from '../core/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';

export interface Background {
  url: string;
  size: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})

export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

  subscription: Subscription = new Subscription();
  environment = environment;
  Currency = Currency;

  activeTab: ENavItem = ENavItem.Lobby;
  navItems = ENavItem;
  background: Background;

  user: User;
  settings: Settings;
  activeGames: Array<NavTableData> = [];
  sideNav: SidenavState;
  depositInitiateResponseUrl$ = this.managerService.depositInitiateResponseUrl$.pipe(
    filter(url => !!url),
    map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)))

  @ViewChild('sidenavLeft', { static: false }) sidenavLeft: any;
  @ViewChild('sidenavRight', { static: false }) sidenavRight: any;
  @ViewChildren(GameComponent) gameComponents: QueryList<GameComponent>;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    if (environment.production) {
      const confirmationMessage = 'Do you want to leave this site?';

      (event || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage;  // Webkit, Safari, Chrome
    }
  }


  config
  constructor(
    private mainService: MainService,
    public managerService: ManagerService,
    private dialog: MatDialog,
    private gameService: GameService,
    private dataManager: DataManagerService,
    private skinSettings: SkinSettingsService,
    private notificationsService: NotificationsService,
    private _assetsLoaderService: AssetsLoaderService,
    protected breakpointObserver: BreakpointObserver,
    private configService: ConfigService,
    private sanitizer: DomSanitizer

  ) {
    this.config = this.configService.config;

  }

  ngOnInit() {
    this.dataManager.shopSettings()

    this.subscription.add(
      this.breakpointObserver
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.Web
        ])
        .pipe(delay(100))
        .subscribe(result => {
          console.log("result@@@", result)
          this.getBackground()
        }))

    this.subscription.add(
      // ## On Logout Close
      this.managerService.settings$
        .subscribe({
          next: (settings) => {
            // console.log("settings",)
            if (!this.settings) { // ## On first received the result
              this.showStartingPopUp(settings);
            }

            this.settings = settings;

            if (!settings) { // ## This manage logout state
              this.managerService.setActiveTab(ENavItem.Lobby)

              this.managerService.setActiveGame(null)
              this.mainService.toggleSidenav(false, false);
            }
          }
        })
    );

    this.subscription.add(
      this.managerService.user$.subscribe({
        next: (user) => {
          this.user = user
        }
      })
    );

    this.subscription.add(
      this.managerService.activeTab$.subscribe({
        next: (activeTab) => {
          this.activeTab = activeTab

          //--
          //this.activeTab = ENavItem.Shop
          //--
          this.getBackground();


        }
      })
    );

    this.subscription.add(
      this.managerService.activeGames$.subscribe({
        next: (activeGames) => {
          const newActiveGames = Object.values(activeGames)


          // ## Add & Remove currently active games
          // ## 1) Remove:
          let removing = true;
          while (removing) {
            removing = false
            for (const key in this.activeGames) {
              if (Object.prototype.hasOwnProperty.call(this.activeGames, key)) {

                if (newActiveGames.findIndex(activeGame => activeGame.table.IdTable === this.activeGames[key].table.IdTable) === -1) {


                  this.activeGames.splice(parseInt(key), 1);
                  removing = true
                  break
                }
              }
            }
          }



          // ## 2) Add new
          for (const activeGame of newActiveGames) {
            const find = this.activeGames.find(el => el.table.IdTable === activeGame.table.IdTable)
            if (find) {
              find.isActive = activeGame.isActive
            } else {


              this.activeGames.push(activeGame)
            }
          }

        }
      })
    );

    this.subscription.add(
      this.mainService.sidenavState.subscribe({
        next: (data) => {
          this.sideNav = data
          if (data.show) {
            this.sidenavLeft.toggle(data.show);
          } else {
            this.sidenavLeft.toggle(false);
          }
        }
      })
    );

    this.subscription.add(
      this.mainService.rightSidenavState.subscribe({
        next: () => {
          this.sidenavRight.toggle(true);
        }
      })
    )

    this.subscription.add(
      this.managerService.handReplay$.subscribe(handReplayData => {
        if (handReplayData.Error) {
          if (handReplayData.Error === ErrorCode.HandNumberNotFound) {
            this.notificationsService.showError(`Hand with ID ${handReplayData.HandNumber} not found`)
          } else {
            this.notificationsService.showError(`Hand Replay error: ID ${ErrorCode[handReplayData.Error]}`)
          }
        } else {
          this.showHandReplay(handReplayData);
        }
      }))


    this.dataManager.subscribeStatsPlayerOnline()

    // ## FEATURE to do PhoneVerification
    // const state = JSON.parse(window.localStorage.getItem('auth-login'));
    // if (state && state.type === 'sms') {
    //   this.dialog.open(PhoneVerificationDialogComponent, { disableClose: true, width: '540px' });
    // }
  }

  ngAfterViewInit(): void {
    this.gameComponents.changes.subscribe((gameComponent) => {
      this.gameService.gameComponents = this.gameComponents.toArray();
    });

    // ## FEATURE to do PhoneVerification
    // setTimeout(() => {
    //   if (this.authService.user.memberProfile.PhoneStatus !== PhoneStatus.Verified &&
    //     this.authService.user.settings.PhoneMustBeVerified === 'True') {
    //     this.dataManager.getSmsForVerfiction();
    //     this.mainService.showPhoneVerification();
    //   }
    // }, 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getBackground() { // ## improve
    console.log("skinSettings background", this.skinSettings)

    let url = this.skinSettings.selectedSettings.carpetLandscape.carpetUrl
    if (this.skinSettings.selectedSettings.isPortrait) {
      url = this.skinSettings.selectedSettings.carpetPortrait.carpetUrl
    }

    this.background = {
      url: `url(${url})`,
      size: this.getBackgroundSize(this.skinSettings.selectedSettings.carpetLandscape.carpetType),
    };


  }

  getBackgroundSize(carpetType: CarpetType) {
    switch (carpetType) {
      case CarpetType.Pattern:
        return 'intiial';
      case CarpetType.RasterImage:
        return '100% 100%';
      case CarpetType.VectorImage:
        return 'cover';
      default:
        return '';
    }
  }

  showStartingPopUp(settings: Settings) {

    /*
    The popup should not be displayed if current time is after PopupExpiration or PopupTitle is empty.
    The popup should have a button to register a tournament if PopupIdTournament is defined.
    */
    if (Math.round(new Date().getTime() / 1000) < Number(settings.PopupExpirationDate) && settings?.PopupTitle !== '') {
      settings.PopupIdTournament = Number(settings.PopupIdTournament);

      this.dialog
        .open(PopUpComponent, { width: '360px', data: { settings } })
        .afterClosed().subscribe(res => {
          if (res === 'register') {
            this.managerService.addTournament({
              tourSum: {} as TournamentSummary,
              tourInfo: {} as TournamentInformation,
              isRegistered: true,
              activeTables: [],
              myActiveTable: undefined,
              myPosition: undefined,
              blindLevels: [],
            }, settings.PopupIdTournament
            )

            // this.dataManager.getTournamentSummary(settings.PopupIdTournament);
            this.managerService.setActiveTab(ENavItem.Tournament)
            this.managerService.tournamentView({ Id: settings.PopupIdTournament } as TournamentSummary);

          }
        });
    }

    if (settings.Welcome && localStorage.getItem('WelcomeMessage') !== 'true') {
      this.dialog
        .open(PopUpComponent, { width: '360px', data: { settings, isWelcome: true } })
        .afterClosed().subscribe(res => {
          localStorage.setItem('WelcomeMessage', 'true')
        });
    }
  }

  showHandReplay(handReplayData) {

    /* 
    🟡 Messages with special attention 
    InfoTournament
    InfoTable
    InfoCurrency
    InfoPlayer
    HandStart
    Dealer
    BidSmallBlind
    */

    const handStart = handReplayData.Events.find(data => data.MsgTypeLog === 'HandStart');
    const bidSmallBlind = handReplayData.Events.find(data => data.MsgTypeLog === 'BidSmallBlind');
    const tourInfo = handReplayData.Events.find(data => data.MsgTypeLog === 'InfoTournament');
    const infoCurrency = handReplayData.Events.find(data => data.MsgTypeLog === 'InfoCurrency');
    const InfoTable = handReplayData.Events.find(data => data.MsgTypeLog === 'InfoTable');

    let replayCurrency = infoCurrency.Value;
    let nbPlayers = 10;
    let tableName = ''

    let tableVariant;

    if (handStart.Values) {
      nbPlayers = handStart.Values[1];
    }

    // check if replay is from tournament table
    // and if so set currenty to tournament
    let fakeTournamentId;
    if (tourInfo) {
      fakeTournamentId = -1 * handReplayData.HandNumber
      tableName = tourInfo.Text
      tableVariant = VariantType2[tourInfo.Value3];

    } else {
      tableVariant = VariantType2[InfoTable.Value3];
    }



    this.managerService.currencyInfo$
      .pipe(take(1))
      .subscribe(currencyInfo => {


        const navItemTypeForReplay = new NavItemType(ENavItem.Game, false);
        navItemTypeForReplay.isActive = false;
        navItemTypeForReplay.itemType = ENavItem.Game;
        navItemTypeForReplay.navTableData = {

          table: {
            name: `Replay hand ${handReplayData.HandNumber}`,
            replayEventData: handReplayData.Events,

            IdTable: -1 * handReplayData.HandNumber,
            IdTournament: fakeTournamentId,
            isReplay: true,

            handId: handReplayData.HandNumber,
            currency: currencyInfo.find(el => el.Id === replayCurrency),



            tourSum: !fakeTournamentId ? undefined : {
              Blind: bidSmallBlind.Value ?? 0,
              TournamentNbSeatsPerTable: nbPlayers,
              tableVariant
            } as TournamentSummary,
            tableSum: fakeTournamentId ? undefined : {
              Blind: bidSmallBlind.Value ?? 0,
              MaxPlayers: nbPlayers,
              tableVariant

            } as TableSummary
          },


          cardsInHand: [],
          onMove: false,
          folded: false,
          gameType: GameType.replay,
          isActive: true,
          createdAt: new Date()
        };


        navItemTypeForReplay.tournamentSummaries = undefined;
        this.managerService.activeGamesAddGame(navItemTypeForReplay.navTableData)
        this.managerService.setActiveTab(ENavItem.Game)
      })
  }

}
