import * as PIXI from 'pixi.js';

import { Point } from '../models';
import { environment } from '../../../../environments/environment';
import { GlowFilter } from '@pixi/filter-glow';
import { CardData } from '../../../shared/models';
import { AnchorPosition } from './const';
import { GameComponent } from '../game.component';

// import { Store } from '@ngrx/store';
import { GameService } from '../game.service';
import { NgZone } from '@angular/core';

import * as CONST from './cards-cotrollers/player-cards-controller-const'
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { ConfigService } from '../../../core/services/config.service';

enum AnimationDirectionType {
    FADE_IN = 0,
    FADE_OUT = 1,
    PAUSE = 2,
}

export class AnimationText {

    public container: PIXI.Container;
    private textInfo: PIXI.Text;
    private textInfoStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 38,
        fill: '#FFFFFF',
        fontWeight: '400'
    });

    private textHandStrength: PIXI.Text;
    private textHandStrengthStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 38,
        fill: '#FFA500',
        fontWeight: '500'
    });

    private background: PIXI.Graphics | PIXI.Sprite;
    private backgroundColor = 0x000000;
    private backgroundAlpha = 0.9;

    private PADDING_TOP = 10;
    private PADDING_LEFT = 25
    private ANIMATION_SPEED = 120

    private positionX = 1920
    private portraitMode = false;

    private animationDirection: AnimationDirectionType = AnimationDirectionType.PAUSE;
    private pauseTimeStart: number;
    private pauseTimeAllowed = 5000 * 10// 5 SEC
    private fadeTimeStart: number;
    private fadeTimeTotal: number;

    private animationConfigSetTime: number;
    private POSITION_TOP = 0
    config;
    constructor(
        public assetsLoader: AssetsLoaderService,
        private configService: ConfigService,
    ) {
        this.config = this.configService.config;

        this.container = new PIXI.Container();
        this.container.position.set(this.positionX, this.POSITION_TOP);


        this.background = new PIXI.Graphics();
        this.background.beginFill(this.backgroundColor, this.backgroundAlpha);
        this.background.drawRect(0, 0, 0, 0);
        this.background.endFill();

        this.container.addChild(this.background);
        this.container.alpha = 0
  
        //manual start:
        // this.setConfig('Smurf won the pot', 'dsaddasdsadsadsa', 24000, false)
        // setTimeout(() => {
        //     this.setConfig('Smurf won the pot', '', 141000, false)

        // }, (3000));
    }

    setPortraitMode(portraitMode: boolean) {
        this.portraitMode = portraitMode;
    }

    setConfig(textInfo: string, textHandStrength: string, pauseTimeAllowed: number, portraitMode: boolean) {

        this.container.removeChild(this.textInfo)
        this.textInfo = new PIXI.Text(textInfo, this.textInfoStyle);
        this.textInfo.position.set(0, this.PADDING_TOP);
        this.container.addChild(this.textInfo);

        this.container.removeChild(this.textHandStrength)
        this.textHandStrength = null


        if (textHandStrength) {
            if (this.config.brand !== '111') {
                (this.background as PIXI.Graphics).clear();
            } else {
                (this.background as PIXI.Sprite).alpha = 0;

            }
            this.textHandStrength = new PIXI.Text(textHandStrength, this.textHandStrengthStyle);

            this.textHandStrength.position.set(this.textInfo.width + 10, this.PADDING_TOP);
            this.container.addChild(this.textHandStrength);
            this.container.alpha = 1    
        }
        this.refreshBackgroud()


        this.pauseTimeAllowed = pauseTimeAllowed;
        this.animationDirection = AnimationDirectionType.FADE_IN;

        this.portraitMode = portraitMode
        this.positionX = this.portraitMode ? 1080 : 1920;
        this.container.position.set(this.positionX, 0);
        this.fadeTimeStart = null;
        this.fadeTimeTotal = null;
        this.pauseTimeStart = null;
        this.animationConfigSetTime = new Date().getTime();
    }



    resetAnimation() {
        this.container.position.set(this.container.width * -1, this.POSITION_TOP);
        this.animationDirection = AnimationDirectionType.FADE_OUT
        this.animationConfigSetTime = 0
    }

    update(dt) {
        // dt ~15ms
        let width = 0;
        if (this.textInfo && this.textHandStrength) {
            width = (this.textInfo.width + this.textHandStrength.width) / 2;
        } else if (this.textInfo) {
            width = this.textInfo.width / 2;
        }

        if (new Date().getTime() - this.animationConfigSetTime >= this.pauseTimeAllowed + 500) { // 500ms SAFE delay
            this.resetAnimation()
            return
        }

        if (this.animationDirection === AnimationDirectionType.FADE_IN) { // ## FADE IN - move to center

            if (!this.fadeTimeStart) {
                this.fadeTimeStart = new Date().getTime()
            }

            if (this.positionX > (((this.portraitMode ? 1080 : 1920) / 2) - width) + this.ANIMATION_SPEED) {
                this.positionX -= this.ANIMATION_SPEED
                this.container.position.set(this.positionX, this.POSITION_TOP);
            } else if (this.positionX > (((this.portraitMode ? 1080 : 1920) / 2) - width)) {
                this.positionX = (((this.portraitMode ? 1080 : 1920) / 2) - width) //this.positionX - (((this.portraitMode ? 1080 : 1920) / 2) - width)
                this.container.position.set(this.positionX, this.POSITION_TOP);
            } else {
                this.fadeTimeTotal = new Date().getTime() - this.fadeTimeStart;
                this.fadeTimeStart = null;

                this.pauseTimeStart = new Date().getTime();
                this.animationDirection = AnimationDirectionType.PAUSE
            }
        } else if (this.animationDirection === AnimationDirectionType.FADE_OUT) { // ## FADE OUT - move out

            if (this.positionX > (-1 * this.container.width)) {
                this.positionX -= this.ANIMATION_SPEED
                this.container.position.set(this.positionX, this.POSITION_TOP);
            }
        } else if (this.animationDirection === AnimationDirectionType.PAUSE) { // ## PAUSE
            if (new Date().getTime() - this.pauseTimeStart >= this.pauseTimeAllowed - (this.fadeTimeTotal * 2)) {
                this.animationDirection = AnimationDirectionType.FADE_OUT
            }
        }

        this.refreshBackgroud()
    }


    private refreshBackgroud() {
        if (this.config.brand !== '111') {
            let backgroundPositionX = 0
            let backgroundWidth = 0
            if (this.textInfo && this.textHandStrength) {
                backgroundWidth = this.textInfo.width + this.textHandStrength.width + 10 // 10 is space
                backgroundPositionX = -(backgroundWidth) / 2;
            } else if (this.textInfo) {
                backgroundWidth = this.textInfo.width
                backgroundPositionX = -(backgroundWidth) / 2;
            }
            backgroundWidth += 50;

            (this.background as PIXI.Graphics).clear();
            (this.background as PIXI.Graphics).beginFill(this.backgroundColor, this.backgroundAlpha);
            (this.background as PIXI.Graphics).drawRoundedRect(-25, 0, backgroundWidth, this.container.height + this.PADDING_TOP * 2, 25);
            (this.background as PIXI.Graphics).endFill();
        } else {
            let backgroundPositionX = 0
            if (this.textInfo && this.textHandStrength) {
                backgroundPositionX = -(this.background.width - (this.textInfo.width + this.textHandStrength.width)) / 2;
            } else if (this.textInfo) {
                backgroundPositionX = -(this.background.width - (this.textInfo.width)) / 2;
            }
            (this.background as PIXI.Sprite).alpha = 1;
            (this.background as PIXI.Sprite).position.x = backgroundPositionX
            //(this.background as PIXI.Sprite).tint = 0x000000;
        }

    }

}
