import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Currency, getCurrencyIconName } from '../../enums/currency';
// import { Store } from '@ngrx/store';
import { State } from '../../../auth/auth-store/auth.reducer';
import { AppActions } from '../../../app.actions';
import { AuthService } from '../../../auth/auth.service';
import { ManagerService } from '../../../core/services/manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyInfo } from '../../models/currency-info';
import { User } from '../../models/user';
import { ConfigService } from '../../../core/services/config.service';
import { c } from 'bowser';
import { SkinSettingsService } from '../../../main/skin-settings/skin-settings.service';

@Component({
  selector: 'app-currency-chooser',
  templateUrl: './currency-chooser.component.html',
  styleUrls: ['./currency-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyChooserComponent implements OnInit, OnDestroy {

  environment = environment;

  destroy$: Subject<boolean> = new Subject<boolean>();
  currencyInfo: CurrencyInfo[];
  user: User;
  config;

  constructor(
    // public store: Store<State>,
    public authService: AuthService,

    private managerService: ManagerService,
    private configService: ConfigService,

    private skinSettingsService: SkinSettingsService
  ) {

    this.config = this.configService.config;


  }

  ngOnInit() {
    this.managerService.currencyInfo$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (currencyInfo) => {
        this.currencyInfo = currencyInfo.filter(el => el.Id !== 255)
      }
    })

    this.managerService.user$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (user) => {
          this.user = user;
        }
      })
  }

  changeCurrency(currency: CurrencyInfo) {
    if (this.config.brand === 'lp-qxmp') {
      if (currency.Name === 'AVAX') {
        this.skinSettingsService.useTheme$.next('avax')
      } else {
        this.skinSettingsService.useTheme$.next(null)
      }
    }
    this.managerService.changeCurrency(currency)
    // this.store.dispatch(new AppActions.AuthAction.CurrencyChange(currencyId));
  }

  // getCurrIcon(currency: Currency) {
  //   return getCurrencyIconName(currency);
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
