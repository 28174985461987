import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AssetsLoaderService } from '../services/assets-loader.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent implements OnInit {
  environment = environment;

  progress = 0;
  visible = true;
  config;

  constructor(
    private _assetsLoader: AssetsLoaderService,
    private configService: ConfigService,
  ) {
    this.config = this.configService.config;
  }

  ngOnInit(): void {
    this._assetsLoader.pixiLoader$.subscribe(data => {
      this.progress = Math.round(data.progress);
      this.visible = data.loading;
    });
  }

}
