import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, takeUntil, tap, mergeMap, pluck ,  switchMap } from 'rxjs/operators';

import * as GameActions from './game-store.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class GameEffects {
  constructor(private actions$: Actions, store: Store<any>) {}

  // @Effect() gameActions$: Observable<GameActions.All> = this.actions$.pipe(
  //   ofType<GameActions.SetOnMove>(GameActions.SET_ON_MOVE),
  //   switchMap(() => Observable.of())
  // );
}
