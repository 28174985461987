import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerService } from './spinner/spinner.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { GenericTextDialog } from './dialogs/generic-text-dialog/generic-text-dialog.component';
import { CreateNewPasswordDialog } from './dialogs/create-new-password-dialog/create-new-password-dialog.component';
import { NotificationsService } from './notifications/notifications.service';

import { LostConnectionComponent } from './lost-connection/lost-connection.component';
import { LostConnectionService } from './lost-connection/lost-connection.service';
import { LoggerService } from './services/logger.service';
import { AppWebSocketService } from './services/app-web-socket.service';

import { AssetsLoaderService } from './services/assets-loader.service';
import { DataManagerService } from './services/data-manager.service';
import { LinkHtmlService } from './services/link-html.service';
import { ClockService } from './services/clock.service';
import { ErrorsHandler } from './services/errors-handler.service';
import { SharedModule } from '../shared/shared.module';
import { BroadcastDialogComponent } from './dialogs/broadcast-dialog/broadcast-dialog.component';
import { LoadingProgressComponent } from './loading-progress/loading-progress.component';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { AskQuestionDialogComponent } from './dialogs/ask-question-dialog/ask-question-dialog.component';
import { AskQuestionR2TComponent } from './dialogs/ask-question-r2-t/ask-question-r2-t.component';
import { LinkifyPipe } from './linkify.pipe';

@NgModule({
  declarations: [
    LoadingProgressComponent,
    SpinnerComponent,

    GenericTextDialog,
    CreateNewPasswordDialog,
    LostConnectionComponent,
    BroadcastDialogComponent,
    FullscreenComponent,
    AskQuestionDialogComponent,
    AskQuestionR2TComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [
    HttpClientModule,
    SpinnerComponent,
    LoadingProgressComponent,
    GenericTextDialog,
    CreateNewPasswordDialog,
    LostConnectionComponent,
    FullscreenComponent,
  ],
  entryComponents: [
    CreateNewPasswordDialog,
    GenericTextDialog,
    BroadcastDialogComponent,
    AskQuestionDialogComponent,
  ],
  providers: [
    SpinnerService,

    NotificationsService,
    LostConnectionService,
    LoggerService,
    AppWebSocketService,

    AssetsLoaderService,
    DataManagerService,
    LinkHtmlService,
    ClockService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    }
  ]
})

export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
