import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { ManagerService } from '../../../core/services/manager.service';
import { CurrencyInfo } from '../../../shared/models/currency-info';
import { User } from '../../../shared/models/user';
import { CurrencyMultiplierPipe } from '../../../shared/utils/currency-multiplier.pipe';

@Component({
  selector: 'app-transfer-money-to-player',
  templateUrl: './transfer-money-to-player.component.html',
  styleUrls: ['./transfer-money-to-player.component.scss']
})
export class TransferMoneyToPlayerComponent implements OnInit, OnDestroy {

  form: FormGroup;
  destroy$ = new Subject<boolean>();
  user: User;
  currencyInfo: CurrencyInfo[];

  constructor(
    private fb: FormBuilder,
    private dataManager: DataManagerService,
    private managerService: ManagerService,
  ) { }

  get currencyName(){
    return this.currencyInfo.find(el => el.Id === this.form.value.currency).Name
  }
  ngOnInit(): void {
    this.managerService.currencyInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: currencyInfo => {
          this.currencyInfo = currencyInfo
        }
      })

    this.managerService.user$
      .pipe(
        // take(1)
      )
      .subscribe({
        next: user => {
          this.user = user

          if(this.form){
            this.form.get('currency').setValue(this.user.selectedCurrency.Id)
          }else{
            this.buildForm();
          }


        }
      })

  }
  buildForm(): void {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      amount: [''],
      currency: [this.user.selectedCurrency.Id],
      note: [''],
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  transferMoneyToPlayer() {
    this.dataManager.sendMoneyTransfer(
      CurrencyMultiplierPipe.prototype.transform(this.form.value.amount, this.currencyInfo.find(el => el.Id === this.form.value.currency)),
      this.form.value.currency,
      this.form.value.username,
      this.form.value.note,
    );
    this.form.reset()
    this.form.get('currency').setValue(this.user.selectedCurrency.Id)
  }

}
