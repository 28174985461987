import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store/src/store_module';

import { CoreModule } from '../../core/core.module';
import { GameComponent } from './game.component';
import { GameService } from './game.service';
import { GamePasswordComponent } from './dialogs/game-password/game-password.component';
import { EffectsModule } from '@ngrx/effects';
import { GameEffects } from './store/game-store.effects';
import { GameRebuyComponent } from './game-rebuy/game-rebuy.component';
import { GameAddonComponent } from './game-addon/game-addon.component';
import { GameControlsModule } from './game-controls-container/game-controls.module';
import { SharedModule } from '../../shared/shared.module';
import { PlayerNoteComponent } from './dialogs/player-note/player-note.component';
import { PlayerReplaceCardsComponent } from './dialogs/player-replace-cards/player-replace-cards.component';
import { DialogLeaveTableComponent } from './dialogs/dialog-leave-table/dialog-leave-table.component';
import { FallGuysComponent } from './dialogs/fall-guys/fall-guys.component';
import { DialogPlayerSpellsComponent } from './dialogs/dialog-player-spells/dialog-player-spells.component';
import { AntibotComponent } from './dialogs/antibot/antibot.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    GameControlsModule,
    EffectsModule.forFeature([GameEffects]),
  ],
  declarations: [
    GameComponent,
    GamePasswordComponent,
    GameRebuyComponent,
    GameAddonComponent,
    PlayerNoteComponent,
    PlayerReplaceCardsComponent,
    DialogLeaveTableComponent,
    FallGuysComponent,
    DialogPlayerSpellsComponent,
    AntibotComponent
  ],
  exports: [
    GameComponent,
    GameRebuyComponent,
    GameControlsModule,
    PlayerReplaceCardsComponent,
  ],
  providers: [GameService],
  entryComponents: [
    GamePasswordComponent,
    GameRebuyComponent,
    GameAddonComponent,
    PlayerNoteComponent,
    PlayerReplaceCardsComponent,
    DialogLeaveTableComponent
  ],
})
export class GameModule { }
