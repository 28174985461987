import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SharedModule } from '../shared/shared.module';
import { AuthEffects } from './auth-store/auth.effects';
import { AuthAdditionalInfoComponent } from './common/auth-additional-info/auth-additional-info.component';
import { RegistrationType } from '../shared/enums/registration-type';

import { LanguageComponent } from './language/language.component';



const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot_password',
    component: ForgotPasswordComponent
  },
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    EffectsModule.forFeature([AuthEffects]),
    RouterModule.forChild(routes),
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    AuthAdditionalInfoComponent,
    LanguageComponent,
  ],
  exports: [RouterModule],
})
export class AuthModule { }
