import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { map, pluck, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AuthService } from './auth.service';
import { getLoggedIn, getLoggedInState } from './auth-store/auth.reducer';
import { State } from '../app.reducers';
import { AppActions } from '../app.actions';
import { AppWebSocketService } from '../core/services/app-web-socket.service';
import { ManagerService } from '../core/services/manager.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private managerService: ManagerService
  ) { }

  canActivate(): Observable<boolean> {
    return this.managerService.loginState$
      .pipe(
        take(1),
        tap(loggedIn => {
          if (!loggedIn) {
            this._router.navigate(['/login']);
            return false;
          }
        })
      )

    // this.store.pipe(
    //   select(getLoggedInState),
    //   map(authed => {
    //     if (!authed) {
    //       this.store.dispatch(new AppActions.AuthAction.LoginRedirect());
    //       return false;
    //     }

    //     return true;
    //   }),
    //   take(1)
    // );
  }
}
