import { Action } from '@ngrx/store';
import { ENavItem } from '../nav-item-type';

export const CHANGE_LAYOUT = '[Nav] CHANGE_LAYOUT';
export const LOGOUT = 'LOGOUT';

export class ChangeLayout implements Action {
    readonly type = CHANGE_LAYOUT;

    constructor(public payload: ENavItem) { }
}

export class Logout implements Action {
    readonly type = LOGOUT;

    constructor() { }
}

export type All
    = ChangeLayout
    | Logout;
