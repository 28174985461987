import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';
import { anchorPoints, AnchorPosition } from './const';

export class TournamentTimers {
    private containerSize: Size = { width: 290, height: 63 };
    public container: PIXI.Container;
    private blindIncreaseText: PIXI.Text;
    private gameBreakText: PIXI.Text;
    private textStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 24,
        fill: '#EEEEEE',
        fontWeight: 'bold'
    });
    private background: PIXI.Graphics;
    private backgroundColor = 0x0;
    private backgroundAlpha = 0.5;

    private blindIncreaseTextPosition: Point = { x: 288, y: 15 };
    private gameBreakTextPosition: Point = { x: 288, y: 46 };

    blindTimer: number;
    breakTimer: number;

    constructor(position: PIXI.Point) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);

        this.background = new PIXI.Graphics();
        this.background.beginFill(this.backgroundColor, this.backgroundAlpha);
        this.background.drawRect(0, 0, this.containerSize.width, this.containerSize.height);
        this.background.endFill();
        this.container.addChild(this.background);

        this.blindIncreaseText = new PIXI.Text('Blind increase in: --:--', this.textStyle);
        this.blindIncreaseText.anchor.set(
            anchorPoints[AnchorPosition.middleRight].x,
            anchorPoints[AnchorPosition.middleRight].y);
        this.blindIncreaseText.position.set(this.blindIncreaseTextPosition.x, this.blindIncreaseTextPosition.y);
        this.container.addChild(this.blindIncreaseText);

        this.gameBreakText = new PIXI.Text('Game break in: --:--', this.textStyle);
        this.gameBreakText.anchor.set(
            anchorPoints[AnchorPosition.middleRight].x,
            anchorPoints[AnchorPosition.middleRight].y);
        this.gameBreakText.position.set(this.gameBreakTextPosition.x, this.gameBreakTextPosition.y);
        this.container.addChild(this.gameBreakText);
    }

    update(dt) {
        this.updateBlindTimer(dt);
        this.updateBreakTimer(dt);
    }

    setBlindTimer(time: number) {
        if (!time) { return; }
        this.blindTimer = time;
    }

    setBreakTimer(time: number) {

        if (!time) { return; }
        this.breakTimer = time;
    }

    private updateBlindTimer(dt) {
        this.blindTimer -= dt / 1000;
        if (this.blindTimer <= 0) {
            return;
        }
        const timeText = this.parseTime(this.blindTimer);

        this.blindIncreaseText.text = 'Blind increase in: ' + timeText;
    }

    private updateBreakTimer(dt) {

        this.breakTimer -= dt / 1000;

        if (this.breakTimer <= 0 || isNaN(this.breakTimer)) {
            return;
        }
        const timeText = this.parseTime(this.breakTimer);

        this.gameBreakText.text = 'Game break in: ' + timeText;
    }

    private parseTime(time: number): string {
        const secondsLeft: number = time % 60;
        const minutesLeft: number = time / 60;
        let minStr = '';
        let secStr = '';

        if (minutesLeft < 10) {
            minStr = '0' + Math.floor(minutesLeft).toString();
        } else {
            minStr = Math.floor(minutesLeft).toString();
        }

        if (secondsLeft < 10) {
            secStr = '0' + Math.floor(secondsLeft).toString();
        } else {
            secStr = Math.floor(secondsLeft).toString();
        }

        return minStr + ':' + secStr;
    }
}
