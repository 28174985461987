import { Component } from '@angular/core';

import { LostConnectionService } from './lost-connection.service';

@Component({
  selector: 'app-lost-connection',
  templateUrl: './lost-connection.component.html',
  styleUrls: ['./lost-connection.component.scss']
})
export class LostConnectionComponent {

  public visible = false;

  constructor(lostConnectionSevice: LostConnectionService) {
    lostConnectionSevice.networkStatus$
      .subscribe((isConnected: boolean) => {
        this.visible = !isConnected;
      });
  }

}
