import { MemberProfile, PlayerBalance, Gift, Settings } from '.';
import { CurrencyInfo } from './currency-info';
import { PlayerLevelStatus } from './player-level-status';

export interface Authenticate {
  token?: string;
  securityCode?: string;
  username?: string;
  password?: string;
}

export class User {
  authenticate: Authenticate;
  memberProfile: MemberProfile;
  playerBalance: Array<PlayerBalance>;
  gifts: Array<Gift>;
  selectedCurrency: CurrencyInfo;
  transactionsHistory: Array<any>;
  settings: Settings;
  exchangeRate: { [index: number]: number };
  playerLevelStatus: Array<PlayerLevelStatus>;

  constructor() {
    this.playerBalance = []
    this.playerLevelStatus = []
    this.exchangeRate = {}
  }
}
