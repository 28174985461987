import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-bounty-spell-active',
  templateUrl: './dialog-bounty-spell-active.component.html',
  styleUrls: ['./dialog-bounty-spell-active.component.scss']
})
export class DialogBountySpellActiveComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogBountySpellActiveComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {

  }

  ngOnInit(): void {
  }

}
