import * as PIXI from 'pixi.js';

export class BlindsText {
    public container: PIXI.Container;
    private blindsText: PIXI.Text;
    private blindsTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 26,
        fill: '#EEEEEE',
        fontWeight: 'bold'
    });

    constructor(position: PIXI.Point) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);

        this.blindsText = new PIXI.Text('', this.blindsTextStyle);
        this.blindsText.anchor.set(0.5);
        this.container.addChild(this.blindsText);
    }

    public setBlinds(smallBlind: number, ante: number) {
        let s = '';
        if (smallBlind > 0) {
            s = 'Blinds: ' + smallBlind + '/' + smallBlind * 2;
        }

        if (ante > 0) {
            s += ' Ante: ' + ante;
        }

        this.blindsText.text = s;
    }
}
