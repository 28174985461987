import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TournamentEndResult } from '../../../../shared/models';
import { Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'dialog-tournament-end-result',
  templateUrl: './dialog-tournament-end-result.component.html',
  styleUrls: ['./dialog-tournament-end-result.component.scss']
})
export class DialogTournamentEndResultComponent implements AfterViewInit {

  private timerSubscription: Subscription;
  public visible: boolean;
  public countdownValue = 30;

  constructor(
    public dialogRef: MatDialogRef<DialogTournamentEndResultComponent>,
    @Inject(MAT_DIALOG_DATA) public tournamentEndResult: TournamentEndResult) {
  }

  ngAfterViewInit(): void {
    this.startInterval();
  }

  private startInterval() {
    this.timerSubscription = timer(0, 1000)
      .pipe(
        map(() => --this.countdownValue)
      )
      .subscribe(() => {
        if (this.countdownValue <= 0) {
          this.timerSubscription.unsubscribe();
          this.dialogRef.close();
        }
      });
  }

}
