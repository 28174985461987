import { BehaviorSubject, Observable, Observer, Subject, interval, of, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
// import { Store } from '@ngrx/store';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { share, distinctUntilChanged, takeWhile, retry, catchError, finalize, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { State } from '../../app.reducers';
import { AppActions } from '../../app.actions';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { AuthService } from '../../auth/auth.service';
import { ConnectionStatus } from '../../shared/enums';
import { LostConnectionService } from '../lost-connection/lost-connection.service';
import { ConfigService } from './config.service';
import { RequestMessage } from '../../shared/enums/poker-types';

@Injectable()
export class AppWebSocketService {

  connectionStatus;
  private connectionSubject = new ReplaySubject<ConnectionStatus>(ConnectionStatus.close);
  connectionStatus$ = this.connectionSubject.asObservable();

  private messageReceivedSubject = new Subject<any>();
  messageReceivedObservable = this.messageReceivedSubject.asObservable();

  private wsObservable: WebSocketSubject<any>;

  activity$ = new ReplaySubject<void>(1);


  constructor(private configService: ConfigService) {

    this.connectionStatus$.pipe(
      tap((status) => {
        console.log("STATUS", status);
      }),

    ).subscribe((status) => {

      console.log("ACTIVITY", status);
    })
  }


  initializeWebSocket() {
    if (this.connectionStatus !== undefined && this.connectionStatus === ConnectionStatus.pending) {
      return
    }

    if (this.connectionStatus === ConnectionStatus.open) {
      this.wsObservable.unsubscribe();
      return // this will trigger CLOSE
    }

    this.setConnectionStatus(ConnectionStatus.pending);

    this.wsObservable = webSocket(this.configService.config.wsUri);

    this.wsObservable
      .pipe(
        //retry()
        finalize(() => {
        })
      ).subscribe({
        next: (msg) => {

          // Called whenever there is a message from the server.
          if (msg.Settings && this.connectionStatus !== ConnectionStatus.open) {

            this.setConnectionStatus(ConnectionStatus.open);

            // setTimeout(() => {
            //   this.messageReceivedSubject.next({
            //     Response: "AntiBotQuestion",
            //     AntiBotQuestion: {
            //       "Id": 3,
            //       "QuestionId": "Random",
            //       "Descriptions": {
            //         "Descriptions": [
            //           {
            //             "Language": "en",
            //             "QuestionText": "Where is teddy bear ",
            //             "OptionalText": "it is not yellow circle"
            //           },
            //           {
            //             "Language": "sr",
            //             "QuestionText": "Gde je medo",
            //             "OptionalText": "u sumi"
            //           }
            //         ]
            //       },
            //       "Image1": "https://testgame.theonlypoker.com/images/antibot/Bear.png",
            //       "Image2": "https://testgame.theonlypoker.com/images/antibot/Emoji.png",
            //       "Image3": "https://testgame.theonlypoker.com/images/antibot/Mario.png",
            //       "Image4": "https://testgame.theonlypoker.com/images/antibot/Phoenix.jpg",
            //       "AnswerId": 783,
            //       "SkippedCount": 0,
            //       "ForcePopup": false,
            //       "MaxWaitForCaptchaAnswer": 9,
            //       "IdTable": 16368
            //     }
            //   });

           // }, 2000);
          }

          this.messageReceivedSubject.next(msg);

        },
        error: (err) => {
          console.log("WS ERR ", err)
          // Called if at any point WebSocket API signals some kind of error.
          this.setConnectionStatus(ConnectionStatus.error);

        },
        complete: () => {
          console.log("WS COMPLETE")
          // Called when connection is closed (for whatever reason).
          this.setConnectionStatus(ConnectionStatus.close);
        }
      });


  }



  sendData(message) {
    console.log("SENDING", message)
    if (this.wsObservable) {
      console.log("SENDING 1", message)

      this.wsObservable.next(message);

      if (message.type !== RequestMessage.Ping) {
        this.activity$.next();
      }
    }
  }

  disconnect() {
    if (this.wsObservable) {
      this.wsObservable.complete()
    }
  }

  private setConnectionStatus(connectionStatus: ConnectionStatus) {
    this.connectionStatus = connectionStatus;
    this.connectionSubject.next(connectionStatus);
  }

}
