import { Action } from '@ngrx/store';
import { NavTableData } from '../../../shared/models/nav-table-data';
import { CardData } from '../../../shared/models';

export const ADD_GAME = '[Game] ADD_GAME';
export const REMOVE_GAME = '[Game] REMOVE_GAME';
export const REMOVE_TOURNAMENT = '[Game] REMOVE_TOURNAMENT';
export const UPDATE_GAME = '[Game] UPDATE_GAME';
export const SET_ACTIVE_GAME = '[Game] SET_ACTIVE_GAME';
export const SET_CARDS_IN_HAND = '[Game] SET_CARDS_IN_HAND';
export const SET_ON_MOVE = '[Game] SET_ON_MOVE';
export const SET_FOLDED = '[Game] SET_FOLDED';
export const LEAVE_SEAT = '[Game] LEAVE_SEAT';
export const SET_PLAYER_MONEY = '[Game] SET_PLAYER_MONEY';
export const LOGOUT = 'LOGOUT';

export class AddGame implements Action {
    readonly type = ADD_GAME;

    constructor(public payload: NavTableData) { }
}

export class SetActiveGame implements Action {
    readonly type = SET_ACTIVE_GAME;

    constructor(public payload: { tableId: number }) { }
}

export class UpdateGame implements Action {
    readonly type = UPDATE_GAME;

    constructor(public payload: NavTableData) { }
}

export class SetCardsInHand implements Action {
    readonly type = SET_CARDS_IN_HAND;

    constructor(public tableId: number, public cardsInHand: CardData[]) { }
}

export class SetPlayerMoney implements Action {
    readonly type = SET_PLAYER_MONEY;

    constructor(public tableId: number, public playerMoney: number) { }
}

export class SetOnMove implements Action {
    readonly type = SET_ON_MOVE;

    constructor(public tableId: number, public onMove: boolean) { }
}

export class SetFolded implements Action {
    readonly type = SET_FOLDED;

    constructor(public tableId: number, public folded: boolean) { }
}

export class LeaveSeat implements Action {
    readonly type = LEAVE_SEAT;

    constructor(public tableId: number) { }
}


export class RemoveGame implements Action {
    readonly type = REMOVE_GAME;

    constructor(public payload: { tableId: number }) { }
}

export class RemoveTournament implements Action {
    readonly type = REMOVE_TOURNAMENT;

    constructor(public payload: { tournamentId: number }) { }
}

export class Logout implements Action {
    readonly type = LOGOUT;

    constructor() { }
}

export type All
    = AddGame
    | SetActiveGame
    | UpdateGame
    | SetCardsInHand
    | SetPlayerMoney
    | SetOnMove
    | SetFolded
    | LeaveSeat
    | RemoveGame
    | RemoveTournament
    | Logout;
