import * as PIXI from 'pixi.js';
import { environment } from '../../../../environments/environment';
import { Point, Size } from '../models';
import { AnchorPosition } from './const';

export class TripleDrawRoundInfo {
    public container: PIXI.Container;
    private titleText: PIXI.Text;
    private titleTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 36,
        fill: '#FFF',
        fontWeight: '500'
    });
    private titlePosition: PIXI.Point = new PIXI.Point(265, 35); // center
    private containerSize: Size = { width: 530, height: 70 };
    private background: PIXI.Graphics;
    private backgroundColor = 0x0;
    private backgroundAlpha = 0.3;

    constructor() {


        if (environment.settings.useVerticalMode) {
            this.containerSize.width = 550
            this.containerSize.height = 70

            this.titlePosition.x = 275;
            //this.titlePosition.y = 800;

            this.backgroundAlpha = 0.2


        }


        this.container = new PIXI.Container();

        this.background = new PIXI.Graphics();
        this.background.beginFill(this.backgroundColor, this.backgroundAlpha);


        if (environment.settings.useVerticalMode) {
            this.background.drawRoundedRect(0, 0, this.containerSize.width, this.containerSize.height, 20);
        } else {
            this.background.drawRect(0, 0, this.containerSize.width, this.containerSize.height);
        }

        this.background.endFill();


        this.container.addChild(this.background);

        this.titleText = new PIXI.Text('title', this.titleTextStyle);


        this.titleText.position.set(this.titlePosition.x, this.titlePosition.y);
        this.titleText.anchor.set(0.5);
        this.container.addChild(this.titleText);

        this.container.visible = false;
    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {
            this.container.position.set(newPosition.x - this.containerSize.width / 2,
                newPosition.y - this.containerSize.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x, newPosition.y);
        }
    }

    setInfoText(info: string) {
        this.titleText.text = info;
        this.container.visible = true;
    }
}
