import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ask-question-r2-t',
  templateUrl: './ask-question-r2-t.component.html',
  styleUrls: ['./ask-question-r2-t.component.scss']
})
export class AskQuestionR2TComponent implements OnInit {

  countdownTime$: Observable<number>

  constructor(
    public dialogRef: MatDialogRef<AskQuestionR2TComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data.Timeout > 0) {
      this.countdownTime$ = timer(0, 1000)
        .pipe(
          map((timer) => data.Timeout/1000 - (timer + 1)), // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9
          tap((timer) => {
            if (timer <= 0) {
              this.cancel()
            }
          }),
          takeWhile((timer) => timer > 0)
        )

    }
  }

  ngOnInit(): void {
  }
  cancel() {
    this.dialogRef.close({ callback: this.data.Callback, idTable: this.data.IdTable, value: 0 });
  }
  confirm() {
    this.dialogRef.close({ callback: this.data.Callback, idTable: this.data.IdTable, value: 1 });
  }
}
