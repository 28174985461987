import { Component, OnInit } from '@angular/core';
import { AppTable } from '../../../shared/abstract-classes/app-table';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.scss']
})
export class TicketTableComponent extends AppTable {

  constructor() {
    super();
  }

  sortData(sort: Sort) {
  }

}
