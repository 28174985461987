import * as PIXI from 'pixi.js';
import { Point } from '../models';

export class ChatBubble {
    private messageTextPosition: Point = { x: 96, y: 47 }; // 40 18

    private messageTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: 18,
        fill: '#000000',
        wordWrap: true,
        wordWrapWidth: 130,
        align: 'center'
    });

    public container: PIXI.Container;
    private bubbleSprite: PIXI.Sprite;
    private messegeText: PIXI.Text;
    private messageToDisplay: string;
    private charIndex = 0;
    private timerRef: NodeJS.Timer;

    constructor(textures, message: string, position: Point) {
        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);
        this.container.scale.set(1.5)

        this.bubbleSprite = new PIXI.Sprite(textures['chatBubble'].texture);
        this.container.addChild(this.bubbleSprite);

        this.messegeText = new PIXI.Text('', this.messageTextStyle);
        this.messegeText.anchor.set(0.5);
        this.messegeText.position.set(this.messageTextPosition.x, this.messageTextPosition.y);
        this.container.addChild(this.messegeText);

        this.messageToDisplay = message;

        this.timerRef = setInterval(() => this.doTextAnimation(), 25);
    }

    private doTextAnimation() {
        if (this.charIndex >= this.messageToDisplay.length) {
            clearInterval(this.timerRef);
            setTimeout(() => this.selfDistruct(), 2000);
            return;
        }
        this.charIndex++;
        this.messegeText.text = this.messageToDisplay.substring(0, this.charIndex);
    }

    private selfDistruct() {
        this.container.parent.removeChild(this.container);
    }
}
