import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { Store } from '@ngrx/store';
import { State } from '../../../app.reducers';
import { getUserState } from '../../../auth/auth-store/auth.reducer';
import { AppWebSocketService } from '../../../core/services/app-web-socket.service';

import { ServerMsg } from '../../models';
import { User } from '../../models/user';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNotification implements OnInit {

  public user: User;


  constructor(
    // private store: Store<State>,
    public dialogRef: MatDialogRef<DialogNotification>,
    @Inject(MAT_DIALOG_DATA) public data: ServerMsg,
    //public _webSocketService: AppWebSocketService,
    ) { }

  ngOnInit() {

    // this.store.select(getUserState).subscribe(user => {
    //   this.user = user;
    // });
    // this._webSocketService.memberProfile$.subscribe({
    //   next: (memberProfile) => {
    //     this.user = memberProfile
    //   }
    // })
  }


  close() {
    this.dialogRef.close();
  }


}
