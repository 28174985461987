import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RoutingModule } from './app-routing.module';
import { reducers } from './app.reducers';
import { environment } from '../environments/environment';
import { RecaptchaModule } from 'ng-recaptcha';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { ToastrModule } from 'ngx-toastr';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
import { MatSliderModule } from '@angular/material/slider';
import { HelperService } from './core/services/helper.service';
import { ManagerService } from './core/services/manager.service';
import { ConfigService } from './core/services/config.service';
import { WINDOW_PROVIDERS } from './core/services/window.providers';
import { v3ManagerService } from './core/services/v3-manager.service';
import { PwaService } from './main/pwa.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';


export function setConfig(config: ConfigService) {
  return () => config.load();
}
const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();
@NgModule({
  imports: [
    BrowserModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 40 //  Retains last 25 states
    }) : [],
    EffectsModule.forRoot([]),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingModule,
    // RecaptchaModule.forRoot(),
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: false,
      enableHtml: true,
      closeButton: true

      // OLD v1 TO BE REMOVODED
      // positionClass: 'toast-bottom-right',
      // preventDuplicates: true,
      // progressBar: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HammerModule,
    MatBottomSheetModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    { provide: APP_INITIALIZER, useFactory: setConfig, deps: [ConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    AuthService,
    AuthGuard,
    ManagerService,
    v3ManagerService,
    HelperService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {
        disabled: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
