import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ManagerService } from '../../../../core/services/manager.service';
import { ENavItem } from '../../../nav/nav-item-type';
import { TournamentStatus2 } from '../../../../shared/enums/poker-types';
import { Tournament } from '../../../../shared/models';
import { Ticket } from '../../../../shared/models/ticket';
import { User } from '../../../../shared/models/user';
import { DialogTournamentRegistrationComponent } from '../dialog-tournament-registration/dialog-tournament-registration.component';

export enum TournamentRegistrationType {
  ANNOUNCED,
  UNREGISTER,
  REGISTER,
  JUMP_IN,
  REGISTRATION_CLOSED,
  COMPLETED,
  CANCELED,
  OPEN_MY_TABLE
}

@Component({
  selector: 'button-tournament-registration',
  templateUrl: './button-tournament-registration.component.html',
  styleUrls: ['./button-tournament-registration.component.scss']
})
export class ButtonTournamentRegistrationComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  tournament: Tournament;

  text: string;
  type: TournamentRegistrationType;
  enumTournamentRegistrationType = TournamentRegistrationType
  enabled: boolean;


  user: User;
  playerTickets: Array<Ticket> = [];
  myActiveTable: number;

  constructor(
    private managerService: ManagerService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user;
        }
      })

    this.managerService.playerTickets$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tickets: Ticket[]) => {
        this.playerTickets = tickets;
      });


    this.managerService.viewTournament$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (viewingTournament) => {
          this.tournament = viewingTournament;
          this.getRegisterButtonText();
        }
      })
  }



  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getRegisterButtonText() {
    for (const item of Object.keys(this.managerService.activeGames)) {
      if (this.managerService.activeGames[item]?.table?.IdTournament === this.tournament?.tourSum?.Id) {
        this.myActiveTable = +item
      }
    }


    if (this.myActiveTable) {
      this.text = 'Open My Table';
      this.type = this.enumTournamentRegistrationType.OPEN_MY_TABLE
      this.enabled = true;
      return
    }

    if (!this.tournament || !this.tournament.tourSum) { return }

    console.log("@this.tournament.tourSum.Status2", this.tournament.tourSum.Status2)
    switch (this.tournament.tourSum.Status2) {
      case TournamentStatus2.Announced: {
        this.text = 'tournamentRegister.announced';
        this.type = this.enumTournamentRegistrationType.ANNOUNCED
        this.enabled = false;
        break;
      }
      case TournamentStatus2.RegistrationOpen: {
        if (this.tournament.isRegistered) {
          this.type = this.enumTournamentRegistrationType.UNREGISTER
          this.text = 'tournamentRegister.unregister';

          // this.translateService.get('TITLE.UNREGISTER').subscribe(trans => {
          //   this.registerButtonText = trans;
          // });
        } else {
          this.type = this.enumTournamentRegistrationType.REGISTER
          this.text = 'tournamentRegister.register';

          // this.translateService.get('BUTTON.REGISTER').subscribe(trans => {
          //   this.registerButtonText = trans;
          // });
        }
        this.enabled = true;

        break;
      }
      case TournamentStatus2.RunningWithLateRegistration: {
        if (this.tournament.isRegistered) {
          if (this.tournament.tourSum.ImRegistered &&
            this.tournament.tourSum.ImEliminated &&
            this.tournament.tourSum.OptionReEntry) {
            this.enabled = true;
          } else {
            this.enabled = false;
          }
        } else {
          this.enabled = true;
        }

        if (this.myActiveTable) {
          this.text = 'tournamentRegister.openMyTable';
          this.type = this.enumTournamentRegistrationType.OPEN_MY_TABLE
          this.enabled = true;
        } else {
          this.text = 'tournamentRegister.jumpIn';
          this.type = this.enumTournamentRegistrationType.JUMP_IN

        }


        // this.translateService.get('BUTTON.JUMPIN').subscribe(trans => {
        //   this.registerButtonText = trans;
        // });
        break;
      }
      case TournamentStatus2.RunningRegistrationClosed: {
        this.text = 'tournamentRegister.registrationClosed';
        this.type = this.enumTournamentRegistrationType.REGISTRATION_CLOSED


        this.enabled = false;
        break;
      }
      case TournamentStatus2.Completed: {
        this.text = 'tournamentRegister.completed';
        this.type = this.enumTournamentRegistrationType.COMPLETED


        this.enabled = false;
        break;
      }
      case TournamentStatus2.Canceled: {
        this.text = 'tournamentRegister.cancelled';
        this.type = this.enumTournamentRegistrationType.CANCELED

        this.enabled = false;
        break;
      }
      default:
        this.enabled = false;
    }
    console.log("@this.tournament.tourSum.Status2", this.enabled, this.text)

  }

  onClick() {
    if (!this.enabled) { return }

    if (this.type === this.enumTournamentRegistrationType.OPEN_MY_TABLE) {
      this.managerService.setActiveGame(this.myActiveTable)
      this.managerService.setActiveTab(ENavItem.Game)
    } else if (this.tournament.isRegistered) {
      if (this.tournament.tourSum.ImRegistered &&
        this.tournament.tourSum.ImEliminated &&
        this.tournament.tourSum.OptionReEntry &&
        this.tournament.tourSum.NbAddonUsed < this.tournament.tourSum.ReBuyNbLimit) {


        this.dialog.open(DialogTournamentRegistrationComponent, {
          data: {
            tournament: this.tournament,
            user: this.user,
            playerTickets: this.playerTickets
          },
          width: '300px'
        }).afterClosed().subscribe(data => {
          if (data) {
            this.managerService.tournamentRegister(this.tournament.tourSum.Id);
          }
        });

      } else {
        this.managerService.tournamentUnregister(this.tournament.tourSum.Id);
      }
    } else {
      this.dialog.open(DialogTournamentRegistrationComponent, {
        data: {
          tournament: this.tournament,
          user: this.user,
          playerTickets: this.playerTickets
        },
        width: '300px'
      }).afterClosed().subscribe(data => {
        if (data) {
          this.managerService.tournamentRegister(this.tournament.tourSum.Id);
        }
      });
    }
  }


}
