import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreModule } from '../../../core/core.module';
import { GameControlsComponent } from './game-controls/game-controls.component';
import {
  GameControlsCommonReplayComponent
} from './game-controls-common/game-controls-common-replay/game-controls-common-replay.component';
import { GameControlsCommonImBackComponent } from './game-controls-common/game-controls-common-im-back/game-controls-common-im-back.component';
import { GameControlsCommonPostBigBlindComponent } from './game-controls-common/game-controls-common-post-big-blind/game-controls-common-post-big-blind.component';
import { GameWaitingListComponent } from './game-controls-common/game-waiting-list/game-waiting-list.component';
import { GameControlsAdditionalDataComponent } from './game-controls-common/game-controls-additional-data/game-controls-additional-data.component';
import { GameControlsChatComponent } from './game-controls-common/game-controls-chat/game-controls-chat.component';
import { GameControlsHistoryComponent } from './game-controls-common/game-controls-history/game-controls-history.component';
import { GameControlsCollapsableComponent } from './game-controls-common/game-controls-collapsable/game-controls-collapsable.component';
import { GameStatsComponent } from './game-controls-common/game-stats/game-stats.component';
import { SharedModule } from '../../../shared/shared.module';
import { GamePreBetControlsComponent } from './game-pre-bet-controls/game-pre-bet-controls.component';
import { GameChatAdvanceComponent } from './game-controls-common/game-chat-advance/game-chat-advance.component';
import { GameChatBubbleComponent } from './game-controls-common/game-chat-advance/game-chat-bubble/game-chat-bubble.component';


import { GameControlsDesktopComponent } from './game-controls/game-controls-desktop/game-controls-desktop.component';
import { GameControlsMobileVerticalComponent } from './game-controls/game-controls-mobile-vertical/game-controls-mobile-vertical.component';
import { GameControlsMobileLandscapeComponent } from './game-controls/game-controls-mobile-landscape/game-controls-mobile-landscape.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
  ],
  declarations: [
    GameControlsComponent,
    GameControlsCommonReplayComponent,
    GameControlsCommonImBackComponent,
    GameControlsCommonPostBigBlindComponent,
    GameWaitingListComponent,
    GameControlsAdditionalDataComponent,
    GameControlsChatComponent,
    GameControlsHistoryComponent,
    GameControlsCollapsableComponent,
    GameStatsComponent,
    GamePreBetControlsComponent,
    GameChatAdvanceComponent,
    GameChatBubbleComponent,

    GameControlsDesktopComponent,
    GameControlsMobileVerticalComponent,
    GameControlsMobileLandscapeComponent,

  ],
  exports: [
    GameWaitingListComponent,
    GameControlsAdditionalDataComponent,
    GameControlsCollapsableComponent,
    GameControlsComponent,

  ],
})
export class GameControlsModule { }
