import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isValid, differenceInMonths } from 'date-fns';
import { interval } from 'rxjs';

// SINGLE FIELD VALIDATORS
export function emailValidator(control: FormControl): { [key: string]: any } {
  const emailRegexp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

// FORM GROUP VALIDATORS
export function passwordMatchValidator(g: FormGroup) {
  return g.get('password').value === g.get('passwordConfirm').value
    ? null : { 'mismatch': true };
}

export function whitespaceValidator(control: FormControl) {
  const letterNumberRegexp = /^\S+$/;
  if (control.value && !letterNumberRegexp.test(control.value)) {
    return { whitespace: true };
  }
}

export function dateValidator(control: FormControl): ValidationErrors {
  if (!validateDateText(control.value)) {
    return { invalidDate: true };
  }

  const inputDate = new Date(control.value);
  const monthDiff = differenceInMonths(new Date(), inputDate);
  if (monthDiff < 18 * 12) { return { underAge: true }; }
}

function validateDateText(inputText) {
  const dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if (inputText.match(dateformat)) {
    // Test which seperator is used '/' or '-'
    const opera1 = inputText.split('/');
    const opera2 = inputText.split('-');
    const lopera1 = opera1.length;
    const lopera2 = opera2.length;
    // Extract the string into month, date and year
    let pdate;
    if (lopera1 > 1) {
      pdate = inputText.split('/');
    } else if (lopera2 > 1) {
      pdate = inputText.split('-');
    }
    const mm = parseInt(pdate[0], 10);
    const dd = parseInt(pdate[1], 10);
    const yy = parseInt(pdate[2], 10);
    // Create list of days of a month [assume there is no leap year by default]
    const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (mm === 1 || mm > 2) {
      if (dd > ListofDays[mm - 1]) {
        return false;
      }
    }

    if (mm === 2) {
      let lyear = false;
      if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
        lyear = true;
      }
      if ((lyear === false) && (dd >= 29)) {
        return false;
      }
      if ((lyear === true) && (dd > 29)) {
        return false;
      }
    }

  } else {
    return false;
  }

  return true;
}
