export enum BetActions {
    PostBigBlind,
    Fold,
    Call,
    Check,
    Raise,
    SliderDecrease,
    SliderMin,
    SliderChange,
    SliderInputChange,
    SliderIncrease,
    SliderMax,
    BB2,
    BB3,
    BB4,
    QuarterPot,
    ThreeQuartersPot,
    HalfPot,
    Pot
}

export enum ReplayActions {
    Play,
    Next,
    Stop,
    Restart
}

export enum ControlActions {
    BuyRebuyChips,
    DealerTip,
    ImBack,
    LeaveTable,
    ShowTourInfo,
    LeaveSeat,
    RunTwoTimes,
    SitoutNextHand,

    StandPat,
    Discard,

    ReplayPreviousHand,
    OfferRabbitHunting,
    Straddle
}
