import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

import { environment } from '../../../environments/environment';
import { DataManagerService } from '../../core/services/data-manager.service';

enum ExclusionPeriod {
  none = 0,
  oneWeak = 1,
  oneMonth = 2
}

@Component({
  selector: 'app-self-exclude',
  templateUrl: './self-exclude.component.html',
  styleUrls: ['./self-exclude.component.scss']
})
export class SelfExcludeComponent implements OnInit {
  public selectedPeriod: ExclusionPeriod = ExclusionPeriod.none;
  public riskAccepted = false;
  public password = '';
  public showInfo = false;
  public environment = environment;

  constructor(
    public dialogRef: MatDialogRef<SelfExcludeComponent>,
    private dataManager: DataManagerService
  ) { }

  ngOnInit() {
  }

  radioChange(event: MatRadioChange) {
    this.selectedPeriod = event.value;
  }

  checkBoxChange(check: boolean) {
    this.riskAccepted = check;
  }

  onApplyClick() {
    if (this.selectedPeriod !== ExclusionPeriod.none && this.riskAccepted && this.password) {
      this.dataManager.sendSelfExclude(this.selectedPeriod, this.password);
    } else {

    }

    this.dialogRef.close();
  }

}
