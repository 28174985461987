import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { Point } from '../../../main/game/models/point';
import { cardDecoder, cardNumbers, cardSuit } from '../../helpers/card-decoder';
import { CardData } from '../../models/card.data';


@Component({
  selector: 'app-cards-preview',
  templateUrl: './cards-preview.component.html',
  styleUrls: ['./cards-preview.component.scss']
})
export class CardsPreviewComponent implements AfterViewInit {
  private _cards: CardData[] = [];

  @ViewChild('cardsCanvas', { static: false }) cardsCanvas: ElementRef;
  public context: CanvasRenderingContext2D;

  @Input()
  set cards(cards: CardData[]) {
    this._cards = cards.map(card => {
      if (card.Number === cardNumbers.Jack) {
        card.formatNumber = 'J';
      } else if (card.Number === cardNumbers.Queen) {
        card.formatNumber = 'Q';
      } else if (card.Number === cardNumbers.King) {
        card.formatNumber = 'K';
      } else if (card.Number === cardNumbers.Ace) {
        card.formatNumber = 'A';
      } else if (card.Number === cardNumbers.T) {
        card.formatNumber = 'T';
      } else {
        card.formatNumber = `${card.Number}`
      }
      if (card.Suit === cardSuit.Hearts) {
        card.symbol = '♥';
      } else if (card.Suit === cardSuit.Diamods) {
        card.symbol = '♦';
      } else if (card.Suit === cardSuit.Clubs) {
        card.symbol = '♣';
      } else if (card.Suit === cardSuit.Spades) {
        card.symbol = '♠';
      }

      return card
    });
    // if (this.cardsCanvas && this.context) {
    //   this.drawCards(cards);
    // }

    // console.log("cards", cards)
    // for (const card of cards) {
    //   console.log("---", cardDecoder(card.Suit, card.Number))
    // }
  }
  get cards(): CardData[] {
    return this._cards;
  }

  @Input() scale: number = 1;
  @Input() offset: boolean = true;

  // public offset = 18;
  // public offset2 = 36;

  constructor(public loaderService: AssetsLoaderService) { }

  ngAfterViewInit(): void {
   // this.context = (<HTMLCanvasElement>this.cardsCanvas.nativeElement).getContext('2d');

 //   this.drawCards(this.cards);
  }

  private drawCard(atlasImage: HTMLImageElement, frame: any, cardPosition: Point) {
    this.context.drawImage(
      atlasImage,
      frame.x,
      frame.y,
      frame.w,
      frame.h,
      cardPosition.x,
      cardPosition.y,
      frame.w,
      frame.h
    );
  }

  drawCards(cards: CardData[] = []) {

    if (!cards) { return; }
    if (cards.length === 0) {
      this.context.clearRect(0, 0, this.cardsCanvas.nativeElement.width, this.cardsCanvas.nativeElement.height);
      return;
    }
    const atlasImage = this.loaderService.textures['tableCardAtlas_image'].data;
    cards.forEach((card, index) => {

      const frame = this.loaderService.textures['tableCardAtlas'].data.frames[card.Name + '_table.png'].frame;

      const offset = this.offset ? (this.cardsCanvas.nativeElement.width / 2) - (frame.w / 2) * cards.length : 0;

      const position: Point = { x: offset + frame.w * index, y: 0 };
      this.drawCard(atlasImage, frame, position);
    });
  }
}
