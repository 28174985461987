import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { GameControlsBase } from '../../game-controls.base';
import { ReplayActions } from '../../game-controls-enum';

@Component({
  selector: 'app-game-controls-common-replay',
  templateUrl: './game-controls-common-replay.component.html',
  styleUrls: ['./game-controls-common-replay.component.scss']
})
export class GameControlsCommonReplayComponent implements OnInit {

  @Output() replayAction = new EventEmitter();
  constructor() {
  }

  ngOnInit() {
  }

  replayPlay() {
    this.replayAction.emit({ actionType: ReplayActions.Play, data: null });
  }

  replayNext() {
    this.replayAction.emit({ actionType: ReplayActions.Next, data: null });
  }

  replayStop() {
    this.replayAction.emit({ actionType: ReplayActions.Stop, data: null });
  }

  replayRestart() {
    this.replayAction.emit({ actionType: ReplayActions.Restart, data: null });
  }

}
