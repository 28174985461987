import { LinkDefinition } from '../app/core/services/link-html.service';
import { environment } from '../environments/environment';

export interface AssetIcon {
    name: string;
    path: string;
}

export interface AssetPixi {
    name: string;
    path: string;
}

export interface Assets {
    icons: AssetIcon[]
    tagLinks: LinkDefinition[];
    pixi: AssetPixi[];
}

export const ASSETS: Assets = {
    icons: [
        {
            name: 'currency_btc',
            path: 'assets/common/icons/currency/btc.svg'
        },
        {
            name: 'currency_btc',
            path: 'assets/common/icons/currency/btc.svg'
        },
        {
            name: 'currency_chip',
            path: 'assets/common/icons/currency/chip.svg'
        },
        {
            name: 'currency_dollar',
            path: 'assets/common/icons/currency/dollar.svg'
        },
        {
            name: 'currency_eth',
            path: 'assets/common/icons/currency/eth.svg'
        },
        {
            name: 'currency_euro',
            path: 'assets/common/icons/currency/euro.svg',
        },
        {
            name: 'currency_iranian_rial',
            path: 'assets/common/icons/currency/iranian_rial.svg',
        },
        {
            name: 'currency_mnt',
            path: 'assets/common/icons/currency/mnt.svg',
        },
        {
            name: 'currency_play_money',
            path: 'assets/common/icons/currency/play_money.svg',
        },
        {
            name: 'currency_rupee',
            path: 'assets/common/icons/currency/rupee.svg',
        },
        {
            name: 'currency_rvn',
            path: 'assets/common/icons/currency/rvn.svg',
        },
        {
            name: 'currency_turkish_lira',
            path: 'assets/common/icons/currency/turkish_lira.svg',
        },
        {
            name: 'currency_ukrainian_hryvnia',
            path: 'assets/common/icons/currency/ukrainian_hryvnia.svg',
        },
        {
            name: 'currency_xgalaxy',
            path: 'assets/common/icons/currency/xgalaxy.svg',
        },
        {
            name: 'top_btc',
            path: 'assets/common/icons/top_btc.svg'
        },
        {
            name: 'top_eth',
            path: 'assets/common/icons/top_eth.svg'
        },
        {
            name: 'top_tourney_info',
            path: 'assets/common/icons/top_tourney_info.svg'
        },
        {
            name: 'top_stopwatch',
            path: `assets/common/icons/top_stopwatch.svg`
        },
        {
            name: 'top_expend_more',
            path: `assets/common/icons/top_expend_more.svg`
        },
        {
            name: 'top_chat_icon',
            path: `assets/common/icons/top_chat_icon.svg`
        },
        {
            name: 'top_chip_icon',
            path: `assets/common/icons/top_chip_icon.svg`
        },
        {
            name: 'top_chip_add',
            path: `assets/common/icons/top_chip_add.svg`
        },
        {
            name: 'top_settings',
            path: `[$CODE]/icons/lobby_settings_icon.svg`
        },
        {
            name: 'top_logout',
            path: `[$CODE]/icons/lobby_logout_icon.svg`
        },
        {
            name: 'top_cash_game',
            path: `[$CODE]/icons/lobby_cash_game_icon.svg`
        },
        {
            name: 'top_sitngo',
            path: `[$CODE]/icons/lobby_sitngo_icon.svg`
        },
        {
            name: 'top_tournament',
            path: `[$CODE]/icons/lobby_tournament_icon.svg`
        },
        {
            name: 'top_table_info',
            path: `[$CODE]/icons/tour_table_info.svg`
        },
        {
            name: 'top_player_and_prizes',
            path: `[$CODE]/icons/tour_players_and_prizes.svg`
        },
        {
            name: 'top_active_table',
            path: `[$CODE]/icons/tour_active_table.svg`
        },
        {
            name: 'top_structure',
            path: `[$CODE]/icons/tour_structure.svg`
        },
        {
            name: 'top_telegram',
            path: `assets/common/icons/top_telegram.svg`
        },
        {
            name: 'top_twitter',
            path: `assets/common/icons/top_twitter.svg`
        },
        {
            name: 'top_instagram',
            path: `assets/common/icons/top_instagram.svg`
        },
        {
            name: 'filter_list',
            path: `assets/common/icons/filter_list.svg`
        },
        {
            name: 'discord_icon',
            path: `assets/common/icons/discord-logo-white.svg`
        },
        {
            name: 'skin_settings',
            path: `assets/common/icons/quick-info/menu-theme.svg`
        },
        {
            name: 'cashier',
            path: `assets/common/icons/quick-info/menu-dealer.svg`
        },
        {
            name: 'hand_history',
            path: `assets/common/icons/quick-info/menu-history.svg`
        },
        {
            name: 'reset_password',
            path: `assets/common/icons/quick-info/menu-password.svg`
        },
        {
            name: 'lobby-sng',
            path: `assets/common/icons/v2/lobby-sng.svg`
        },
        {
            name: 'lobby-spin',
            path: `assets/common/icons/v2/lobby-spin.svg`
        },
        {
            name: 'lobby-ring',
            path: `assets/common/icons/v2/lobby-ring.svg`
        },
        {
            name: 'lobby-play-now',
            path: `assets/common/icons/v2/lobby-play-now.svg`
        },
        {
            name: 'lobby-tourney',
            path: `assets/common/icons/v2/lobby-tourney.svg`
        },
        {
            name: 'lobby-register',
            path: `assets/common/icons/v2/lobby-register.svg`
        },
        {
            name: 'lobby-unregister',
            path: `assets/common/icons/v2/lobby-unregister.svg`
        },
        {
            name: 'vertical-slider-open',
            path: `assets/common/icons/v2/vertical-slider-open.svg`
        },
        {
            name: 'ticket',
            path: `assets/common/icons/v2/ticket.svg`
        },
        {
            name: 'logout',
            path: `assets/common/icons/v2/logout.svg`
        },
        {
            name: 'select-down-arrow',
            path: `assets/common/icons/v2/select-down-arrow.svg`
        },
        {
            name: 'filters',
            path: `assets/common/icons/v2/filters.svg`
        },
        {
            name: 'sort-down-arrow',
            path: `assets/common/icons/v2/sort-down-arrow.svg`
        },
        {
            name: 'sort-up-arrow',
            path: `assets/common/icons/v2/sort-up-arrow.svg`
        },
        {
            name: 'chat_box_predefined',
            path: `assets/common/icons/chat_box_predefined.svg`
        },
        {
            name: 'chat_box_mute',
            path: `assets/common/icons/chat_box_mute.svg`
        },
        {
            name: 'chat_box_text',
            path: `assets/common/icons/chat_box_text.svg`
        },
        {
            name: 'chat_box_stickers',
            path: `assets/common/icons/chat_box_stickers.svg`
        },
        {
            name: 'chat_box_history',
            path: `assets/common/icons/chat_box_history.svg`
        },
        {
            name: 'chat_box_stats',
            path: `assets/common/icons/chat_box_stats.svg`
        },
        {
            name: 'menu',
            path: `assets/common/icons/v2/menu.svg`
        },
        {
            name: 'settings',
            path: `assets/common/icons/settings.svg`
        },
        {
            name: 'home',
            path: `assets/common/icons/home.svg`
        },
        {
            name: 'exit',
            path: `assets/common/icons/exit.svg`
        },
        {
            name: 'cashier-deposit',
            path: `assets/common/icons/cashier/deposit.svg`
        },
        {
            name: 'cashier-history',
            path: `assets/common/icons/cashier/history.svg`
        },
        {
            name: 'cashier-transfer',
            path: `assets/common/icons/cashier/transfer.svg`
        },
        {
            name: 'cashier-verify',
            path: `assets/common/icons/cashier/verify.svg`
        },
        {
            name: 'cashier-withdraw',
            path: `assets/common/icons/cashier/withdraw.svg`
        },
        {
            name: 'tourney-active-tables',
            path: `assets/common/icons/tourney/active-tables.svg`
        },
        {
            name: 'tourney-info',
            path: `assets/common/icons/tourney/info.svg`
        },
        {
            name: 'tourney-prizes',
            path: `assets/common/icons/tourney/prizes.svg`
        },
        {
            name: 'tourney-structure',
            path: `assets/common/icons/tourney/structure.svg`
        },
        {
            name: 'tourney-checkmark',
            path: `assets/common/icons/tourney-checkmark.svg`
        },
        {
            name: 'add-plus',
            path: `assets/common/icons/add-icon.svg`
        },
        {
            name: 'emoticons-gif',
            path: `assets/common/icons/emoticons-gif.svg`
        },
        {
            name: 'emoticons-text',
            path: `assets/common/icons/emoticons-text.svg`
        },
        {
            name: 'emoticons-shopping-cart',
            path: `assets/common/icons/emoticons-shopping-cart.svg`
        },
        {
            name: 'check-mark',
            path: `assets/common/icons/check-mark.svg`
        },
        {
            name: 'clock-1',
            path: `assets/common/icons/clock-1.svg`
        },


        {
            name: 'gifts-tickets-bonuses',
            path: `assets/common/icons/gifts-tickets/bonuses.svg`
        },
        {
            name: 'gifts-tickets-rakeback',
            path: `assets/common/icons/gifts-tickets/rakeback.svg`
        },
        {
            name: 'gifts-tickets-raf',
            path: `assets/common/icons/gifts-tickets/raf.svg`
        },
        {
            name: 'gifts-tickets-tickets',
            path: `assets/common/icons/gifts-tickets/tickets.svg`
        },
        {
            name: 'replay',
            path: `assets/common/icons/replay.svg`
        },

    ],
    tagLinks: [
        {
            rel: 'stylesheet',
            href: './assets/common/cards/small-cards.css',
            type: 'text/css'
        },
        {
            rel: 'icon',
            href: `[$CODE]/fav-launcher-icons/favicon-16x16.png`
        },
        {
            name: 'apple-mobile-web-app-title',
            content: '[$TITLE]'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-57x57.png`,
            sizes: '57x57'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-60x60.png`,
            sizes: '60x60'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-72x72.png`,
            sizes: '72x72'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-76x76.png`,
            sizes: '76x76'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-144x144.png`,
            sizes: '144x144'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-120x120.png`,
            sizes: '120x120'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-152x152.png`,
            sizes: '152x152'
        },
        {
            rel: 'apple-touch-icon',
            href: `[$CODE]/fav-launcher-icons/apple-icon-180x180.png`,
            sizes: '180x180'
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: `[$CODE]/fav-launcher-icons/android-icon-192x192.png`,
            sizes: '192x192'
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: `[$CODE]/fav-launcher-icons/favicon-16x16.png`,
            sizes: '16x16'
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: `[$CODE]/fav-launcher-icons/favicon-32x32.png`,
            sizes: '32x32'
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: `[$CODE]/fav-launcher-icons/favicon-96x96.png`,
            sizes: '96x96'
        }
    ],
    pixi: [
        { name: `nbRebuyUsed`, path: `assets/common/player/nb-rebuy-used.png` },
        { name: `straddle`, path: `assets/common/player/straddle.png` },
        { name: `dealer`, path: `[$CODE]/tables/dealer.png` },
        { name: `emptyAvatar`, path: `[$CODE]/player/avatar.png` },
        { name: `avatarMask`, path: `assets/common/player/avatarMask.png` },
        { name: `chip`, path: `[$CODE]/chips/chip.png` },
        { name: `seatDefault`, path: `[$CODE]/seat/take_a_seat_default.png` },
        { name: `seatClick`, path: `[$CODE]/seat/take_a_seat_click.png` },
        { name: `playerStar`, path: `assets/common/icons/ic_player_level_star.png` },
        { name: `tableShadow`, path: `assets/common/table/table_shadow.png` },
        { name: `chatBubble`, path: `assets/common/standard/chat/chatBubble.png` },
        { name: `playerDefaultAvatars`, path: `assets/common/player/avatars.json` },
        { name: `playerNoteIcon`, path: `assets/common/player/noteIcon.png` },
        { name: `cardRabbitHunting`, path: `assets/common/cards/rabbit-hunting.png` },
        { name: `playerDefault`, path: `[$CODE]/player/player_default.png` },
        { name: `playerActive`, path: `[$CODE]/player/player_active.png` },
        { name: `playerFold`, path: `[$CODE]/player/player_fold.png` },
        { name: `playerSitout`, path: `[$CODE]/player/player_sitout.png` },
        { name: `playerDisconnected`, path: `[$CODE]/player/player_disconnected.png` },
        { name: `avatarDefault`, path: `[$CODE]/player/avatar_default.png` },
        { name: `avatarActive`, path: `[$CODE]/player/avatar_active.png` },
        { name: `avatarFold`, path: `[$CODE]/player/avatar_fold.png` },
        { name: `avatarSitout`, path: `[$CODE]/player/avatar_sitout.png` },
        { name: `avatarDisconnected`, path: `[$CODE]/player/avatar_disconnected.png` },
        { name: `playerProMark`, path: `assets/common/player/pro-mark.png` },
        { name: `logo`, path: `[$CODE]/logo.png` },
        { name: `barBackground`, path: `[$CODE]/timer/barBackground.png` },
        { name: `barCap`, path: `[$CODE]/timer/barCap.png` },
        { name: `barMiddle`, path: `[$CODE]/timer/barMiddle.png` },
        { name: `barGlow`, path: `[$CODE]/timer/barGlow.png` },
        { name: `bountyTarget`, path: `assets/common/player/bountyTarget.png` },
        { name: `animDealer`, path: `assets/common/dealers/animDealer.json` },
        { name: `spinRays`, path: `assets/common/spinNgo/spinRays.png` },
        { name: `spinLogo`, path: `assets/common/spinNgo/spinLogo.png` },
        { name: `spinNumbers`, path: `assets/common/spinNgo/numbers.jpg` },
        { name: `spinSlotF1`, path: `assets/common/spinNgo/spinSlotF1.png` },
        { name: `spinSlotF2`, path: `assets/common/spinNgo/spinSlotF2.png` },
        { name: `spinNumberBlock1`, path: `assets/common/spinNgo/number-block-1.jpg` },

        { name: `tableLogo`, path: `[$CODE]/tables/table_logo.png` },

        { name: `callTimeAttention`, path: `assets/common/icons/call-time/attention.png` },
        { name: `callTimeCheck`, path: `assets/common/icons/call-time/check.png` },
        { name: `callTimeBackground`, path: `assets/common/icons/call-time/background.png` },

        { name: `bountyTargetRegular`, path: `assets/common/player/bounty/target.png` },
        { name: `bountyTargetCelebrity`, path: `assets/common/player/bounty/target_celebrity.png` },
        { name: `bountyTargetCelebrityOverlay`, path: `assets/common/player/bounty/target_celebrity_overlay.png` },

        { name: `bountySpellVariant_1`, path: `assets/common/bounty-spell/spell_1_icon.png` },
        { name: `bountySpellVariant_1_active`, path: `assets/common/bounty-spell/spell_1_icon_active.png` },
        { name: `bountySpellVariant_1_inactive`, path: `assets/common/bounty-spell/spell_1_icon_inactive.png` },

        { name: `bountySpellVariant_2`, path: `assets/common/bounty-spell/spell_2_icon.png` },
        { name: `bountySpellVariant_2_active`, path: `assets/common/bounty-spell/spell_2_icon_active.png` },
        { name: `bountySpellVariant_2_inactive`, path: `assets/common/bounty-spell/spell_2_icon_inactive.png` },

        { name: `bountySpellVariant_3`, path: `assets/common/bounty-spell/spell_3_icon.png` },
        { name: `bountySpellVariant_3_active`, path: `assets/common/bounty-spell/spell_3_icon_active.png` },
        { name: `bountySpellVariant_3_inactive`, path: `assets/common/bounty-spell/spell_3_icon_inactive.png` },

        { name: `bountySpellVariant_4`, path: `assets/common/bounty-spell/spell_4_icon.png` },
        { name: `bountySpellVariant_4_active`, path: `assets/common/bounty-spell/spell_4_icon_active.png` },
        { name: `bountySpellVariant_4_inactive`, path: `assets/common/bounty-spell/spell_4_icon_inactive.png` },

        { name: `bountySpellVariant_5`, path: `assets/common/bounty-spell/spell_5_icon.png` },
        { name: `bountySpellVariant_5_active`, path: `assets/common/bounty-spell/spell_5_icon_active.png` },
        { name: `bountySpellVariant_5_inactive`, path: `assets/common/bounty-spell/spell_5_icon_inactive.png` },

        { name: `bountySpellVariant_6`, path: `assets/common/bounty-spell/spell_6_icon.png` },
        { name: `bountySpellVariant_6_active`, path: `assets/common/bounty-spell/spell_6_icon_active.png` },
        { name: `bountySpellVariant_6_inactive`, path: `assets/common/bounty-spell/spell_6_icon_inactive.png` },

        { name: `bountySpellVariant_7`, path: `assets/common/bounty-spell/spell_7_icon.png` },
        { name: `bountySpellVariant_7_active`, path: `assets/common/bounty-spell/spell_7_icon_active.png` },
        { name: `bountySpellVariant_7_inactive`, path: `assets/common/bounty-spell/spell_7_icon_inactive.png` },

        { name: `bountySpellIndicator`, path: `assets/common/bounty-spell/spell_indicator_crop.png` },
        { name: `bountySpellMore`, path: `assets/common/bounty-spell/spell_more_crop.png` },

        { name: `disconnectionProtectionOverlay`, path: `assets/common/player/disconnection_protection_overlay.png` },
        { name: `disconnectionProtectionIcon`, path: `assets/common/player/disconnection_protection.png` },


        // Countries
        { name: `abkhazia`, path: `assets/common/player/flag/abkhazia.png` },
        { name: `afghanistan`, path: `assets/common/player/flag/afghanistan.png` },
        { name: `alaska`, path: `assets/common/player/flag/alaska.png` },
        { name: `albania`, path: `assets/common/player/flag/albania.png` },
        { name: `algeria`, path: `assets/common/player/flag/algeria.png` },
        { name: `andorra`, path: `assets/common/player/flag/andorra.png` },
        { name: `angola`, path: `assets/common/player/flag/angola.png` },
        { name: `anguilla`, path: `assets/common/player/flag/anguilla.png` },
        { name: `antartica`, path: `assets/common/player/flag/antartica.png` },
        { name: `antigua_and_barbuda`, path: `assets/common/player/flag/antigua_and_barbuda.png` },
        { name: `argentina`, path: `assets/common/player/flag/argentina.png` },
        { name: `argentine_antartica`, path: `assets/common/player/flag/argentine_antartica.png` },
        { name: `armenia`, path: `assets/common/player/flag/armenia.png` },
        { name: `aruba`, path: `assets/common/player/flag/aruba.png` },
        { name: `asean`, path: `assets/common/player/flag/asean.png` },
        { name: `australia`, path: `assets/common/player/flag/australia.png` },
        { name: `austria`, path: `assets/common/player/flag/austria.png` },
        { name: `autonomos_region_of_bougainville`, path: `assets/common/player/flag/autonomos_region_of_bougainville.png` },
        { name: `azerbaijan`, path: `assets/common/player/flag/azerbaijan.png` },


        { name: `bahamas`, path: `assets/common/player/flag/bahamas.png` },
        { name: `bahrain`, path: `assets/common/player/flag/bahrain.png` },
        { name: `bangladesh`, path: `assets/common/player/flag/bangladesh.png` },
        { name: `barbados`, path: `assets/common/player/flag/barbados.png` },
        { name: `belarus`, path: `assets/common/player/flag/belarus.png` },
        { name: `belgium`, path: `assets/common/player/flag/belgium.png` },
        { name: `belize`, path: `assets/common/player/flag/belize.png` },
        { name: `benin`, path: `assets/common/player/flag/benin.png` },
        { name: `bhutan`, path: `assets/common/player/flag/bhutan.png` },
        { name: `bolivia`, path: `assets/common/player/flag/bolivia.png` },
        { name: `bosnia_and_herzegovina`, path: `assets/common/player/flag/bosnia_and_herzegovina.png` },
        { name: `botswana`, path: `assets/common/player/flag/botswana.png` },
        { name: `brazil`, path: `assets/common/player/flag/brazil.png` },
        { name: `brunei`, path: `assets/common/player/flag/brunei.png` },
        { name: `bulgaria`, path: `assets/common/player/flag/bulgaria.png` },
        { name: `burkina_faso`, path: `assets/common/player/flag/burkina_faso.png` },
        { name: `burundi`, path: `assets/common/player/flag/burundi.png` },


        { name: `cambodia`, path: `assets/common/player/flag/cambodia.png` },
        { name: `cameroon`, path: `assets/common/player/flag/cameroon.png` },
        { name: `canada`, path: `assets/common/player/flag/canada.png` },
        { name: `cape_verde`, path: `assets/common/player/flag/cape_verde.png` },
        { name: `catalonia`, path: `assets/common/player/flag/catalonia.png` },
        { name: `central_african_republic`, path: `assets/common/player/flag/central_african_republic.png` },
        { name: `chad`, path: `assets/common/player/flag/chad.png` },
        { name: `chile`, path: `assets/common/player/flag/chile.png` },
        { name: `chilean_antartic_territory`, path: `assets/common/player/flag/chilean_antartic_territory.png` },
        { name: `china`, path: `assets/common/player/flag/china.png` },
        { name: `christmas_island`, path: `assets/common/player/flag/christmas_island.png` },
        { name: `cocos_islands`, path: `assets/common/player/flag/cocos_islands.png` },
        { name: `colombia`, path: `assets/common/player/flag/colombia.png` },
        { name: `comoros`, path: `assets/common/player/flag/comoros.png` },
        { name: `cook_islands`, path: `assets/common/player/flag/cook_islands.png` },
        { name: `costa_rica`, path: `assets/common/player/flag/costa_rica.png` },
        { name: `cote_d_ivoire`, path: `assets/common/player/flag/cote_d_ivoire.png` },
        { name: `croatia`, path: `assets/common/player/flag/croatia.png` },
        { name: `cuba`, path: `assets/common/player/flag/cuba.png` },
        { name: `curacao`, path: `assets/common/player/flag/curacao.png` },
        { name: `cyprys`, path: `assets/common/player/flag/cyprys.png` },
        { name: `czechia`, path: `assets/common/player/flag/czechia.png` },

        { name: `denmark`, path: `assets/common/player/flag/denmark.png` },
        { name: `djibouti`, path: `assets/common/player/flag/djibouti.png` },
        { name: `dominica`, path: `assets/common/player/flag/dominica.png` },
        { name: `dominican_republic`, path: `assets/common/player/flag/dominican_republic.png` },
        { name: `democratic_republic_of_the_congo`, path: `assets/common/player/flag/democratic_republic_of_the_congo.png` },

        { name: `ecuador`, path: `assets/common/player/flag/ecuador.png` },
        { name: `egypt`, path: `assets/common/player/flag/egypt.png` },
        { name: `el_salvador`, path: `assets/common/player/flag/el_salvador.png` },
        { name: `equatorial_guinea`, path: `assets/common/player/flag/equatorial_guinea.png` },
        { name: `eritrea`, path: `assets/common/player/flag/eritrea.png` },
        { name: `estonia`, path: `assets/common/player/flag/estonia.png` },
        { name: `ethiopia`, path: `assets/common/player/flag/ethiopia.png` },
        { name: `england`, path: `assets/common/player/flag/england.png` },
        { name: `european_union`, path: `assets/common/player/flag/european_union.png` },

        { name: `french_southern_and_antartic_land`, path: `assets/common/player/flag/french_southern_and_antartic_land.png` },
        { name: `faroe_islands`, path: `assets/common/player/flag/faroe_islands.png` },
        { name: `fiji`, path: `assets/common/player/flag/fiji.png` },
        { name: `finland`, path: `assets/common/player/flag/finland.png` },
        { name: `france`, path: `assets/common/player/flag/france.png` },
        { name: `french_guiana`, path: `assets/common/player/flag/french_guiana.png` },


        { name: `gabon`, path: `assets/common/player/flag/gabon.png` },
        { name: `georgia`, path: `assets/common/player/flag/georgia.png` },
        { name: `germany`, path: `assets/common/player/flag/germany.png` },
        { name: `ghana`, path: `assets/common/player/flag/ghana.png` },
        { name: `greece`, path: `assets/common/player/flag/greece.png` },
        { name: `greenland`, path: `assets/common/player/flag/greenland.png` },
        { name: `grenada`, path: `assets/common/player/flag/grenada.png` },
        { name: `guadeloupe`, path: `assets/common/player/flag/guadeloupe.png` },
        { name: `guam`, path: `assets/common/player/flag/guam.png` },
        { name: `guatemala`, path: `assets/common/player/flag/guatemala.png` },
        { name: `guernsey`, path: `assets/common/player/flag/guernsey.png` },
        { name: `guinea`, path: `assets/common/player/flag/guinea.png` },
        { name: `guinea_basau`, path: `assets/common/player/flag/guinea_basau.png` },
        { name: `guyana`, path: `assets/common/player/flag/guyana.png` },

        { name: `haiti`, path: `assets/common/player/flag/haiti.png` },
        { name: `honduras`, path: `assets/common/player/flag/honduras.png` },
        { name: `hong_kong`, path: `assets/common/player/flag/hong_kong.png` },
        { name: `hungary`, path: `assets/common/player/flag/hungary.png` },

        { name: `iceland`, path: `assets/common/player/flag/iceland.png` },
        { name: `india`, path: `assets/common/player/flag/india.png` },
        { name: `indonesia`, path: `assets/common/player/flag/indonesia.png` },
        { name: `iran`, path: `assets/common/player/flag/iran.png` },
        { name: `iraq`, path: `assets/common/player/flag/iraq.png` },
        { name: `iraqi_kurdistan`, path: `assets/common/player/flag/iraqi_kurdistan.png` },
        { name: `ireland`, path: `assets/common/player/flag/ireland.png` },
        { name: `isle_of_man`, path: `assets/common/player/flag/isle_of_man.png` },
        { name: `israel`, path: `assets/common/player/flag/israel.png` },
        { name: `italy`, path: `assets/common/player/flag/italy.png` },

        { name: `jamaica`, path: `assets/common/player/flag/jamaica.png` },
        { name: `japan`, path: `assets/common/player/flag/japan.png` },
        { name: `jordan`, path: `assets/common/player/flag/jordan.png` },

        { name: `kazakhstan`, path: `assets/common/player/flag/kazakhstan.png` },
        { name: `kenya`, path: `assets/common/player/flag/kenya.png` },
        { name: `kiribati`, path: `assets/common/player/flag/kiribati.png` },
        { name: `kuwait`, path: `assets/common/player/flag/kuwait.png` },
        { name: `kyrgyzstan`, path: `assets/common/player/flag/kyrgyzstan.png` },

        { name: `laos`, path: `assets/common/player/flag/laos.png` },
        { name: `latvia`, path: `assets/common/player/flag/latvia.png` },
        { name: `lebanon`, path: `assets/common/player/flag/lebanon.png` },
        { name: `lesotho`, path: `assets/common/player/flag/lesotho.png` },
        { name: `liberia`, path: `assets/common/player/flag/liberia.png` },
        { name: `libya`, path: `assets/common/player/flag/libya.png` },
        { name: `liechtenstein`, path: `assets/common/player/flag/liechtenstein.png` },
        { name: `lithuania`, path: `assets/common/player/flag/lithuania.png` },
        { name: `luxembourg`, path: `assets/common/player/flag/luxembourg.png` },

        { name: `macau`, path: `assets/common/player/flag/macau.png` },
        { name: `madagascar`, path: `assets/common/player/flag/madagascar.png` },
        { name: `madeira`, path: `assets/common/player/flag/madeira.png` },
        { name: `malawi`, path: `assets/common/player/flag/malawi.png` },
        { name: `malaysia`, path: `assets/common/player/flag/malaysia.png` },
        { name: `maldives`, path: `assets/common/player/flag/maldives.png` },
        { name: `mali`, path: `assets/common/player/flag/mali.png` },
        { name: `malta`, path: `assets/common/player/flag/malta.png` },
        { name: `marshal_islands`, path: `assets/common/player/flag/marshal_islands.png` },
        { name: `mauritania`, path: `assets/common/player/flag/mauritania.png` },
        { name: `mauritius`, path: `assets/common/player/flag/mauritius.png` },
        { name: `mexico`, path: `assets/common/player/flag/mexico.png` },
        { name: `micronesia`, path: `assets/common/player/flag/micronesia.png` },
        { name: `moldova`, path: `assets/common/player/flag/moldova.png` },
        { name: `mongolia`, path: `assets/common/player/flag/mongolia.png` },
        { name: `montenegro`, path: `assets/common/player/flag/montenegro.png` },
        { name: `morocco`, path: `assets/common/player/flag/morocco.png` },
        { name: `mozambique`, path: `assets/common/player/flag/mozambique.png` },
        { name: `myanmar`, path: `assets/common/player/flag/myanmar.png` },

        { name: `namibia`, path: `assets/common/player/flag/namibia.png` },
        { name: `nauru`, path: `assets/common/player/flag/nauru.png` },
        { name: `nepal`, path: `assets/common/player/flag/nepal.png` },
        { name: `netherlands`, path: `assets/common/player/flag/netherlands.png` },
        { name: `netherlands_antilles`, path: `assets/common/player/flag/netherlands_antilles.png` },
        { name: `new_caledonia`, path: `assets/common/player/flag/new_caledonia.png` },
        { name: `new_zealand`, path: `assets/common/player/flag/new_zealand.png` },
        { name: `nicaragua`, path: `assets/common/player/flag/nicaragua.png` },
        { name: `niger`, path: `assets/common/player/flag/niger.png` },
        { name: `nigeria`, path: `assets/common/player/flag/nigeria.png` },
        { name: `niue`, path: `assets/common/player/flag/niue.png` },
        { name: `norfork_island`, path: `assets/common/player/flag/norfork_island.png` },
        { name: `north_korea`, path: `assets/common/player/flag/north_korea.png` },
        { name: `north_macedonia`, path: `assets/common/player/flag/north_macedonia.png` },
        { name: `northern_cyprys`, path: `assets/common/player/flag/northern_cyprys.png` },
        { name: `norway`, path: `assets/common/player/flag/norway.png` },

        { name: `oman`, path: `assets/common/player/flag/oman.png` },

        { name: `pakistan`, path: `assets/common/player/flag/pakistan.png` },
        { name: `palau`, path: `assets/common/player/flag/palau.png` },
        { name: `palestine`, path: `assets/common/player/flag/palestine.png` },
        { name: `papua_new_guinea`, path: `assets/common/player/flag/papua_new_guinea.png` },
        { name: `paraguay`, path: `assets/common/player/flag/paraguay.png` },
        { name: `peru`, path: `assets/common/player/flag/peru.png` },
        { name: `philippines`, path: `assets/common/player/flag/philippines.png` },
        { name: `poland`, path: `assets/common/player/flag/poland.png` },
        { name: `portugal`, path: `assets/common/player/flag/portugal.png` },
        { name: `puerto_rico`, path: `assets/common/player/flag/puerto_rico.png` },

        { name: `qatar`, path: `assets/common/player/flag/qatar.png` },

        { name: `republic_of_artsakh`, path: `assets/common/player/flag/republic_of_artsakh.png` },
        { name: `republic_of_the_congo`, path: `assets/common/player/flag/republic_of_the_congo.png` },
        { name: `romania`, path: `assets/common/player/flag/romania.png` },
        { name: `russia`, path: `assets/common/player/flag/russia.png` },
        { name: `rwanda`, path: `assets/common/player/flag/rwanda.png` },

        { name: `sahrawi_arab_democratic_republic`, path: `assets/common/player/flag/sahrawi_arab_democratic_republic.png` },
        { name: `saint_kitts`, path: `assets/common/player/flag/saint_kitts.png` },
        { name: `saint_luca`, path: `assets/common/player/flag/saint_luca.png` },
        { name: `saint_vincent`, path: `assets/common/player/flag/saint_vincent.png` },
        { name: `samoa`, path: `assets/common/player/flag/samoa.png` },
        { name: `san_marino`, path: `assets/common/player/flag/san_marino.png` },
        { name: `sao_tome`, path: `assets/common/player/flag/sao_tome.png` },
        { name: `saudi_arabia`, path: `assets/common/player/flag/saudi_arabia.png` },
        { name: `senegal`, path: `assets/common/player/flag/senegal.png` },
        { name: `serbia`, path: `assets/common/player/flag/serbia.png` },
        { name: `seychelles`, path: `assets/common/player/flag/seychelles.png` },
        { name: `sierra_leone`, path: `assets/common/player/flag/sierra_leone.png` },
        { name: `singapore`, path: `assets/common/player/flag/singapore.png` },
        { name: `slovakia`, path: `assets/common/player/flag/slovakia.png` },
        { name: `slovenia`, path: `assets/common/player/flag/slovenia.png` },
        { name: `solomon_islands`, path: `assets/common/player/flag/solomon_islands.png` },
        { name: `somalia`, path: `assets/common/player/flag/somalia.png` },
        { name: `somaliland`, path: `assets/common/player/flag/somaliland.png` },
        { name: `south_africa`, path: `assets/common/player/flag/south_africa.png` },
        { name: `south_korea`, path: `assets/common/player/flag/south_korea.png` },
        { name: `south_ossetia`, path: `assets/common/player/flag/south_ossetia.png` },
        { name: `south_sudan`, path: `assets/common/player/flag/south_sudan.png` },
        { name: `spain`, path: `assets/common/player/flag/spain.png` },
        { name: `sri_lanka`, path: `assets/common/player/flag/sri_lanka.png` },
        { name: `sudan`, path: `assets/common/player/flag/sudan.png` },
        { name: `suriname`, path: `assets/common/player/flag/suriname.png` },
        { name: `swaziland`, path: `assets/common/player/flag/swaziland.png` },
        { name: `sweden`, path: `assets/common/player/flag/sweden.png` },
        { name: `switzerland`, path: `assets/common/player/flag/switzerland.png` },
        { name: `syria`, path: `assets/common/player/flag/syria.png` },

        { name: `taiwan`, path: `assets/common/player/flag/taiwan.png` },
        { name: `tajikistan`, path: `assets/common/player/flag/tajikistan.png` },
        { name: `tanzania`, path: `assets/common/player/flag/tanzania.png` },
        { name: `thailand`, path: `assets/common/player/flag/thailand.png` },
        { name: `the_gambia`, path: `assets/common/player/flag/the_gambia.png` },
        { name: `timor_leste`, path: `assets/common/player/flag/timor_leste.png` },
        { name: `togo`, path: `assets/common/player/flag/togo.png` },
        { name: `tokelau`, path: `assets/common/player/flag/tokelau.png` },
        { name: `tonga`, path: `assets/common/player/flag/tonga.png` },
        { name: `transnistria`, path: `assets/common/player/flag/transnistria.png` },
        { name: `trinidad_and_tobago`, path: `assets/common/player/flag/trinidad_and_tobago.png` },
        { name: `tunisia`, path: `assets/common/player/flag/tunisia.png` },
        { name: `turkey`, path: `assets/common/player/flag/turkey.png` },
        { name: `turkmenistan`, path: `assets/common/player/flag/turkmenistan.png` },
        { name: `tuvalu`, path: `assets/common/player/flag/tuvalu.png` },

        { name: `uganda`, path: `assets/common/player/flag/uganda.png` },
        { name: `ukraine`, path: `assets/common/player/flag/ukraine.png` },
        { name: `united_arab_emirates`, path: `assets/common/player/flag/united_arab_emirates.png` },
        { name: `united_kingdom`, path: `assets/common/player/flag/united_kingdom.png` },
        { name: `uruguay`, path: `assets/common/player/flag/uruguay.png` },
        { name: `usa`, path: `assets/common/player/flag/usa.png` },
        { name: `uzbekistan`, path: `assets/common/player/flag/uzbekistan.png` },

        { name: `vanuatu`, path: `assets/common/player/flag/vanuatu.png` },
        { name: `vatican_city_state`, path: `assets/common/player/flag/vatican_city_state.png` },
        { name: `venezuela`, path: `assets/common/player/flag/venezuela.png` },
        { name: `vietnam`, path: `assets/common/player/flag/vietnam.png` },

        { name: `wales`, path: `assets/common/player/flag/wales.png` },

        { name: `yemen`, path: `assets/common/player/flag/yemen.png` },

        { name: `zambia`, path: `assets/common/player/flag/zambia.png` },
        { name: `zimbabwe`, path: `assets/common/player/flag/zimbabwe.png` },



        { name: `american_samoa`, path: `assets/common/player/flag/american_samoa.png` },
        { name: `bermuda`, path: `assets/common/player/flag/bermuda.png` },
        { name: `bouvet_island`, path: `assets/common/player/flag/bouvet_island.png` },
        { name: `british_indian_ocean_territory`, path: `assets/common/player/flag/british_indian_ocean_territory.png` },
        { name: `british_virgin_islands`, path: `assets/common/player/flag/british_virgin_islands.png` },
        { name: `cayman_islands`, path: `assets/common/player/flag/cayman_islands.png` },

        { name: `east_timor`, path: `assets/common/player/flag/east_timor.png` },
        { name: `eswatini`, path: `assets/common/player/flag/eswatini.png` },
        { name: `falkland_islands`, path: `assets/common/player/flag/falkland_islands.png` },
        { name: `french_polynesia`, path: `assets/common/player/flag/french_polynesia.png` },
        { name: `gibraltar`, path: `assets/common/player/flag/gibraltar.png` },
        { name: `heard_island_and_mcsonald_islands`, path: `assets/common/player/flag/heard_island_and_mcsonald_islands.png` },
        { name: `jersey`, path: `assets/common/player/flag/jersey.png` },
        { name: `land`, path: `assets/common/player/flag/land.png` },
        { name: `martinique`, path: `assets/common/player/flag/martinique.png` },
        { name: `mayotte`, path: `assets/common/player/flag/mayotte.png` },
        { name: `monaco`, path: `assets/common/player/flag/monaco.png` },
        { name: `montserrat`, path: `assets/common/player/flag/montserrat.png` },
        { name: `northern_mariana_islands`, path: `assets/common/player/flag/northern_mariana_islands.png` },
        { name: `panama`, path: `assets/common/player/flag/panama.png` },
        { name: `pitcairn_islands`, path: `assets/common/player/flag/pitcairn_islands.png` },
        { name: `r_union`, path: `assets/common/player/flag/r_union.png` },
        { name: `saint_barth_lemy`, path: `assets/common/player/flag/saint_barth_lemy.png` },
        { name: `saint_helena`, path: `assets/common/player/flag/saint_helena.png` },
        { name: `saint_martin`, path: `assets/common/player/flag/saint_martin.png` },
        { name: `saint_pierre_and_miquelon`, path: `assets/common/player/flag/saint_pierre_and_miquelon.png` },
        { name: `svalbard_and_jan_mayen`, path: `assets/common/player/flag/svalbard_and_jan_mayen.png` },

        { name: `turks_and_caicos_islands`, path: `assets/common/player/flag/turks_and_caicos_islands.png` },
        { name: `u_s__minor_outlying_islands`, path: `assets/common/player/flag/u_s__minor_outlying_islands.png` },
        { name: `u_s__virgin_islands`, path: `assets/common/player/flag/u_s__virgin_islands.png` },
        { name: `wallis_and_futuna`, path: `assets/common/player/flag/wallis_and_futuna.png` },
        { name: `western_sahara`, path: `assets/common/player/flag/western_sahara.png` }

    ]
}


