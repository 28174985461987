import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { DataManagerService } from '../../../core/services/data-manager.service';

@Component({
  selector: 'app-phone-verification-dialog',
  templateUrl: './phone-verification-dialog.component.html',
  styleUrls: ['./phone-verification-dialog.component.scss']
})
export class PhoneVerificationDialogComponent implements OnInit {

  code: string;

  constructor(
    public dialogRef: MatDialogRef<PhoneVerificationDialogComponent>,
    public authService: AuthService,
    private dataManager: DataManagerService,
  ) { }

  ngOnInit() {
  }

  onGetSmsCodeClick() {
    this.dataManager.getSmsForVerfiction();
  }

  onApplyClick() {
    if (this.code !== undefined) {
      this.dataManager.sendPhoneVerificationData(this.code);
    }
    this.dialogRef.close('Apply');
  }
}
