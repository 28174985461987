import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuyChips } from '../../../../shared/models';
import { CurrencyMultiplierPipe } from '../../../../shared/utils/currency-multiplier.pipe';
import { ManagerService } from '../../../../core/services/manager.service';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe } from '../../../../shared/utils/currency.pipe';
import { CurrencyDeviderPipe } from '../../../../shared/utils/currency-devider.pipe';
import { ConfigService } from '../../../../core/services/config.service';

@Component({
  selector: 'app-buy-chips',
  templateUrl: './buy-chips.component.html',
  styleUrls: ['./buy-chips.component.scss']
})
export class BuyChipsComponent implements OnInit, OnDestroy {
  environment = environment;
  dialogTimeout = 30;

  destroy$ = new Subject<boolean>();
  minAmount: number;
  config;
  constructor(
    public dialogRef: MatDialogRef<BuyChipsComponent>,
    @Inject(MAT_DIALOG_DATA) public buyChips: BuyChips,
    public managerService: ManagerService,

    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

    this.dialogTimeout = buyChips.dialogTimeout;

    this.minAmount = CurrencyDeviderPipe.prototype.transform(buyChips.min, buyChips.currency)

  }

  ngOnInit() {
    timer(0, 1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogTimeout--;
        if (this.dialogTimeout <= 0) {
          this.dialogRef.close();
        }
      });


    // ** On Logout Close
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (!settings) {
            this.dialogRef.close();
          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clickOk(chipsValue: any) {
    if (!chipsValue || chipsValue < this.minAmount) {
      return
    }
    chipsValue = parseFloat(chipsValue);
    chipsValue = parseFloat(chipsValue.toFixed(this.buyChips.currency.NbDecimalVisible));

    chipsValue = CurrencyMultiplierPipe.prototype.transform(chipsValue, this.buyChips.currency);
    this.dialogRef.close(chipsValue);
  }

  clickCancel() {
    this.dialogRef.close(undefined);
  }

  clickChipsMin() {
    this.buyChips.value = this.buyChips.min;
  }

  clickChipsMax() {
    this.buyChips.value = this.getMaxChips();
  }

  getMaxChips() {
    return this.buyChips.available > this.buyChips.max ? this.buyChips.max : this.buyChips.available;
  }

}
