import { Component, Input } from '@angular/core';
import { TableType } from '../../../../shared/enums/table-type';
import { TournamentStatus2 } from '../../../../shared/enums/poker-types';
import { Tournament } from '../../../../shared/models';

@Component({
  selector: 'view-tournament-status',
  templateUrl: './view-tournament-status.component.html',
  styleUrls: ['./view-tournament-status.component.scss']
})
export class ViewTournamentStatusComponent {

  enumTableType = TableType
  enumTournamentStatus2 = TournamentStatus2

  @Input() tournament: Tournament;

}
