import { Point } from '../../models';

export const FoldedCardsPositions: Point[] = [
    { x: 541, y: 307 },
    { x: 685, y: 342 },
    { x: 685, y: 576 },
    { x: 685, y: 1038 },
    { x: 685, y: 1227 }, // center
    { x: 541, y: 1238 },
    { x: 391, y: 1227 },
    { x: 391, y: 1038 },
    { x: 387, y: 576 },
    { x: 387, y: 321 },
];
export const FoldedCardsPositionsFor6Players: Point[] = [
    { x: 618, y: 307 },
    { x: 690, y: 722 },
    { x: 684, y: 1148 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 619, y: 1251 },
    { x: 386, y: 1153 },
    { x: 387, y: 722 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];

export const FoldedCardsPositionsUnlimited: Point[] = [
    { x: 524, y: 289 },
    { x: 931, y: 264 },
    { x: 1408, y: 264 },
    { x: 1519, y: 429 },
    { x: 1505, y: 507 }, // center
    { x: 1372, y: 642 }, // { x: 1372, y: 642 - 62 },
    { x: 834, y: 652 }, // { x: 892, y: 652 - 62 },
    { x: 556, y: 604 }, // { x: 556, y: 604 - 62 },
    { x: 407, y: 525 },
    { x: 381, y: 429 },
];
export const FoldedCardsPositionsFor6PlayersUnlimited: Point[] = [
    { x: 552, y: 236 },
    { x: 931, y: 264 },
    { x: 1410, y: 252 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1382, y: 665 - 62 },
    { x: 892, y: 652 - 62 },
    { x: 536, y: 666 - 62 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];
