
export class TimeParser {

    static parseTime(time: number): string {
        const secondsLeft: number = time % 60;
        const minutesLeft: number = time / 60;

        let minStr = '';
        let secStr = '';

        if (minutesLeft < 10) {
            minStr = '0' + Math.floor(minutesLeft).toString();
        } else {
            minStr = Math.floor(minutesLeft).toString();
        }

        if (secondsLeft < 10) {
            secStr = '0' + Math.floor(secondsLeft).toString();
        } else {
            secStr = Math.floor(secondsLeft).toString();
        }

        return minStr + ':' + secStr;
    }
}
