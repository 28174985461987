import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordDialogComponent } from './lobby-dialogs/password-dialog/password-dialog.component';
import { LobbyContainerComponent } from './lobby-container/lobby-container.component';

import { PlayerRankLevelComponent } from './lobby-dialogs/player-rank-level/player-rank-level.component';
import { BonusUnlockComponent } from './lobby-dialogs/bonus-unlock/bonus-unlock.component';
import { SharedModule } from '../../shared/shared.module';
import { LobbyGameListComponent } from './lobby-game-list/lobby-game-list.component';
import { LobbyGameTableComponent } from './lobby-game-table/lobby-game-table.component';
import { LobbyListTablePreviewComponent } from './lobby-list-table-preview/lobby-list-table-preview.component';
import { LobbyListTableComponent } from './lobby-list-table/lobby-list-table.component';
import { LobbyChatComponent } from './lobby-chat/lobby-chat.component';
import { DialogLegendSymbol } from './lobby-dialogs/dialog-legend-symbol/dialog-legend-symbol.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NavModule } from '../nav/nav.module';
import { TournamentModule } from '../tournament/tournament.module';
import { SwiperModule } from 'swiper/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NavModule,
    TournamentModule,
    SwiperModule,

    BrowserAnimationsModule,
    MatTooltipModule
  ],
  declarations: [
    LobbyContainerComponent,
    PasswordDialogComponent,
    PlayerRankLevelComponent,
    BonusUnlockComponent,
    LobbyGameListComponent,
    LobbyGameTableComponent,
    LobbyListTablePreviewComponent,
    LobbyListTableComponent,
    LobbyChatComponent,
    DialogLegendSymbol,
  ],
  exports: [
    LobbyContainerComponent,
  ],
  providers: [
    DatePipe,
  ],
  entryComponents: [
    PasswordDialogComponent,
    PlayerRankLevelComponent,
    BonusUnlockComponent,
    DialogLegendSymbol
  ],
})

export class LobbyModule { }
