import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManagerService } from '../../services/manager.service';

@Component({
  selector: 'app-generic-text-dialog',
  templateUrl: './generic-text-dialog.component.html',
  styleUrls: ['./generic-text-dialog.component.scss']
})
export class GenericTextDialog implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();

  public textHeader: string;
  public textBody: string;
  public textAcceptBtn: string;
  public textDismissBtn: string;
  public enableClose = true;
  public enableAccept = true;

  constructor(
    public dialogRef: MatDialogRef<GenericTextDialog>,
    // public managerService: ManagerService,
  ) { }

  ngOnInit(): void {

    // Circular dependency detected

    // ** On Logout Close
    // this.managerService.settings$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: (settings) => {
    //       if (!settings) {
    //         this.dialogRef.close();
    //       }
    //     }
    //   })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    this.dialogRef.close('ok')
  }
}
