import { th } from 'date-fns/locale';
import * as PIXI from 'pixi.js';
import { Subject } from 'rxjs';
import { Point, Size } from '../models';
import { AnchorPosition } from './const';
export enum SpinAndGoNumbersState {
    idle, stopping, done
}
export class SpinAndGo {
    public container: PIXI.Container;
    private onShowDone = new Subject<any>();
    onShowDoneObservable$ = this.onShowDone.asObservable();

    private containerSize: Size = { width: 960, height: 960 };
    private logoPosition: Point = { x: 480, y: 302 };
    private raysSprite: PIXI.Sprite;
    private numbersSprite: PIXI.Sprite;
    private slotMachineSprite: PIXI.Sprite;
    private logoSprite: PIXI.Sprite;

    private animTextures: PIXI.Texture[] = [];
    private animIndex = 0;
    private animTimer = 0;

    private minNumYPos = 0;
    private maxNumYPos = 0;

    private maskSize: Size = { width: 490, height: 136 };
    private maskPos: Point = { x: 480, y: 539 };
    private numberMask: PIXI.Graphics;

    private logoScale = 1;
    private dScale = 0.003;

    private targetPosition;
    private numbersTempTimer = 0;
    private numbersState: SpinAndGoNumbersState = SpinAndGoNumbersState.idle;
    private targetLength;
    private currentLength = 0;


    private multipliersContainer: PIXI.Container;
    private textures;
    constructor(textures) {
        this.textures = textures;
        this.container = new PIXI.Container();

        this.multipliersContainer = new PIXI.Container();

        this.raysSprite = new PIXI.Sprite(textures['spinRays'].texture);
        this.raysSprite.anchor.set(0.5);
        this.raysSprite.position.set(this.containerSize.width / 2, this.containerSize.height / 2);
        this.container.addChild(this.raysSprite);




        this.minNumYPos = -45;
        this.container.addChild(this.multipliersContainer)

        this.animTextures.push(textures['spinSlotF1'].texture);
        this.animTextures.push(textures['spinSlotF2'].texture);

        this.slotMachineSprite = new PIXI.Sprite(this.animTextures[0]);
        this.slotMachineSprite.anchor.set(0.5);
        this.slotMachineSprite.position.set(this.containerSize.width / 2, this.containerSize.height / 2);
        this.container.addChild(this.slotMachineSprite);


        this.logoSprite = new PIXI.Sprite(textures['spinLogo'].texture);
        this.logoSprite.anchor.set(0.5);
        this.logoSprite.position.set(this.logoPosition.x, this.logoPosition.y);
        this.container.addChild(this.logoSprite);

        this.numberMask = new PIXI.Graphics();
        this.numberMask.beginFill(0xffffff);
        this.numberMask.drawRect(
            this.maskPos.x - this.maskSize.width / 2,
            this.maskPos.y - this.maskSize.height / 2,
            this.maskSize.width,
            this.maskSize.height
        );
        this.numberMask.endFill();
        this.container.addChild(this.numberMask);
        this.multipliersContainer.mask = this.numberMask;
    }

    update(dt: number) {
        if (dt > 300) { return; }
        dt /= 1000;

        this.updateSlotMachine(dt);
        this.updateLogo(dt);
        this.updateNumbers(dt);

        this.raysSprite.rotation += 20 * 0.0174533 * dt;
    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {
            this.container.position.set(newPosition.x - this.containerSize.width / 2,
                newPosition.y - this.containerSize.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x, newPosition.y);
        }
    }

    setNumbers(numbers: number[], targetNumber: number) {

        // #1 Adding numbers
        let index = 0
        for (const number of numbers.slice().reverse()) {
            index++
            const background = new PIXI.Sprite(this.textures['spinNumberBlock1'].texture);
            background.anchor.set(0.5);
            background.position.set(this.containerSize.width / 2, this.containerSize.height / 2 - 30 + (index * background.height));

            let text = new PIXI.Text(`x${number}`, {
                fontFamily: 'Saira Semi Condensed',
                fontSize: 40,
                fill: '#ffffff',
                fontWeight: 'bold'
            });

            text.anchor.set(0.5);
            text.position.set(this.containerSize.width / 2, this.containerSize.height / 2 - 30 + (index * background.height));
            this.multipliersContainer.addChild(background);
            this.multipliersContainer.addChild(text);
        }

       // const blurFilter = new PIXI.filters.BlurFilter()
      //  this.multipliersContainer.filters = [blurFilter]
       // blurFilter.blurY = 5;

        this.multipliersContainer.position.set(0, 0)

        this.minNumYPos = -this.multipliersContainer.height + 82
        this.maxNumYPos = 0

        // #2 Setting Target numbers;

        this.targetPosition = this.minNumYPos + 84 * numbers.findIndex(number => number === targetNumber); // index

    }


    private updateLogo(dt: number) {
        if (this.logoScale > 1.05) {
            this.dScale *= -1;
        } else if (this.logoScale < 0.95) {
            this.dScale *= -1;
        }
        this.logoScale += this.dScale;
        this.logoSprite.scale.set(this.logoScale);
    }

    private updateSlotMachine(dt: number) {
        this.animTimer += dt;
        if (this.animTimer > 0.2) {
            this.animTimer = 0;
            this.animIndex++;
            if (this.animIndex > 1) { this.animIndex = 0; }
            this.slotMachineSprite.texture = this.animTextures[this.animIndex];
        }
    }


    private updateNumbers(dt: number) {

        switch (this.numbersState) {
            case SpinAndGoNumbersState.idle: {
                this.numbersTempTimer += dt;
                this.multipliersContainer.position.y += 10;

                if (this.numbersTempTimer > 2) {
                    this.numbersTempTimer = 0;
                    this.numbersState = SpinAndGoNumbersState.stopping;
                    if (this.targetPosition > this.multipliersContainer.position.y) {
                        this.targetLength = (this.maxNumYPos - this.minNumYPos) + (this.targetPosition - this.multipliersContainer.position.y);
                    } else {
                        this.targetLength = (this.maxNumYPos - this.minNumYPos) - (this.multipliersContainer.position.y - this.targetPosition);
                    }
                }
                break;
            }
            case SpinAndGoNumbersState.stopping: {
                const percent = 1 - (this.currentLength / this.targetLength);
                const speed = 10 * percent + 1 - percent;
                this.currentLength += speed;
                this.multipliersContainer.position.y += speed;
                if (this.currentLength >= this.targetLength) {
                    this.numbersState = SpinAndGoNumbersState.done;
                }
                break;
            }
            case SpinAndGoNumbersState.done: {
                this.numbersTempTimer += dt;
                if (this.numbersTempTimer > 2) {
                    this.onShowDone.next();
                }
                break;
            }
        }

        if (this.multipliersContainer.position.y > this.maxNumYPos) {
            this.multipliersContainer.position.y = this.minNumYPos;
        }
    }
}
