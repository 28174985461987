import { PlayerTimer } from './player-timer';
import * as PIXI from 'pixi.js';
import { Point, Size } from '../models';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../../core/services/config.service';

export class PlayerTimerStandard extends PlayerTimer {
    private containerSize: Point = { x: 360, y: 8 };
    private barSize: Size = { width: 360, height: 6 };
    private barDefaultColor = 0xdf0226;
    private barTimeBankColor = 0x0231df;
    private barBackgroundColor = 0xe3e3e3;
    private barCurrentColor = 0xdf0226;

    private bar: PIXI.Graphics;
    private barBackground: PIXI.Graphics;

    evironment = environment;
    config;

    constructor(isLeftSided: boolean, position: Point, config: any) {
        super(/*isLeftSided*/false);
        this.config = config;

        this.barDefaultColor = this.config.settings.playerTimerColor;
        this.barTimeBankColor = this.config.settings.playerTimerTimeBankColor;
        this.barCurrentColor = this.config.settings.playerTimerColor;
        this.barBackgroundColor = this.config.settings.playerTimerBackgroundColor;

        this.container = new PIXI.Container();
        this.container.position.set(position.x - this.containerSize.x / 2,
            position.y - this.containerSize.y / 2);

        this.barBackground = new PIXI.Graphics();
        this.barBackground.beginFill(this.barBackgroundColor);
        this.barBackground.drawRect(0, 0, this.barSize.width, this.barSize.height);
        this.barBackground.endFill();
        this.container.addChild(this.barBackground);

        this.bar = new PIXI.Graphics();
        this.bar.beginFill(this.barCurrentColor);
        this.bar.drawRect(0, 0, this.barSize.width, this.barSize.height);
        this.bar.endFill();
        this.container.addChild(this.bar);
    }

    public update(newScale: number): void {

        this.bar.clear();
        this.bar.beginFill(this.barCurrentColor);
        if (this.isLeftSided) {
            this.bar.drawRect(this.barSize.width - this.barSize.width * newScale, 0,
                this.barSize.width * newScale, this.barSize.height);
        } else {
            this.bar.drawRect(0, 0, this.barSize.width * newScale, this.barSize.height);
        }
        this.bar.endFill();
    }

    public reset() {
        this.bar.clear();
        this.bar.beginFill(this.barCurrentColor);
        this.bar.drawRect(0, 0, this.barSize.width, this.barSize.height);
        this.bar.endFill();
    }

    public changeToTimebankColor() {
        this.barCurrentColor = this.barTimeBankColor;
    }

    public changeToDefaultColor() {
        this.barCurrentColor = this.barDefaultColor;
    }

}
