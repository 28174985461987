import * as TournamentsActions from './tournaments.actions';

import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Tournament, TournamentInformation } from '../../../shared/models';


// 🛠️ CHANGE

export type Action = TournamentsActions.All;

export interface State {
    tournaments: { [index: number]: Tournament; };
    viewingTournamentId: number;
}

const initialState: State = {
    tournaments: {},
    viewingTournamentId: 0
};

const newState = (state, newData) => {
    return Object.assign({}, state, newData);
};

export function tournamentsReducer(state: State = initialState, action: Action): State {
    switch (action.type) {
        case TournamentsActions.ADD_TOURNAMENT:
            state.tournaments[action.tournamentId] = action.tournament;
            return newState(state, state);

        case TournamentsActions.UPDATE_TOURNAMENT:
            if (action.tournament.tourSum) {
                state.tournaments[action.tournament.tourSum.Id] = action.tournament;
            } else if (action.tournament.tourInfo.IdTournament) {
                state.tournaments[action.tournament.tourInfo.IdTournament] = action.tournament;
            }
            return newState(state, state);

        case TournamentsActions.UPDATE_BLIND_LIST:
            state.tournaments[action.tournamentId].blindLevels = action.blindLevels;
            return newState(state, state);

        case TournamentsActions.SET_VIEW_TOURNAMENT:
            state.viewingTournamentId = action.tournamentId;
            return newState(state, state);

        case TournamentsActions.VIEW_TOURNAMENT:
            if (!state.tournaments[action.tournamentSummary.Id]) {
                state.tournaments[action.tournamentSummary.Id] = {} as Tournament;
                state.tournaments[action.tournamentSummary.Id].tourInfo = {} as TournamentInformation;
                state.tournaments[action.tournamentSummary.Id].activeTables = [];
            }
            state.tournaments[action.tournamentSummary.Id].tourSum = action.tournamentSummary;
            state.viewingTournamentId = action.tournamentSummary.Id;
            return newState(state, state);

        case TournamentsActions.UNVIEW_TOURNAMENT:
            delete state.tournaments[action.tournamentId];
            return newState(state, state);

        case TournamentsActions.REGISTER_ON_TOURNAMENT:
            state.tournaments[action.tournamentId].isRegistered = true;
            return newState(state, state);

        case TournamentsActions.UNREGISTER_FROM_TOURNAMENT:
            state.tournaments[action.tournamentId].isRegistered = false;
            return newState(state, state);

        case TournamentsActions.UPDATE_TOURNAMENT_INFO:
            if (!state.tournaments[action.tournamentInformation.IdTournament]) {
                state.tournaments[action.tournamentInformation.IdTournament] = <Tournament>{};
                state.tournaments[action.tournamentInformation.IdTournament].activeTables = [];
            }
            state.tournaments[action.tournamentInformation.IdTournament].tourInfo = action.tournamentInformation;
            return newState(state, state);

        case TournamentsActions.SET_TOURNAMENT_SUMMARY:
            if (!state.tournaments[action.tournamentSummary.Id]) {
                state.tournaments[action.tournamentSummary.Id] = <Tournament>{};
                state.tournaments[action.tournamentSummary.Id].activeTables = [];
            }
            state.tournaments[action.tournamentSummary.Id].tourSum = action.tournamentSummary;
            return newState(state, state);

        case TournamentsActions.UPDATE_TOURNAMENT_SUMMARY:
            const tournamentId = action.liveLobbyTournament.IdTournament;
            if (state.tournaments[tournamentId] && state.tournaments[tournamentId].tourSum) {
                state.tournaments[tournamentId].tourSum.NbPlayersActive = action.liveLobbyTournament.NbPlayersActive;
                state.tournaments[tournamentId].tourSum.NbPlayersActive = action.liveLobbyTournament.NbPlayersActive;
                state.tournaments[tournamentId].tourSum.NbPlayerRegistered = action.liveLobbyTournament.NbPlayersRegistered;
                state.tournaments[tournamentId].tourSum.Status2 = action.liveLobbyTournament.Status2;
                state.tournaments[tournamentId].tourSum.StartIn = action.liveLobbyTournament.StartIn;
                state.tournaments[tournamentId].tourSum.RegistrationsStartIn = action.liveLobbyTournament.RegistrationsStartIn;
                state.tournaments[tournamentId].tourSum.RegistrationEndIn = action.liveLobbyTournament.RegistrationEndIn;
                state.tournaments[tournamentId].tourSum.BlindIncreaseIn = action.liveLobbyTournament.BlindIncreaseIn;
                state.tournaments[tournamentId].tourSum.BreakIn = action.liveLobbyTournament.BreakIn;
                state.tournaments[tournamentId].tourSum.BreakEndIn = action.liveLobbyTournament.BreakEndIn;
                state.tournaments[tournamentId].tourSum.EndedSince = action.liveLobbyTournament.EndedSince;
            }
            return newState(state, state);

        case TournamentsActions.LOGOUT:
            state = {
                tournaments: {},
                viewingTournamentId: 0
            };
            return state;

        default:
            return newState(state, state);
    }
}

export const getTournamentsState = createFeatureSelector<State>('tournaments');
export const getTournaments = createSelector(getTournamentsState, state => state.tournaments);
export const getViewingTournament = createSelector(getTournamentsState,
    state => Object.assign({}, state.tournaments[state.viewingTournamentId]));
