import { Component, Attribute } from '@angular/core'

@Component({
    selector: 'now-date-time',
    template: `
  <h2>{{date | date: format}}</h2>
  `,
})

export class NowDateTime {
    date;
    format;

    constructor(@Attribute('format') format) {
        this.format = format;
        this.date = new Date();

        setInterval(() => {
            this.date = new Date();
        }, 1000);
    }
}
