import { Subscription, BehaviorSubject, Subject, ReplaySubject, Observable, of, interval, combineLatest, merge } from 'rxjs';
import { Injectable } from '@angular/core';
import { format, addSeconds } from 'date-fns';
import { ENavItem } from '../../main/nav/nav-item-type';
import { TournamentHelper } from '../../main/tournament/tournament-helper';
import { LoggerService } from './logger.service';
import { DataManagerService } from './data-manager.service';
import { ClockService } from './clock.service';
import { MatDialog } from '@angular/material/dialog';
import { Authenticate, User } from '../../shared/models/user';
import { GiftType, getCurrencyName, getCurrencyIconName, ConnectionStatus } from '../../shared/enums';
import { Comparators } from '../../shared/helpers/comparators';
import { CurrencyDeviderPipe } from '../../shared/utils/currency-devider.pipe';
import { CurrencyCryptoConverterPipe } from '../../shared/utils/currency-crypto-converter.pipe';
import { Legends } from '../../shared/constants/legend.constants';
import { HandHistory } from '../../shared/models/hand-history';
import { ServerMessage, Limit, TournamentStatus2, VariantType2, AccountType, ErrorCode, LobbyFilters, GameLayoutTypes, TransactionsHistoryStatus } from '../../shared/enums/poker-types';
import { CurrencyPipe } from '../../shared/utils/currency.pipe';
import { TournamentStatusTextAdjusted } from '../../shared/constants/tournament.constants';
import { NotificationsService } from '../notifications/notifications.service';
import { GenericTextDialog } from '../dialogs/generic-text-dialog/generic-text-dialog.component';
import { Ticket } from '../../shared/models/ticket';
import { TranslateService } from '@ngx-translate/core';
import { SpinNGoSummary } from '../../shared/models/spin-and-go-summary';
import { BroadcastDialogComponent } from '../dialogs/broadcast-dialog/broadcast-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrencyInfo } from '../../shared/models/currency-info';
import { AuthService, PlayersStatistics } from '../../auth/auth.service';
import { LostConnectionService } from '../lost-connection/lost-connection.service';
import { SpinnerService } from '../spinner/spinner.service';
import { Router } from '@angular/router';
import { GatewaysInfo } from '../../shared/models/gateway-info';
import { TransactionType } from '../../shared/enums/transaction-type';
import { getConvertedAmountText } from '../../shared/helpers/utils';
import { MemberPreferences } from '../../shared/models/member-preferences';
import { auditTime, debounceTime, filter, finalize, map, pairwise, shareReplay, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import {
  TableSummary,
  TournamentSummary,
  Tournament,
  NavTableData,
  TournamentInformation,
  LiveLobbyTournament,
  ServerMsg,
  TournamentEndResult,
  MTTTableAssigned,
  SitNGoSummary,
  TournamentActiveTable,
  GameTableStart,
  Gift,
  LobbyFilterOptions,
  PlayerBalance,
  LobbyFilter,
  Settings,
  CardData,
  MemberProfile,
  TournamentBlindLevel,
  BlindInformation,
  PlayerLevelStatus,
  TournamentPlayers,
  TournamentPrizes2,
  TournamentPrize2,
  TournamentPrize
} from '../../shared/models';
import { AppWebSocketService } from './app-web-socket.service';

import { TransactionsHistory } from '../../shared/models/transaction-history';
import { addMinutes } from 'date-fns/esm';

export enum DataResponse {
  Tickets = 'Tickets',

  CurrenciesInfo = 'CurrenciesInfo',
  TournamentsSummaries = 'TournamentsSummaries',
  TournamentSummary = 'TournamentSummary',
  TournamentInformation = 'TournamentInformation',
  LiveLobbyTournament = 'LiveLobbyTournament',
  BlindInformation = 'BlindInformation',

  TransactionsHistory = 'TransactionsHistory',
  MemberProfile = 'MemberProfile',
  PlayerLevelStatus = 'PlayerLevelStatus'
}


@Injectable()
export class v3ManagerService {

  // STORE:
  // tournaments;
  // currencies
  // user


  // TO DO:

  activeGames = new Map<string, NavTableData>(); // 📖 key: TOURNAMENT_123 OR TABLE_123 HeaderMenuBar+InitTable[] => should be only table id check that


  // -------------------------------------------------------------------------------
  private _user = new User()
  private _currencies = new Map<number, CurrencyInfo>(); // 📖 key: Currency ID
  private _tournaments = new Map<number, Tournament>(); // 📖 key: Tournament ID 
  private _playerLevelStatus = new Map<number, PlayerLevelStatus>(); // 📖 key: Tournament ID 

  transactionsHistory$: Observable<TransactionsHistory[]>
  tournamentSummaries$: Observable<TournamentSummary[]>
  tournaments$: Observable<Map<number, Tournament>> // 📖 key: Tournament ID, tourSum is required!
  currencies$: Observable<Map<number, CurrencyInfo>> // 📖 key: Currency ID
  playerLevelStatus$: Observable<Map<number, PlayerLevelStatus>>  // 📖 key: Currency ID

  user$: Observable<User>
  tickets$: Observable<Ticket[]>

  constructor(
    private _wsService: AppWebSocketService,
    private _dataManager: DataManagerService,
  ) {


    this._wsService.messageReceivedObservable
      .subscribe(data => {
        // this.parseServerMessage(data);
      });


    // #####################
    // ## USER  
    // #####################
    this.user$ = this.getDataResponse<MemberProfile>(DataResponse.MemberProfile, 'MemberProfile')
      .pipe(
        tap(memberProfile => {
          if (memberProfile.PlayTimeBankSound === undefined) {
            memberProfile.PlayTimeBankSound = Boolean(localStorage.getItem('top-timebank-sound'));
          }
          this._user.memberProfile = memberProfile;
        }),
        map(() => this._user),
        shareReplay(1)
      )

    this.playerLevelStatus$ = this.getDataResponse<PlayerLevelStatus>(DataResponse.PlayerLevelStatus, 'PlayerLevelStatus')
      .pipe(
        tap(playerLevelStatus => {
          this._playerLevelStatus.set(playerLevelStatus.Currency, playerLevelStatus)
        }),
        map(() => this._playerLevelStatus),
        shareReplay(1)
      )


    // #####################
    // ## Cashier  
    // #####################
    this.currencies$ = this.getDataResponse<CurrencyInfo[]>(DataResponse.CurrenciesInfo, 'CurrenciesInfo')
      .pipe(
        tap(currenciesInfo => {
          currenciesInfo.forEach(currenciesInfo => this._currencies.set(currenciesInfo.Id, currenciesInfo))
        }),
        map(() => this._currencies),
        shareReplay({ bufferSize: 1, refCount: false })
      )



    this.tickets$ = this.getDataResponse<Ticket[]>(DataResponse.Tickets, 'Tickets')
      .pipe(
        map(tickets => {
          return tickets.map(ticket => {
            ticket._currency = this._currencies.get(ticket.Currency)
            return ticket;
          })
        }),
        shareReplay({ bufferSize: 1, refCount: false })
      )

    this.transactionsHistory$ = this.getDataResponse<TransactionsHistory[]>(DataResponse.TransactionsHistory, 'TransactionsHistory')
      .pipe(
        map(data => (data.map(el => {
          switch (el.Status) {
            case TransactionsHistoryStatus.Confirmed:
              el.statusTranslate = 'Confirmed';
              break;
            case TransactionsHistoryStatus.Pending:
              el.statusTranslate = 'Pending';
              break;
            case TransactionsHistoryStatus.Cancelled:
              el.statusTranslate = 'Cancelled';
              break;
            case TransactionsHistoryStatus.NotDefined:
              el.statusTranslate = 'Not Defined';
            default:
              el.typeTranslate = `Unknown`;
              break;
          }

          switch (el.Type) {
            case TransactionType.Deposit:
              el.typeTranslate = 'Deposit';
              break;
            case TransactionType.Withdraw:
              el.typeTranslate = 'Withdrawal';
              break;
            case TransactionType.Transfer:
              el.typeTranslate = 'Sent Money';
              break;
            case TransactionType.TransferFrom:
              el.typeTranslate = 'Received Money';
              break;
            case TransactionType.GiftDepositBonus:
              el.typeTranslate = 'Gift deposit bonus';
              break;
            case TransactionType.GiftRakeBackBonus:
              el.typeTranslate = 'RakeBack';
              break;
            case TransactionType.WithdrawCanceled:
              el.typeTranslate = 'Cancelled of a withdrawal';
              break;
            case TransactionType.IncomePayout:
              el.typeTranslate = 'Income release';
              break;
            case TransactionType.RabbitHunting:
              el.typeTranslate = 'Rabbit Hunting';
              break;
            default:
              el.typeTranslate = `Unknown`;
              break;
          }

          el.currency = this._currencies.get(el.Currency);

          return el
        })))
      )

    // #####################
    // ## Tournaments  
    // #####################

    this.tournamentSummaries$ = this.getDataResponse<TournamentSummary[]>(DataResponse.TournamentsSummaries, 'TournamentsSummaries')
      .pipe(
        map(data => (data.map(el => {
          el.isTourney = true
          return el
        }))),
        switchMap((tournamentsSummaries) => {
          return this.getDataResponse<LiveLobbyTournament>(DataResponse.LiveLobbyTournament, 'LiveLobbyTournament')
            .pipe(
              map(liveLobbyTournament => {

                let tournamentSummary = tournamentsSummaries.find(el => el.Id === liveLobbyTournament.IdTournament)
                if (tournamentSummary) {
                  tournamentSummary = this.updateTournamentSummaryFromLiveLobbyTournament(tournamentSummary, liveLobbyTournament)
                }
                return tournamentsSummaries

              })
            )
        })
      )

    this.tournaments$ = merge(
      this.getDataResponse<TournamentSummary>(DataResponse.TournamentSummary, 'TournamentSummary')
        .pipe(
          tap(tournamentSummary => {
            // 🟢 added
            if (tournamentSummary.BreakIn) {
              tournamentSummary.breakInDate = addSeconds(new Date(), tournamentSummary.BreakIn)
            }
            if (tournamentSummary.BlindIncreaseIn) {
              tournamentSummary.blindIncreaseInDate = addSeconds(new Date(), tournamentSummary.BlindIncreaseIn)
            }

            if (tournamentSummary.StartIn) {
              tournamentSummary._startInDate = addSeconds(new Date(), tournamentSummary.StartIn)
            }

            if (tournamentSummary.DateStarting) {
              tournamentSummary._dateStarting = new Date(tournamentSummary.DateStarting)
            }

            tournamentSummary._registrationsStartInDate = addSeconds(new Date(), tournamentSummary.RegistrationsStartIn)
            tournamentSummary._lateRegistrationPeriodDate = addSeconds(new Date(), (tournamentSummary.StartIn + tournamentSummary.LateRegistrationDuration * 60))




            // Table rebuy costs
            if (tournamentSummary.OptionNoFeesOnRebuyAndAddOn) {
              tournamentSummary._rebuyCosts =
                CurrencyPipe.prototype.transform(
                  CurrencyDeviderPipe.prototype.transform(tournamentSummary.RebuyFee, this._user.selectedCurrency),
                  this._user.selectedCurrency)
                  .replace(/ /g, '');
            } else {
              const rebuyCosts = tournamentSummary.RebuyFee / (1 + tournamentSummary.HouseFeePercentage);

              tournamentSummary.tableRebuyCosts =
                CurrencyPipe.prototype.transform(
                  CurrencyDeviderPipe.prototype.transform(rebuyCosts, this._user.selectedCurrency),
                  this._user.selectedCurrency)
                  .replace(/ /g, '')
                + ' + ' +
                CurrencyPipe.prototype.transform(
                  CurrencyDeviderPipe.prototype.transform(
                    (rebuyCosts * tournamentSummary.HouseFeePercentage), this._user.selectedCurrency), this._user.selectedCurrency)
                  .replace(/ /g, '');
            }

            // Table buy in
            if (tournamentSummary.Freeroll) {
              tournamentSummary._buyIn = 'Freeroll';
            } else if (tournamentSummary.InitialBuyFree) {
              tournamentSummary._buyIn = '1st Buy In Free';
            } else if (tournamentSummary.OptionOnlyTicket) {
              tournamentSummary._buyIn = 'Ticket';
            } else {
              tournamentSummary._buyIn =
                CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(tournamentSummary.EntryFee, this._user.selectedCurrency),
                  this._user.selectedCurrency).replace(/ /g, '')
                + ' + ' +
                CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(
                  (tournamentSummary.EntryFee * tournamentSummary.HouseFeePercentage), this._user.selectedCurrency),
                  this._user.selectedCurrency).replace(/ /g, '');
            }






            let tournament = this._tournaments.get(tournamentSummary.Id)

            if (tournament) {
              tournament.tourSum = tournamentSummary;
            } else {
              tournament = {} as Tournament;
              tournament.tourSum = tournamentSummary
            }
            this._tournaments.set(tournamentSummary.Id, tournament);

          })
        ),
      this.getDataResponse<BlindInformation>(DataResponse.BlindInformation, 'BlindInformation')
        .pipe(
          tap(blindInformation => {
            let tournament = this._tournaments.get(blindInformation.IdTournament)
            if (tournament) {
              tournament._blindLevels = blindInformation.Blinds;
              this._tournaments.set(blindInformation.IdTournament, tournament);
            }
          })
        ),

      this.getDataResponse<LiveLobbyTournament>(DataResponse.LiveLobbyTournament, 'LiveLobbyTournament')
        .pipe(
          tap(liveLobbyTournament => {
            let tournament = this._tournaments.get(liveLobbyTournament.IdTournament)
            if (tournament) {
              tournament.tourSum = this.updateTournamentSummaryFromLiveLobbyTournament(tournament.tourSum, liveLobbyTournament)
              this._tournaments.set(liveLobbyTournament.IdTournament, tournament);
            }
          })
        ),
      this.getDataResponse<TournamentInformation>(DataResponse.TournamentInformation, 'TournamentInformation')
        .pipe(
          tap(tournamentInformation => {
            let tournament = this._tournaments.get(tournamentInformation.IdTournament)

            if (!tournament) {
              return
            }

            if (!tournament.tourInfo) {
              tournament.tourInfo = tournamentInformation;
              tournament.tourInfo.Players = tournamentInformation.Players ?? [];
              tournament.tourInfo.Prizes = tournamentInformation.Prizes ?? [];
              tournament.tourInfo.Prizes2 = tournamentInformation.Prizes2 ?? [];
              tournament.activeTables = []
              tournament.isRegistered = false;

            }

            // tournament.myActiveTable = null; // ❌ myActiveTable

            // 📚 if this is true restart player list
            if (tournamentInformation.NbPlayerRegistered === tournamentInformation.Players.length) {
              tournament.tourInfo.Players = tournamentInformation.Players;

              // 📚
              // sometimes server doesn't send Prizes so check is needed
              // from doc: "If this property is not present it's because the previous one didn't change."

              if (tournamentInformation.Prizes) {
                tournament.tourInfo.Prizes = tournamentInformation.Prizes;
              }

              if (tournamentInformation.Prizes2) {
                tournament.tourInfo.Prizes2 = tournamentInformation.Prizes2;
              }
            } else {
              // 📚 check for new players to be added to the list
              for (const playerUpdate of tournamentInformation.Players) {
                let existingPlayer = tournament.tourInfo.Players.find(oldPlayer => oldPlayer.IdPlayer === playerUpdate.IdPlayer);
                if (existingPlayer) {
                  existingPlayer = playerUpdate;
                } else {
                  tournament.tourInfo.Players.push(playerUpdate);
                }
              }
            }

            // 🟢 added
            let prizes;
            if (tournamentInformation?.Prizes) {
              prizes = tournament.tourInfo.Prizes.sort((a, b) => Comparators.compare(+a.Position, +b.Position, true));
            }
            if (tournamentInformation?.Prizes2) {
              prizes = tournament.tourInfo.Prizes2.sort((a, b) => Comparators.compare(+a.Position, +b.Position, true));
            }
            if (prizes) {
              tournament.tourInfo.prizes = prizes
              tournament.tourInfo.firstPrize = prizes[0]
            }




            // 🟢 added
            let highestStack = 0
            let lowestStack = 0
            let averageStack = 0

            if (tournament.tourInfo.Players.length > 0) {
              const numberOfChips = tournament.tourInfo.Players
                .filter(player => player.NbChips > 0)
                .map(player => player.NbChips);

              highestStack = Math.max(...numberOfChips);
              lowestStack = Math.min(...numberOfChips);
              averageStack = Math.round(numberOfChips.reduce((total, num) => total + num)) / numberOfChips.length
            } else {
              tournament.tourInfo.Players = tournament.tourInfo.Players.sort((a, b) => {
                return Comparators.compare(+a.Rank, +b.Rank, true);
              });
            }

            tournament.tourInfo._stack = {
              highest: highestStack,
              lowest: lowestStack,
              average: averageStack
            }

            const myPlayer = tournament.tourInfo.Players.find(player => player.IdPlayer === this._user.memberProfile.Id);
            const imOnTour = !myPlayer?.PositionEnded ? myPlayer : undefined;
            const iWasOnTour = myPlayer?.PositionEnded ? myPlayer : undefined;

            if (imOnTour) {
              tournament.isRegistered = !imOnTour.PositionEnded;

              // 📚
              // Menu bar - Active Games
              // if tournament is not on the list, add it to the list of registered tournaments in navBar
              // check if game is already running, if so open new gameTable

              if (!imOnTour.PositionEnded && !this.activeGames.has(`TOURNAMENT_${tournamentInformation.IdTournament}`)) { // ==> 🔴 fix this active games

                const gameTableStart = <GameTableStart>{
                  IdTable: imOnTour.IdTable, // 📖 Tournament can have multiple tables, so we will use a IdTable from Player Object
                  IdTournament: tournamentInformation.IdTournament,
                  tourSum: tournament.tourSum,
                  currency: this._currencies.get(tournament.tourSum.Currency),
                  name: tournament.tourSum.Name
                };

                // temporary commented 🔴
                // this.gameStart(gameTableStart);


                // ❌ myActiveTable - DELETE
                // tournament.myActiveTable = this.checkForTourGameTable(
                //   imOnTour.IdTable, tourInfo.IdTournament, imOnTour.PositionEnded, tournament.tourSum
                // );
              }
            } else {
              tournament.isRegistered = false;
            }

            // 🟢 added
            if (!tournament._blindLevels) {
              this._dataManager.requestBlindSchedule(tournamentInformation.IdTournament);
            }

            tournament = this.updateTourActiveTables(tournament, tournamentInformation);
            tournament = this.updatePlayersRank(tournament, this._user.memberProfile.Id);

            this._tournaments.set(tournamentInformation.IdTournament, tournament);
          })
        )
    ).pipe(

      map(() => this._tournaments),
      shareReplay(1)
    )

    // Activate and observe
    this.tickets$.subscribe()
    this.currencies$.subscribe()
    this.tournaments$.subscribe()
    this.user$.subscribe()
    this.playerLevelStatus$.subscribe()

    // Temporary test 

    this.tournamentSummaries$.subscribe((d) => {
    })
  }









  getTournament(idTournament: number): Observable<Tournament> {
    return this.tournaments$.pipe(
      filter(tournaments => tournaments.has(idTournament)),
      //throttleTime(500), // will emit the first request immediately and then ignore further requests within a 500 ms time window
      map(tournaments => JSON.parse(JSON.stringify(tournaments.get(idTournament)))),
    )
  }

























  private getDataResponse<T>(messageResponse: DataResponse, key: string): Observable<T> {
    return this._wsService.messageReceivedObservable
      .pipe(
        filter(data => data.Response === messageResponse),
        map(data => data[key] as T)
      )
  }

  private updateTournamentSummaryFromLiveLobbyTournament(tournamentSummary: TournamentSummary, liveLobbyTournament: LiveLobbyTournament): TournamentSummary {
    tournamentSummary.NbPlayersActive = liveLobbyTournament.NbPlayersActive;
    tournamentSummary.NbPlayerRegistered = liveLobbyTournament.NbPlayersRegistered;
    tournamentSummary.Status2 = liveLobbyTournament.Status2;
    tournamentSummary.StartIn = liveLobbyTournament.StartIn;
    tournamentSummary.RegistrationsStartIn = liveLobbyTournament.RegistrationsStartIn;
    tournamentSummary.RegistrationEndIn = liveLobbyTournament.RegistrationEndIn;
    tournamentSummary.BlindIncreaseIn = liveLobbyTournament.BlindIncreaseIn;
    tournamentSummary.BreakIn = liveLobbyTournament.BreakIn;
    tournamentSummary.BreakEndIn = liveLobbyTournament.BreakEndIn;
    tournamentSummary.EndedSince = liveLobbyTournament.EndedSince;
    tournamentSummary.ReBuyEndIn = liveLobbyTournament.ReBuyEndIn;

    tournamentSummary.NextBlind = liveLobbyTournament.NextBlind;
    tournamentSummary.NextAnte = liveLobbyTournament.NextAnte;


    if (tournamentSummary.Limit === Limit.FL) {
      tournamentSummary.minStake = liveLobbyTournament.Blind * 2;
      tournamentSummary.maxStake = liveLobbyTournament.Blind * 4;
    } else {
      tournamentSummary.minStake = liveLobbyTournament.Blind;
      tournamentSummary.maxStake = liveLobbyTournament.Blind * 2;
    }

    // 🟢 added
    if (liveLobbyTournament.BreakIn) {
      tournamentSummary.breakInDate = addSeconds(new Date(), liveLobbyTournament.BreakIn)
    }
    if (liveLobbyTournament.BlindIncreaseIn) {
      tournamentSummary.blindIncreaseInDate = addSeconds(new Date(), liveLobbyTournament.BlindIncreaseIn)
    }

    tournamentSummary._startInDate = addSeconds(new Date(), liveLobbyTournament.StartIn)
    tournamentSummary._registrationsStartInDate = addMinutes(new Date(), liveLobbyTournament.RegistrationsStartIn)


    tournamentSummary.LevelNumber = liveLobbyTournament.LevelNumber;
    tournamentSummary.FallGuysCurrentLevel = liveLobbyTournament.FallGuysCurrentLevel;
    tournamentSummary.FallGuysTotalLevels = liveLobbyTournament.FallGuysTotalLevels;
    tournamentSummary.FallGuysNextLevelStartsWith = liveLobbyTournament.FallGuysNextLevelStartsWith;




    return tournamentSummary
  }




  private updateTourActiveTables(tournament: Tournament, tourInfo: TournamentInformation): Tournament {
    // new data arrived, discard all old data
    if (tourInfo.NbPlayerRegistered === tourInfo.Players.length) {
      tournament.activeTables = [];
    }

    // update tables and players
    for (const player of tourInfo.Players) {
      // if player belongs to one of the existing tables
      const tableExist = tournament.activeTables.find(table => table.idTable === player.IdTable);


      if (!tableExist) {
        if (!player.PositionEnded && player.IdTable !== 0) { // => Player at active table have TableId diffrent than 0, inactive tables have ids 0
          tournament.activeTables.push(<TournamentActiveTable>{
            idTable: player.IdTable,
            minChips: player.NbChips,
            maxChips: player.NbChips,
            playersList: [player]
          });
        }
      } else {
        // check if player is already assigned to table
        const existingPlayer = tableExist.playersList.find(tablePlayer => tablePlayer.IdPlayer === player.IdPlayer);
        // if player doesn't already exist in table list add the player to players list
        if (!existingPlayer) {
          tableExist.playersList.push(player);
        }

        if (player.PositionEnded) {
          const index = tableExist.playersList.findIndex(p => p.IdPlayer === player.IdPlayer);
          tableExist.playersList.splice(index, 1);
        }
      }



      if (tableExist && !tableExist.playersList.length) {
        const tableIndex = tournament.activeTables.findIndex(table => table.idTable === tableExist.idTable);
        tournament.activeTables.splice(tableIndex, 1);
      }
    }

    let iAmOnTour = false

    // recalculate min/max chips and numb of players
    for (const table of tournament.activeTables) {
      const tableChips = [];

      let isMyTable = false;
      for (const player of table.playersList) {
        tableChips.push(player.NbChips);
        if (player.IdPlayer === this._user.memberProfile.Id) {
          isMyTable = true;
          iAmOnTour = true;
        }
      }

      table.maxChips = Math.max(...tableChips);
      table.minChips = Math.min(...tableChips);
      table.numberOfPlayers = table.playersList.length;
      table._isMyTable = isMyTable;
    }

    tournament.activeTables.sort((a, b) => {
      return Comparators.compare(+a.idTable, +b.idTable, false);
    });

    if (iAmOnTour) {
      tournament.activeTables.sort((a, b) => {
        return Comparators.compare(a._isMyTable ? 1 : -1, b._isMyTable ? 1 : -1, false);
      });
    }

    return tournament
  }

  private updatePlayersRank(tournament: Tournament, loggedInUserId: number): Tournament {
    tournament.tourInfo.Players.forEach(player => { player.NbChips = player.NbChips ? player.NbChips : 0; });

    tournament.tourInfo.Players = tournament.tourInfo.Players.sort((a, b) => {
      return Comparators.compare(+a.NbChips, +b.NbChips, false);
    });

    tournament.tourInfo.Players.forEach((player, index) => {
      if (player.PositionEnded) {
        player.Rank = player.PositionEnded;
      } else {
        player.Rank = index + 1;
      }

      if (player.IdPlayer === loggedInUserId) {
        tournament.myPosition = player.Rank;
      }
    });

    return tournament
  }






}

