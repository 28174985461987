import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tournament } from '../../../../shared/models';
import { TournamentStatus2 } from '../../../../shared/enums/poker-types';
import { User } from '../../../../shared/models/user';
import { TableType } from '../../../../shared/enums/table-type';

@Component({
  selector: 'view-tournament-info',
  templateUrl: './view-tournament-info.component.html',
  styleUrls: ['./view-tournament-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VewTournamentInfoComponent {

  @Input() user: User;
  @Input() tournament: Tournament;

  enumTournamentStatus2 = TournamentStatus2;
  enumTableType = TableType;
}
