export enum GameEvents {
    TableInit = 'Table opened',
    HandStart = 'Hand starts',
    PlayerFold = 'Folded',
    PlayerBidAnte = 'Ante',
    PlayerBidSmallBlind = 'Posts Small Blind',
    PlayerBidBigBlind = 'Posts Big Blind',
    PlayerBidCheck = 'Checked',
    PlayerBidBet = 'Bet',
    PlayerBidCall = 'Called',
    PlayerBidRaise = 'Raised',
    PlayerBidAllIn = 'All In',
    PlayerTurnChange = 'On The Move',
    PlayerRevealCard = 'Reveal Cards',
    MoveToPot = 'Bets Moved To Pot',
    WinnerByFold = 'Won Hand By Fold',
    WinnerByStrongestHand = 'Won with ',
    WinnerSplit = 'Won Split',
    PlayerLeaveTable = 'Leaves Table',
    PlayerTakeSeat = 'Join Table',
    GamePause = 'Game Paused',
    VariantChanged = 'Variant Changed',
    LimitChanged = 'Limit Changed',
    EndOfHand = 'End Of Hand',
    PlayerDealer = 'Is The Dealer',
    CommunityCards = 'Community Cards ',
    DealtFlop = 'Dealt Flop',
    DealtTurn = 'Dealt Turn',
    DealtRiver = 'Dealt River',
    DiscardedCards = 'Discarded',
    StandPat = 'Stand Pat',

    RequestedRabbitHunting = 'Requested rabbit hunting',
}
