import { AuthService } from '../../auth/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MainService } from '../main.service';
import { Currency, Gateway } from '../../shared/enums';
import { DataManagerService } from '../../core/services/data-manager.service';
import { GenericTextDialog } from '../../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../shared/models/user';
import { ManagerService } from '../../core/services/manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '../../../environments/environment';
import { GatewaysInfo } from '../../shared/models/gateway-info';
import { Settings } from '../../shared/models';
import { ConfigService } from '../../core/services/config.service';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})
export class CashierComponent implements OnInit, OnDestroy {

  user: User;
  destroy$ = new Subject<boolean>();

  handsetPortrait = false;
  handsetLandscape = false;


  @ViewChild('cashierNavSwiper') cashierNavSwiper: any;
  cashierNavSwiperIndex: number = 0

  logoUrl: string;

  gatewaysInfo: GatewaysInfo;
  settings: Settings;
  config;
  constructor(
    public managerService: ManagerService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;

  }

  slideTo(index: number) {
    this.cashierNavSwiper.swiperRef.slideTo(index, 600);
  }

  activeIndexChange($event) {
    this.cashierNavSwiperIndex = $event.activeIndex
  }

  ngOnInit() {

    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          this.settings = settings;
          console.log("@sett", settings)
        }
      })

    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: user => {
          this.user = user

          if (this.user?.settings?.LobbyClubLogo) {
            this.logoUrl = this.configService.config.httpUrl + '/' + this.user.settings.LobbyClubLogo;
          } else {
            this.logoUrl = `${environment.dataSource}/assets/${this.config.code}/logo.png`;



          }
        }
      })

    this.managerService.gatewaysInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: gatewaysInfo => {
          this.gatewaysInfo = gatewaysInfo;
        }
      })


    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.handsetLandscape = result.matches;
      })
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }




  // DELETE ******************************************************

  // casierDeposit(gateway: Gateway) {
  //   if (gateway === Gateway.Coinbase) {
  //     const dialogRef = this.dialog.open(GenericTextDialog, {
  //       disableClose: true
  //     });

  //     dialogRef.componentInstance.textAcceptBtn = 'OK';
  //     dialogRef.componentInstance.textDismissBtn = 'Cancel';
  //     dialogRef.componentInstance.textHeader = 'Deposit';
  //     dialogRef.componentInstance.textBody =
  //       `You are about to be forwarded to our gateway \'Coinbase\'. <br>
  //       Please have your Wallet ready. Simply select your currency and send funds to the address Coinbase will display. <br><br>
  //       After the funds have been sent, it may take up to 30 minutes for the funds to appear in your account. <br><br>
  //       Please be patient.`;
  //     dialogRef.afterClosed().subscribe(clicked => {
  //       if (clicked === 'ok') {
  //         this.dataManager.sendDepositRequestNew(Gateway.Coinbase, 0, this.mainService.user.selectedCurrency.Id, '');
  //         this.authService.depositWithdrawalWindow = window.open('', '_blank');
  //       }
  //     });
  //   } else if (gateway === Gateway.coinSwitch) {
  //     const dialogRef = this.dialog.open(GenericTextDialog, {
  //       disableClose: true
  //     });

  //     dialogRef.componentInstance.textDismissBtn = 'Close';
  //     dialogRef.componentInstance.textHeader = 'INFO';
  //     dialogRef.componentInstance.enableAccept = false;
  //     // dialogRef.componentInstance.textBody =
  //     //   `Deposit any Altcoin and instantly convert it to Bitcoin.<br><br>
  //     //    Deposited funds will be added to your BTC balance after it is confirmed on the blockchain
  //     //   `;
  //     dialogRef.componentInstance.textBody = 'Coming Soon!';
  //     dialogRef.afterClosed().subscribe(clicked => {
  //       if (clicked === 'ok') {
  //         this.dataManager.sendDepositRequestNew(Gateway.deposit_altcoin, 0, this.mainService.user.selectedCurrency.Id, '');
  //         this.authService.depositWithdrawalWindow = window.open('', '_blank');
  //       }
  //     });
  //   }
  // }

  // casierWithdrawal() {
  //   this.mainService.casierWithdrawal();
  // }

  // casierHistory() {
  //   this.mainService.showCasierHistory();
  // }

  // casierPlayerRankLevel() {
  //   this.mainService.showPlayerRankLevelDialog();
  // }

  // casierBonusUnlock() {
  //   this.mainService.showBonusUnlock();
  // }

}
