import { Component, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';

import { AppTable } from '../../../shared/abstract-classes/app-table';
import { Comparators } from '../../../shared/helpers/comparators';
import { Legends } from '../../../shared/constants/legend.constants';
import { environment } from '../../../../environments/environment';
import { Required } from '../../../shared/decorators/required.decorator';
import { ManagerService } from '../../../core/services/manager.service';
import { GameType } from '../../../shared/enums/game-type.enum';
import { Colors } from '../../../shared/constants/color.constants';


@Component({
  selector: 'app-lobby-game-table',
  templateUrl: './lobby-game-table.component.html',
  styleUrls: ['./lobby-game-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LobbyGameTableComponent implements OnInit, AfterViewInit {

  public Legends = Legends;
  public Colors = Colors;
  public showExchange = true;

  protected _dataSource = [];
  protected _columns = [];
  private selectedColumns = {};
  private _sort: Sort;
  public tableData = [];
  public sortedData = [];

  @Input() gameType: GameType;
  @Input() @Required currency: string;
  @Input()
  public get columns(): Array<string> { return this._columns; }
  public set columns(val: Array<string>) {
    this._columns = val;
    this.selectedColumns = {};
    this._columns.forEach(column => {
      this.selectedColumns[column] = true;
    });
  }
  @Input()
  public get dataSource(): Array<any> { return this._dataSource; }
  public set dataSource(val) {
    this._dataSource = val || [];

    if (this.managerService.useSmartSort) {
      this._dataSource.sort((a, b) => {
        if (this.gameType === GameType.ring) {
          return this.managerService.useSmartSort ?
            Comparators.smartSortingPlayerComparator(a, b)
            : Comparators.playerComparator(+a.PlayerCount, +b.PlayerCount, false);
        } else if (this.gameType === GameType.sitNgo) {
          return Comparators.sngPlayerComparator(a.tablePlayers, b.tablePlayers);
        } else if (this.gameType === GameType.tournament) {
          return Comparators.dateComparator(a.DateStarting, b.DateStarting, true);
        }
      });
      this.sortedData = this._dataSource;
    } else if (this._sort) {
      this.sortData(this._sort);
    }
  }

  @Output() public onRowClick = new EventEmitter();
  @Output() public onRowDblClick = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private managerService: ManagerService) {
    this._sort = { active: 'ringPlayers', direction: 'desc' };
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  rowClick(row) {
    this.onRowClick.emit(row);
  }

  rowDblCLick(row) {
    this.onRowDblClick.emit(row);
  }

  toggleShowExchange() {
    this.showExchange = !this.showExchange;
  }

  trackById(index, item) {
    return item.Id;
  }

  sortData(sort: Sort) {
    this._sort = sort;
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'tableId': return Comparators.compare(+a.Id, +b.Id, isAsc);
        case 'variant': return Comparators.compare(a.tableVariant, b.tableVariant, isAsc);
        case 'speed': return Comparators.compare(a.tableSpeed, b.tableSpeed, isAsc);
        case 'ringPlayers': return this.managerService.useSmartSort ?
          Comparators.smartSortingPlayerComparator(a, b)
          : Comparators.playerComparator(+a.PlayerCount, +b.PlayerCount, isAsc);
        case 'tourPlayers': return Comparators.compare(a.NbPlayersActive, b.NbPlayersActive, isAsc);
        case 'sngPlayers': return Comparators.sngPlayerComparator(a.tablePlayers, b.tablePlayers);
        case 'wait': return Comparators.compare(+a.tableWait, +b.tableWait, isAsc);
        case 'stakes': return Comparators.compare(+a.Blind, +b.Blind, isAsc);
        case 'buyIn': return Comparators.compare(+a.EntryFee, +b.EntryFee, isAsc);
        case 'limit': return Comparators.compare(a.tableLimit, b.tableLimit, isAsc);
        case 'name': return Comparators.compare(a.Name, b.Name, isAsc);
        case 'date': return Comparators.dateComparator(a.DateStarting, b.DateStarting, isAsc);
        case 'scheduler': return Comparators.dateComparator(a.DateStarting, b.DateStarting, isAsc);

        default: return 0;
      }
    });

    this.sortedData = sortedData;
  }
}
