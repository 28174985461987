import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppWebSocketService } from '../../../core/services/app-web-socket.service';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { ManagerService } from '../../../core/services/manager.service';
import { RequestMessage } from '../../../shared/enums/poker-types';
import { User } from '../../../shared/models/user';
import { GameComponent } from '../../game/game.component';
import { GameService } from '../../game/game.service';
import { UserAppConfig } from '../../game/models/user-app-config';
import { MainService } from '../../main.service';
import { ENavItem } from '../../nav/nav-item-type';
import { ConfigService } from '../../../core/services/config.service';
import { MemberPreferencesRunItTwice } from '../../../shared/models/member-preferences';

@Component({
  selector: 'app-menu-game-settings',
  templateUrl: './menu-game-settings.component.html',
  styleUrls: ['./menu-game-settings.component.scss']
})
export class MenuGameSettingsComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<boolean>();
  user: User;
  volume = true;
  tableId: number;
  game: GameComponent;
  userAppConfig$: Observable<UserAppConfig>;
  config;


  r2t: MemberPreferencesRunItTwice;
  memberPreferencesRunItTwice = MemberPreferencesRunItTwice

  r2tOpen = false;


  constructor(
    private managerService: ManagerService,
    private gameService: GameService,
    private mainService: MainService,
    private service: AppWebSocketService,
    public assetsLoader: AssetsLoaderService,

    private configService: ConfigService,
  ) {
    this.config = this.configService.config;

  }

  ngOnInit(): void {
    this.userAppConfig$ = this.managerService.userAppConfig$


    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user
        }
      })

    this.mainService.sidenavState
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data.show && data.settings && this.tableId) {
            this.game = this.gameService.getGameById(this.tableId)
          }
        })


    this.managerService.activeGames$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (activeGames) => {
          const activeGame = Object.values(activeGames).find(el => el.isActive)
          if (activeGame) {
            this.tableId = activeGame.table.IdTable
            this.game = this.gameService.getGameById(this.tableId)
            this.r2t = this.game?.actionControls?.checkRunItTwice
          } else {
            this.tableId = null
            this.game = null

          }

        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  muckCardClick(value: boolean) {
    this.user.memberProfile.Preferences.AutoMuck = value;
    this.managerService.muckCard(value);
  }


  updateMuteSound(value: boolean) {
    Howler.volume(value ? 0 : 1);
    this.playToggleSound()
    this.managerService.updateUserAppConfig({ muteSound: value })
  }

  playToggleSound() {
    const themes = this.config.themes
    if (themes && themes.length > 0) {
      const theme = themes.find(el => el.name === localStorage.getItem('theme'))
      if (theme && theme.sound.toggle) {
        this.assetsLoader.sound[theme.name + '-toggle'].play()
      }
    }
  }


  clickStraddle() {
    this.game.betControls.straddle = !this.game.betControls.straddle;

    const item = {
      Type: RequestMessage.SetStraddle,
      IdTable: this.game.id,
      value: this.game.betControls.straddle ? 1 : 0
    };

    this.service.sendData(item);
  }


  jumpToTableClick(value: boolean) {
    this.user.memberProfile.Preferences.JumpToTable = value;
    this.managerService.jumpToTable(value);
  }

  onLobbyClick() {
    this.managerService.setActiveTab(ENavItem.Lobby)
    this.managerService.setActiveGame(null)
    this.mainService.toggleSidenav(false, true);
    setTimeout(() => {
      this.mainService.toggleSidenav(false, false);
    }, 600);

    // this.mainService.toggleSidenav(false, false);

    // setTimeout(() => {
    //   this.managerService.setActiveTab(ENavItem.Lobby)
    //   this.managerService.setActiveGame(null)
    // }, 300);

    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Lobby)); DEC
    // this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: undefined })); DEC
  }

  onLeaveTable() {
    this.mainService.toggleSidenav(false, true);
    setTimeout(() => {
      this.managerService.onLeaveTable.next(this.tableId)
    }, 150);
  }


  addChips() {
    if (!this.game?.actionControls?.showBuyRebuyChipsButton) {
      return
    }
    this.mainService.toggleSidenav(false, true);
    setTimeout(() => {
      this.game.clickBuyRebuyChips()

    }, 150);
  }
  runTwiceClick(value: MemberPreferencesRunItTwice) {
    console.log("MemberPreferencesRunItTwice", value)
    this.managerService.runItTwiceTable(value, this.tableId);
    this.playToggleSound()
  }
}
