export const TournamentStatusText = [
    'Hidden',
    'Announced',
    'RegistrationOpen',
    'RunningWithLateRegistration',
    'RunningRegistrationClosed',
    'Completed',
    'Canceled',
];

export const TournamentStatusTextAdjusted = [
    'Hidden',
    'Announced',
    'Open',
    'Late Registration',
    'Running',
    'Completed',
    'Canceled',
];
