import * as authAction from './auth/auth-store/auth.actons';
import * as navActions from './main/nav/nav-store/nav-store.actions';
import * as gameActions from './main/game/store/game-store.actions';
import * as tournamentsActions from './main/tournament/store/tournaments.actions';
import * as lobbyActions from './main/lobby/lobby-store/lobby.actons';

export const AppActions = {
    AuthAction: authAction,
    NavAction: navActions,
    GameAction: gameActions,
    TournamentsActions: tournamentsActions,
    LobbyActions: lobbyActions
};
