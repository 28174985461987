import { Component, Renderer2, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, timer } from 'rxjs';
import { LobbyFilters } from '../../../shared/enums/poker-types';
import { AuthService, PlayersStatistics } from '../../../auth/auth.service';
import { User } from '../../../shared/models/user';
import { ManagerService } from '../../../core/services/manager.service';
import { CasinoGamesCategory, DataManagerService } from '../../../core/services/data-manager.service';
import { MainService } from '../../main.service';
import { LobbyFilterOptions, NavTableData, Settings, TableSummary, Tournament, TournamentSummary } from '../../../shared/models';
import { GameService } from '../../game/game.service';
import { environment } from '../../../../environments/environment';
import { Colors } from '../../../shared/constants/color.constants';
import { Comparators } from '../../../shared/helpers/comparators';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, map, pluck, share, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DialogLegendSymbol } from '../lobby-dialogs/dialog-legend-symbol/dialog-legend-symbol.component';
import { AppWebSocketService } from '../../../core/services/app-web-socket.service';
import { Ticket } from '../../../shared/models/ticket';
import { PasswordDialogComponent } from '../lobby-dialogs/password-dialog/password-dialog.component';
import { GameType } from '../../../shared/enums/game-type.enum';
import { FilterType } from '../../../shared/enums/filter-type';
import { Currency } from '../../../shared/enums';
import { ENavItem } from '../../nav/nav-item-type';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Howl } from 'howler';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { CurrencyInfo } from '../../../shared/models/currency-info';
import { ConfigService } from '../../../core/services/config.service';
import { GenericTextDialog } from '../../../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';
import { CurrencyPipe } from '../../../shared/utils/currency.pipe';
import { EditProfileComponent } from '../../dialogs/edit-profile/edit-profile.component';
import { HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
export interface TableDataSort {
  direction: string;
  active: string;
}

@Component({
  selector: 'app-lobby-container',
  templateUrl: './lobby-container.component.html',
  styleUrls: ['./lobby-container.component.scss'],
})
export class LobbyContainerComponent implements OnDestroy {
  lobbySound: Howl;

  environment = environment;
  gameTypeFilter: any = {}
  gameVariantFilter = [
    { displayValue: 'Any', realValue: 'ALL', type: 0 },

    // Hold'em - - - - - - - - - - - - - - - - - - - - - - - -
    {
      displayValue: 'Hold\'em Games',
      realValue: [
        LobbyFilters.VariantHoldem,
        LobbyFilters.VariantHoldemRock,
        LobbyFilters.VariantCapTexasHoldEm
      ],
      type: 0
    },
    { realValue: LobbyFilters.VariantHoldem, displayValue: 'Hold\'em', type: 1 },
    { realValue: LobbyFilters.VariantHoldemRock, displayValue: 'Hold\'em Rock', type: 1 },
    { realValue: LobbyFilters.VariantCapTexasHoldEm, displayValue: 'Cap Hold\'em', type: 1 },
    { realValue: LobbyFilters.VariantCapTexasHoldEm, displayValue: 'Cap Hold\'em', type: 1 },
    { realValue: LobbyFilters.SixPlusHoldem, displayValue: '6+ Hold\'em', type: 1 },

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Omaha - - - - - - - - - - - - - - - - - - - - - - - -  
    {
      displayValue: 'Omaha Games',
      realValue: [
        LobbyFilters.VariantOmaha,
        LobbyFilters.VariantOmahaHiLow,
        LobbyFilters.VariantOmaha3,
        LobbyFilters.VariantOmaha5,
        LobbyFilters.VariantOmaha5HiLow,
        LobbyFilters.VariantOmaha6,
        LobbyFilters.Omaha7,
        LobbyFilters.CapOmaha,
        LobbyFilters.CapOmaha5,
        LobbyFilters.CapOmaha6,
        LobbyFilters.CapOmaha7,
        LobbyFilters.VariantTriOmaha
      ],
      type: 0
    },
    { realValue: LobbyFilters.VariantOmaha, displayValue: 'Omaha', type: 1 },
    { realValue: LobbyFilters.VariantOmahaHiLow, displayValue: 'Omaha Hi/Lo', type: 1 },
    { realValue: LobbyFilters.VariantOmaha3, displayValue: 'Omaha 3', type: 1 },
    { realValue: LobbyFilters.VariantOmaha5, displayValue: 'Omaha 5', type: 1 },
    { realValue: LobbyFilters.VariantOmaha5HiLow, displayValue: 'Omaha 5 Hi/Lo', type: 1 },
    { realValue: LobbyFilters.VariantOmaha6, displayValue: 'Omaha 6', type: 1 },
    { realValue: LobbyFilters.Omaha7, displayValue: 'Omaha 7', type: 1 },
    { realValue: LobbyFilters.CapOmaha, displayValue: 'Cap Omaha', type: 1 },
    { realValue: LobbyFilters.CapOmaha5, displayValue: 'Cap Omaha 5', type: 1 },
    { realValue: LobbyFilters.CapOmaha6, displayValue: 'Cap Omaha 6', type: 1 },
    { realValue: LobbyFilters.CapOmaha7, displayValue: 'Cap Omaha 7', type: 1 },
    { realValue: LobbyFilters.VariantTriOmaha, displayValue: 'Tri Omaha', type: 1 },
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Stud Games - - - - - - - - - - - - - - - - - - - - - - - -
    {
      displayValue: 'Stud Games',
      realValue: [
        LobbyFilters.Variant7Stud,
        LobbyFilters.SevenStudHiLo,
        LobbyFilters.Razz
      ],
      type: 0
    },
    { realValue: LobbyFilters.Variant7Stud, displayValue: '7 Stud', type: 1 },
    { realValue: LobbyFilters.SevenStudHiLo, displayValue: '7 Stud Hi/Lo', type: 1 },
    { realValue: LobbyFilters.Razz, displayValue: 'Razz', type: 1 },
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Draw Games - - - - - - - - - - - - - - - - - - - - - - - -
    {
      displayValue: 'Draw Games',
      realValue: [
        LobbyFilters.Badugi,
        LobbyFilters.VariantTripleDraw,
        LobbyFilters.FiveCardDraw,
        LobbyFilters.Badeucey
      ],
      type: 0
    },
    { realValue: LobbyFilters.Badugi, displayValue: 'Badugi', type: 1 },
    { realValue: LobbyFilters.VariantTripleDraw, displayValue: '2-7 Tripple Draw', type: 1 },
    { realValue: LobbyFilters.FiveCardDraw, displayValue: '5 Card Draw', type: 1 },
    { realValue: LobbyFilters.Badeucey, displayValue: 'Badeucey', type: 1 },
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    { realValue: LobbyFilters.VariantMixTable, displayValue: 'Mix table', type: 0 },

  ]

  Colors = Colors;
  Currency = Currency;
  LobbyFilters = LobbyFilters;
  FilterType = FilterType;

  lobbyLoader = false;
  destroy$ = new Subject<boolean>();

  dialogPasswordRef: MatDialogRef<PasswordDialogComponent>;

  filterValue: LobbyFilterOptions;
  lobbyFilterOptions: LobbyFilterOptions;

  tournamentData: TournamentSummary;

  selectedTableId;
  selectedTableSum: TableSummary;
  selectedMixTable;





  user: User;
  logoUrl: string;



  asidePreview: string = 'previewRingGames'

  selectedGameVariantFilter

  viewingTournament: Tournament;
  playerTickets: Array<Ticket> = [];

  banners: any = {
    top: null,
    bottom: null
  }





  enumLobbyFilters = LobbyFilters;

  tableSort: TableDataSort = { active: 'ringPlayers', direction: 'desc' }
  tableData: any[] = []
  tableDb: any[] = []


  selectedGameVariantFilterControl = new FormControl('ALL')
  search: FormControl = new FormControl('')
  lobbyStatsPlayer: PlayersStatistics
  lobbyChatOpen: boolean = false;


  handsetPortrait = false;
  handsetLandscape = false;
  navTables: Array<NavTableData> = [];


  settings: Settings;

  showPlayerPlus = false;


  currencyInfo: CurrencyInfo[];
  emotikenCurrency: CurrencyInfo;
  skinsSettings: any;
  config;


  bannerTop$: Observable<any>;

  gameFilters$: Observable<any>;

  showHome = false;
  showCasino = false;
  casinosInfo$: Observable<any[]>;
  selectedCasino: any = null;
  casinoBuyInAmount: number;
  casinoProduct: string;
  showCasinoCategory = false;
  casinoUrl;
  showLobbyV2 = false;
  lobbyV2Tab = false;
  lobbyV1Tab = false;



  reloadCasinoGamesCategories$ = new BehaviorSubject<void>(undefined)
  casinoGamesCategories$: Observable<CasinoGamesCategory[]>;
  lobbyCasinoGamesCategories$: Observable<CasinoGamesCategory[]>;
  topMenuCasinoGamesCategories$: Observable<CasinoGamesCategory[]>;

  lobbyCasinoGamesCategoriesLoader = true;


  reloadAllCasinoGames$ = new ReplaySubject<void>()
  allCasinoGames$: Observable<CasinoGamesCategory[]>;
  allCasinoGamesLoader = true;


  reloadCategoryCasinoGames$ = new ReplaySubject<number>()
  categoryCasinoGames$: Observable<CasinoGamesCategory[]>;
  categoryCasinoGamesLoader = true;


  activeCasinoGame$ = this.managerService.activeCasinoGame$;
  activeCasinoGames$ = this.managerService.activeCasinoGames$;

  constructor(
    public translate: TranslateService,
    public managerService: ManagerService,
    public dataManager: DataManagerService,
    public authService: AuthService,
    public mainService: MainService,
    public dialog: MatDialog,
    private gameService: GameService,
    public zone: NgZone,
    public ref: ChangeDetectorRef,
    public _webSocketService: AppWebSocketService,
    private breakpointObserver: BreakpointObserver,
    public assetsLoader: AssetsLoaderService,
    private configService: ConfigService,
    private sanitizer: DomSanitizer
  ) {

    this.selectedGameVariantFilterControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log("selectedGameVariantFilterControl", value)
      this.filterChange(FilterType.Variant, value)
    })

    // Casino Integration

    this.casinoGamesCategories$ = this.reloadCasinoGamesCategories$.pipe(
      switchMap(() => this.dataManager.getGameToken()),
      map(response => response.token),
      switchMap(token => this.dataManager.getCasinoGamesCategories(token)),
      map(response => response.categories),
      shareReplay(1)
    )

    this.lobbyCasinoGamesCategories$ = this.casinoGamesCategories$.pipe(
      tap(() => this.lobbyCasinoGamesCategoriesLoader = true),
      map(categories => categories.filter(category => category.showInMainLobby).sort((a, b) => a.orderInMainMenu - b.orderInMainMenu)),  // filter categories that should be shown in main lobby
      tap(() => this.lobbyCasinoGamesCategoriesLoader = false),
      shareReplay(1)

    )


    this.topMenuCasinoGamesCategories$ = this.casinoGamesCategories$.pipe(
      map(categories => categories.filter(category => category.showInTopMenu).sort((a, b) => a.orderInTopMenu - b.orderInTopMenu)),  // filter categories that should be shown in main lobby
      shareReplay(1)

    )

    this.allCasinoGames$ = this.reloadAllCasinoGames$.pipe(
      tap(() => this.allCasinoGamesLoader = true),
      switchMap(() => this.dataManager.getGameToken()),
      map(response => response.token),
      switchMap(token => this.dataManager.getAllCasinoGames(token)),
      map(response => response.categoriesWithGames),
      tap(() => this.allCasinoGamesLoader = false),
      map(categories => categories.filter(category => category.showInMainMenu).sort((a, b) => a.orderInMainMenu - b.orderInMainMenu)),  // filter categories that should be shown in main lobby

      shareReplay(1),
    )

    this.categoryCasinoGames$ = this.reloadCategoryCasinoGames$.pipe(
      tap(() => this.categoryCasinoGamesLoader = true),
      switchMap((categoryId) => this.dataManager.getGameToken().pipe(
        map(response => response.token),
        switchMap(token => this.dataManager.getCasinoGames(token, categoryId, 0)),
        map(response => response.categoriesWithGames),
      )),
      tap(() => this.categoryCasinoGamesLoader = false),

      shareReplay(1)

    )

    // =====




    this.config = this.configService.config;
    this.gameTypeFilter = this.config.lobbyGameTypeFilters;
    //this.managerService.setLobbyFilterGameType(this.gameTypeFilter[0].realValue)


    if (this.config.code === 'neonpoker-qijy') {
      this.lobbySound = this.assetsLoader.sound.Lobby;
      this.lobbySound.stop();

      this.lobbySound.volume(0)
      this.lobbySound.play();
      this.lobbySound.fade(0, 1, 500)
    }

    if (this.config.code === 'newwavepoker-cxzo' || this.config.code === 'newwavepokertest-kwwa' || this.config.code === 'devryops2-nxwr') {
      this.showLobbyV2 = true;
      this.lobbyV2Tab = true;
      this.lobbyV1Tab = false;

      this.btnOnOpenHome()

      this.managerService.casinoTokenReceivedObservable$.subscribe(({ token, operatorid }) => {
        const casino = this.selectedCasino;
        let queryParams = {}
        if (casino.Parameters.token && casino.Parameters.token === '##') { queryParams = { ...queryParams, token } }
        if (casino.Parameters.username && casino.Parameters.username === '##') { queryParams = { ...queryParams, username: this.user.memberProfile.Username } }
        if (casino.Parameters.buyInAmount && casino.Parameters.buyInAmount === '##') { queryParams = { ...queryParams, buyInAmount: this.casinoBuyInAmount } }
        if (casino.Parameters.product && casino.Parameters.buyInAmount === '##') { queryParams = { ...queryParams, product: this.casinoProduct } }

        const url = `${casino.LandingUrl}?${new HttpParams({ fromObject: queryParams }).toString()}`
        console.log("@url", url)
        this.casinoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
        console.log("casinoUrl", this.casinoUrl)

        this.managerService.setActiveTab(ENavItem.CasinoGame)
      });

    }else{
      this.showLobbyV2 = false;
      this.lobbyV2Tab = false;
      this.lobbyV1Tab = true;
    }



    this.playLobbySound(localStorage.getItem('theme'))

    this.assetsLoader.reloadObservable$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.playLobbySound(localStorage.getItem('theme'))
    });


    // console.log("config", this.config)


    this.selectedGameVariantFilter = this.gameVariantFilter[0].realValue
    this.managerService.subscribeLiveLobby();
    this.casinosInfo$ = this.managerService.casinosInfo$;

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.handsetLandscape = result.matches;
      })

    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        this.tableData = this.sortData(this.tableSort, value ? this.tableDb.filter(el => el.Name.toLowerCase().includes(value.toLowerCase())) : this.tableDb)
      })

    this.managerService.currencyInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (currencyInfo) => {
          this.currencyInfo = currencyInfo
          this.emotikenCurrency = this.currencyInfo.find(el => el.Id === 255)
        }
      })

    this.managerService.skinsSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (skinsSettings) => {
          this.skinsSettings = skinsSettings
        }
      })


    this.managerService.settings$.pipe().subscribe(data => {
      // console.log("settings", data.FeatureBanners)
      // const FeatureBanners = JSON.parse(data.FeatureBanners)
      // console.log("settings FeatureBanners", FeatureBanners)
      // let lobbyBanners = FeatureBanners.find(el => el.Location === 2)
      // lobbyBanners.Banners = lobbyBanners.Banners.map(el => `${this.config.httpUrl}${el}`)
      // console.log("@lobbyBanners", lobbyBanners)
    })


    this.bannerTop$ = this.managerService.settings$.pipe(
      //take(1),
      map(settings => settings?.Banners?.filter(el => el.Position === `Top`)),
      switchMap(banners => {
        return timer(0, 6000)
          .pipe(
            // tap((index) => {
            //   console.log('banners', banners, 'size:', banners.length, 'index:', index,)

            //   const arrayIndex = index % banners.length;
            //   const currentItem = banners[arrayIndex];
            //   // Perform any operations with the currentItem
            //   console.log(`Processing index ${arrayIndex}:`, currentItem);
            // }),
            map((index) => banners ? banners[index % banners.length] : null)
          )
      }),
      takeUntil(this.destroy$)
    )
    this.bannerTop$.subscribe(banner => console.log('###banner', banner))

    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          this.settings = settings;


          this.banners.top = this.settings?.Banners?.find(el => el.Position === `Top`) || null
          this.banners.bottom = this.settings?.Banners?.find(el => el.Position === `Bottom`) || null
          this.banners.right = this.settings?.Banners?.find(el => el.Position === `Right`) || null

          if (!settings) { // on logout return to ring games
            this.filterChange(FilterType.GameType, this.gameTypeFilter[0].realValue)
          }
        }
      })

    this.managerService.reloadLobby$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.filterChange(FilterType.GameType, this.gameTypeFilter[0].realValue)
        }
      })


    this.managerService.viewTournament$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (viewingTournament) => {
          this.viewingTournament = viewingTournament;
        }
      })

    this.managerService.lobbyListObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {



        const gameFilter = this.gameTypeFilter?.find(el => el?.realValue === this.lobbyFilterOptions?.TableType)

        switch (gameFilter?.realValue) {
          case LobbyFilters.TypeRing:
            this.tableSort = { active: 'ringPlayers', direction: 'desc' }
            break;
          case LobbyFilters.TypeSitnGo:
            this.tableSort = { active: 'sngPlayers', direction: 'desc' }
            break;
          case LobbyFilters.TypeTournament:
            this.tableSort = { active: 'scheduler', direction: 'asc' }
            break;
          case LobbyFilters.TypeSpinAndGo:
            this.tableSort = { active: 'name', direction: 'desc' }

            break;

          default:
            break;
        }

        this.tableDb = data.slice()
        this.tableData = this.sortData(this.tableSort, this.search.value ? data.filter(el => el.Name.toLowerCase().includes(this.search.value.toLowerCase())) : data)
        this.lobbyLoader = false
      })


    this.managerService.activeGames$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (activeGames) => {
          // ## for mobile view
          this.navTables = Object.values(activeGames).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        }
      })

    this.managerService.lobbyStatsPlayer$
      .pipe(takeUntil(this.destroy$))
      .subscribe(lobbyStatsPlayer => {
        this.lobbyStatsPlayer = lobbyStatsPlayer

      })

    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {

          if (this.user && this.user.selectedCurrency.Id !== user.selectedCurrency.Id) {
            this.managerService.requestTableData(this.filterValue, user.selectedCurrency.Id);
          }
          this.user = JSON.parse(JSON.stringify(user));
          if (this.user?.settings?.LobbyClubLogo) {
            this.logoUrl = this.config.httpUrl + '/' + this.user.settings.LobbyClubLogo;
          } else {
            if (this.config.code === 'newwavepoker-cxzo' || this.config.code === 'newwavepokertest-kwwa') {
              this.logoUrl = `${environment.dataSource}/assets/${this.config.code}/logo.svg`;

            } else {
              this.logoUrl = `${environment.dataSource}/assets/${this.config.code}/logo.png`;

            }
          }
        }
      })


    this.managerService.tableSumsObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(tableSums => {
        this.selectedTableSum = tableSums.find(tableSum => tableSum.Id === this.selectedTableId);
        if (this.selectedTableSum?.Info?.Players) {
          this.selectedTableSum.players = this.selectedTableSum.Info.Players.filter(el => !!el)
        }
      })

    this.managerService.mixTableObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(mixTable => {
        this.selectedMixTable = mixTable
        this.asidePreview = 'mix'
        if (this.selectedTableSum?.Info?.Players) {
          this.selectedTableSum.players = this.selectedTableSum.Info.Players.filter(el => !!el)
        }
      })

    this.managerService.playerTickets$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tickets: Ticket[]) => {
        this.playerTickets = tickets;
      })


    this.gameFilters$ = this.managerService.lobbyFilter$
      .pipe(
        takeUntil(this.destroy$),
        pluck('filters'),
        switchMap((lobbyFilterOptions) => this.managerService.tableFilters$.pipe(
          map((tableFilters) => {
            if (tableFilters) {
              if (lobbyFilterOptions.TableType === 1) {
                return tableFilters.RingFilters
              }
              if (lobbyFilterOptions.TableType === 2) {
                return tableFilters.SitNGoFilters
              }
              if (lobbyFilterOptions.TableType === 4) {
                return tableFilters.TournamentFilters
              }
              if (lobbyFilterOptions.TableType === 16) {
                return tableFilters.SpinNGoFilters
              }
            }


            return {}
          })
        )),
        tap((d) => console.log("F:,", d))
      )

    this.managerService.lobbyFilter$
      .pipe(
        takeUntil(this.destroy$),
        pluck('filters')
      )
      .subscribe(lobbyFilterOptions => {
        console.log("@lobbyFilterOptions", lobbyFilterOptions)
        this.filterValue = lobbyFilterOptions;
        this.managerService.requestTableData(this.filterValue, this.user.selectedCurrency.Id);
        this.lobbyFilterOptions = lobbyFilterOptions;
        this.onChangeGameType(lobbyFilterOptions.TableType);
      })


    this.managerService.showPlayerPlus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showPlayerPlus = value;
      })
  }

  async playLobbySound(localStorageTheme) {
    const themes = this.config.themes

    if (themes) {
      const theme = themes.find(el => el.name === localStorageTheme)
      if (theme && theme.sound.lobby) {
        this.lobbySound = this.assetsLoader.sound[theme.name + '-lobby']
        this.lobbySound.stop();

        this.lobbySound.volume(0)
        this.lobbySound.play();
        this.lobbySound.fade(0, 1, 500)

      }
    }
  }

  openCashier() {
    this.managerService.setActiveTab(ENavItem.Cashier)
  }

  editProfile() {
    this.dialog.open(EditProfileComponent, {
      width: '300px',
      data: this.user.memberProfile
    })
  }

  openTournament(tournamentId) {
    this.managerService.setActiveTab(ENavItem.Tournament)
    this.managerService.tournamentView({ Id: tournamentId } as TournamentSummary);
  }

  playClickSound() {
    const themes = this.config.themes
    if (themes && themes.length > 0) {
      const theme = themes.find(el => el.name === localStorage.getItem('theme'))
      if (theme && theme.sound.click) {
        this.assetsLoader.sound[theme.name + '-click'].play()
      }
    }
  }

  loadSound(soundUrl: string): Promise<Howl> {
    return new Promise((resolve, reject) => {
      const sound = new Howl({ src: [soundUrl], loop: true });


      if (sound.state() === 'loaded') {
        resolve(sound);
      } else if (sound.state() === 'unloaded') {
        resolve(sound);
      } else {
        sound.once('load', () => {
          resolve(sound);
        })
      }

    });
  }

  onPlayerPlusOk() {
    this.dataManager.playerAlignmentInitiate()
  }

  onTableClick(navTableData: NavTableData) {
    this.mainService.hideSidenav();
    this.managerService.setActiveTab(ENavItem.Game);
    this.managerService.setActiveGame(navTableData.table.IdTable);
  }


  showCasinosInfo() {
    this.mainService.showCasino()
  }

  ngOnDestroy(): void {
    if (this.lobbySound) {

      this.lobbySound.fade(1, 0, 500)
      setTimeout(() => {
        this.lobbySound.stop();

      }, 500);
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  viewTournament(tableSelectedData: TournamentSummary, redirection?: boolean) {

    if (tableSelectedData.HasPassword && tableSelectedData.IsLocked && redirection) {
      this.dialogPasswordRef = this.dialog.open(PasswordDialogComponent);

      this.dialogPasswordRef.afterClosed().subscribe((password: string) => {

        if (password) {

          const subscription = this.managerService.tournamentInformationObservable$.subscribe((IdTournament: number) => {

            if (redirection) {
              this.managerService.setActiveTab(ENavItem.Tournament)
            }
            subscription.unsubscribe();
          });
          this.managerService.tournamentView(tableSelectedData, password);

          setTimeout(() => { subscription.unsubscribe(); }, 500);
        }

      });
    } else if (!tableSelectedData.HasPassword || !tableSelectedData.IsLocked) {
      this.managerService.tournamentView(tableSelectedData);
      if (redirection) {
        this.managerService.setActiveTab(ENavItem.Tournament)
      }
    }
  }

  // unlockGift() {
  //   this.managerService.unlockGift(this.dataManager.getGiftIdForUnlock(this.user, this.settings), this.user, this.settings);
  // }

  // ===================

  openGame(gameCode: string, name: string) {
    this.dataManager.getGameToken().pipe(
      map(response => response.token),
      switchMap(token => this.dataManager.getCasinoGameLaunchUrl(token, gameCode, this.user.selectedCurrency.Name, false))
    )
      .subscribe(response => {
        console.log("openGame", response)
        this.managerService.setActiveCasinoGame({ url: response.url, gameCode: response.gameCode, name })
      })
  }
  onOpenCategoryGames(categoryId: number) {
    this.reloadCategoryCasinoGames$.next(categoryId)

    this.showHome = false;
    this.showCasino = true;
    this.showCasinoCategory = true;
  }

  btnOnOpenHome() {
    this.showHome = true;
    this.showCasino = false;
    this.showCasinoCategory = false;
  }

  btnOnOpenCasino() {
    this.reloadAllCasinoGames$.next();

    this.showHome = false;
    this.showCasino = true;
    this.showCasinoCategory = false;
  }

  // ===================

  filterChange(type, value, index?: number) {
    this.showHome = false; this.showCasino = false
    console.log("filterChange", type, value, index)
    this.managerService.tournamentView(null)
    this.viewingTournament = null

    this.managerService.stopTournamentCountDown()

    switch (type) {
      case FilterType.GameType:
        this.lobbyLoader = true
        this.managerService.setLobbyFilterGameType(value)

        switch (value) {

          case LobbyFilters.TypeRing:
            this.tableSort = { active: 'ringPlayers', direction: 'desc' }
            this.selectedGameVariantFilter = 'ALL'
            this.managerService.setLobbyFilterVariant(null)

            break;
          case LobbyFilters.TypeSitnGo:
            this.tableSort = { active: 'sngPlayers', direction: 'desc' }
            this.selectedGameVariantFilter = 'ALL'
            this.managerService.setLobbyFilterVariant(null)

            break;
          case LobbyFilters.TypeTournament:
            this.tableSort = { active: 'scheduler', direction: 'asc' }

            this.selectedGameVariantFilter = 'ALL'
            this.managerService.setLobbyFilterVariant(null)

            break;
          case LobbyFilters.TypeSpinAndGo:
            this.tableSort = { active: 'name', direction: 'desc' }
            this.selectedGameVariantFilter = 'ALL'

            this.managerService.setLobbyFilterVariant(null)


            break;

          default:
            break;
        }
        break;
      case FilterType.Variant:
        if (value === 'ALL') { value = null }
        this.managerService.setLobbyFilterVariant(Array.isArray(value) ? value : [value])
        break;
      case FilterType.Limit:
        this.managerService.setLobbyFilterLimit(value)
        break;
      case FilterType.Stakes:
        this.managerService.setLobbyFilterStake(value)
        break;
      case FilterType.TableOption:
        this.managerService.setLobbyFilterTableOptions(value)
        break;
      case FilterType.Speed:
        this.managerService.setLobbyFilterSpeed(value)
        break;

      default:
        break;
    }
  }

  onChangeGameType(lobbyFilter: LobbyFilters) {
    this.search.setValue('', { emitEvent: false })

    switch (lobbyFilter) {

      case LobbyFilters.TypeRing:
        this.managerService.stopTournamentCountDown();
        this.selectedTableSum = undefined;
        this.selectedTableId = undefined;
        this.selectedMixTable = undefined;
        this.asidePreview = 'previewRingGames'

        break;
      case LobbyFilters.TypeSitnGo:

        this.managerService.stopTournamentCountDown();
        this.selectedTableSum = undefined;
        this.selectedTableId = undefined;
        this.selectedMixTable = undefined;
        this.asidePreview = 'previewSitNGo'

        break;
      case LobbyFilters.TypeTournament:
        this.managerService.startTournamentCountDown();
        this.selectedTableSum = undefined;
        this.selectedTableId = undefined;
        this.selectedMixTable = undefined;
        this.asidePreview = 'previewTourney'

        break;
      case LobbyFilters.TypeSpinAndGo:
        this.managerService.startTournamentCountDown();
        this.selectedTableSum = undefined;
        this.selectedTableId = undefined;
        this.selectedMixTable = undefined;
        this.asidePreview = 'previewSpinNGo'

        break;
    }
  }



  mainFilterClick() {
    this.mainService.toggleRightSidenav();
  }

  isObject(o) {
    return null != o &&
      typeof o === 'object' &&
      Object.prototype.toString.call(o) === '[object Object]';
  }

  onChatOpenClose($event: boolean) {
    this.lobbyChatOpen = $event
  }

  sortChange($event) {
    // TO DO
  }
  sortData(sort: TableDataSort, arr: any[]) {

    const data = arr.slice();
    if (!sort.active || sort.direction === '') {
      return data;
    }
    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tableId': return Comparators.compare(+a.Id, +b.Id, isAsc);
        case 'variant': return Comparators.compare(a.tableVariant, b.tableVariant, isAsc);
        case 'speed': return Comparators.compare(a.tableSpeed, b.tableSpeed, isAsc);
        case 'ringPlayers': return this.managerService.useSmartSort ?
          Comparators.smartSortingPlayerComparator(a, b)
          : Comparators.playerComparator(+a.PlayerCount, +b.PlayerCount, isAsc);
        case 'tourPlayers': return Comparators.compare(+a.PlayerCount, +b.PlayerCount, isAsc);
        case 'sngPlayers': return Comparators.sngPlayerComparator(a.tablePlayers, b.tablePlayers);
        case 'wait': return Comparators.compare(+a.tableWait, +b.tableWait, isAsc);
        case 'stakes': return Comparators.compare(+a.Blind, +b.Blind, isAsc);
        case 'buyIn': return Comparators.compare(+a.EntryFee, +b.EntryFee, isAsc);
        case 'limit': return Comparators.compare(a.tableLimit, b.tableLimit, isAsc);
        case 'name': return Comparators.compare(a.Name, b.Name, isAsc);
        case 'date': return Comparators.dateComparator(a.DateStarting, b.DateStarting, isAsc);
        case 'scheduler': return Comparators.dateComparator(a.DateStarting, b.DateStarting, isAsc);

        case 'averagePot': return Comparators.compare(a.AveragePot, b.AveragePot, isAsc);
        case 'guarantee': return Comparators.compare(a.PrizeGuaranteed, b.PrizeGuaranteed, isAsc);


        default: return 0;
      }
    });

    return sortedData;
  }

  preview() {
    switch (this.lobbyFilterOptions.TableType) {
      case LobbyFilters.TypeRing:
        this.asidePreview = 'previewRingGames'
        break;
      case LobbyFilters.TypeSitnGo:
        this.asidePreview = 'previewSitNGo'
        break;
      case LobbyFilters.TypeTournament:
        this.asidePreview = 'previewTourney'
        break;
      case LobbyFilters.TypeSpinAndGo:
        this.asidePreview = 'previewSpinNGo'
        break;
    }
  }

  onTableRowClick(row: any): void {
    switch (this.lobbyFilterOptions.TableType) {
      case LobbyFilters.TypeRing:
        this.selectedTableId = row.Id;
        if (window.innerWidth < 1280) {
          this.managerService.gamestartFromTableSummeries(row);
        } else {
          this.dataManager.getTableDetail(row.Id);
          this.selectedMixTable = null
          this.asidePreview = 'previewRingGames'

          if (row.isMixTable) {
            this.dataManager.getMixTablesDetails(row.Id)
          }
        }

        break;
      case LobbyFilters.TypeSitnGo:

        this.selectedTableId = row.Id;
        if (window.innerWidth < 1280) {
          this.viewTournament(row, true);
        } else {
          this.asidePreview = 'previewSitNGo'
          this.selectedTableSum = row
          this.viewingTournament = new Tournament()
          this.viewingTournament.tourSum = row
          this.viewTournament(row);

        }

        break;
      case LobbyFilters.TypeTournament:
        this.selectedTableId = row.Id;
        if (window.innerWidth < 1280) {
          this.viewTournament(row, true);
        } else {
          this.asidePreview = 'previewTourney'
          this.selectedTableSum = row
          this.viewingTournament = new Tournament()
          this.viewingTournament.tourSum = row
          this.viewTournament(row);

        }

        break;


      case LobbyFilters.TypeSpinAndGo:
        this.selectedTableId = row.Id;
        if (window.innerWidth < 1280) {
          this.viewTournament(row, true);
        } else {
          this.asidePreview = 'previewSpinNGo'
          this.selectedTableSum = row
          this.viewingTournament = new Tournament()
          this.viewingTournament.tourSum = row
          this.viewTournament(row);

        }

        break;



    }
  }


  startRingGame(tableSummary: TableSummary) {
    this.managerService.gamestartFromTableSummeries(tableSummary);
  }



  openLegendSymbol() {
    this.dialog.open(DialogLegendSymbol, {
      width: '300px'
    })
  }

  openFilters() {
    this.mainService.toggleRightSidenav()
  }


  openCallTimeInfo(row) {
    const dialogRef = this.dialog.open(GenericTextDialog, { width: '300px', disableClose: false });

    dialogRef.componentInstance.textHeader = 'Call Time';
    dialogRef.componentInstance.textBody = `
<div><strong>> +${row.CallTimeConfiguration.CallTimePercent}%</strong></div>
<div>${row.CallTimeConfiguration.CallTimeDuration}min</div>
`;

    dialogRef.componentInstance.enableClose = false;

    dialogRef.componentInstance.enableAccept = false;

  }

  openJackpotInfo(data) {
    console.log("openJackpotInfo", this.user.selectedCurrency)
    this.managerService.jackPotsInfo$
      .pipe(take(1))
      .subscribe(jackPotsInfo => {

        let jackpot: any;
        for (const item of jackPotsInfo) {
          if (item.Id === data.Id) {
            jackpot = item
            jackpot.prizePool = CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(item.PrizePool, this.user.selectedCurrency), this.user.selectedCurrency)
          }
        }



        const dialogRef = this.dialog.open(GenericTextDialog, { width: '300px', disableClose: false });

        dialogRef.componentInstance.textHeader = jackpot.Name;
        dialogRef.componentInstance.textBody = `
    <div>${jackpot.prizePool}</div>
    <div>${jackpot.Description}</div>
    `;

        dialogRef.componentInstance.textDismissBtn = `OK`

        dialogRef.componentInstance.enableAccept = false;
      })


  }




  //++++


  confirmCasinoForm() {
    this.processCasino()
  }

  processCasino() {
    this.mainService.openCasinoFromLobby(this.casinoBuyInAmount, this.casinoProduct)
  }



  lobbyV2Holdem() {

    this.selectedGameVariantFilterControl.setValue(LobbyFilters.VariantHoldem)

    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;
  }
  lobbyV2SitAndGo() {
    this.selectedGameVariantFilterControl.setValue('ALL')

    this.filterChange(FilterType.GameType, 2)
    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;
  }
  lobbyV2SpinAndGo() {
    this.selectedGameVariantFilterControl.setValue('ALL')

    this.filterChange(FilterType.GameType, 16)
    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;
  }
  lobbyV2Tourney() {
    this.selectedGameVariantFilterControl.setValue('ALL')

    this.filterChange(FilterType.GameType, 4)
    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;
  }

  lobbyV2Omaha() {
    this.selectedGameVariantFilterControl.setValue(LobbyFilters.VariantOmaha)

    // this.filterChange(FilterType.GameType, 1)
    // this.filterChange(FilterType.Variant, [
    //   LobbyFilters.VariantOmaha,
    //   // LobbyFilters.VariantOmahaHiLow,
    //   // LobbyFilters.VariantOmaha3,
    //   // LobbyFilters.VariantOmaha5,
    //   // LobbyFilters.VariantOmaha5HiLow,
    //   // LobbyFilters.VariantOmaha6,
    //   // LobbyFilters.Omaha7,
    //   // LobbyFilters.CapOmaha,
    //   // LobbyFilters.CapOmaha5,
    //   // LobbyFilters.CapOmaha6,
    //   // LobbyFilters.CapOmaha7,
    //   // LobbyFilters.VariantTriOmaha
    // ])
    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;

    //  this.ref.detectChanges()
  }

  lobbyV2Home() {
    this.lobbyV2Tab = true;
    this.lobbyV1Tab = false;
  }

  lobbyV2Poker() {
    this.lobbyV2Tab = false;
    this.lobbyV1Tab = true;
  }

  lobbyV2Cashier() {
    this.managerService.setActiveTab(ENavItem.Cashier)
  }

  lobbyV2Profile() {
    this.dialog.open(EditProfileComponent, {
      width: '300px',
      data: this.user.memberProfile
    })
  }
  openCasinoGame(game) {
    this.managerService.setActiveCasinoGame(game)
  }
}
