import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { ManagerService } from '../../../core/services/manager.service';
import { User } from '../../../shared/models/user';

@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.component.html',
  styleUrls: ['./transfer-history.component.scss']
})
export class TransferHistoryComponent implements OnInit, OnDestroy {

  user: User;
  destroy$ = new Subject<boolean>();

  getTransactionHistory = false;

  constructor(
    private managerService: ManagerService,
    private dataManagerService: DataManagerService

  ) { }

  ngOnInit(): void {

    this.managerService.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: user => {
          this.user = user
          if(!this.getTransactionHistory){
            this.getTransactionHistory = true
            this.dataManagerService.getTransactionsHistory(this.user.selectedCurrency.Id);

          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  cancelTransaction() {
    this.dataManagerService.sendCancelWithdrawal(this.user.selectedCurrency.Id);
  }
}
